import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CourseFilesPage from "./CourseFilesPage";
import { getCourseById } from "../../selectors/courseSelectors";
import { fetchCourseFiles } from "../../processes/courseFileProcesses";
import { fetchCourse } from "../../processes/courseProcesses";
import { getFilesByCourseId } from "../../selectors/courseFileSelectors";

function CourseFilesPageContainer(props) {
  const { dispatch, courseId } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!courseId) return;
    fetchCourse(dispatch, courseId);

    setLoading(true);
    fetchCourseFiles(dispatch, courseId).finally(() => {
      setLoading(false);
    });
  }, [dispatch, courseId]);

  return <CourseFilesPage loading={loading} {...props} />;
}

function mapStateToProps(state, ownProps) {
  const { courseId } = ownProps.match.params;
  const course = getCourseById(state, courseId);

  return {
    course,
    courseId,
    files: getFilesByCourseId(state, courseId),
  };
}

export default connect(mapStateToProps)(CourseFilesPageContainer);
