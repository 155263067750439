import { normalize, schema } from "normalizr";
import { apiGet, apiPost, baseUrl } from "./helpers/api";
import { classroomSchema } from "./classroomsProcesses";
import middleware from "./helpers/railsMiddleware";
import { post } from "superagent";

const companySchema = new schema.Entity("companies", {
  meta: { classrooms: [classroomSchema] },
});

export const fetchCompany = (dispatch, companyId) => {
  if (!companyId) return;

  return apiGet(`/api/v1/companies/${companyId}`).then((response) => {
    dispatch({
      type: "COMPANY_FETCHED",
      ...normalize(response.body.data, companySchema),
    });
  });
};

export const createClassroom = (values, dispatch, { company, handleClose }) => {
  if (!company) return;

  return apiPost(`/api/v1/companies/${company?.id}/classrooms`)
    .send(values)
    .then((response) => {
      dispatch({
        type: "COMPANY_FETCHED",
        ...normalize(response.body.data, companySchema),
      });
      handleClose();
    });
};

export const importStudents = (
  { file, classroomId, courseId },
  dispatch,
  { company, handleClose }
) => {
  if (!company || !classroomId || !courseId) return;

  const req = post(
    `${baseUrl}/api/v1/companies/${company?.id}/classrooms/${classroomId}/import_students`
  )
    .use(middleware)
    .attach("file", file)
    .field("classroom_id", classroomId)
    .field("course_id", courseId);

  return req.then((response) => {
    dispatch({
      type: "COMPANY_FETCHED",
      ...normalize(response.body.data, companySchema),
    });
    handleClose();
  });
};
