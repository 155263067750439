import { css } from "glamor";
import React from "react";
import { Menu } from "semantic-ui-react";
import { DropDownField } from "../library/forms";
import { colors, spacing } from "../library/theme";
import CourseMenuItemContainer from "./CourseMenuItemContainer";
import { makeIsMobile } from "../../utils/useScreenWidth";
import makeDropDownOptions from "../../utils/makeDropDownOptions";

const container = css({
  width: "100%",
  backgroundColor: colors.white,
});

const filtersWrapper = css({
  display: "flex",
  justifyContent: "space-between",
  gap: spacing.xs,
  "@media(max-width: 992px)": {
    gap: 0,
    flexDirection: "column",
  },
});

const menuWrapperClass = css({
  position: "relative",
  marginBottom: spacing.m,
  "> .ui.menu": {
    marginBotom: "none !important",
  },
});

const menuClass = css({
  flexWrap: "wrap",
  gap: spacing.xxs,
  borderBottom: "none !important",
  "@media(max-width: 767px)": {
    paddingBottom: spacing.s,
  },
}).toString();

const dropdownOptionsWrapper = css({ minWidth: 300, lineHeight: 1 });

const finishedOptions = [
  { text: "Finalizado", value: false },
  { text: "Em andamento", value: true },
];

export default function FilterForm({
  courses,
  courseMenuOptions,
  selectedCourse,
  setSelectedCourse,
}) {
  const isMobile = makeIsMobile();

  return (
    <div className={container}>
      <div className={filtersWrapper}>
        <div className={menuWrapperClass}>
          {isMobile ? (
            <div className={dropdownOptionsWrapper}>
              <DropDownField
                name="courseIdEq"
                options={makeDropDownOptions(courses, "Todos os cursos")}
                placeholder="Todos os cursos"
                fluid
              />
            </div>
          ) : (
            <Menu
              secondary
              fluid
              pointing
              className={menuClass}
              items={courseMenuOptions.map((course) => (
                <CourseMenuItemContainer
                  course={course}
                  selectedCourse={selectedCourse}
                  setSelectedCourse={setSelectedCourse}
                />
              ))}
            />
          )}
        </div>

        <div className={dropdownOptionsWrapper}>
          <DropDownField
            name="finishedAtNull"
            options={finishedOptions}
            clearable
            innerLabel="STATUS"
            fluid
          />
        </div>
      </div>
    </div>
  );
}
