import { css } from "glamor";
import React from "react";
import formatDate from "../../../utils/formatDate";
import Link from "../../library/Link";
import Progress from "../../library/Progress";
import { colors, spacing } from "../../library/theme";

const container = css({
  padding: spacing.m,
  display: "flex",
  flexDirection: "column",
  gap: spacing.m,
  border: `1px solid ${colors.greyDark}`,
  marginBottom: spacing.m,
  borderRadius: spacing.s,
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
});
const infoClass = css({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: spacing.s,
  textAlign: "left",
  lineHeight: 1,
  "& + &": {
    marginLeft: spacing.xs,
    borderLeft: `1px solid ${colors.greyLight}`,
    paddingLeft: spacing.xs,
  },
});
const infoTitleClass = css({
  color: colors.greyShadow,
});
const infoValueClass = css({
  color: colors.black,
  fontWeight: "bold",
  textTransform: "uppercase",
  fontSize: 16,
});
const infoWrapperClass = css({
  flex: 1,
  display: "flex",
  justifyContent: "space-between",
});
const progressWrapper = css({
  display: "flex",
  alignItems: "center",
});
const progressClass = css({
  flex: 1,
  marginBottom: "0px !important",
  marginLeft: `${spacing.xs} !important`,
  ".ui.progress .bar": {
    backgroundColor: `${colors.primary} !important`,
  },
}).toString();

export default function MockListItem({ mock, routePrefix = "" }) {
  const completed = mock?.exam ? mock?.finishedAt : mock.progress >= 100;

  return mock ? (
    <Link to={`${routePrefix}/mocks/${mock.id}`} className={container}>
      <div className={infoClass}>
        <div className={infoTitleClass}>
          {completed ? "Finalizado" : "Em andamento"}
        </div>
        <div className={infoValueClass}>{mock?.title}</div>
      </div>

      <div className={infoWrapperClass}>
        <div className={infoClass}>
          <div className={infoTitleClass}>Data</div>
          <div className={infoValueClass}>
            {formatDate(mock.createdAt, "DD/MM/YYYY")}
          </div>
        </div>

        <div className={infoClass}>
          <div className={infoTitleClass}>Questões</div>
          <div className={infoValueClass}>{mock.questionIds.length}</div>
        </div>

        <div className={infoClass}>
          <div className={infoTitleClass}>
            {completed ? "Nota" : "Progresso"}
          </div>
          <div className={infoValueClass}>
            {completed ? (
              `${mock.average.toFixed(0)}%`
            ) : (
              <div className={progressWrapper}>
                <div>{Math.round(mock.progress)}%</div>
                <Progress
                  percent={mock.progress}
                  className={progressClass}
                  size="tiny"
                  fluid
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  ) : null;
}
