import React from "react";
import { css } from "glamor";
import LessonListItemContainer from "./LessonListItemContainer";
import { spacing, uiColors } from "../library/theme";

const titleClass = css({
  color: uiColors.backgroundDark,
  textTransform: "uppercase",
  fontWeight: "bold",
  marginTop: spacing.m,
});

export default function SubLevelListItem({ level, lessons }) {
  return (
    <>
      <h5 className={titleClass}>{level.name}</h5>
      {lessons?.map((lesson) => (
        <LessonListItemContainer key={lesson.id} lesson={lesson} />
      ))}
    </>
  );
}
