import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchMock, finishMock } from "../../../processes/mockProccess";
import {
  getCourseFromMock,
  hasMockCourses,
} from "../../../selectors/courseSelectors";
import { getMockPreferenceByName } from "../../../selectors/mockPreferencesSelectors";
import {
  getMock,
  makeIsComplete,
  showAnswer,
} from "../../../selectors/mockSelectors";
import Mock from "./Mock";
import { replace } from "connected-react-router";

function MockContainer(props) {
  const { dispatch, mock, isComplete } = props;
  const { mockId } = props.match.params;

  useEffect(() => {
    if (!mock) fetchMock(dispatch, mockId);
  }, [dispatch, mockId, mock]);

  useEffect(() => {
    if (isComplete && !mock?.finishedAt && !mock?.exam) {
      finishMock(dispatch, mock?.id).then(() => {
        dispatch(replace(`/mobile/mocks/${mock?.id}/results`));
      });
    }
  }, [dispatch, isComplete, mock]);

  return <Mock {...props} />;
}

function mapStateToProps(state) {
  const mock = getMock(state);

  return {
    mock,
    showAnswer: showAnswer(state),
    showReport: getMockPreferenceByName(state, "showReport"),
    course: getCourseFromMock(state),
    isComplete: makeIsComplete(state),
    hasMockCourses: hasMockCourses(state),
  };
}

export default connect(mapStateToProps)(MockContainer);
