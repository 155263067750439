import { css } from "glamor";
import React from "react";
import { FaRegComments } from "react-icons/fa";
import { colors, spacing, uiColors } from "../library/theme";
import QuestionNavigationContainer from "./QuestionNavigationContainer";
import QuestionRepliesListContainer from "./QuestionRepliesListContainer";
import QuestionTitle from "./QuestionTitle";
import ScaleupPlayer from "../library/ScaleupPlayer";
import VimeoPlayer from "../library/VimeoPlayer";

const commentClass = css({
  margin: `${spacing.m} 0`,
  paddingBottom: spacing.m,
  borderBottom: `1px solid ${uiColors.border}`,
});
const commentTitle = {
  marginBottom: spacing.s,
};
const icon = {
  verticalAlign: "top",
  color: colors.primaryDarker,
  marginRight: spacing.xs,
};

export default function Question(props) {
  const {
    question,
    replied,
    answered,
    readOnly,
    showMockAnswer,
    lesson,
    selectedReply,
    mockPreferences,
    routePrefix,
  } = props;
  const mockMode = !readOnly || !lesson;

  const questionCommentClass = css(
    commentClass,
    readOnly && {
      borderBottom: "none",
      paddingBottom: "0",
      marginBottom: "0",
    }
  );

  const showQuestionVideo =
    ((answered && (showMockAnswer || lesson)) || readOnly) &&
    (question?.vimeoUrl || question?.scaleupUrl) &&
    (mockPreferences?.showVideo || readOnly);

  return question ? (
    <div>
      <QuestionTitle {...props} />
      <QuestionRepliesListContainer
        questionId={question.id}
        replied={lesson ? selectedReply : replied}
        readOnly={readOnly}
        lesson={lesson}
      />
      {!readOnly && (
        <QuestionNavigationContainer
          lesson={lesson}
          routePrefix={routePrefix}
        />
      )}
      {showQuestionVideo && (
        <div className={questionCommentClass}>
          <h3 style={commentTitle}>
            <FaRegComments size={22} style={icon} /> Comentário da Questão
          </h3>

          {question?.scaleupUrl ? (
            <ScaleupPlayer url={question.scaleupUrl} />
          ) : (
            <>
              {!!question?.vimeoUrl && <VimeoPlayer url={question.vimeoUrl} />}
            </>
          )}
        </div>
      )}
    </div>
  ) : null;
}
