import { normalize, schema } from "normalizr";
import { SubmissionError } from "redux-form";
import { apiGet } from "./helpers/api";

const coupon = new schema.Entity("coupons");

export function searchCoupon({ code }, dispatch, { offer }) {
  return apiGet(`/api/v1/public/course_offers/${offer?.id}/coupons/${code}`)
    .then((response) => {
      dispatch({
        type: "COUPONS_FETCHED",
        ...normalize(response.body.data, coupon),
      });
    })
    .catch((err) => {
      throw new SubmissionError({
        _error: err.response.body.errors,
      });
    });
}
