const initialState = {
  lessons: [],
  mocks: [],
};

export default function continueReducer(state = initialState, action) {
  switch (action.type) {
    case "CONTINUE_LESSONS_FETCHED":
      return { ...state, lessons: action?.lessons };
    case "CONTINUE_MOCKS_FETCHED":
      return { ...state, mocks: action?.mocks };
    default:
      return state;
  }
}
