import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

const content = new schema.Entity("contents");

export const fetchContents = (dispatch) => {
  dispatch({ type: "CONTENTS_FETCH_REQUESTED" });
  apiGet("/api/v1/contents/").then((response) => {
    dispatch({
      type: "CONTENTS_FETCHED",
      ...normalize(response.body.data, new schema.Array(content)),
    });
  });
};
