import React from "react";
import { css } from "glamor";
import { spacing, styles, uiColors } from "../library/theme";
import UrlPreview from "./UrlPreview";
import ContentCommentReplyFormContainer from "./ContentCommentReplyFormContainer";
import ContentReplyListItemContainer from "./ContentReplyListItemContainer";
import UserHeaderContainer from "./UserHeaderContainer";
import { Dropdown, Label } from "semantic-ui-react";
import ContentLikeFormContainer from "./ContentLikeFormContainer";
import Link from "../library/Link";
import Button from "../library/Button";
import truncateString from "../../utils/truncateString";
import { makeIsMobile } from "../../utils/useScreenWidth";
import ContentCommentEditFormContainer from "./ContentCommentEditFormContainer";

const container = css(styles.border, styles.roundedCorners, {
  marginBottom: spacing.s,
  display: "flex",
  flexDirection: "column",
  padding: spacing.s,
  backgroundColor: uiColors.fieldBackground,
});
const commentImage = css({
  width: "100%",
  height: 400,
  marginBottom: spacing.s,
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  border: `1px solid ${uiColors.border}`,
});
const textClass = css(styles.bodyText, {
  flex: 1,
  padding: `${spacing.s} 0`,
  whiteSpace: "pre-line",
  wordBreak: "break-word",
  width: "100%",
  paddingTop: spacing.s,
  ">iframe": {
    ...styles.roundedCorners,
    maxWidth: "100%",
  },
});
const titleClass = css({
  marginTop: spacing.m,
});
const categoriesWrapper = css({
  marginBottom: spacing.s,
});
const mobileBannerClass = css({
  maxWidth: "100%",
});
const bannerModeClass = css({
  flex: 1,
  textAlign: "initial",
  maxWidth: "calc(50% - 10px)",
  margin: 0,
  ".ui.segment": {
    margin: 0,
  },
});
const repliesWrapper = css(styles.roundedCorners, {
  margin: `${spacing.m} 0 0 0`,
});
const actionLine = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: spacing.m,
};
const formStyle = {
  marginTop: spacing.s,
};

export default function ContentCommentListItem({
  comment,
  replies,
  currentUserId,
  deleteHandler,
  categories,
  banner = false,
  isEditMode,
  toogleIsEditMode,
}) {
  const { createdAt, text, commentableId, likeableCount, id, imageUrl } =
    comment;
  const user = comment.meta.user;
  const isMobile = makeIsMobile();

  return (
    <div
      className={css(
        container,
        banner && bannerModeClass,
        isMobile && mobileBannerClass,
      ).toString()}
    >
      <UserHeaderContainer
        createdAt={createdAt}
        user={user}
        actions={
          user.id == currentUserId && (
            <>
              <Dropdown.Item onClick={toogleIsEditMode}>Editar</Dropdown.Item>
              <Dropdown.Item onClick={deleteHandler}>Apagar</Dropdown.Item>
            </>
          )
        }
      />
      {isEditMode ? (
        <ContentCommentEditFormContainer
          comment={comment}
          toogleIsEditMode={toogleIsEditMode}
        />
      ) : (
        <>
          <div
            className={textClass}
            dangerouslySetInnerHTML={{
              __html: banner ? truncateString(text, 140) : text,
            }}
          ></div>
          {!imageUrl && !banner && <UrlPreview text={text} />}
          {imageUrl ? (
            <div
              className={css(commentImage, {
                backgroundImage: `url(${imageUrl})`,
              })}
            ></div>
          ) : null}
          {categories?.length > 0 && comment?.commentableType == "Content" && (
            <div className={categoriesWrapper}>
              {categories.map((category) => (
                <Link
                  to={`/contents/${comment?.commentableId}/feed?categoryId=${category.id}`}
                  component={Label}
                  key={`${id}${category.name}`}
                  size="small"
                >
                  {category.name}
                </Link>
              ))}
            </div>
          )}
          <div style={actionLine}>
            <ContentLikeFormContainer
              commentId={id}
              likesCount={likeableCount}
            />
            {banner && (
              <Link
                to={`/publications/${comment?.id}`}
                component={Button}
                size="mini"
              >
                Ver Mais
              </Link>
            )}
          </div>

          {!banner && (
            <>
              {replies.length > 0 ? (
                <div className={repliesWrapper}>
                  <h3 className={titleClass}>Comentários</h3>
                  {replies.map((reply) => (
                    <ContentReplyListItemContainer
                      key={`reply${reply.id}`}
                      reply={reply}
                    />
                  ))}
                </div>
              ) : null}
              <div style={formStyle}>
                <ContentCommentReplyFormContainer
                  form={`ReplyComment${comment?.id}Form`}
                  key={`ReplyComment${comment?.id}Form`}
                  comment={comment}
                  contentId={commentableId}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
