import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import MockModal from "./MockModal";
import { makeIsMobile } from "../../utils/useScreenWidth";

function MockModalContainer(props) {
  const { dispatch, history } = props;

  const isMobile = makeIsMobile();

  const handleClose = useCallback(() => {
    history?.goBack();
  }, [history?.goBack]);

  const [firstOption, setFirstOption] = useState(false);
  const [secondOption, setSecondOption] = useState(false);

  const setFirstOptionHandler = useCallback(() => {
    setFirstOption(true);
    setSecondOption(false);
  }, []);

  const setSecondOptionHandler = useCallback(() => {
    setFirstOption(false);
    setSecondOption(true);
  }, []);

  return (
    <MockModal
      isMobile={isMobile}
      handleClose={handleClose}
      firstOption={firstOption}
      secondOption={secondOption}
      setFirstOptionHandler={setFirstOptionHandler}
      setSecondOptionHandler={setSecondOptionHandler}
      {...props}
    />
  );
}

export default connect()(MockModalContainer);
