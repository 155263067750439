import React, { useEffect } from "react";
import { connect } from "react-redux";
import NetworkingProfile from "./NetworkingProfile";
import {
  getCurrentUserId,
  getUserById,
  getUserCourseNames,
} from "../../selectors/userSelectors";
import { fetchUser } from "../../processes/userProcesses";
import { getConfigByNameAndId } from "../../selectors/configSelectors";
import { fetchCities } from "../../processes/configProcesses";

function NetworkingProfileContainer(props) {
  const { dispatch, id, user } = props;

  useEffect(() => {
    fetchUser(dispatch, id);
    !!user?.stateId && fetchCities(dispatch, user?.stateId);
  }, [dispatch, id, user?.stateId]);

  return <NetworkingProfile {...props} />;
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps.match.params;
  const user = getUserById(state, id);

  return {
    user,
    id,
    state: getConfigByNameAndId(state, "states", [user?.stateId]),
    city: getConfigByNameAndId(state, "cities", [user?.cityId]),
    courseNames: getUserCourseNames(state, id),
    currentUserId: getCurrentUserId(state),
  };
}

export default connect(mapStateToProps)(NetworkingProfileContainer);
