import React, { useCallback, useRef } from "react";
import { createTextMask } from "redux-form-input-masks";
import { Form, Grid } from "semantic-ui-react";
import { Button, colors, spacing } from "../library";
import {
  ImageField,
  NameField,
  PhoneField,
  TextAreaField,
  TextField,
} from "../library/forms";
import ProfileEditPage from "./ProfileEditPage";

import { css } from "glamor";
import SubmitButton from "./SubmitButton";
import EmailField from "../library/forms/EmailField";

const cpfMask = createTextMask({
  pattern: "999.999.999-99",
  guide: false,
  allowEmpty: true,
});

const imageWrapper = {
  display: "flex",
  alignItems: "center",
  gap: spacing.m,
  height: "50px",
};

const whiteButton = {
  background: colors.white,
  border: `1px solid ${colors.grey}`,
};

const container = css({
  padding: `${spacing.l} !important`,
  background: colors.white,
  borderRadius: spacing.s,
  marginBottom: `${spacing.m} !important`,
});

export default function ProfileEditPersonalForm(props) {
  const { handleSubmit, error, countryCodeOptions } = props;

  const avatarRef = useRef();

  const clickImageFieldHandler = useCallback(() => {
    avatarRef.current.click();
  }, []);

  return (
    <ProfileEditPage
      currentUser={props?.currentUser}
      shouldBlockNavigation={props?.dirty}
    >
      <Form onSubmit={handleSubmit} error={error}>
        <div className={container}>
          <Grid>
            <Grid.Column mobile={16}>
              <div style={imageWrapper}>
                <ImageField
                  name="avatarUrl"
                  height={50}
                  width={50}
                  inputRef={avatarRef}
                />
                <div>
                  <Button
                    type="button"
                    circular={false}
                    style={whiteButton}
                    onClick={clickImageFieldHandler}
                  >
                    Carregar nova foto
                  </Button>
                </div>
              </div>
            </Grid.Column>

            <Grid.Column computer={8} mobile={16}>
              <NameField
                name="name"
                label="Nome"
                placeholder="Seu nome"
                required
                fluid
              />
            </Grid.Column>

            <Grid.Column computer={8} mobile={16}>
              <EmailField
                name="email"
                label="E-mail"
                placeholder="E-mail..."
                disabled
                required
              />
            </Grid.Column>

            <Grid.Column computer={8} mobile={16}>
              <TextField
                name="document"
                label="CPF"
                placeholder="CPF"
                {...cpfMask}
                required
                fluid
              />
            </Grid.Column>

            <Grid.Column computer={8} mobile={16}>
              <PhoneField
                name="phone"
                label="Celular (WhatsApp)"
                countryCodeOptions={countryCodeOptions}
                countryCodeFieldName="phoneCountryCodeId"
                required
                fluid
              />
            </Grid.Column>

            <Grid.Column mobile={16}>
              <TextAreaField
                name="bio"
                label="Bio"
                placeholder="Fale um pouco sobre você..."
                rows={5}
                fluid
              />
            </Grid.Column>
          </Grid>
        </div>

        <SubmitButton {...props} />
      </Form>
    </ProfileEditPage>
  );
}
