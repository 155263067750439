import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import MockPreferencesModal from "./MockPreferencesModal";
import { getMockPreferences } from "../../selectors/mockPreferencesSelectors";
import { getCurrentMock } from "../../selectors/mockSelectors";

function MockPreferecencesContainer(props) {
  const { dispatch, history, mock } = props;

  const togglePreference = useCallback(
    (preference) => () => {
      dispatch({ type: "MOCK_PREFERENCE_TOGGLED", preference });
    },
    [dispatch]
  );

  return (
    <MockPreferencesModal togglePreference={togglePreference} {...props} />
  );
}

function mapStateToProps(state) {
  const mock = getCurrentMock(state);

  return {
    mock,
    mockPreferences: getMockPreferences(state),
  };
}

export default connect(mapStateToProps)(MockPreferecencesContainer);
