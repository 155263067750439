import React from "react";
import { css } from "glamor";
import { Card } from "semantic-ui-react";
import truncateString from "../../utils/truncateString";
import Link from "../library/Link";

const firstAndLastName = (str) => {
  const [firstName, ...rest] = str.split(" ");
  const lastName = rest.length ? rest.pop() : "";
  return `${firstName} ${lastName}`;
};
const nameStyle = {
  textTransform: "capitalize",
};
const imageContainer = css({
  height: 0,
  paddingBottom: "100%",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
});

export default React.memo(function NetworkingUserListItem({ user }) {
  const { id, avatarUrl, firstName, lastName, bio } = user;
  return (
    <Card as={Link} to={`/networking/${id}`}>
      <div
        className={css(imageContainer, {
          backgroundImage: `url(${avatarUrl})`,
        })}
      ></div>
      <Card.Content>
        <Card.Header style={nameStyle}>
          {`${firstName} ${lastName ? lastName : ""}`}
        </Card.Header>
        {user.bio && (
          <Card.Description>{truncateString(bio, 70)}</Card.Description>
        )}
      </Card.Content>
    </Card>
  );
});
