import { createSelector } from "reselect";
import orderByPosition from "./helpers/orderByPosition";
import { getCurrentMockId } from "./mockSelectors";

export const getReplies = (state) => state.entities.replies;
const getQuestionId = (_, id) => id;
const getDiscardReplies = (state) => state.discardReplies;

export const getRepliesFromQuestion = createSelector(
  getReplies,
  getQuestionId,
  (replies, questionId) =>
    Object.values(replies)
      .filter((reply) => reply.questionId === questionId)
      .sort(orderByPosition)
);

export const makeDiscardReply = createSelector(
  getDiscardReplies,
  getQuestionId,
  getCurrentMockId,
  (discardReplies, id, mockId) => {
    return !!discardReplies.filter(
      (dA) => dA.replyId == id && dA.mockId == mockId
    )[0];
  }
);
