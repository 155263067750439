import React, { useCallback, useState } from "react";
import { signOut } from "../../processes/sessionProcesses";
import Header from "./Header";

export default function HeaderContainer(props) {
  const { dispatch } = props;
  const [appSidebarVisible, setAppSidebarVisible] = useState(false);

  const toggleAppSidebarVisibility = useCallback(() => {
    setAppSidebarVisible(!appSidebarVisible);
  }, [appSidebarVisible]);

  const [userSidebarVisible, setUserSidebarVisible] = useState(false);
  const toggleUserSidebarVisibility = useCallback(() => {
    setUserSidebarVisible(!userSidebarVisible);
  }, [userSidebarVisible]);

  const signOutHandler = useCallback(() => signOut(dispatch), [dispatch]);

  return (
    <Header
      appSidebarVisible={appSidebarVisible}
      toggleAppSidebarVisibility={toggleAppSidebarVisibility}
      userSidebarVisible={userSidebarVisible}
      toggleUserSidebarVisibility={toggleUserSidebarVisibility}
      signOutHandler={signOutHandler}
      {...props}
    />
  );
}
