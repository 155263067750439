const initialState = {};
export default function UsersReducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case "USER_FETCHED":
    case "USER_UPDATED":
    case "FOLLOW_CREATED":
    case "FOLLOW_DELETED":
    case "MOST_ACTIVE_USERS_FETCHED":
    case "USERS_FETCHED":
      return { ...state, ...action.entities.users };
    case "USER_DEFAULT_AVATAR_SETTED":
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          avatarUrl: action.defaultAvatar,
          hasDefaultAvatar: true,
        },
      };
    case "USER_TERMS_ACCEPTED":
      newState = state;
      const user = newState[action.userId];
      return user
        ? { ...newState, [user.id]: { ...user, acceptedTerms: true } }
        : state;
    default:
      return state;
  }
}
