import { css } from "glamor";
import React from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { TbInfoCircle } from "react-icons/tb";
import { Header, Message } from "semantic-ui-react";
import makeDropDownOptions from "../../../utils/makeDropDownOptions";
import Link from "../../library/Link";
import Paragraph from "../../library/Paragraph";
import { DropDownField, Form } from "../../library/forms";
import { colors, spacing, typographyColors } from "../../library/theme";
import MockModalButton from "../../mocks/MockModalButton";

const container = css({
  "> div > label": {
    color: colors.greyShadow,
    fontWeight: 500,
  },
});
const contentClass = css({
  padding: spacing.m,
});
const messageClass = css({
  display: "flex",
  alignItems: "center",
  gap: spacing.xs,
});
const examInfoWrapper = css({
  display: "flex",
  justifyContent: "space-between",
  gap: spacing.m,
  marginTop: spacing.s,
  padding: spacing.s,
  backgroundColor: colors.greyLighter,
  borderRadius: 20,
  "@media (max-width: 992px)": {
    flexDirection: "column",
    gap: spacing.xs,
  },
});
const examInfoItemWrapper = css({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
  gap: spacing.s,
  "> p > strong": {
    whiteSpace: "nowrap",
    color: typographyColors.blackLetters,
    fontSize: 34,
  },
  "@media (max-width: 992px)": {
    flexDirection: "column",
    "> p > strong": {
      fontSize: 18,
    },
  },
});
const examInfoTitleClass = css({
  color: typographyColors.blackLetters,
  fontSize: 14,
  lineHeight: 1.2,
  "@media (max-width: 768px)": {
    fontSize: 16,
  },
});

const infoContainer = css({
  display: "flex",
  alignItems: "center",
  border: `1px solid ${colors.greyLighter}`,
  borderRadius: 10,
  height: 150,
  marginTop: spacing.m,
  "@media (max-width: 768px)": {
    height: "auto",
    padding: spacing.xxs,
  },
});
const iconWrapper = css({
  paddingLeft: spacing.m,
});
const iconStyle = {
  color: colors.black,
};
const infoTextContainer = css({
  padding: spacing.s,
});
const headerStyle = { marginBottom: spacing.xxs };
const paragraphStyle = { color: colors.black, lineHeight: 1.3 };
const titleClass = css({
  textAlign: "center",
  marginBottom: spacing.l,
  fontWeight: "bold",
});

export default function MockModalExamForm(props) {
  const {
    mockTemplates,
    error,
    loading,
    course,
    coursesReady,
    reinstatementHandler,
    isMobile,
    chosenMockTemplate,
    examQuestionsCount,
    courseUser,
  } = props;

  const examLevelsCount = chosenMockTemplate?.levels.length;
  const examDuration = chosenMockTemplate?.examDuration;

  return (
    <div className={contentClass}>
      <h2 className={titleClass}>Simular Prova</h2>

      <Form error={error} className={container}>
        <DropDownField
          name="mockTemplateId"
          innerLabel="PROVA"
          options={makeDropDownOptions(mockTemplates)}
          disabled={!mockTemplates?.length || loading}
          loading={loading}
          noMessages
          required
          fluid
          inline
        />
        {!loading && !mockTemplates?.length && coursesReady && (
          <Message
            content={
              <div className={messageClass}>
                <AiOutlineExclamationCircle size={18} /> Atenção! Você não tem
                cursos disponíveis para criar simulados.
              </div>
            }
            color="yellow"
            size="small"
          />
        )}
        {courseUser?.paused && (
          <Message
            content={
              <div className={messageClass}>
                <AiOutlineExclamationCircle size={18} />
                Atenção! O seu curso está trancado. Destranque-o para criar
                novos simulados.{" "}
                <Link onClick={reinstatementHandler}>
                  Clique aqui para destrancar.
                </Link>
              </div>
            }
            color="red"
            size="small"
          />
        )}
        {courseUser?.trial && (
          <>
            {course?.meta?.mocksCount >= 1 ? (
              <Message
                content={
                  <div className={messageClass}>
                    <AiOutlineExclamationCircle size={18} />
                    Atenção! Você não tem mais simulados disponíveis no período
                    de teste deste curso.
                  </div>
                }
                color="red"
                size="small"
              />
            ) : (
              <Message
                content={
                  <div className={messageClass}>
                    <AiOutlineExclamationCircle size={18} />
                    Atenção! Você só tem um simulado disponível no período de
                    teste deste curso.
                  </div>
                }
                color="blue"
                size="small"
              />
            )}
          </>
        )}

        {!!examLevelsCount && !!examQuestionsCount && !!examDuration && (
          <div className={examInfoWrapper}>
            <div className={examInfoItemWrapper}>
              <p className={examInfoTitleClass}>Quantidade de perguntas</p>
              <p>
                <strong>{examQuestionsCount}</strong>
              </p>
            </div>
            <div className={examInfoItemWrapper}>
              <p className={examInfoTitleClass}>Duração</p>
              <p>
                <strong>{examDuration}</strong>
              </p>
            </div>
            <div className={examInfoItemWrapper}>
              <p className={examInfoTitleClass}>Conteúdo da prova</p>
              <p>
                <strong>{examLevelsCount} Módulos</strong>
              </p>
            </div>
          </div>
        )}

        <div className={infoContainer}>
          {!isMobile && (
            <div className={iconWrapper}>
              <TbInfoCircle style={iconStyle} size="50px" />
            </div>
          )}

          <div className={infoTextContainer}>
            <Header as="h3" style={headerStyle}>
              Informação
            </Header>
            <Paragraph style={paragraphStyle}>
              Reserve um local apropriado para ter um melhor desempenho. Você
              poderá pausar a qualquer momento, porém é mais eficaz se expor ao
              cenário mais próximo do real.
            </Paragraph>
          </div>
        </div>

        <MockModalButton buttonText="Iniciar Prova" {...props} />
      </Form>
    </div>
  );
}
