import { css } from "glamor";
import React, { useState, useCallback } from "react";
import { Icon } from "semantic-ui-react";
import { Page, Link, Button, spacing, styles, colors } from "../../library";
import QuestionContainer from "../../questions/QuestionContainer";
import MockTimer from "../../mocks/MockTimer";
import MockPreferecencesContainer from "../../mocks/MockPreferencesModalContainer";

const container = css({
  padding: spacing.m,
});
const actionsClass = css({
  display: "flex",
  gap: spacing.s,
  flexWrap: "wrap",
  overflowX: "auto",
  scrollbarWidth: "none",
  "::-webkit-scrollbar": {
    display: "none",
  },
  marginBottom: spacing.m,
});
const mockTimerClass = css({
  display: "flex",
  justifyContent: "right",
  position: "relative",
  bottom: 68,
  "@media (max-width: 1200px)": {
    display: "none",
  },
});
const titleClass = css(styles.alternativeFontFamily, {
  display: "flex",
  alignItems: "center",
  gap: spacing.m,
  fontSize: 24,
  fontWeight: "bold",
  color: colors.secondary,
  lineHeight: 1.5,
  marginBottom: spacing.l,
});

const timeTextStyle = { color: colors.black, fontSize: 20 };

export default function Mock({ mock }) {
  const canViewResults = !!mock?.finishedAt && !!mock?.results;
  const canViewRanking =
    canViewResults && !mock?.exam && !!mock?.mockTemplateId;

  return (
    <div className={container}>
      <div className={titleClass}>{mock?.title}</div>

      <MockTimer
        className={mockTimerClass}
        textStyle={timeTextStyle}
        createdAt={mock?.createdAt}
        finishedAt={mock?.finishedAt}
        showTimer
      />

      <div className={actionsClass}>
        {canViewResults && (
          <Link component={Button} to={`/mobile/mocks/${mock?.id}/results`}>
            Ver Resultado
          </Link>
        )}
        {canViewRanking && (
          <Link component={Button} to={`/mobile/mocks/${mock?.id}/ranking`}>
            Ver Ranking
          </Link>
        )}
        {!!mock?.finishedAt && (
          <Link component={Button} href={mock?.exportUrl} target="_blank">
            Exportar PDF
          </Link>
        )}
        {!mock?.exam && <MockPreferecencesContainer />}
      </div>

      <QuestionContainer routePrefix="/mobile" />
    </div>
  );
}
