import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";
import formatDate from "../../utils/formatDate";
import Link from "../library/Link";
import { GoMortarBoard } from "react-icons/go";
import { css } from "glamor";
import { colors, spacing, uiColors, styles } from "../library/theme";

const container = css(styles.cardShadow, {
  border: `1px solid ${uiColors.border} !important`,
  marginBottom: spacing.s,
  width: "280px",
  background: `${colors.white} !important`,
  borderRadius: spacing.xs,
});
const content = css({
  display: "flex",
  flexDirection: "column",
});
const notificationTitle = css({
  flex: 1,
  width: "100%",
  whiteSpace: "normal",
  fontSize: 14,
  fontWeight: "bold",
});
const notificationText = css({
  flex: 1,
  width: "100%",
  marginTop: spacing.xs,
  whiteSpace: "normal",
  fontSize: 12,
  wordBreak: "break-word",
  lineHeight: 1.3,
});
const textWrapper = css({
  width: "100%",
});
const notificationFooterText = css({
  fontSize: 10,
});
const notificationHeader = css({
  display: "flex",
  gap: spacing.s,
});
const notificationFooter = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginTop: spacing.xs,
  color: colors.greyDark,
});
const iconClass = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "black",
  borderRadius: "3px",
  height: "44px",
  minWidth: "44px",
});

export default function NotificationListItem(props) {
  const { notification, onClick, notificationsUser } = props;
  return (
    <Link
      component={Dropdown.Item}
      href={notification.url}
      target={notification?.isCta && "_blank"}
      className={container}
      onClick={onClick}
      selected={!notification.seenAt}
    >
      <div className={content}>
        <div className={notificationHeader}>
          <div className={iconClass}>
            <Icon>
              <GoMortarBoard size={22} color="white" />
            </Icon>
          </div>
          <div>
            <div className={notificationTitle}>{notification.title}</div>
          </div>
        </div>
        <div className={textWrapper}>
          <div className={notificationText}>{notification.text}</div>
          <div className={notificationFooter}>
            <span className={notificationFooterText}>
              {formatDate(notification.createdAt, "DD/MM/YYYY HH:mm")}
            </span>

            <span className={notificationFooterText}>
              {!!notificationsUser.seenAt ? "Lida" : "Não lida"}
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
}
