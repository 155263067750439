import React, { useState, useCallback, useMemo } from "react";
import Page from "../library/Page";
import Spinner from "../library/Spinner";
import { Tab, Card, Menu, Statistic } from "semantic-ui-react";
import { colors, spacing, styles } from "../library/theme";
import { css } from "glamor";
import ClassroomDashboard from "./ClassroomDashboard";
import ClassroomStudents from "./ClassroomStudents";
import ClassroomMockTemplates from "./ClassroomMockTemplates";

const tabOptions = { color: "blue", secondary: true, pointing: true };

const getPanes = (classroom) => [
  {
    menuItem: <Menu.Item key="dashboard">Dashboard da turma</Menu.Item>,
    render: () => <ClassroomDashboard classroom={classroom} />,
  },
  {
    menuItem: <Menu.Item key="students">Alunos</Menu.Item>,
    render: () => <ClassroomStudents classroom={classroom} />,
  },
  {
    menuItem: <Menu.Item key="mocks">Avaliações</Menu.Item>,
    render: () => <ClassroomMockTemplates classroom={classroom} />,
  },
];

export default function ClassroomContainer({ classroom, loading }) {
  const [activeItem, setActiveItem] = useState(0);
  const tabChangeHandler = useCallback((_, { activeIndex }) => {
    setActiveItem(activeIndex);
  }, []);
  const panes = useMemo(() => getPanes(classroom), [classroom]);

  return (
    <Page title={classroom?.name}>
      {loading ? (
        <Spinner wrapped />
      ) : (
        <Tab
          menu={tabOptions}
          panes={panes}
          activeIndex={activeItem}
          onTabChange={tabChangeHandler}
        />
      )}
    </Page>
  );
}
