import React, { useLayoutEffect, useRef } from "react";
import { documentFormatter } from "./DocumentField";
import { colors } from "../theme";

const container = {
  backgroundColor: colors.greyLighter,
  border: "none",
};

const _getcaret = (input) => {
  if ("selectionStart" in input) {
    return input.selectionStart;
  }

  if (document.selection) {
    input.focus();

    const sel = document.selection.createRange();
    const selLen = document.selection.createRange().text.length;

    sel.moveStart("character", -input.value.length);

    return sel.text.length - selLen;
  }
};

const _setcaret = (input, pos) => {
  if (input.setSelectionRange) {
    input.focus();
    input.setSelectionRange(pos, pos);
  }

  if (input.createTextRange) {
    const range = input.createTextRange();
    range.move("character", pos);
    range.select();
  }
};

function _set_document_number(event, inputNode) {
  const input = inputNode || event?.target;

  const oldval = input.value;
  let caret_position = _getcaret(input);
  let before_caret = oldval.substring(0, caret_position);
  before_caret = documentFormatter(before_caret);
  caret_position = before_caret.length;

  const newvalue = documentFormatter(oldval);

  if (oldval === newvalue) return;

  input.value = newvalue;

  _setcaret(input, caret_position);
}

const eventListeners = [
  "input",
  "keyup",
  "keydown",
  "keypress",
  "change",
  "focus",
];

const useDocumentMask = () => {
  const attach = (input) => {
    if (!input) return;
    _set_document_number(undefined, input);

    eventListeners.map((event) =>
      input.addEventListener(event, _set_document_number)
    );
  };

  const detach = (input) => {
    if (!input) return;

    eventListeners.map((event) =>
      input.removeEventListener(event, _set_document_number)
    );
  };

  const getNumber = (text) => text.toString()?.replace(/[^0-9]+/g, "");

  return {
    attach,
    detach,
    getNumber,
  };
};

export default function DocumentInput(props) {
  const inputDocumentNumber = useRef();
  const { attach, detach } = useDocumentMask();

  useLayoutEffect(() => {
    const inputNode = inputDocumentNumber?.current;
    if (inputNode) {
      attach(inputNode);
    }

    return () => {
      detach(inputNode);
    };
  }, [inputDocumentNumber, attach, detach]);

  return <input style={container} {...props} ref={inputDocumentNumber} />;
}
