import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchQuestions } from "../../processes/questionProccess";
import { fetchAnswers } from "../../processes/answerProccess";
import { fetchReplies } from "../../processes/replyProccess";
import { makeCurrentQuestion } from "../../selectors/questionSelectors";
import Question from "./Question";
import { getReplyAnswered } from "../../selectors/answerSelector";
import { getCurrentUser } from "../../selectors/userSelectors";
import { showAnswer } from "../../selectors/mockSelectors";
import { getMockPreferences } from "../../selectors/mockPreferencesSelectors";

function QuestionContainer(props) {
  const { dispatch, currentMockId } = props;

  useEffect(() => {
    if (currentMockId) {
      fetchQuestions(dispatch, currentMockId);
      fetchAnswers(dispatch, currentMockId);
      fetchReplies(dispatch, currentMockId);
    }
  }, [dispatch, currentMockId]);

  return <Question {...props} />;
}

function mapStateToProps(state) {
  const question = makeCurrentQuestion(state);
  const currentMockId = state.mock.currentMockId;
  const currentUserId = state.currentUserId;
  return {
    showMockAnswer: showAnswer(state),
    currentUser: getCurrentUser(state, currentUserId),
    replied: state.mock.selectedReplies[question?.id],
    mockPreferences: getMockPreferences(state),
    answered: getReplyAnswered(state, {
      questionId: question?.id,
      mockId: currentMockId,
    }),
    currentMockId,
    question,
  };
}

export default connect(mapStateToProps)(QuestionContainer);
