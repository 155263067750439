import React, { useLayoutEffect, useRef } from "react";
import { cardNumberFormatter } from "./CreditCardField";
// Code Reference: https://gist.github.com/djalmaaraujo/c2bee91ec40513abf7aca7ec6ce63adb
import { colors } from "../theme";

const container = {
  backgroundColor: colors.greyLighter,
  border: "none",
};

const _getcaret = (input) => {
  if ("selectionStart" in input) {
    return input.selectionStart;
  }

  if (document.selection) {
    input.focus();

    const sel = document.selection.createRange();
    const selLen = document.selection.createRange().text.length;

    sel.moveStart("character", -input.value.length);

    return sel.text.length - selLen;
  }
};

const _setcaret = (input, pos) => {
  if (input.setSelectionRange) {
    input.focus();
    input.setSelectionRange(pos, pos);
  }

  if (input.createTextRange) {
    const range = input.createTextRange();
    range.move("character", pos);
    range.select();
  }
};

function _set_creditcard_number(event) {
  const input = event.target;

  const oldval = input.value;
  let caret_position = _getcaret(input);
  let before_caret = oldval.substring(0, caret_position);
  before_caret = cardNumberFormatter(before_caret);
  caret_position = before_caret.length;

  const newvalue = cardNumberFormatter(oldval);

  if (oldval === newvalue) return;

  input.value = newvalue;

  _setcaret(input, caret_position);
}

const eventListeners = ["input", "keyup", "keydown", "keypress", "change"];

const useCreditCardSpacer = () => {
  const attach = (input) => {
    if (!input) return;

    eventListeners.map((event) =>
      input.addEventListener(event, _set_creditcard_number)
    );
  };

  const detach = (input) => {
    if (!input) return;

    eventListeners.map((event) =>
      input.removeEventListener(event, _set_creditcard_number)
    );
  };

  const getNumber = (text) => text.toString().replace(/[^0-9]+/g, "");

  return {
    attach,
    detach,
    getNumber,
  };
};

export default function CreditCardInput(props) {
  const inputCreditCardNumber = useRef();
  const { attach, detach } = useCreditCardSpacer();

  useLayoutEffect(() => {
    const inputNode = inputCreditCardNumber?.current;
    if (inputNode) attach(inputCreditCardNumber.current);

    return () => {
      detach(inputNode);
    };
  }, [inputCreditCardNumber, attach, detach]);

  return <input style={container} {...props} ref={inputCreditCardNumber} />;
}
