import React from "react";
import { Input } from "semantic-ui-react";
import CountryCodeField from "./CountryCodeField";
import { colors } from "../theme";
import { css } from "glamor";

const inputClass = css({
  flex: 1,
  width: "100%",
  "> input": {
    backgroundColor: `${colors.greyLighter} !important`,
    borderColor: `${colors.greyLighter} !important`,
  },
});
const redBorderClass = css({
  "> input": {
    border: `1px solid red !important`,
  },
});

export default function PhoneInput(props) {
  const {
    error,
    countryCodeOptions = [],
    countryCodeFieldName,
    countryCodeProps,
    ...rest
  } = props;

  return (
    <Input
      {...rest}
      error={error}
      className={css(inputClass, error && redBorderClass)}
      actionPosition="left"
      action={
        !!countryCodeOptions?.length &&
        countryCodeFieldName && (
          <CountryCodeField
            name={countryCodeFieldName}
            error={error}
            options={countryCodeOptions}
            {...countryCodeProps}
          />
        )
      }
    />
  );
}
