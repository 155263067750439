import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import Networking from "./Networking";
import { fetchUsers } from "../../processes/userProcesses";
import { getNetworkingUsers } from "../../selectors/userSelectors";

function NetworkingContainer(props) {
  const { dispatch } = props;
  const [activePage, setActivePage] = useState(1);

  const fetchUsersHandler = useCallback(
    (_, data = { activePage: 1 }) => {
      fetchUsers({}, dispatch, data.activePage);
      setActivePage(data.activePage ? data.activePage : 1);
    },
    [dispatch]
  );

  useEffect(() => {
    fetchUsersHandler(dispatch);
  }, [dispatch, fetchUsersHandler]);

  return (
    <Networking
      fetchUsersHandler={fetchUsersHandler}
      activePage={activePage}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  return {
    users: getNetworkingUsers(state),
    loading: state.networking.loading,
    totalPages: state.networking.totalPages,
  };
}

export default connect(mapStateToProps)(NetworkingContainer);
