import React from "react";
import { css } from "glamor";
import { Select } from "semantic-ui-react";
import { spacing } from "../library/theme";
import Button from "../library/Button";
import Icon from "../library/Icon";
import Modal from "../library/Modal";
import Spinner from "../library/Spinner";
import AnnotationsListContainer from "./AnnotationsListContainer";
import AnnotationFormContainer from "./AnnotationFormContainer";

const scopeOptions = [
  { text: "De todo curso", value: "course" },
  { text: "Da aula atual", value: "lesson" },
];
const orderOptions = [
  { text: "Mais novas primeiro", value: "newer" },
  { text: "Mais antigas primeiro", value: "older" },
];

const headerWrapper = css({
  display: "flex",
  flexWrap: "wrap",
  gap: spacing.m,
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginBottom: spacing.m,
});
const selectWrapper = css({
  display: "flex",
  flexWrap: "wrap",
  gap: spacing.m,
  alignItems: "center",
  flex: 1,
  "> .ui.selection.dropdown": {
    minHeight: "36px !important",
  },
});

export default function Annotations({ lesson, loading, showCreate = true }) {
  const [openNew, setOpenNew] = React.useState(false);
  const handleOpen = React.useCallback(() => setOpenNew(true), []);
  const handleClose = React.useCallback(() => setOpenNew(false), []);

  const [order, setOrder] = React.useState("newer");
  const [scope, setScope] = React.useState(lesson ? "lesson" : "course");

  const handleOrderChange = React.useCallback(
    (e, { value }) => setOrder(value),
    []
  );
  const handleScopeChange = React.useCallback(
    (e, { value }) => setScope(value),
    []
  );

  return (
    <div>
      <Modal open={openNew} onClose={handleClose} closeIcon>
        <Modal.Header>Nova Anotação</Modal.Header>
        <Modal.Content>
          <AnnotationFormContainer handleClose={handleClose} />
        </Modal.Content>
      </Modal>

      <div className={headerWrapper}>
        <div className={selectWrapper}>
          {lesson && (
            <Select
              defaultValue="lesson"
              onChange={handleScopeChange}
              options={scopeOptions}
            />
          )}
          <Select
            defaultValue="newer"
            onChange={handleOrderChange}
            options={orderOptions}
          />
        </div>

        {showCreate && (
          <Button color="primary" onClick={handleOpen}>
            <Icon name="add" />
            Nova Anotação
          </Button>
        )}
      </div>

      {loading ? (
        <Spinner wrapped />
      ) : (
        <AnnotationsListContainer lesson={lesson} scope={scope} order={order} />
      )}
    </div>
  );
}
