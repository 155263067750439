import React from "react";
import { connect } from "react-redux";
import CompanyDashboard from "./CompanyDashboard";
import {
  getTotalSudentsByCompanyId,
  getMocksAverageByCompanyId,
  getAverageProgressByCompanyId,
} from "../../selectors/classroomSelectors";

const mapStateToProps = (state, { company }) => ({
  totalStudents: getTotalSudentsByCompanyId(state, company.id),
  mocksAverage: getMocksAverageByCompanyId(state, company.id),
  averageProgress: getAverageProgressByCompanyId(state, company.id),
});

export default connect(mapStateToProps)(CompanyDashboard);
