import { connect } from "react-redux";
import { getCurrentUserId } from "../../selectors/userSelectors";
import UserHeader from "./UserHeader";

function mapStateToProps(state) {
  return {
    currentUserId: getCurrentUserId(state),
  };
}

export default connect(mapStateToProps)(UserHeader);
