import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  canAccessTrialEnrollment,
  currentCourse,
  getCurrentCourseId,
} from "../../selectors/courseSelectors";
import { getUserCourseCertificationExam } from "../../selectors/userSelectors";
import { getItem } from "../../utils/localStorage";
import CertificationExamModal from "./CertificationExamModal";

function CertificationExamModalContainer(props) {
  const { course, certificationExam, isTrialEnrollment } = props;
  const { progress } = course.meta;

  const storage = useMemo(() => getItem("certification_exam"), []);
  const storageCourseData = storage && storage[course.id];
  const displayAt = storageCourseData?.displayAt;
  const shouldDisplay = !displayAt || moment().isAfter(displayAt);
  const storedProgress = Number(storageCourseData?.progress);

  const shouldOpenModal = useMemo(
    () =>
      course.certificationExamModalEnabled &&
      !isTrialEnrollment &&
      shouldDisplay &&
      !certificationExam &&
      progress >= 0.5 &&
      (!storedProgress || progress >= storedProgress),
    [
      course.id,
      shouldDisplay,
      !!certificationExam,
      progress,
      storedProgress,
      isTrialEnrollment,
    ]
  );

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (shouldOpenModal) setOpenModal(true);
  }, [shouldOpenModal]);

  const handleModalClosure = useCallback(() => {
    setOpenModal(false);
  }, []);

  return (
    <CertificationExamModal
      openModal={openModal}
      setOpenModal={setOpenModal}
      handleModalClosure={handleModalClosure}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const course = currentCourse(state);

  return {
    certificationExam: getUserCourseCertificationExam(state),
    courseId: getCurrentCourseId(state),
    isTrialEnrollment: canAccessTrialEnrollment(state, course?.id),
    course,
  };
}

export default connect(mapStateToProps)(CertificationExamModalContainer);
