import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { getReplyByComment } from "../../selectors/commentSelectors";
import ContentCommentListItem from "./ContentCommentListItem";
import { deleteComment } from "../../processes/commentProcesses";
import { getCurrentUserId } from "../../selectors/userSelectors";
import { getConfigByNameAndIds } from "../../selectors/configSelectors";

function ContentCommentListItemContainer(props) {
  const { dispatch, comment } = props;
  const [isEditMode, setIsEditMode] = useState(false);

  const toogleIsEditMode = useCallback(() => {
    setIsEditMode(!isEditMode);
  }, [isEditMode]);

  const deleteHandler = useCallback(() => {
    deleteComment(dispatch, comment.id);
  }, [dispatch, comment]);

  return (
    <ContentCommentListItem
      isEditMode={isEditMode}
      toogleIsEditMode={toogleIsEditMode}
      deleteHandler={deleteHandler}
      {...props}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const { comment } = ownProps;

  return {
    replies: getReplyByComment(state, comment.id),
    currentUserId: getCurrentUserId(state),
    categories: getConfigByNameAndIds(
      state,
      "contentCategories",
      comment.categoryIds
    ),
  };
}

export default connect(mapStateToProps)(ContentCommentListItemContainer);
