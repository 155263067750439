import { connect } from "react-redux";
import buildForm from "../library/forms/buildForm";
import CommentForm from "./CommentForm";
import { createLessonComment } from "../../processes/commentProcesses";

const form = buildForm("commentForm");

function mapStateToProps(state, ownProps) {
  const currentCourseId = state.currentCourseId;
  return {
    initialValues: {
      courseId: currentCourseId,
    },
    lessonId: ownProps.lessonId,
    currentCourseId,
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: createLessonComment,
  })(CommentForm)
);
