import { css } from "glamor";
import React, { useCallback, useState } from "react";
import Cards from "react-credit-cards";
import { createTextMask } from "redux-form-input-masks";
import { Icon, Message } from "semantic-ui-react";
import { hasSpecialCharacters } from "../../utils/hasSpecialCharacters";
import CourseCheckoutDetails from "../courseCheckout/CourseCheckoutDetails";
import CourseCheckoutPrivacyWarning from "../courseCheckout/CourseCheckoutPrivacyWarning";
import { Button, Link, colors, spacing } from "../library";
import {
  ButtonField,
  CreditCardField,
  DropDownField,
  TextField,
} from "../library/forms";
import DocumentField from "../library/forms/DocumentField";
import NameField from "../library/forms/NameField";
import PhoneField from "../library/forms/PhoneField";
import HighFiveSvg from "../library/images/high-five.svg";
import MailSvg from "../library/images/mail.svg";
import AddressFields from "./AddressFields";
import ReCAPTCHA from 'react-google-recaptcha';
import { change } from "redux-form";
import { useDispatch } from "react-redux";

const PAYMENT_FORM_NAME = "payment";
const CREDIT_CARD_STEP = "creditCardStep";

const titleClass = css({
  display: "contents",
  color: colors.black,
  fontWeight: "bold",
  lineHeight: 1.3,
  marginBottom: 0,
  marginRight: spacing.xxs,
});

const goodThruMask = createTextMask({
  pattern: "99/99",
  guide: false,
  allowEmpty: false,
  stripMask: false,
});

const container = {
  background: colors.white,
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const submitSucceededContainer = {
  background: "#D0D4DC",
};

const actionsWrapper = {
  marginTop: spacing.l,
  textAlign: "center",
};

const backWrapper = {
  textAlign: "left",
  paddingTop: spacing.s,
};

const linkStyle = {
  cursor: "pointer",
};

const leftContentClass = css({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  minWidth: "350px",
  padding: spacing.l,
});

const afterPaymentWrapper = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: spacing.l,
  background: colors.white,
  padding: spacing.l,
  width: 350,
  borderRadius: spacing.m,
};

const textStyle = {
  color: colors.black,
  fontSize: 12,
  textAlign: "center",
  padding: `0 ${spacing.m}`,
  lineHeight: 1.2,
};

const afterPaymentTitle = {
  color: colors.black,
  fontWeight: "bold",
  textAlign: "center",
};

const rightContentWrapeprClass = css({
  flex: 1,
  minWidth: "350px",
  background: "#F0F1F6",
  display: "flex",
  flexDirection: "column",
  padding: spacing.l,
  "@media(max-width: 575px)": {
    padding: spacing.m,
  },
});

const rightContentClass = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flex: 1,
});

const cardPreviewerWrapper = css({
  margin: `${spacing.l} 0px`,
});

const titleWrapper = css({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
});

const marginBottom = css({
  marginBottom: spacing.l,
});

const errorMessageClass = css({
  flex: 1,
});

const detailsWrapper = css({
  marginTop: spacing.m,
  borderTop: `1px solid ${colors.greyLight}`,
  paddingTop: spacing.m,
  "@media(max-width: 575px)": {
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
    paddingBottom: spacing.m,
  },
});

const privacyWrapper = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
};

const mailImageStyle = {
  marginRight: spacing.xs,
};
const cardFieldsWrapper = css({
  display: "grid",
  gridTemplateColumns: "repeat(8, 1fr)",
  gridGap: spacing.m,
  lineHeight: "normal",
});
const mobileFullWidth = css({
  "@media(max-width: 767px)": {
    gridColumn: "span 8",
  },
});
const fullWidthWrapper = css(mobileFullWidth, {
  gridColumn: "span 8",
});
const halfWidthWrapper = css(mobileFullWidth, {
  gridColumn: "span 4",
});
const fiveEightGridWrapper = css(mobileFullWidth, {
  gridColumn: "span 5",
});
const recaptchaWrapper = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  marginBottom: spacing.l,
});

const steps = [
  {
    key: "creditCard",
    title: "Dados do Cartão",
    component: PaymentCreditCardFields,
  },

  {
    key: "creditCardHolderInfo",
    title: "Informações Pessoais",
    component: PaymentCreditCardHolderFields,
  },

  {
    key: "billingAddress",
    title: "Endereço de Cobrança",
    component: PaymentBillingAddress,
  },
];

const holderNameValidator = (value) => {
  if (value && hasSpecialCharacters(value)) {
    return "specialCharacterOrNumber";
  }
  return undefined;
};

const holderNameValidationMessage = {
  specialCharacterOrNumber:
    "* Números e caracteres especiais não são permitidos",
  minWords: "* Colocar o nome como consta no cartão",
};
const cvvValidationMessages = {
  minSize: "* O CVV deve possuir 3 ou 4 caracteres",
  maxSize: "* O CVV deve possuir 3 ou 4 caracteres",
};
const goodThruValidationMessages = {
  invalid: "* A data de expiração não é válida",
};

function PaymentBillingAddress({
  street,
  neighborhood,
  cityId,
  stateId,
  stateIdField,
  addressLoading,
  states,
  cities,
  fetchCities,
}) {
  return (
    <AddressFields
      street={street}
      neighborhood={neighborhood}
      cityId={cityId}
      stateId={stateId}
      stateIdField={stateIdField}
      addressLoading={addressLoading}
      states={states}
      cities={cities}
      fetchCities={fetchCities}
    />
  );
}

function PaymentCreditCardHolderFields({ incompleteName, countryCodeOptions }) {
  return (
    <div>
      {incompleteName && (
        <NameField
          name="customerData[name]"
          placeholder="Seu nome completo"
          fluid
          required
        />
      )}
      <TextField name="customerData[email]" fluid required disabled />
      <PhoneField
        name="customerData[phone]"
        countryCodeOptions={countryCodeOptions}
        countryCodeFieldName="customerData[phoneCountryCodeId]"
        fluid
        required
      />
      <DocumentField
        name="customerData[cpfCnpj]"
        placeholder="CPF - Ex.: 999.999.999-99"
        required
      />
    </div>
  );
}

function PaymentCreditCardFields({ installments }) {
  return (
    <div className={cardFieldsWrapper}>
      <div className={fullWidthWrapper}>
        <CreditCardField
          name="creditCard[number]"
          placeholder="Número do cartão"
          required
        />
      </div>
      <div className={fullWidthWrapper}>
        <TextField
          required
          name="creditCard[holderName]"
          placeholder="Nome no cartão"
          validate={holderNameValidator}
          validationMessages={holderNameValidationMessage}
          minWords={2}
          shortMessages
          fluid
        />
      </div>
      <div className={halfWidthWrapper}>
        <TextField
          required
          name="goodThru"
          placeholder="MM/AA"
          fluid
          shortMessages
          validationMessages={goodThruValidationMessages}
          {...goodThruMask}
        />
      </div>
      <div className={halfWidthWrapper}>
        <TextField
          required
          name="creditCard[cvv]"
          placeholder="CVV"
          maxSize={4}
          minSize={3}
          validationMessages={cvvValidationMessages}
          fluid
          shortMessages
        />
      </div>
      <div className={fiveEightGridWrapper}>
        <DropDownField
          name="installmentCount"
          options={installments}
          fluid
          shortMessages
          required
          direction="upward"
        />
      </div>
    </div>
  );
}

export default function PaymentMethodCreditCard(props) {
  const {
    creditCardStep,
    previousStepHandler,
    submitSucceeded,
    installments,
    discountConfirmed,
    confirmDiscount,
    resetBillingTypeHandler,
    creditCard,
    goodThru,
    user,
    error,
  } = props;

  const handleSubmit = useCallback(() => {
    !discountConfirmed && confirmDiscount();
    props.handleSubmit();
  }, [props?.handleSubmit, confirmDiscount, discountConfirmed]);

  const isLastStep = creditCardStep == steps.length - 1;

  const CurrentStep = steps[creditCardStep]?.component;
  const title = steps[creditCardStep]?.title;
  const errorMessage = error?.join(" ");
  
  const dispatch = useDispatch();
  const [isRecaptchaValid, setIsRecaptchaValid] = useState(false);
  const handleRecaptchaChange = useCallback((value) => {
    dispatch(change('payment', 'recaptchaToken', value));
    setIsRecaptchaValid(value ? true : false);
  }, [dispatch, change]);

  return (
    <div
      className={css(container, submitSucceeded && submitSucceededContainer)}
    >
      {submitSucceeded ? (
        <div style={afterPaymentWrapper}>
          <h2 style={afterPaymentTitle}>
            Parabéns! Compra realizada com sucesso!
          </h2>
          <img src={HighFiveSvg} width="200px" alt="high five" />
          <Link
            href="/"
            component={Button}
            type="button"
            color="black"
            size="big"
            icon="shopping cart"
            fluid
            content="Acessar a plataforma"
          />
          {!user && (
            <p style={textStyle}>
              <img
                src={MailSvg}
                width="14px"
                alt="mail"
                style={mailImageStyle}
              />
              Você também receberá um email com as instruções de boas vindas.
              Cheque sua caixa postal / Spam
            </p>
          )}
        </div>
      ) : (
        <>
          <div class={leftContentClass}>
            <div className={marginBottom}>
              <div className={titleWrapper}>
                <h3 className={titleClass}>Cartão de Crédito {">"} </h3>
                <h4 className={titleClass}> {title}</h4>
              </div>

              {errorMessage && (
                <Message
                  content={errorMessage}
                  error
                  className={errorMessageClass}
                />
              )}
            </div>

            <CurrentStep installments={installments} {...props} />

            <div style={actionsWrapper}>
                {isLastStep ? (
                  <>
                    <div className={recaptchaWrapper}>
                      <ReCAPTCHA onChange={handleRecaptchaChange} hl='pt' sitekey="6Lejd2QqAAAAANuG-z7MA6EgIw6aBHYWyUAgSAHb" />
                    </div>
                    <Button
                      type="button"
                      color="black"
                      size="big"
                      icon="shopping cart"
                      fluid
                      disabled={props.invalid || props.submitting || !isRecaptchaValid}
                      content="CONCLUIR COMPRA"
                      onClick={isRecaptchaValid ? handleSubmit : null}
                      confirmationText={
                        discountConfirmed
                          ? undefined
                          : "Você tem direito ao desconto de aluno da T2!"
                      }
                      confirmButton="Usar desconto"
                      cancelButton="Cancelar"
                    />
                  </>
              ) : (
                <ButtonField
                  formName={PAYMENT_FORM_NAME}
                  fieldName={CREDIT_CARD_STEP}
                  value={creditCardStep + 1}
                  content="AVANÇAR"
                  color="black"
                  size="big"
                  icon="shopping cart"
                  disabled={props.invalid || props.submitting}
                  fluid
                />
              )}

              <div style={backWrapper}>
                <Link
                  style={linkStyle}
                  onClick={
                    creditCardStep == 0
                      ? resetBillingTypeHandler
                      : previousStepHandler
                  }
                >
                  <Icon name="angle left" /> Etapa Anterior
                </Link>
              </div>
            </div>

            <div style={privacyWrapper}>
              <CourseCheckoutPrivacyWarning />
            </div>
          </div>

          <div className={rightContentWrapeprClass}>
            <div className={rightContentClass}>
              <div className={cardPreviewerWrapper}>
                <Cards
                  cvc={creditCard?.cvv || ""}
                  expiry={goodThru || ""}
                  name={creditCard?.holderName || ""}
                  number={creditCard?.number || ""}
                  locale={{ valid: "Valido até" }}
                  placeholders={{ name: "SEU NOME" }}
                />
              </div>

              <div className={detailsWrapper}>
                <CourseCheckoutDetails {...props} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
