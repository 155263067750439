import React from "react";
import Spinner from "../library/Spinner";
import { css } from "glamor";
import { spacing } from "../library/theme";

const container = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  width: "100vw",
});

export default function CreateMock({ mockTemplate }) {
  return (
    <div className={container}>
      {(!mockTemplate || true) && (
        <Spinner text="Carregando Simulado" wrapped />
      )}
    </div>
  );
}
