const getLessons = (action) => action.entities.lessons;

export default function lessonsReducer(state = {}, action) {
  switch (action.type) {
    case "COURSE_LESSON_SEARCH_FETCHED":
    case "LESSONS_FETCHED":
      return { ...state, ...getLessons(action) };
    case "LESSON_WATCHED":
      const lessonWatched = state[action.lessonId];
      return {
        ...state,
        [action.lessonId]: {
          ...lessonWatched,
          watched: true,
        },
      };
    default:
      return state;
  }
}
