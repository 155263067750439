import React, { useCallback } from "react";
import { Field } from "redux-form";
import buildFormField from "./buildFormField";
import memoize from "fast-memoize";
import CreditCardInput from "./CreditCardInput";

const _format_465 = (cc) =>
  [cc.substring(0, 4), cc.substring(4, 10), cc.substring(10, 15)]
    .join(" ")
    .trim();

const _format_4444 = (cc) => (cc ? cc.match(/[0-9]{1,4}/g).join(" ") : "");

const CARD_TYPES = [
  {
    type: "amex",
    pattern: /^3[47][0-9]/,
    format: _format_465,
    maxlength: 15,
  },
  { type: "visa", pattern: /^4/, format: _format_4444, maxlength: 19 },
  {
    type: "master",
    pattern: /^5[1-5][0-9]/,
    format: _format_4444,
    maxlength: 16,
  },
  {
    type: "discover",
    pattern:
      /^65[4-9][0-9]|64[4-9][0-9]|6011[0-9]|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9])/,
    format: _format_4444,
    maxlength: 19,
  },
];

export function cardNumberFormatter(cc) {
  const onlyNumbers = cc?.replace(/[^0-9]+/g, "");

  const ct = CARD_TYPES.find((cardType) =>
    onlyNumbers?.match(cardType.pattern)
  );

  if (onlyNumbers?.length && ct) {
    return ct.format(onlyNumbers?.substring(0, ct.maxlength));
  }

  return _format_4444(onlyNumbers?.substring(0, 19));
}

const FormField = buildFormField(CreditCardInput, (input, meta, rest) => {
  return {
    ...input,
    autoComplete: "off",
    ...rest,
    invalid: `${meta.touched && (meta.invalid || rest.invalid)}`,
  };
});

let id = 0;
function memoizedId(x) {
  if (!x.__memoizedId) x.__memoizedId = ++id;
  return { __memoizedId: x.__memoizedId };
}

// this needs to be memoizable!
const buildValidator = memoize(
  (required, other) => {
    return [
      (value) => {
        if (required && !value) {
          return "required";
        }
        return undefined;
      },
    ].concat(other || []);
  },
  {
    serializer: (args) => {
      const argumentsWithFuncIds = Array.from(args).map((x) => {
        if (typeof x === "function") return memoizedId(x);
        return x;
      });
      return JSON.stringify(argumentsWithFuncIds);
    },
  }
);

export default function CreditCardField(props) {
  const { required, validate, ...attrs } = props;
  const normalize = useCallback((value) => value?.replace(/[^0-9]+/g, ""), []);
  const format = useCallback((value) => cardNumberFormatter(value), []);

  return (
    <Field
      {...attrs}
      required={required}
      component={FormField}
      normalize={normalize}
      format={format}
      validate={buildValidator(required, validate)}
    />
  );
}
