import { css } from "glamor";
import React, { useCallback, useState } from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { Button, Confirm, Popup } from "semantic-ui-react";
import { isString } from "../../utils/isString";
import { colors, spacing } from "./theme";

const confirmationClass = css({
  backgroundColor: colors.white,
  ".ui.modal>.content": {
    color: `${colors.greyShadow} !important`,
  },
  ".ui.modal>.actions": {
    backgroundColor: `${colors.white} !important`,
    "> .ui.primary.button": {
      backgroundColor: `${colors.primary} !important`,
    },
  },
}).toString();

const confirmationHeaderWrapper = {
  display: "flex",
  gap: spacing.s,
  alignItems: "center",
  color: colors.black,
  padding: spacing.s,
  fontSize: 16,
  borderBottom: `1px solid ${colors.greyLight}`,
};

const confirmationHeaderIconStyle = { color: colors.warning };

export default ({
  confirmationSize = "mini",
  confirmationHeader = "Atenção!",
  confirmationHeaderIcon,
  confirmationText,
  confirmButton = "Confirmar",
  cancelButton = "Cancelar",
  popupText,
  onClick,
  circular = true,
  ...rest
}) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const toogleOpenConfirmationHandler = useCallback(() => {
    setOpenConfirmation(!openConfirmation);
  }, [openConfirmation]);

  const confirmationHandler = useCallback(() => {
    onClick();
    setOpenConfirmation(false);
  }, [onClick]);

  return (
    <React.Fragment>
      {confirmationText && (
        <Confirm
          open={openConfirmation}
          className={confirmationClass}
          onCancel={toogleOpenConfirmationHandler}
          onConfirm={confirmationHandler}
          header={
            isString(confirmationHeader) ? (
              <div style={confirmationHeaderWrapper}>
                {confirmationHeaderIcon ? (
                  confirmationHeaderIcon
                ) : (
                  <AiOutlineExclamationCircle
                    style={confirmationHeaderIconStyle}
                    size={22}
                  />
                )}
                <span>{confirmationHeader}</span>
              </div>
            ) : (
              confirmationHeader
            )
          }
          content={confirmationText}
          size={confirmationSize}
          confirmButton={confirmButton}
          cancelButton={cancelButton}
        />
      )}

      <Popup
        disabled={!!!popupText}
        content={popupText}
        trigger={
          <Button
            circular={circular}
            onClick={confirmationText ? toogleOpenConfirmationHandler : onClick}
            {...rest}
          />
        }
      />
    </React.Fragment>
  );
};
