import React from "react";
import { css } from "glamor";
import { Grid, Card, Button, Icon, Label } from "semantic-ui-react";
import Spinner from "../library/Spinner";
import { spacing, utilityColors, styles, uiColors } from "../library/theme";
import Link from "../library/Link";
import { parseSocialMediaUrl } from "../../utils/parseSocialMediaUrl";
import UserBadges from "../library/UserBadges";
import FollowFormContainer from "./FollowFormContainer";

const IMAGE_SIZE = 150;
const backgroundImg = css({
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
});
const coverClass = css(backgroundImg, {
  backgroundColor: uiColors.fieldBackground,
  width: "100%",
  minHeight: IMAGE_SIZE,
  height: "0",
  paddingBottom: "20%",
});
const avatarClass = css(backgroundImg, styles.border, {
  borderRadius: "50%",
  width: IMAGE_SIZE,
  height: IMAGE_SIZE,
  boxShadow: `0 0 1em ${utilityColors.shadow}`,
  margin: `0 auto ${spacing.m} auto`,
});
const userNameClass = css({
  "@media(max-width: 770px)": {
    marginLeft: 0,
  },
});
const cardStyle = {
  ...styles.shadow,
  padding: spacing.m,
};
const header = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "center",
  marginTop: -100,
  marginBottom: spacing.m,
});
const bioClass = css(styles.wrappingText, {
  margin: `0 auto ${spacing.s} auto`,
});
const infoStyle = {
  padding: spacing.s,
};
const nameAndBadgeClass = css({
  display: "flex",
  alignItems: "start",
  justifyContent: "center",
});
const cardTitle = css({
  height: "29px",
  lineHeight: "29px",
});
const followWrapperClass = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});
const followInfoClass = css({
  display: "flex",
  alignItems: "center",
});
const followNumber = css({
  margin: 0,
  marginRight: spacing.xxs,
  "& + &": {
    marginLeft: spacing.s,
  },
});
const badgeWrapper = css({
  paddingTop: spacing.xs,
});
const labelStyle = {
  marginBottom: spacing.xxs,
};
const labelWrapper = css({
  display: "flex",
  flexWrap: "wrap",
});
const contactStyle = {
  wordBreak: "break-all",
};

const renderContactButton = (url, icon, label) => {
  const parsedUrl = parseSocialMediaUrl(url, icon);
  return parsedUrl ? (
    <Link
      component={Button}
      href={parsedUrl}
      style={contactStyle}
      target="_blank"
    >
      {icon && <Icon name={icon} />}
      {label || parsedUrl}
    </Link>
  ) : null;
};

const parserdWhatsapp = (phone) =>
  `https://wa.me/${
    phone.slice(0, 1) !== ("+5" || "55") ? `+55${phone}` : phone
  }`;

export default function NetworkingProfile({
  user,
  state,
  city,
  courseNames,
  currentUserId,
}) {
  const coverPic = css(coverClass, {
    backgroundImage: `url(${user?.coverUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "right",
  });
  const avatarPic = css(avatarClass, {
    backgroundImage: `url(${user?.avatarUrl})`,
  });

  return user ? (
    <>
      <div className={coverPic}></div>
      <Grid centered padded style={infoStyle}>
        <Grid.Column computer={16} mobile={16} tablet={16}>
          <div className={header}>
            <div className={avatarPic}></div>
            <div className={nameAndBadgeClass}>
              <h2 className={userNameClass}>{user.name}</h2>
            </div>
            <h4>{user.jobTitle}</h4>
            {state && city && <p>{`${city.name} - ${state.uf}`}</p>}
            {user.badges.length > 0 && (
              <div className={badgeWrapper}>
                <UserBadges size="small" badges={user.badges} />
              </div>
            )}
          </div>
          <Grid centered reversed="mobile vertically">
            <Grid.Column computer={10} mobile={16} tablet={16}>
              {!!user.bio && (
                <>
                  <h3 className={cardTitle}>Bio</h3>
                  <Card fluid style={cardStyle}>
                    {user.bio && <p className={bioClass}>{user.bio}</p>}
                  </Card>
                </>
              )}
              {courseNames && (
                <>
                  <h3 className={cardTitle}>Cursos</h3>
                  <Card fluid style={cardStyle}>
                    <div className={labelWrapper}>
                      {courseNames.map((courseName) => (
                        <Label
                          horizontal
                          style={labelStyle}
                          key={`course${courseName}`}
                        >
                          {courseName}
                        </Label>
                      ))}
                    </div>
                  </Card>
                </>
              )}
            </Grid.Column>
            <Grid.Column computer={6} mobile={16} tablet={16}>
              <div className={followWrapperClass}>
                <div className={followInfoClass}>
                  <h4 className={followNumber}>{user.followersCount}</h4>
                  Seguidores
                  <h4 className={followNumber}>{user.followedsCount}</h4>
                  Seguindo
                </div>
                {currentUserId === user.id ? (
                  <Link component={Button} size="mini" to="/profile/edit">
                    Editar perfil
                  </Link>
                ) : (
                  <FollowFormContainer userId={user.id} />
                )}
              </div>
              <Card fluid style={cardStyle}>
                <h3>Contatos</h3>
                {user.linkedinUrl ||
                user.facebookUrl ||
                user.instagramUrl ||
                user.twitterUrl ? (
                  <Button.Group basic vertical color="blue">
                    {renderContactButton(user.linkedinUrl, "linkedin")}
                    {renderContactButton(user.facebookUrl, "facebook")}
                    {renderContactButton(user.instagramUrl, "instagram")}
                    {renderContactButton(user.twitterUrl, "twitter")}
                  </Button.Group>
                ) : (
                  <div>Nenhum contato informado</div>
                )}
              </Card>
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>
    </>
  ) : (
    <Spinner text="Carregando usuário..." />
  );
}
