import React from "react";
import { connect } from "react-redux";
import { getCourseById } from "../../../selectors/courseSelectors";
import MockListItem from "./MockListItem";

function mapStateToProps(state, { mock }) {
  return {
    course: getCourseById(state, mock.courseId),
  };
}

export default connect(mapStateToProps)(MockListItem);
