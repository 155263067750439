import React from "react";
import ContentName from "./ContentName";
import { css } from "glamor";
import Page from "../library/Page";
import Link from "../library/Link";
import { spacing, uiColors, typographyColors } from "../library/theme";

const container = css({
  padding: 0,
});
const tabs = css({
  display: "flex",
  boxShadow: `0 -2px ${uiColors.border}90 inset`,
});
const tab = css({
  color: `${typographyColors.blackLetters} !important`,
  padding: `${spacing.xs} ${spacing.s}`,
  ":hover": {
    color: `${typographyColors.defaultTitle} !important`,
    boxShadow: `0 -2px ${uiColors.border} inset`,
  },
});
const activeTab = css({
  color: uiColors.background,
  boxShadow: `0 -2px ${uiColors.background} inset`,
  ":hover": {
    color: uiColors.background,
    boxShadow: `0 -2px ${uiColors.background} inset`,
  },
  pointerEvents: "none",
});
const pageContent = css({
  padding: spacing.m,
});

export default function ContentPage(props) {
  const { children, content, path, className, goBack } = props;

  const isHighlightsdPath = path == `/contents/${content?.id}/highlights`;
  const isCategoriesPath = path == `/contents/${content?.id}/categories`;
  const isFeedPath = path == `/contents/${content?.id}/feed`;

  return (
    <Page
      title={<ContentName content={content} large />}
      contentClass={container}
      goBack={goBack}
    >
      <div className={css(tabs, className)}>
        <Link
          as="div"
          to={`/contents/${content?.id}/highlights`}
          className={css(tab, isHighlightsdPath && activeTab)}
        >
          Destaques
        </Link>
        <Link
          as="div"
          to={`/contents/${content?.id}/categories`}
          className={css(tab, isCategoriesPath && activeTab)}
        >
          Assuntos
        </Link>
        <Link
          as="div"
          to={`/contents/${content?.id}/feed`}
          className={css(tab, isFeedPath && activeTab)}
        >
          Feed
        </Link>
      </div>
      <div className={pageContent}>{children}</div>
    </Page>
  );
}
