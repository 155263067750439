import React from "react";
import { Doughnut } from "react-chartjs-2";
import toPercentage from "../../utils/toPercentage";

const graphStyle = { maxHeight: 170 };

const dataForLessonsWatched = (lessonsCount, lessonsWatchedCount) => ({
  labels: [
    `Assistidas - ${toPercentage(lessonsWatchedCount, lessonsCount)}`,
    `Não Assistidas - ${toPercentage(
      lessonsCount - lessonsWatchedCount,
      lessonsCount
    )}`,
  ],
  datasets: [
    {
      label: "Aulas",
      data: [lessonsWatchedCount, lessonsCount - lessonsWatchedCount],
      backgroundColor: ["#0D1D51", "#e9ecef"],
      borderColor: ["#fff", "#fff"],
    },
  ],
});

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "right",
    },
  },
};

export default React.memo(function LessonWatchedGraph({
  lessonsCount,
  lessonsWatchedCount,
  workloadTotalTime,
  workloadWatchedTime,
}) {
  return (
    <Doughnut
      data={dataForLessonsWatched(
        lessonsCount,
        lessonsWatchedCount,
        workloadTotalTime,
        workloadWatchedTime
      )}
      options={options}
      style={graphStyle}
    />
  );
});
