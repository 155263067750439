import React, { useEffect } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { fetchCourseFiles } from "../../processes/courseFileProcesses";
import { fetchCourseOffers } from "../../processes/courseOffersProcesses";
import { makeHasFilesByCourseId } from "../../selectors/courseFileSelectors";
import {
  currentCourse,
  canAccessCourse,
} from "../../selectors/courseSelectors";
import { getLessonsByIds } from "../../selectors/lessonSelectors";
import { getLevelsFromCurrentCourse } from "../../selectors/levelSelectors";
import Course from "./Course";
import { getCurrentCourseUser } from "../../selectors/coursesUserSelectors";
import { getCurrentContinueLesson } from "../../selectors/continueSelectors";
import { fetchLessons } from "../../processes/lessonProcesses";

function CourseContainer(props) {
  const { dispatch, course, courseLoading } = props;

  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true);

    const fetchCourseData = async () => {
      await fetchCourseFiles(dispatch, course.id);
      await fetchCourseOffers(dispatch, course.id);
      await fetchLessons(dispatch, course.id);

      setLoading(false);
    };

    fetchCourseData();
  }, [dispatch, course?.id]);

  return <Course loading={courseLoading || loading} {...props} />;
}

function mapStateToProps(state) {
  const searchLessonSelector = formValueSelector("courseSearchLesson");
  const nameOrKeywordsCont = searchLessonSelector(state, "nameOrKeywordsCont");
  const course = currentCourse(state);

  return {
    searchParam: nameOrKeywordsCont,
    lessons: getLessonsByIds(state, state.coursesList.searchLessonIds),
    levelCount: getLevelsFromCurrentCourse(state)?.length,
    lesson: getCurrentContinueLesson(state),
    course,
    courseLoading: state.coursesList.loading,
    canAccessCourse: canAccessCourse(state, course?.id),
    courseUser: getCurrentCourseUser(state),
    hasFiles: makeHasFilesByCourseId(state, course?.id),
  };
}

export default connect(mapStateToProps)(CourseContainer);
