import { createSelector } from "reselect";
import { getCurrentMock } from "./mockSelectors";

const getQuestions = (state) => state.entities.questions;
const getCurrentIndex = (state) => state.mock.currentIndex;
const getIndex = (_, index) => index;
const getQuestionFetchedIds = (state) => state.questionList.questionIds;

export const getAllQuestions = createSelector(getQuestions, (questions) =>
  Object.values(questions)
);

export const getQuestionById = createSelector(
  getQuestions,
  getIndex,
  (questions, id) => questions[id]
);

export const getQuestionsFromMock = createSelector(
  getQuestions,
  getCurrentMock,
  (questions, mock) =>
    mock?.questionIds
      ?.map((questionId) => questions[questionId])
      .filter((q) => q) ?? []
);

export const makeCurrentQuestion = createSelector(
  getQuestionsFromMock,
  getCurrentIndex,
  (questions, currentIndex) => questions[currentIndex]
);

export const getQuestionByIndex = createSelector(
  getQuestionsFromMock,
  getIndex,
  (questions, index) => Object.values(questions)[index]
);

export const getQuestionsByFetchedIds = createSelector(
  getAllQuestions,
  getQuestionFetchedIds,
  (questions, ids) => questions.filter((question) => ids.includes(question.id))
);
