import { replace } from "connected-react-router";
import { css } from "glamor";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { Prompt } from "react-router-dom";
import { Confirm } from "semantic-ui-react";
import { isString } from "../../utils/isString";
import { colors, spacing } from "./theme";

const baseClass = css({
  backgroundColor: colors.white,
  ".ui.modal>.content": {
    color: `${colors.greyShadow} !important`,
  },
  ".ui.modal>.actions": {
    backgroundColor: `${colors.white} !important`,
    "> .ui.primary.button": {
      backgroundColor: `${colors.primaryLighter} !important`,
    },
  },
}).toString();

const headerIconStyle = { color: colors.warning };

const headerWrapper = {
  display: "flex",
  gap: spacing.s,
  alignItems: "center",
  color: colors.black,
  padding: spacing.s,
  fontSize: 16,
  borderBottom: `1px solid ${colors.greyLight}`,
};

const RouteLeavingGuard = ({
  when,
  shouldBlockNavigation = () => true,
  onConfirmHandler = () => undefined,
  className,
  size = "mini",
  header,
  headerIcon,
  content,
  cancelButton = "Cancelar",
  confirmButton = "Confirmar",
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const hasSufficientParams = header && content;

  const dispatch = useDispatch();

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
        setModalVisible(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [confirmedNavigation, shouldBlockNavigation]
  );

  const handleConfirmNavigationClick = useCallback(() => {
    onConfirmHandler();
    setModalVisible(false);
    setConfirmedNavigation(true);
  }, [onConfirmHandler]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      dispatch(replace(lastLocation.pathname));
    }
  }, [dispatch, confirmedNavigation, lastLocation]);

  return hasSufficientParams ? (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Confirm
        className={css(baseClass, className).toString()}
        open={modalVisible}
        header={
          isString(header) ? (
            <div style={headerWrapper}>
              {headerIcon ? (
                headerIcon
              ) : (
                <AiOutlineExclamationCircle style={headerIconStyle} size={22} />
              )}
              <span>{header}</span>
            </div>
          ) : (
            header
          )
        }
        content={content}
        size={size}
        cancelButton={cancelButton}
        confirmButton={confirmButton}
        onCancel={closeModal}
        onConfirm={handleConfirmNavigationClick}
      />
    </>
  ) : null;
};
export default RouteLeavingGuard;
