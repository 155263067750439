import React from "react";
import { LinkPreview } from "@dhaiwat10/react-link-preview";
import { spacing } from "../library/theme";

export default function UrlPreview({ text }) {
  const hasIframe = /<iframe/.test(text);
  const regexMatchLink = text?.match(
    /(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))?/
  );
  const [link] = regexMatchLink ?? [null];
  const hasHttps = /^[a-zA-Z]+:\/\//.test(link);
  const showPreview = hasHttps && !hasIframe;

  return showPreview ? (
    <LinkPreview url={link} width="100%" margin={`0 0 ${spacing.s} 0`} />
  ) : null;
}
