import React from "react";
import LevelListItemContainer from "./LevelListItemContainer";
import Spinner from "../library/Spinner";

export default function CourseContents({ loading, levels }) {
  return loading ? (
    <Spinner wrapped />
  ) : (
    <>
      {levels?.map((level, index) => (
        <LevelListItemContainer
          key={level.id}
          level={level}
          currentIndex={index}
        />
      ))}
    </>
  );
}
