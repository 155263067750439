import { css } from "glamor";
import React from "react";
import { Menu } from "semantic-ui-react";
import { colors, spacing } from "../library";

const menuItemClass = css({
  padding: `${spacing.s} ${spacing.xs} !important`,
  ".active": {
    borderColor: `${colors.primary} !important`,
    fontWeight: "bold !important",
  },
}).toString();

export default function CourseMenuItem({
  course,
  changeSelectedCourse,
  selectedCourse,
}) {
  return (
    <Menu.Item
      content={course?.slug}
      active={course?.id == selectedCourse?.id}
      onClick={changeSelectedCourse}
      className={menuItemClass}
    />
  );
}
