import { normalize, schema } from "normalizr";
import { apiGet } from "./helpers/api";

const certificateCourses = new schema.Object("certificateCourses");
const certificateLessons = new schema.Object("certificateLessons");

export const fetchCertificateCourses = (userId, dispatch) => {
  dispatch({ type: "CERTIFICATE_COURSES_FETCH_REQUESTED" });
  return apiGet(`/users/${userId}/course_certificates`).then((response) => {
    dispatch({
      type: "CERTIFICATE_COURSES_FETCHED",
      ...normalize(response.body, certificateCourses),
    });
  });
};

export const fetchCertificateLessons = (userId, dispatch) => {
  dispatch({ type: "CERTIFICATE_LESSONS_FETCH_REQUESTED" });
  return apiGet(`/users/${userId}/lesson_certificates`).then((response) => {
    dispatch({
      type: "CERTIFICATE_LESSONS_FETCHED",
      ...normalize(response.body, certificateLessons),
    });
  });
};
