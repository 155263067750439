import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import NewClassroomForm from "./NewClassroomForm";

import { createClassroom } from "../../processes/companyProcesses";

const FORM_NAME = "newClassroomForm";

function mapStateToProps(state, ownProps) {
  const { courseId: initialCourseId } = ownProps;

  const formSelector = formValueSelector(FORM_NAME);
  const courseId = formSelector(state, "courseId");
  const selectedCourseId = courseId || initialCourseId;

  return {
    initialValues: {
      courseId: initialCourseId,
    },
    selectedCourseId,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    onSubmit: createClassroom,
  })(NewClassroomForm)
);
