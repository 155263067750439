import { css } from "glamor";
import React from "react";
import { toBRLCurrency } from "../../utils/formatCurrency";
import { colors, spacing, styles } from "../library";
import CourseCheckoutDetailItem from "./CourseCheckoutDetailItem";
import calcPaymentValue, {
  couponDiscount,
  installmentInterestValue,
} from "../../utils/calcPaymentValue";

const PIX_METHOD = "PIX";

const titleClass = css({
  color: colors.greyShadow,
  fontWeight: 700,
  letterSpacing: 0.8,
  marginBottom: spacing.xs,
});
const totalWrapper = css({
  flex: 1,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "end",
  width: "100%",
});
const totalLabel = css({
  fontSize: 18,
});
const totalNumber = css({
  fontSize: 18,
  color: colors.black,
  fontWeight: "bold",
});
const details = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
};

export default function CourseCheckoutDetails(props) {
  const {
    offer,
    wasPreviousStudent,
    discountConfirmed,
    coupon,
    installmentCount,
    billingType,
  } = props;

  const paymentValue = calcPaymentValue(
    discountConfirmed ? wasPreviousStudent : null,
    offer,
    coupon,
    installmentCount,
    billingType
  );

  const paymentValueWithoutPixDiscount = calcPaymentValue(
    wasPreviousStudent,
    offer,
    null,
    installmentCount
  );

  const paymentValueWithoutCouponDiscount = discountConfirmed
    ? calcPaymentValue(wasPreviousStudent, offer, null, null, billingType)
    : offer?.price;

  const interestValue = installmentInterestValue(
    wasPreviousStudent,
    offer,
    coupon,
    installmentCount,
    billingType
  );

  const shouldShowBonusCourses = !!offer?.meta?.bonusCourses?.length;
  const shouldShowStudentDiscount =
    !!discountConfirmed && offer?.hasStudentDiscount && !!wasPreviousStudent;
  const shouldShowCouponDiscount = !!coupon;
  const shouldShowInterest = interestValue > 0;
  const shouldShowPixDiscount =
    billingType == PIX_METHOD && offer?.pixDiscount > 0;
  const pixDiscountPercentage = offer?.pixDiscount / 100;
  const pixDiscountValue =
    paymentValueWithoutPixDiscount * pixDiscountPercentage;

  return (
    <div style={details}>
      <h3 className={titleClass}>Resumo da compra</h3>

      <CourseCheckoutDetailItem
        title={offer?.name}
        value={offer?.price}
        valueColor={colors.primary}
        titleColor={colors.primary}
        titleWeight="bold"
      />

      {shouldShowBonusCourses && (
        <CourseCheckoutDetailItem
          title={offer?.meta?.course?.name}
          value={0}
          valueFontWeight={400}
        />
      )}

      {offer?.meta?.bonusCourses?.map(({ name, price }) => (
        <CourseCheckoutDetailItem
          title={name}
          value={0}
          valueFontWeight={400}
        />
      ))}

      {shouldShowStudentDiscount && (
        <CourseCheckoutDetailItem
          title={
            <>
              Desconto de
              <b> {offer?.meta?.course?.resellDiscount}% para Aluno T2</b>
            </>
          }
          value={offer?.price - offer?.resellPrice}
          valuePrefix="-"
          valueColor={colors.successDark}
        />
      )}

      {shouldShowStudentDiscount && shouldShowPixDiscount && (
        <CourseCheckoutDetailItem
          title="Sub-total"
          value={paymentValueWithoutPixDiscount}
          valueColor={colors.black}
          fontSize="18px !important"
        />
      )}

      {shouldShowPixDiscount && (
        <CourseCheckoutDetailItem
          title={
            <>
              Desconto de <b>{offer?.pixDiscount}% para pagamentos via PIX</b>
            </>
          }
          value={pixDiscountValue}
          valuePrefix="-"
          valueColor={colors.successDark}
          backgroundColor={colors.successClear}
        />
      )}

      {shouldShowCouponDiscount &&
        (shouldShowPixDiscount || shouldShowStudentDiscount) && (
          <CourseCheckoutDetailItem
            title="Sub-total"
            value={paymentValueWithoutCouponDiscount}
            valueColor={colors.black}
            fontSize="18px !important"
          />
        )}

      {shouldShowInterest && (
        <CourseCheckoutDetailItem
          title="Juros do Parcelamento"
          value={interestValue}
          valuePrefix="+"
          valueColor={colors.dangerLight}
        />
      )}

      {shouldShowCouponDiscount && (
        <CourseCheckoutDetailItem
          title={`Cupom ${coupon.code}`}
          titleWeight={700}
          value={couponDiscount(coupon, paymentValueWithoutCouponDiscount)}
          valuePrefix="-"
          fontSize={20}
          titleColor="#F94343"
          valueColor="#F94343"
        />
      )}

      <CourseCheckoutDetailItem
        title={shouldShowCouponDiscount ? "Total com desconto" : "Total"}
        titleWeight={600}
        value={paymentValue}
        fontSize={18}
        valueFontSize={25}
      />
    </div>
  );
}
