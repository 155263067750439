import { connect } from "react-redux";
import { getRepliesFromQuestion } from "../../selectors/replySelectors";
import QuestionRepliesList from "./QuestionRepliesList";

function mapStateToProps(state, ownProps) {
  return {
    replies: getRepliesFromQuestion(state, ownProps.questionId),
  };
}

export default connect(mapStateToProps)(QuestionRepliesList);
