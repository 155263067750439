import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import LessonQuestion from "./LessonQuestion";
import { getCurrentLesson } from "../../selectors/lessonSelectors";
import { getQuestionById } from "../../selectors/questionSelectors";
import { getCurrentUser } from "../../selectors/userSelectors";
import { fetchQuestionReplies } from "../../processes/replyProccess";
import { fetchLessonAnswers } from "../../processes/answerProccess";
import { getReplyAnswered } from "../../selectors/answerSelector";
import useScreenVerticalOffset from "../../utils/useScreenVerticalOffset";

export const LessonQuestionContainer = (props) => {
  const { question, lesson, dispatch, questionReady } = props;
  const questionRef = useRef(null);
  const [wasAlerted, setWasAlerted] = useState(false);

  useEffect(() => {
    if (lesson?.meta?.question) {
      fetchLessonAnswers(dispatch, lesson?.courseId, lesson.id);
    }
  }, [dispatch, lesson]);

  useEffect(() => {
    if (question?.id) {
      fetchQuestionReplies(dispatch, question.id);
    }
  }, [dispatch, question]);

  const scrollAmount = useScreenVerticalOffset();

  useEffect(() => {
    scrollAmount > 100 && wasAlerted == false && setWasAlerted(true);
  }, [wasAlerted, scrollAmount]);

  return question && questionReady ? (
    <LessonQuestion
      wasAlerted={wasAlerted}
      scrollAmount={scrollAmount}
      questionRef={questionRef}
      {...props}
    />
  ) : null;
};

function mapStateToProps(state) {
  const currentLessonId = state.currentLessonId;
  const lesson = getCurrentLesson(state, currentLessonId);
  const question = getQuestionById(state, lesson.meta.question);
  const loadingAlternatives = state.lesson.loadingAlternatives;
  const loadingAnswer = state.lesson.loadingAnswer;

  return {
    lesson,
    question,
    currentUser: getCurrentUser(state),
    selectedReply: state.lesson.selectedReply,
    questionReady: !loadingAlternatives && !loadingAnswer,
    answered: getReplyAnswered(state, {
      questionId: question?.id,
      lessonId: lesson?.id,
    }),
  };
}

export default connect(mapStateToProps)(LessonQuestionContainer);
