import { createSelector } from "reselect";

export const getMockPreferences = (state) => state.mockPreferences;
const getName = (_, name) => name;

export const getMockPreferenceByName = createSelector(
  getMockPreferences,
  getName,
  (mockPreferences, preference) => mockPreferences[`${preference}`]
);
