import React from "react";
import { Form, Message } from "semantic-ui-react";

export default function FormComponent(props) {
  const { children, success, error, wrapperStyle, noMessages, ...rest } = props;

  const shouldShowMessage = !noMessages && (error || success);

  return (
    <div style={wrapperStyle}>
      {shouldShowMessage && (
        <Message
          header={success?.header}
          content={success?.content || error}
          error={error}
          success={success}
        />
      )}
      <Form {...rest} error={!!error}>
        {children}
      </Form>
    </div>
  );
}
