import { css } from "glamor";
import React from "react";
import Image from "../library/Image";
import Link from "../library/Link";
import Button from "../library/Button";
import LobbyBg from "../library/images/lobby-bg.png";
import LogoInverse from "../library/images/logo-inverse.svg";
import { colors, spacing } from "../library/theme";

const container = css({
  minHeight: "100vh",
  backgroundColor: "rgb(4,4,4)",
  backgroundImage: `url(${LobbyBg})`,
  backgroundPosition: "center center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
});
const headerClass = css({
  backgroundColor: "rgb(4,4,4)",
  color: "white",
});
const headerContentClass = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: spacing.l,
  margin: "0 auto",
  maxWidth: "1300px",
  height: 102,
  paddingRight: spacing.m,
});
const logoStyle = { width: 125, marginLeft: spacing.m };
const backButton = css({
  color: `${colors.white} !important`,
  border: `1px solid ${colors.white} !important`,
  backgroundColor: `${colors.black} !important`,
  fontWeight: "bold",
  ":hover": {
    color: `${colors.primary} !important`,
    borderColor: `${colors.primary} !important`,
  },
});

export default function LobbyLayout({ children, className }) {
  return (
    <div className={css(container, className)}>
      <div className={headerClass}>
        <div className={headerContentClass}>
          <div>
            <Link
              to="/signin"
              component={Image}
              src={LogoInverse}
              style={logoStyle}
              centered
            />
          </div>

          <Link
            href="https://t2.com.br/"
            component={Button}
            circular
            className={backButton}
          >
            Voltar para o site
          </Link>
        </div>
      </div>

      {children}
    </div>
  );
}
