import { css } from "glamor";
import React from "react";
import { Header } from "semantic-ui-react";
import { spacing, styles, uiColors } from "../library/theme";

const container = css(styles.shadow, styles.roundedCorners, {
  display: "flex",
  backgroundColor: uiColors.fieldBackground,
  overflow: "hidden",
  cursor: "pointer",
  padding: spacing.m,
  justifyContent: "space-between",
  marginBottom: spacing.l,
});
const box = css({
  flex: 1,
  textAlign: "center",
  "& + &": {
    paddingLeft: spacing.m,
    marginLeft: spacing.m,
    borderLeft: `1px solid ${uiColors.border}`,
  },
});

const toPercentage = (number) => (number * 100).toFixed(0);

export default function MockReportPanel(props) {
  return (
    <div className={container}>
      <div className={box}>
        <Header as="h1">
          {props.questionsAnsweredCount}/{props.questionsCount}
          <p>Questões Resolvidas</p>
        </Header>
      </div>
      <div className={box}>
        <Header as="h1" color="green">
          {props.correctAnswersCount}
          <p>
            Respostas Certas (
            {toPercentage(props.correctAnswersCount / props.questionsCount)}
            %)
          </p>
        </Header>
      </div>
      <div className={box}>
        <Header as="h1" color="red">
          {props.wrongAnswersCount}
          <p>
            Respostas Erradas (
            {toPercentage(props.wrongAnswersCount / props.questionsCount)}
            %)
          </p>
        </Header>
      </div>
      <div className={box}>
        <Header as="h1">
          {props.isFinished ? `${props.mockAverage.toFixed(0)}%` : "--"}
          <p>Nota do Simulado</p>
        </Header>
      </div>
    </div>
  );
}
