import { apiGet, apiPost } from "./helpers/api";
import { normalize, schema } from "normalizr";
import { notifySubmitSucceeded } from "./notifierProcesses";
import toQueryString from "../utils/toQueryString";

const ratingSchema = new schema.Entity("ratings");

export const fetchRatings = (dispatch, filters = {}) => {
  return apiGet(`/api/v1/ratings?${toQueryString(filters)}`).then(
    (response) => {
      dispatch({
        type: "RATINGS_FETCHED",
        ...normalize(response.body.data, new schema.Array(ratingSchema)),
      });
    }
  );
};

export const createLessonRating = (values, dispatch, props) => {
  return apiPost("/api/v1/ratings")
    .send(values)
    .then((response) => {
      dispatch({
        type: "RATING_CREATED",
        ...normalize({ ...response.body.data }, ratingSchema),
      });
      notifySubmitSucceeded(dispatch, "Recebemos sua avaliação. Obrigado!");
      props.onClose();
    });
};
