import { LOCATION_CHANGE } from "connected-react-router";
import { matchPath } from "react-router-dom";

const lastVisitedCourseId = localStorage.getItem("lastVisitedCourseId");
let initialState = lastVisitedCourseId ? Number(lastVisitedCourseId) : null;

export default function currentCourseReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      const match = matchPath(action.payload.location.pathname, {
        path: "/courses/:courseId",
      });
      const courseId =
        match && match.params.courseId && Number(match.params.courseId);

      if (courseId) localStorage.setItem("lastVisitedCourseId", courseId);

      return courseId || state;
    default:
      return state;
  }
}
