import { css } from "glamor";
import React from "react";
import { createTextMask } from "redux-form-input-masks";
import { Grid } from "semantic-ui-react";
import makeDropDownOptions from "../../utils/makeDropDownOptions";
import DropDownField from "../library/forms/DropDownField";
import TextField from "../library/forms/TextField";
import { spacing } from "../library/theme";

const postalCodeMask = createTextMask({
  pattern: "99999-999",
  guide: false,
  allowEmpty: false,
});
const addressFieldsWrapper = css({
  display: "grid",
  gridTemplateColumns: "repeat(8, 1fr)",
  gridGap: spacing.m,
});
const mobileFullWidth = css({
  "@media(max-width: 767px)": {
    gridColumn: "span 8",
  },
});
const halfWidthWrapper = css(mobileFullWidth, {
  gridColumn: "span 4",
});
const threeEightGridWrapper = css(mobileFullWidth, {
  gridColumn: "span 3",
});
const fiveEightGridWrapper = css(mobileFullWidth, {
  gridColumn: "span 5",
});
const quarterGridWrapper = css(mobileFullWidth, {
  gridColumn: "span 2",
});

export default function AddressFields(props) {
  const { stateIdField, addressLoading, states, cities, fetchCities } = props;

  return (
    <div className={addressFieldsWrapper}>
      <div className={threeEightGridWrapper}>
        <TextField
          name="customerData[postalCode]"
          placeholder="CEP"
          fluid
          required
          loading={addressLoading}
          {...postalCodeMask}
        />
      </div>
      <div className={fiveEightGridWrapper}>
        <TextField
          name="customerData[street]"
          placeholder="Endereço"
          fluid
          required
        />
      </div>
      <div className={threeEightGridWrapper}>
        <TextField
          name="customerData[neighborhood]"
          placeholder="Bairro"
          fluid
          required
        />
      </div>
      <div className={quarterGridWrapper}>
        <TextField
          name="customerData[addressNumber]"
          placeholder="Número"
          fluid
          required
          shortMessages
        />
      </div>
      <div className={threeEightGridWrapper}>
        <TextField
          name="customerData[addressComplement]"
          placeholder="Complemento"
          noMessages
          fluid
        />
      </div>
      <div className={halfWidthWrapper}>
        <DropDownField
          name="customerData[stateId]"
          options={makeDropDownOptions(states, "Estado")}
          placeholder="Estado"
          required
          selection
          search
          onChange={fetchCities}
          fluid
        />
      </div>
      <div className={halfWidthWrapper}>
        <DropDownField
          name="customerData[cityId]"
          options={makeDropDownOptions(cities, "Cidade")}
          placeholder="Cidade"
          disabled={!stateIdField}
          search
          required
          selection
          fluid
        />
      </div>
    </div>
  );
}
