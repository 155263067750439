import { createSelector } from "reselect";
import { orderByCreatedAtDesc } from "../utils/orderByCreatedAt";
import { getUnreadNotificationIds } from "./notificationsUserSelectors";

const getNotifications = (state) => state.entities.notifications;

export const getAllNotifications = createSelector(
  getNotifications,
  (notifications) => Object.values(notifications).sort(orderByCreatedAtDesc)
);

export const getUnreadNotifications = createSelector(
  getAllNotifications,
  getUnreadNotificationIds,
  (notifications, unreadNotificationIds) =>
    notifications.filter((notification) =>
      unreadNotificationIds.includes(notification.id)
    )
);

export const getReadNotifications = createSelector(
  getAllNotifications,
  getUnreadNotificationIds,
  (notifications, unreadNotificationIds) =>
    notifications.filter(
      (notification) => !unreadNotificationIds.includes(notification.id)
    )
);

export const getNotificationsOrderedByUnread = createSelector(
  getReadNotifications,
  getUnreadNotifications,
  (read, unread) => {
    const sortedNotifications = [
      ...unread.sort(orderByCreatedAtDesc),
      ...read.sort(orderByCreatedAtDesc),
    ];

    return sortedNotifications;
  }
);
