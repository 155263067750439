import { css } from "glamor";
import React, { useRef } from "react";
import { Icon, Loader } from "semantic-ui-react";
import { spacing } from "../theme";
import DropdownSearchInput from "./DropdownSearchInput";

const container = css({
  display: "flex",
  position: "relative",
  alignItems: "center",
  width: 350,
  border: "none",
});
const searchIconStyle = {
  position: "absolute",
  fontSize: 16,
  lineHeight: 1,
  opacity: 0.3,
  top: 18,
  left: 10,
};
const inputClass = css({
  width: "100%",
  borderRadius: `${spacing.m} !important`,
  paddingLeft: `${spacing.l} !important`,
});
const clearIconWrapper = {
  position: "absolute",
  right: spacing.s,
};
const clearIconStyle = {
  cursor: "pointer",
  fontSize: 12,
  opacity: 0.3,
};
const fluidClass = css({
  width: "100%",
});

export default function SearchInput(props) {
  const { onChange, value, fluid, dropdown, loading, bordered, ...rest } =
    props;

  const inputRef = useRef(null);

  if (dropdown) {
    return <DropdownSearchInput {...props} />;
  }

  const handleClear = React.useCallback(() => {
    if (inputRef.current) inputRef.current.value = "";

    onChange("");
  }, [onChange]);

  const containerClass = css(container, fluid && fluidClass);
  return (
    <div className={containerClass}>
      {loading ? (
        <Loader
          color="black"
          active
          inline
          size="tiny"
          style={searchIconStyle}
        />
      ) : (
        <Icon name="search" style={searchIconStyle} />
      )}

      <input
        ref={inputRef}
        onChange={onChange}
        disabled={loading}
        {...rest}
        className={inputClass}
      />

      {value !== "" && (
        <div style={clearIconWrapper} onClick={handleClear}>
          <Icon name="close" style={clearIconStyle} />
        </div>
      )}
    </div>
  );
}
