import React from "react";
import NotifierContainer from "../components/notifier/NotifierContainer";

export default function Lobby(props) {
  return (
    <React.Fragment>
      <NotifierContainer />
      {props.children}
    </React.Fragment>
  );
}
