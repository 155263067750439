import React from "react";
import LevelListItemContainer from "./LevelListItemContainer";
import Spinner from "../library/Spinner";
import { colors, spacing, styles } from "../library/theme";
import LessonListItemContainer from "../courseContents/LessonListItemContainer";

const cardBase = {
  ...styles.cardShadow,
  backgroundColor: colors.white,
  borderRadius: spacing.xs,
  margin: spacing.s,
};
const cardContent = {
  padding: spacing.m,
};
const blackText = {
  color: colors.greyShadow,
};
const searchTerm = {
  color: colors.greyDark,
  fontWeight: "normal",
};
const emptyStyle = {
  paddingTop: spacing.m,
  color: colors.grey,
};

export default function LessonSidebarContents({
  loading,
  levels,
  searchParam = "",
  lessons = [],
}) {
  if (loading) return <Spinner wrapped />;

  return !!searchParam ? (
    <div style={cardBase}>
      <div style={cardContent}>
        <h4 style={blackText}>
          Exibindo resultados para{" "}
          <span style={searchTerm}>{`"${searchParam}"`}</span>
        </h4>

        {!lessons?.length && (
          <p style={emptyStyle}>Sem resultados para sua busca</p>
        )}

        {lessons?.map((lesson, index) => (
          <LessonListItemContainer key={index} lesson={lesson} />
        ))}
      </div>
    </div>
  ) : (
    levels?.map((level, index) => (
      <LevelListItemContainer
        key={level.id}
        level={level}
        currentIndex={index}
      />
    ))
  );
}
