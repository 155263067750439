import { createSelector } from "reselect";
import orderByPosition from "../utils/orderByPosition";

const getViews = (state) => state.entities.views;
const getProps = (_, props) => props;

export const getAllViews = createSelector(getViews, (views) => {
  return Object.values(views);
});

export const getViewByViewable = createSelector(
  getAllViews,
  getProps,
  (views, { viewableType, viewableId }) => {
    return views.find(
      (view) =>
        view.viewableType == viewableType && view.viewableId == viewableId
    );
  }
);
