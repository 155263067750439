import { LOCATION_CHANGE } from "connected-react-router";

const initialState = {
  showVideo: true,
  showReport: true,
  showAnswerStatus: true,
  showPercentage: true,
  showDifficulty: true,
};

export default function mockPreferencesReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return initialState;
    case "MOCK_PREFERENCE_TOGGLED":
      return {
        ...state,
        [`${action.preference}`]: !state[`${action.preference}`],
      };
    default:
      return state;
  }
}
