import { createSelector } from "reselect";
import { getLessonId } from "./lessonSelectors";
import { orderByHighlight } from "../utils/orderByName";
import {
  orderByCreatedAtAsc,
  orderByCreatedAtDesc,
} from "../utils/orderByCreatedAt";
import { orderByLikeableCount } from "../utils/orderByLikeableCount";

const getComments = (state) => state.entities.comments;
const getCommentId = (_, id) => id;
const getFetchedIds = (state) => state.commentList.commentIds;
const getFetchedContentIds = (state) => state.contentFeed.commentIds;
const getId = (_, id) => id;
const getCategoryId = (_, id, categoryId) => categoryId;
const getContentCommentCategories = (state) => state.content.categories;
const getTopContentComment = (state) => state.content.topComments;
const getTopContentTeamComment = (state) => state.content.topTeamComments;

export const getCommentsByLesson = createSelector(
  getLessonId,
  getComments,
  (lessonId, comments) =>
    Object.values(comments)
      .filter(
        (comment) =>
          comment.commentableType === "Lesson" &&
          comment.commentableId === lessonId
      )
      .sort(orderByHighlight)
);

export const getFetchedComments = createSelector(
  getComments,
  getFetchedIds,
  (comments, ids) =>
    Object.values(comments)
      .filter((comment) => ids?.includes(comment.id))
      .sort(orderByCreatedAtDesc)
);

export const getFetchedContentComments = createSelector(
  getComments,
  getFetchedContentIds,
  (comments, ids) =>
    Object.values(comments)
      .filter(
        (comment) =>
          Array.isArray(ids) &&
          ids?.includes(comment.id) &&
          comment.commentableType == "Content"
      )
      .sort(orderByCreatedAtDesc)
);

export const getReplyByComment = createSelector(
  getComments,
  getCommentId,
  (comments, commentId) =>
    Object.values(comments)
      .filter(
        (comment) =>
          comment.commentableType == "Comment" &&
          comment.commentableId == commentId
      )
      .sort(orderByCreatedAtDesc)
);

export const getReplyByCommentAsc = createSelector(
  getReplyByComment,
  (comments) => comments.sort(orderByCreatedAtAsc)
);

export const getCommentById = createSelector(
  getComments,
  getCommentId,
  (comments, commentId) => comments[commentId]
);

export const getContentCommentsByCategory = createSelector(
  getComments,
  getId,
  getCategoryId,
  (comments, contentId, categoryId) => {
    return Object.values(comments).filter(
      (comment) =>
        comment.categoryIds?.includes(categoryId) &&
        comment.commentableType == "Content" &&
        comment.commentableId == contentId
    );
  }
);

export const getTopContentCommentsByCategory = createSelector(
  getComments,
  getId,
  getContentCommentCategories,
  (comments, categoryId, contentCommentsCategories) => {
    return contentCommentsCategories[categoryId]?.topContentCommentIds
      ?.map((commentId) => comments[commentId])
      .sort(orderByLikeableCount);
  }
);

export const getTopContentComments = createSelector(
  getComments,
  getTopContentComment,
  (comments, commentIds) => {
    return commentIds
      .map((commentId) => comments[commentId])
      .sort(orderByLikeableCount);
  }
);

export const getTopContentTeamComments = createSelector(
  getComments,
  getTopContentTeamComment,
  (comments, commentIds) => {
    return commentIds
      .map((commentId) => comments[commentId])
      .sort(orderByLikeableCount);
  }
);
