import { schema } from "normalizr";
import { SubmissionError } from "redux-form";
import { apiPost, apiGet } from "./helpers/api";
import { removeItem } from "../utils/localStorage";
import { notifySubmitError } from "./notifierProcesses";

export const user = new schema.Entity("users");

export const signUp = (values, dispatch, props) => {
  const { origin } = props;

  return apiPost("/api/v1/auth")
    .send({ ...values, origin })
    .then((response) => {
      if (values.enrollment) {
        removeItem("enrollment");
      }
    })
    .catch((err) => {
      notifySubmitError(dispatch, {
        title: "Verifique seus dados!",
        message: "E-mail inválido e/ou senha inválida. Verifique seus dados.",
      });
      throw new SubmissionError({
        _error: err.response.body.errors.full_messages,
      });
    });
};

export const emailTaken = (email) => {
  return apiGet(`/api/v1/email_taken?email=${email}`).then((response) => {
    return response.body.taken;
  });
};

export const resendConfirmationEmail = (values, disatch, props, a) => {
  return apiPost("/api/v1/auth/confirmation")
    .send(values)
    .then((response) => {
      throw new SubmissionError({
        _error: response.body.message,
      });
    });
};
