const hasPecCourse = (course, userCourses, currentUser) => {
  const isPecCourse = course.categories.includes("pec");
  const isPecUser = currentUser?.origin === "pec";
  const hasAncordCourse = userCourses?.some(({ slug }) => slug == "aai");

  return isPecCourse && (isPecUser || hasAncordCourse);
};

export const hasCourse = (course, userCourses, currentUser) => {
  const canManageCourses = currentUser?.meta?.features?.canManageCourses;
  const hasCourseEnrollment = userCourses?.some(({ id }) => id == course.id);

  return (
    canManageCourses ||
    hasCourseEnrollment ||
    hasPecCourse(course, userCourses, currentUser)
  );
};
