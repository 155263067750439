import { css } from "glamor";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { Modal } from "semantic-ui-react";
import { useDebounceEffect } from "../../../utils/useDebounceEffect.js";
import Button from "../Button";
import defaultImage from "../images/default.png";
import { styles } from "../theme";
const IMAGE_MIME_TYPE = new RegExp("^image/.*$");

const TO_RADIANS = Math.PI / 180;

const layout = css({
  position: "relative",
  width: "100%",
});
const wrapper = css({
  position: "absolute",
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  height: "100%",
  boxSizing: "border-box",
});
const imageInput = {
  position: "relative",
  padding: "0 0 0 100%",
  opacity: 0,
  maxWidth: "100%",
  boxSizing: "border-box",
  display: "block",
  cursor: "pointer",
};
const previewBaseClass = css(styles.shadow, {
  position: "absolute",
  borderRadius: "50%",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
});
const modalContent = {
  display: "flex",
  justifyContent: "center",
  maxWidth: 540,
};
const cropStyle = {
  width: "100%",
  maxWidth: 500,
};
const canvasClass = css({
  display: "none",
  objectFit: "contain",
});
const modalStyle = {
  maxWidth: 540,
};
const imagePreviewStyle = {
  maxWidth: 500,
  width: "100%",
};

function freeMemory(url) {
  url && window.URL.revokeObjectURL(url);
}
const doFocus = (e) => e.target.focus();

const initialCrop = (mediaWidth, mediaHeight) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      1,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
};

async function canvasPreview(image, canvas, crop) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = 0 * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();
  ctx.translate(-cropX, -cropY);
  ctx.translate(centerX, centerY);
  ctx.rotate(rotateRads);
  ctx.scale(1, 1);
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}

export default function ImageInput(props) {
  const {
    id,
    name,
    value,
    onChange,
    invalid,
    previewStyle,
    width = 150,
    height = 150,
    bordered,
    fluid,
    error,
    inputRef,
    onBlur,
    ...rest
  } = props;

  const imageSize = { height, width };
  const [openCropModal, setOpenCropModal] = useState(null);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const cropImageRef = useRef();
  const hiddenPreviewCanvasRef = useRef();

  let previewUrl = "";
  let objectUrl = "";
  if (typeof value === "string") {
    previewUrl = value;
  } else if (value) {
    objectUrl = previewUrl = URL.createObjectURL(value);
  }

  useEffect(() => () => freeMemory(objectUrl), [objectUrl]);

  const changeHandler = useCallback(
    (file) => {
      if (file && file.type.match(IMAGE_MIME_TYPE)) {
        onChange(file);
      }
    },
    [onChange]
  );

  const fileSelctionHandler = useCallback((e) => {
    e.preventDefault();

    let files = e.target.files;

    if (files && files.length > 0 && files[0].type.match(IMAGE_MIME_TYPE)) {
      setCrop(undefined);
      setImage(URL.createObjectURL(files[0]));
      setOpenCropModal(true);
    }

    e.target.value = "";
  }, []);

  const onImageLoad = useCallback((e) => {
    const { width, height } = e.currentTarget;
    setCrop(initialCrop(width, height));
  }, []);

  const changeCropHandler = useCallback((_, percentCrop) => {
    setCrop(percentCrop);
  }, []);

  const completeCropHandler = useCallback((c) => {
    setCompletedCrop(c);
  }, []);

  const clearHandler = useCallback(() => {
    setOpenCropModal(false);
    changeHandler(value);
  }, [value]);

  const finishCropHandler = useCallback(() => {
    if (!hiddenPreviewCanvasRef.current) {
      throw new Error("Crop canvas does not exist");
    }

    hiddenPreviewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      const now = new Date();
      const fileName = `${name}${now.toUTCString()}.jpg`;
      const imageFile = new File([blob], fileName, {
        type: "image/jpeg",
      });
      changeHandler(imageFile);
      setOpenCropModal(false);
    });
  }, [name, completedCrop]);

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        cropImageRef.current &&
        hiddenPreviewCanvasRef.current
      ) {
        canvasPreview(
          cropImageRef.current,
          hiddenPreviewCanvasRef.current,
          completedCrop
        );
      }
    },
    100,
    [completedCrop]
  );

  return (
    <>
      <Modal open={openCropModal} style={modalStyle}>
        <Modal.Header>Ajustar imagem</Modal.Header>
        <Modal.Content style={modalContent}>
          {!!completedCrop && (
            <canvas
              ref={hiddenPreviewCanvasRef}
              className={css(canvasClass, {
                width: completedCrop.width,
                height: completedCrop.height,
              })}
            />
          )}
          <ReactCrop
            crop={crop}
            onChange={changeCropHandler}
            onComplete={completeCropHandler}
            aspect={1}
            circularCrop
            style={cropStyle}
          >
            <img
              src={image}
              style={imagePreviewStyle}
              ref={cropImageRef}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={clearHandler} circular={false}>
            Fechar
          </Button>
          <Button primary onClick={finishCropHandler} circular={false}>
            Usar imagem
          </Button>
        </Modal.Actions>
      </Modal>

      <div className={css(layout, imageSize)}>
        <div className={css(wrapper, imageSize)}>
          <div
            className={css(
              css(
                previewBaseClass,
                {
                  backgroundImage: `url(${
                    previewUrl ? previewUrl : defaultImage
                  })`,
                },
                imageSize,
                previewStyle && previewStyle
              )
            )}
          />
          <input
            accept="image/*"
            {...rest}
            hidden
            ref={inputRef}
            title={value ? value.name : undefined}
            className={css(imageInput, imageSize)}
            onClick={doFocus}
            onChange={fileSelctionHandler}
          />
        </div>
      </div>
    </>
  );
}
