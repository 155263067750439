import { getItem } from "../utils/localStorage";
import { LOCATION_CHANGE } from "connected-react-router";
import { matchPath } from "react-router-dom";

const initialState = {
  selectedReply: null,
  loadingAlternatives: true,
  loadingAnswer: true,
  playerOption: getItem("lessonPlayerOption") || "vimeo",
  id: null,
  currentTime: null,
  initialTime: null,
  requestedInitialTimeChangeAt: null,
};

export default function lessonReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        initialTime: null,
        requestedInitialTimeChangeAt: null,
      };
    case "ANSWERS_FETCH_REQUESTED":
      return {
        ...state,
        loadingAnswer: true,
        selectedReply: null,
      };
    case "LESSON_ANSWER_FETCHED":
      return {
        ...state,
        loadingAnswer: false,
      };
    case "REPLIES_FETCH_REQUESTED":
      return {
        ...state,
        loadingAlternatives: true,
        selectedReply: null,
      };
    case "REPLIES_FETCHED":
      return {
        ...state,
        loadingAlternatives: false,
      };
    case "REPLY_SELECTED":
      return {
        ...state,
        selectedReply: action.reply,
      };
    case "PLAYER_SELECTED":
      return {
        ...state,
        playerOption: action.option,
      };
    case "INITIAL_TIME_CHANGED":
      return {
        ...state,
        initialTime: action.initialTime,
        requestedInitialTimeChangeAt: action.requestedInitialTimeChangeAt,
      };
    case "CURRENT_TIME_UPDATED":
      return {
        ...state,
        id: action.id,
        currentTime: action.currentTime,
      };
    default:
      return state;
  }
}
