import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

const reply = new schema.Entity("replies");

export const fetchReplies = (dispatch, mockId) => {
  apiGet(`/api/v1/mocks/${mockId}/replies`).then((response) => {
    dispatch({
      type: "REPLIES_FETCHED",
      ...normalize(response.body.data, new schema.Array(reply)),
    });
  });
};

export const selectReply = (dispatch, reply) =>
  dispatch({
    type: "REPLY_SELECTED",
    reply,
  });

export const fetchQuestionReplies = (dispatch, questionId) => {
  dispatch({ type: "REPLIES_FETCH_REQUESTED" });
  apiGet(`/api/v1/questions/${questionId}/replies`).then((response) => {
    dispatch({
      type: "REPLIES_FETCHED",
      ...normalize(response.body.data, new schema.Array(reply)),
    });
  });
};

export const toggleDiscardReply = (values, dispatch) => {
  const { isDiscarted, ...rest } = values;

  return dispatch({
    type: isDiscarted ? "UNDISCARD_REPLY" : "DISCARD_REPLY",
    ...rest,
  });
};
