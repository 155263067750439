import React, { useEffect } from "react";
import { connect } from "react-redux";
import { createMock } from "../../processes/mockProccess";
import CreateMock from "./CreateMock";
import { getMockTemplateById } from "../../selectors/mockSelectors";
import { fetchMockTemplate } from "../../processes/mockTemplateProcesses";
import { matchPath } from "react-router-dom";

function CreateMockContainer(props) {
  const { dispatch, mockTemplateId, mockTemplate, routePrefix } = props;

  useEffect(() => {
    if (mockTemplate?.id) {
      createMock({ mockTemplateId: mockTemplate?.id }, dispatch, {
        routePrefix,
      });
    }
  }, [dispatch, mockTemplate?.id, routePrefix]);

  useEffect(() => {
    fetchMockTemplate(dispatch, mockTemplateId);
  }, [dispatch, mockTemplateId]);

  return <CreateMock {...props} />;
}

function mapStateToProps(state, ownProps) {
  const { mockTemplateId } = ownProps.match.params;

  return {
    mockTemplate: getMockTemplateById(state, mockTemplateId),
    mockTemplateId,
    routePrefix: state.router.location.pathname.includes("/mobile")
      ? "/mobile"
      : undefined,
  };
}

export default connect(mapStateToProps)(CreateMockContainer);
