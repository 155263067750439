import React, { useEffect } from "react";
import { connect } from "react-redux";
import { updateProfile } from "../../processes/profileProcesses";
import { getCurrentUser } from "../../selectors/userSelectors";
import buildForm from "../library/forms/buildForm";
import GreetingForm from "./GreetingForm";
import { reduxForm, formValueSelector, change } from "redux-form";
import { replace } from "connected-react-router";

export const companies = [
  "Bradesco",
  "Itaú",
  "Santander",
  "Safra",
  "Banco do Brasil",
  "Caixa",
  "BTG",
];

export const jobTitles = [
  "Operador de caixa",
  "Analista",
  "Supervisor/coordenador",
  "Gerente assistente",
  "Gerente comercial",
  "Gerente de contas",
  "Gerente administrativo",
  "Gerente de relacionamento",
  "Agente de negócios",
  "Especialista de investimentos",
  "Advogado",
  "Superintendente",
  "Cientista de dados",
];

const FORM_NAME = "greeting";
const form = buildForm(FORM_NAME);

const useClearFieldByCondition = (condition, dispatch, field) => {
  useEffect(() => {
    if (condition) dispatch(change(FORM_NAME, field, ""));
  }, [condition, dispatch, field]);
};

function GreetingFormContainer(props) {
  const {
    dispatch,
    otherJobTitle,
    otherEmployerCompany,
    alreadyWorksInFinance,
    jobTitle,
    employerCompany,
    reasonToStudy,
  } = props;

  useClearFieldByCondition(
    employerCompany && employerCompany != otherEmployerCompany,
    dispatch,
    "otherEmployerCompany",
    ""
  );
  useClearFieldByCondition(
    otherEmployerCompany,
    dispatch,
    "employerCompany",
    ""
  );
  useClearFieldByCondition(
    jobTitle && jobTitle != otherJobTitle,
    dispatch,
    "otherJobTitle",
    ""
  );
  useClearFieldByCondition(otherJobTitle, dispatch, "jobTitle", "");
  useClearFieldByCondition(
    alreadyWorksInFinance == "Sim",
    dispatch,
    "reasonToStudy",
    ""
  );
  useClearFieldByCondition(
    alreadyWorksInFinance == "Não",
    dispatch,
    "employerCompany",
    ""
  );
  useClearFieldByCondition(reasonToStudy, dispatch, "employerCompany", "");
  useClearFieldByCondition(reasonToStudy, dispatch, "otherEmployerCompany", "");

  return <GreetingForm {...props} />;
}

function mapStateToProps(state) {
  const user = getCurrentUser(state);
  const formSelector = formValueSelector(FORM_NAME);

  const {
    alreadyWorksInFinance,
    otherEmployerCompany,
    otherJobTitle,
    employerCompany,
    jobTitle,
    reasonToStudy,
  } = formSelector(
    state,
    "alreadyWorksInFinance",
    "otherEmployerCompany",
    "otherJobTitle",
    "employerCompany",
    "jobTitle",
    "reasonToStudy"
  );

  return {
    initialValues: {
      ...user,
      alreadyWorksInFinance: user?.employerCompany ? "Sim" : "Não",
      otherJobTitle: jobTitles.find((item) => item == user?.jobTitle)
        ? ""
        : user?.jobTitle,
      otherEmployerCompany: companies.find(
        (item) => item == user?.employerCompany
      )
        ? ""
        : user?.employerCompany,
    },
    alreadyWorksInFinance,
    otherEmployerCompany,
    otherJobTitle,
    jobTitle,
    employerCompany,
    reasonToStudy,
  };
}

const onSubmit = (values, dispatch, props) => {
  const {
    alreadyWorksInFinance,
    otherJobTitle,
    otherEmployerCompany,
    jobTitle,
    employerCompany,
    ...rest
  } = values;

  return updateProfile(
    {
      ...rest,
      jobTitle: otherJobTitle || jobTitle,
      employerCompany: otherEmployerCompany || employerCompany,
    },
    dispatch,
    props
  ).then(() => {
    dispatch(replace("/"));
  });
};

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    onSubmit,
  })(GreetingFormContainer)
);
