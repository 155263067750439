import { connect } from "react-redux";
import { getCourseCategoriesBySlugs } from "../../selectors/configSelectors";
import {
  getCoursePosition,
  canAccessCourse,
} from "../../selectors/courseSelectors";
import TrailSelectedCourse from "./TrailSelectedCourse";

function mapStateToProps(state, ownProps) {
  const { course } = ownProps;

  return {
    courseCategories: getCourseCategoriesBySlugs(state, course?.categories),
    position: getCoursePosition(state, course?.id),
    canAccessCourse: canAccessCourse(state, course?.id),
  };
}

export default connect(mapStateToProps)(TrailSelectedCourse);
