import { createSelector } from "reselect";

const getCoupons = (state) => state.entities.coupons;
const getCode = (_, code) => code;

export const getAllCoupons = createSelector(getCoupons, (coupons) =>
  Object.values(coupons)
);

export const getCouponByCode = createSelector(
  getAllCoupons,
  getCode,
  (coupons, code) => {
    return coupons.filter((coupon) => coupon.code == code)[0];
  }
);

export const couponFound = createSelector(
  getAllCoupons,
  getCode,
  (coupons, code) => {
    return !!coupons.filter((coupon) => coupon.code == code)[0];
  }
);
