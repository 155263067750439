import React from "react";
import { connect } from "react-redux";
import { getCourseOffersDefaultByCourseId } from "../../selectors/courseOfferSelectors";
import {
  currentCourse,
  canAccessCourse,
} from "../../selectors/courseSelectors";
import { getCurrentUser } from "../../selectors/userSelectors";
import { getCurrentCourseUser } from "../../selectors/coursesUserSelectors";
import CourseBanner from "./CourseBanner";
import { createEnrollment } from "../../processes/coursesUserProcesses";

function CourseBannerContainer(props) {
  const { dispatch, course } = props;

  const createFreeEnrollmentHandler = React.useCallback(() => {
    createEnrollment(dispatch, { courseId: course?.id, free: true });
  }, [dispatch, course]);

  return (
    <CourseBanner
      createFreeEnrollmentHandler={createFreeEnrollmentHandler}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const course = currentCourse(state);
  const offers = getCourseOffersDefaultByCourseId(state, course?.id);

  return {
    course,
    canAccessCourse: canAccessCourse(state, course?.id),
    courseUser: getCurrentCourseUser(state),
    offers,
    user: getCurrentUser(state),
  };
}

export default connect(mapStateToProps)(CourseBannerContainer);
