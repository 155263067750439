import { connect } from "react-redux";
import ContentCategoryListItem from "./ContentCategoryListItem";
import { getTopContentCommentsByCategory } from "../../selectors/commentSelectors";

const mapStateToProps = (state, ownProps) => {
  const { category } = ownProps;

  return {
    comments: getTopContentCommentsByCategory(state, category?.id),
  };
};

export default connect(mapStateToProps)(ContentCategoryListItem);
