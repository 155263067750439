import { apiPost } from "./helpers/api";

export const syncContact = (values) => {
  return apiPost("/integrations/rdstation/sync_contact")
    .send(values)
    .then(() => {});
};

export const rdstation = {
  syncContact,
};
