import { createSelector } from "reselect";

const getPromotions = (state) => state.entities.promotions;

export const getAllPromotions = createSelector(getPromotions, (promotions) =>
  Object.values(promotions)
);

export const hasPromotions = createSelector(
  getAllPromotions,
  (promotions) => !!promotions.length
);
