import { css } from "glamor";
import React from "react";
import Email from "react-email-autocomplete";
import { colors } from "../theme";

const inputClass = css({
  backgroundColor: `${colors.greyLighter} !important`,
  border: "none !important",
  borderRadius: "10px",
});

export default function EmailInput(props) {
  const className = css(
    inputClass,
    props.className,
    props.disabled && { opacity: 0.5, caretColor: "transparent" }
  );

  return <Email className={className} type="email" {...props} />;
}
