import React from "react";
import { Table, Tab, Card, Statistic, Popup } from "semantic-ui-react";
import Link from "../library/Link";
import Button from "../library/Button";
import formatDate from "../../utils/formatDate";
import { spacing } from "../library/theme";
import { css } from "glamor";
import NewClassroomModal from "./NewClassroomModal";
import AddStudentModal from "./AddStudentModal";
import CompanyImportDetails from "./CompanyImportDetails";
import Empty from "./Empty";

const nameColumnStyle = {
  width: "100%",
};
const createdAtColumnStyle = {
  paddingRight: spacing.m,
};

const topTenTitle = css({
  marginBottom: spacing.m,
});
const topTenTitleWrapper = css({
  padding: spacing.m,
  flexGrow: "unset !important",
});
const headerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};
const headerTitleStyle = {
  margin: 0,
};
const actionsTableCellStyle = {
  display: "flex",
  gap: spacing.xs,
  justifyContent: "center",
  alignItems: "center",
};
const importStatuses = {
  processing: "Processando",
  finished: "Finalizado com sucesso",
  finished_with_errors: "Algo deu errado",
};

export default function CompanyImports({ company, classrooms = [] }) {
  return (
    <div>
      {company?.meta?.courses?.length === 0 ? <Empty /> : null}
      {company?.meta?.courses?.map((course) => (
        <Card fluid style={{ overflowX: "auto" }}>
          <Card.Header className={topTenTitleWrapper}>
            <h3>{course.name}</h3>
          </Card.Header>
          <Card.Content>
            <div style={headerStyle}>
              <h3 style={headerTitleStyle}>Importações</h3>
              <AddStudentModal
                triggerTitle="Importar alunos"
                company={company}
                courseId={course?.id}
              />
            </div>

            <Table unstackable style={{ overflowX: "auto" }}>
              <Table.Header>
                <Table.Row textAlign="center">
                  <Table.HeaderCell>Importado em</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Criador</Table.HeaderCell>
                  <Table.HeaderCell>Turma</Table.HeaderCell>
                  <Table.HeaderCell>Ações</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {company?.meta?.imports
                  ?.filter(({ courseId }) => courseId == course.id)
                  .map((item) => (
                    <Table.Row textAlign="center">
                      <Table.Cell>
                        {formatDate(item?.createdAt, "DD/MM/YYYY HH:mm")}
                      </Table.Cell>
                      <Table.Cell>{importStatuses[item?.status]}</Table.Cell>
                      <Table.Cell>{item?.userName}</Table.Cell>
                      <Table.Cell>{item?.classroomName}</Table.Cell>
                      <Table.Cell style={actionsTableCellStyle}>
                        <Popup
                          content="Baixar Planilha"
                          position="top center"
                          trigger={
                            <Link
                              href={item?.fileUrl}
                              target="_blank"
                              primary
                              component={Button}
                              icon="file excel outline"
                            />
                          }
                        />
                        <CompanyImportDetails details={item.details} />
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
            {!company?.meta?.imports?.filter(
              ({ courseId }) => courseId == course.id
            )?.length && <Empty />}
          </Card.Content>
        </Card>
      ))}
    </div>
  );
}
