import React from "react";
import { css } from "glamor";
import SecurityBadge from "../library/images/security-badge.svg";
import { Link, colors, spacing, styles } from "../library";

const privacyWrapperClass = css({
  paddingTop: spacing.m,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: spacing.xs,
});
const privacyTextClass = css({
  lineHeight: 1.3,
  textAlign: "center",
  width: 350,
  color: colors.greyDarker,
});
const privacyLinkStyle = {
  textDecoration: "underline",
  color: colors.greyDarker,
};

export default function CourseCheckoutPrivacyWarning() {
  return (
    <div className={privacyWrapperClass}>
      <img src={SecurityBadge} alt="Segurança" height="22px" />
      <span className={privacyTextClass}>
        Ao preencher o formulário você permite que a T2 salve suas informações.{" "}
        <Link
          href="https://t2.com.br/politica-de-privacidade/"
          target="_blank"
          style={privacyLinkStyle}
        >
          Políticas de privacidade
        </Link>
      </span>
    </div>
  );
}
