import React from "react";
import { spacing } from "../library/theme";
import ContentCarousel from "./ContentCarousel";

const container = {
  marginBottom: spacing.m,
};

export default function ContentCategoryCarousel({ category, comments }) {
  return comments?.length > 0 ? (
    <div style={container}>
      <h3>{category.name}</h3>
      <ContentCarousel items={comments} />
    </div>
  ) : null;
}
