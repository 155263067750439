import { normalize, schema } from "normalizr";
import { apiDel, apiGet, apiPost, apiPut } from "./helpers/api";
import toQueryString from "../utils/toQueryString";
import { updatedUser } from "./profileProcesses";
import { notifySubmitError } from "./notifierProcesses";

export const userSchema = new schema.Entity("users");

const fetchedUsers = (dispatch, response) =>
  dispatch({
    type: "USERS_FETCHED",
    ...normalize(response.body.data, new schema.Array(userSchema)),
    totalPages: response.body.totalPages,
  });

export const fetchUsers = (values, dispatch, page = 1) => {
  dispatch({ type: "USERS_FETCH_REQUESTED" });
  const filters = toQueryString(values);
  apiGet(`/api/v1/users?${filters}page=${page}`).then((response) =>
    fetchedUsers(dispatch, response),
  );
};

export const fetchUser = (dispatch, userId) => {
  apiGet(`/api/v1/users/${userId}`).then((response) => {
    dispatch({
      type: "USER_FETCHED",
      ...normalize(response.body.data, userSchema),
    });
  });
};

export const updateCurrentUser = async (values, dispatch) => {
  return apiPut("/api/v1/auth")
    .send(values)
    .then((response) => {
      updatedUser(dispatch, response);
    });
};

const deleteFollow = (dispatch, userId, currentUserId) => {
  return apiDel(`/api/v1/follows/${userId}`).then((response) => {
    dispatch({
      type: "FOLLOW_DELETED",
      userId,
      currentUserId,
      ...normalize(response.body.data.meta.users, new schema.Array(userSchema)),
    });
  });
};

export const createOrDeleteFollow = (values, dispatch, props) => {
  const { isFollower, userId, currentUserId } = props;
  if (isFollower) return deleteFollow(dispatch, userId, currentUserId);
  return apiPost("/api/v1/follows")
    .send({ followedId: userId })
    .then((response) => {
      dispatch({
        type: "FOLLOW_CREATED",
        userId,
        currentUserId,
        ...normalize(
          response.body.data.meta.users,
          new schema.Array(userSchema),
        ),
      });
    });
};

export const fetchTopUsers = (dispatch) => {
  return apiGet("/api/v1/users?q[most_active_members]=true&per_page=4").then(
    (response) => {
      dispatch({
        type: "MOST_ACTIVE_USERS_FETCHED",
        ...normalize(response.body.data, new schema.Array(userSchema)),
      });
    },
  );
};
