import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

const courseFile = new schema.Entity("courseFiles");

export const fetchCourseFiles = (dispatch, courseId) => {
  return apiGet(`/api/v1/courses/${courseId}/course_files`).then((response) => {
    dispatch({
      type: "COURSE_FILES_FETCHED",
      ...normalize(response.body.data, new schema.Array(courseFile)),
    });
  });
};
