import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { getQuestionsByFetchedIds } from "../../selectors/questionSelectors";
import { getCurrentUser } from "../../selectors/userSelectors";
import { hasMockCourses } from "../../selectors/courseSelectors";

export default function withQuestionSearchData(WrappedComponent) {
  function WrappedComponentContainer(props) {
    const { dispatch, history } = props;

    const handleClose = useCallback(() => {
      history?.goBack();
    }, [history?.goBack]);

    useEffect(() => {
      dispatch({ type: "QUESTIONS_SEARCH_RESET" });
    }, [dispatch]);

    return <WrappedComponent handleClose={handleClose} {...props} />;
  }

  function mapStateToProps(state) {
    const questionSearchFormSelector = formValueSelector("questionSearchForm");
    const search = questionSearchFormSelector(state, "search");

    return {
      currentUser: getCurrentUser(state),
      isSearching: !!search,
      loading: state.questionList.loading,
      questions: getQuestionsByFetchedIds(state),
      courseLoading: state.coursesList.loading,
      hasMockCourses: hasMockCourses(state),
    };
  }

  return connect(mapStateToProps)(WrappedComponentContainer);
}
