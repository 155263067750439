import React from "react";
import { connect } from "react-redux";
import Greeting from "./Greeting";
import { getCurrentUser } from "../../selectors/userSelectors";

const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
});

export default connect(mapStateToProps)(Greeting);
