import React, { useState, useCallback, useEffect, useMemo } from "react";
import { css } from "glamor";
import { spacing } from "./theme";
import useEventListener from "../../utils/useEventListener";
import toQueryString from "../../utils/toQueryString";

const iframeClass = css({
  width: "100%",
  aspectRatio: "16 / 9",
  border: "0px",
  margin: "0px auto",
});
const roundedTopClass = css({
  borderTopLeftRadius: `${spacing.xs} !important`,
  borderTopRightRadius: `${spacing.xs} !important`,
});

const noop = () => {};

const toNumber = (value) => (!!value ? 1 : 0);

const useMessageHandler = (eventName, callback) => {
  const messageHandler = useCallback(
    (event) => {
      if (event.data.eventName === eventName) callback(event.data);
    },
    [eventName, callback]
  );

  useEventListener("message", messageHandler);
};

const makeIframeUrl = ({ url, initialTime = 0, controls, watermarkText }) => {
  const params = {
    events: ["ended", "progress", "firstplay"].join(","),
    seekTo: initialTime || 0,
    fullscreen: 1,
    controls: toNumber(controls),
    watermarkText,
    watermarkPosition: "top-left",
    watermarkOpacity: 0.5,
    watermarkTime: 20,
    watermarkType: "text",
  };
  const queryParams = toQueryString(params, {
    queryKey: null,
    underscoreKeys: false,
  });

  return `${url}?${queryParams}`;
};

export default function ScaleupPlayer({
  url,
  onFinish = noop,
  onProgress = noop,
  progress = 0,
  controls = true,
  roundedTop,
  watermarkText,
}) {
  const [firstPlay, setFirstPlay] = useState(false);
  const [initialTime, setInitialTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const handleDuration = useCallback(
    ({ duration = 0, currentTime = 0 }) => {
      setDuration(Math.round(duration));
      setCurrentTime(Math.round(currentTime));
    },
    [setDuration, setCurrentTime]
  );

  const handleFirstPlay = useCallback(() => setFirstPlay(true));

  useMessageHandler("firstplay", handleFirstPlay);
  useMessageHandler("progress", handleDuration);
  useMessageHandler("ended", onFinish);

  useEffect(() => setFirstPlay(false), [url]);

  useEffect(() => {
    if (!firstPlay) setInitialTime(duration * progress);
  }, [duration, progress, firstPlay]);

  useEffect(() => {
    if (!currentTime || !duration) return;

    if (currentTime % 10 === 0) {
      onProgress({ played: currentTime / duration });
    }
  }, [currentTime, duration, onProgress]);

  const iframeUrl = useMemo(
    () => makeIframeUrl({ url, initialTime, controls, watermarkText }),
    [url, initialTime, controls, watermarkText]
  );

  return (
    <iframe
      src={iframeUrl}
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullscreen=""
      className={css(iframeClass, roundedTop && roundedTopClass)}
    ></iframe>
  );
}
