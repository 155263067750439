export default function discardRepliesReducer(state = [], action) {
  const { replyId, mockId } = action;
  switch (action.type) {
    case "DISCARD_REPLY":
      return [...state, { replyId, mockId }];
    case "UNDISCARD_REPLY":
      return state.filter(
        (discardedReply) =>
          discardedReply.replyId != action.replyId ||
          discardedReply.mockId != action.mockId
      );
    default:
      return state;
  }
}
