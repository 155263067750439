import React from "react";
import { css } from "glamor";
import { Label } from "semantic-ui-react";
import { spacing } from "./theme";

const container = css({
  diplay: "inline-flex",
});

const labelStyle = {
  marginRight: spacing.xs,
};

export default function UserBadges({ badges, size = "tiny" }) {
  return badges ? (
    <div className={container}>
      {badges.map((badge) => (
        <Label
          style={labelStyle}
          size={size}
          color="blue"
          key={`badge${badge.id}`}
        >
          {badge.name}
        </Label>
      ))}
    </div>
  ) : null;
}
