import React from "react";
import { Modal } from "semantic-ui-react";
import Button from "../library/Button";
import Link from "../library/Link";

export default function Terms({
  term,
  open,
  onClose,
  onClickHandler,
  justShow,
  cookiesPolicyUrl,
  privacyPolicyUrl,
  privacyWarningUrl,
  isRenewal,
}) {
  return (
    <Modal open={open} onClose={onClose} closeOnDimmerClick={justShow}>
      <Modal.Header>
        {isRenewal && "Atualização dos "}Termos de Uso
      </Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <p dangerouslySetInnerHTML={{ __html: term?.content }} />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {cookiesPolicyUrl && (
          <Link
            href={cookiesPolicyUrl}
            target="_blank"
            component={Button}
            primary
          >
            Política de Cookies
          </Link>
        )}
        {privacyPolicyUrl && (
          <Link
            href={privacyPolicyUrl}
            target="_blank"
            component={Button}
            primary
          >
            Política de Privacidade
          </Link>
        )}
        {privacyWarningUrl && (
          <Link
            href={privacyWarningUrl}
            target="_blank"
            component={Button}
            primary
          >
            Avisos de Privacidade
          </Link>
        )}

        {!justShow && (
          <Button
            content="Aceitar e Continuar"
            icon="checkmark"
            onClick={onClickHandler}
            positive
          />
        )}
      </Modal.Actions>
    </Modal>
  );
}
