import { css } from "glamor";
import React from "react";
import { Card, Grid, Select } from "semantic-ui-react";
import makeDropDownOptions from "../../utils/makeDropDownOptions";
import { Link, Page, colors, spacing } from "../library";
import MyPerformanceLessonsCard from "./MyPerformanceLessonsCard";
import MyPerformanceMockCard from "./MyPerformanceMockCard";
import MyPerformanceProgressCard from "./MyPerformanceProgressCard";
import { AiFillCaretLeft } from "react-icons/ai";
import Spinner from "../library/Spinner";

const filterClass = css({
  marginBottom: spacing.m,
});
const backLinkClass = css({
  color: colors.primaryShadow,
  display: "flex",
  alignItems: "center",
  gap: spacing.xxs,
  marginBottom: spacing.xs,
  fontSize: 18,
  fontWeight: "bold",
  ":hover": {
    color: colors.primary,
    cursor: "pointer",
  },
});

export default function MyPerformance({
  courses,
  handleCourseChange,
  selectedCourseId,
  report,
  course,
  disableCourseDropdown,
  title = "Meu Desempenho",
  backLink,
  coursesLoading,
  reportLoading,
}) {
  return (
    <Page title={title}>
      {backLink && (
        <Link to={backLink} className={backLinkClass} component="div">
          <AiFillCaretLeft size={14} /> VOLTAR
        </Link>
      )}
      {coursesLoading ? (
        <Spinner wrapped />
      ) : (
        <>
          <Select
            options={makeDropDownOptions(courses)}
            onChange={handleCourseChange}
            value={selectedCourseId}
            className={filterClass}
            disabled={disableCourseDropdown}
            loading={reportLoading}
            fluid
          />
          {report ? (
            <>
              <Grid columns="equal" stretched>
                <Grid.Column computer={8} mobile={16}>
                  <MyPerformanceLessonsCard report={report} />
                </Grid.Column>
                <Grid.Column computer={8} mobile={16}>
                  <MyPerformanceProgressCard report={report} />
                </Grid.Column>
              </Grid>
              <Grid columns="equal" doubling>
                <Grid.Column>
                  <MyPerformanceMockCard course={course} report={report} />
                </Grid.Column>
              </Grid>
            </>
          ) : (
            <Card fluid>
              <Card.Content textAlign="center">
                <p>Simulados não disponível para este curso</p>
              </Card.Content>
            </Card>
          )}
        </>
      )}
    </Page>
  );
}
