import React from "react";
import { Form, Segment, Message } from "semantic-ui-react";

export default function FormComponent(props) {
  const { children, success, style, error, ...rest } = props;
  return (
    <Segment padded stacked style={style}>
      {(error || success) && (
        <Message
          header={success?.header}
          content={success?.content || error}
          error={error}
          success={success}
        />
      )}
      <Form {...rest} error={!!error}>
        {children}
      </Form>
    </Segment>
  );
}
