const initialState = {
  data: {},
  selectedCourseId: null,
  loading: false,
};

export default function reportReducer(state = initialState, action) {
  switch (action.type) {
    case "REPORT_FETCH_REQUESTED":
      return { ...state, loading: true };
    case "REPORT_FETCHED":
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload },
      };
    case "SELECTED_COURSE_CHANGED":
      return {
        ...state,
        selectedCourseId: action.id,
      };
    default:
      return state;
  }
}
