import React from "react";
import { css } from "glamor";
import { spacing, styles, uiColors } from "../library/theme";
import { Form, TextAreaField } from "../library/forms";
import Button from "../library/Button";

const formWrapper = css(styles.roundedCorners, {
  backgroundColor: `${uiColors.fieldBackground}00`,
});
const fieldsWrapper = css({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
});
const buttonStyle = {
  marginLeft: spacing.s,
  height: 41,
};
const avatar = {
  ...styles.roundedCorners,
  height: 41,
  width: 41,
  marginTop: spacing.xxs,
  marginRight: spacing.s,
};
const textFieldWrap = css({
  flex: 1,
  minWidth: 230,
});
const rejoinderTextFieldWrap = css({
  minWidth: 180,
});
const buttonWrapper = css({
  marginTop: spacing.xxs,
  "@media(max-width: 575px)": {
    marginTop: spacing.s,
    flexGrow: 1,
    display: "flex",
    justifyContent: "right",
  },
});

export default function ContentCommentForm({
  handleSubmit,
  currentUser,
  rejoinder,
}) {
  return (
    <div className={formWrapper}>
      <Form onSubmit={handleSubmit}>
        <div className={fieldsWrapper}>
          <img style={avatar} src={currentUser.avatarUrl} />
          <div
            className={css(textFieldWrap, rejoinder && rejoinderTextFieldWrap)}
          >
            <TextAreaField
              rows={1}
              name="text"
              placeholder={
                rejoinder
                  ? "Escreva uma resposta..."
                  : "Escreva um comentário..."
              }
              width="100%"
              fluid
            />
          </div>
          <div className={buttonWrapper}>
            <Button
              content={rejoinder ? "Responder" : "Comentar"}
              labelPosition="left"
              icon="conversation"
              style={buttonStyle}
              primary
            />
          </div>
        </div>
      </Form>
    </div>
  );
}
