import { css } from "glamor";
import React from "react";
import { Progress } from "semantic-ui-react";
import { colors, spacing } from "../library/theme";

const container = css({
  paddingBottom: 0,
});
const wrapper = css({
  display: "flex",
  color: colors.grey,
  justifyContent: "space-between",
  "> p": {
    marginBottom: spacing.xs,
  },
});
const progressBar = css({
  marginBottom: "0 !important",
  backgroundColor: `${colors.greyLight} !important`,
});

export default function CourseProgress({
  progress = 0,
  showCounters = "bottom",
  dark = false,
  color = colors.primary,
}) {
  const value = progress * 100;
  const counter = showCounters && (
    <div className={wrapper}>
      <small>{`${value.toFixed(0)}% concluído`}</small>
    </div>
  );

  const progressBarClass = css(
    progressBar,
    dark && { backgroundColor: `${colors.greyShadow} !important` },
    { ".ui.progress .bar": { backgroundColor: `${color} !important` } }
  ).toString();

  return (
    <div className={container}>
      {showCounters == "top" && counter}

      <Progress percent={value} className={progressBarClass} size="tiny" />

      {showCounters == "bottom" && counter}
    </div>
  );
}
