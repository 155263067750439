import { normalize, schema } from "normalizr";
import { apiGet } from "./helpers/api";

export const classroomSchema = new schema.Entity("classrooms");

export const fetchClassroom = (dispatch, id) => {
  if (!id) return;
  return apiGet(`/api/v1/classrooms/${id}`).then((response) => {
    dispatch({
      type: "CLASSROOM_FETCHED",
      ...normalize(response.body.data, classroomSchema),
    });
  });
};
