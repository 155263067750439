import React from "react";
import { css } from "glamor";
import Button from "../../library/Button";
import Link from "../../library/Link";
import Progress from "../../library/Progress";
import Spinner from "../../library/Spinner";
import { spacing, colors } from "../../library/theme";

const container = css({
  display: "flex",
  border: `1px solid ${colors?.greyLight}`,
  borderRadius: spacing.s,
  marginBottom: spacing.m,
  width: "100%",
  overflow: "hidden",
});
const subtitleClass = css({
  lineHeight: 1,
  fontSize: 12,
  marginBottom: spacing.xs,
});
const titleClass = css({
  maxHeight: 65,
  width: "100%",
  overflow: "hidden",
  lineHeight: 1.1,
});
const imageWrapperClass = css({
  backgroundPosition: "center center",
  backgroundSize: "cover",
  padding: spacing.s,
  borderRight: `1px solid ${colors?.greyLight}`,
  height: 150,
  width: 150,
  display: "flex",
  flexDirection: "column",
  justifyContent: "end",
  color: colors.white,
});
const contentClass = css({
  flex: 1,
  padding: spacing.s,
  textAlign: "left",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});
const progressWrapper = css({
  display: "flex",
  alignItems: "center",
  lineHeight: 1.5,
});
const progressStyle = {
  flex: 1,
  marginBottom: 0,
  marginRight: spacing.s,
};
const progressTitleStyle = {
  textAlign: "left",
  lineHeight: 1,
};
const resumeButtonStyle = {
  margin: 0,
};
const spinnerWrapper = css({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 150,
  width: 150,
});
const imageSpinnerWrapper = css({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export default function MockResume({ mock, course, loading, courseLoading }) {
  return (
    <div className={container}>
      {loading ? (
        <div className={spinnerWrapper}>
          <Spinner inline />
        </div>
      ) : (
        <>
          <div
            className={css(imageWrapperClass, {
              backgroundImage: `linear-gradient(189.3deg, rgba(10, 32, 40, 0) 7.01%, #0A2028 87.41%), url(${course?.imageUrl})`,
            })}
          >
            {courseLoading ? (
              <div className={imageSpinnerWrapper}>
                <Spinner inline inverted />
              </div>
            ) : (
              <>
                {mock && (
                  <>
                    <span style={progressTitleStyle}>Progresso</span>
                    <div className={progressWrapper}>
                      <Progress
                        percent={mock?.progress}
                        style={progressStyle}
                        color="grey"
                        size="tiny"
                        inverted
                        fluid
                      />
                      <div>{mock?.progress}%</div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <div className={contentClass}>
            <div>
              <p className={subtitleClass}>{mock && "Em andamento"}</p>
              <h3 className={titleClass}>
                {mock ? mock?.title : "Você não possui simulados em andamento"}
              </h3>
            </div>

            <Link
              component={Button}
              to={
                mock ? `/mobile/mocks/${mock?.id}` : "/mobile/mocks/custom/new"
              }
              primary
              circular={false}
              size="small"
              style={resumeButtonStyle}
            >
              {mock ? "Continuar" : "Criar simulado"}
            </Link>
          </div>
        </>
      )}
    </div>
  );
}
