const getPayments = (action) => action.entities.payments;

export default function paymentReducer(state = {}, action) {
  switch (action.type) {
    case "PAYMENTS_FETCHED":
    case "PAYMENT_FETCHED":
      return { ...state, ...getPayments(action) };
    default:
      return state;
  }
}
