import React from "react";
import Page from "../library/Page";
import { css } from "glamor";
import Link from "../library/Link";
import { spacing, stateColors } from "../library/theme";

const container = css({ marginBottom: spacing.m });
const promotionWrapperClass = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: stateColors.empty,
  position: "relative",
  width: "100%",
  paddingBottom: "10%",
  "@media(max-width: 575px)": {
    paddingBottom: "calc(9/16 * 100%)",
  },
});
const promotionClass = css({
  zIndex: 2,
  cursor: "pointer",
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: "100%",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  color: "red",
});
const emptyClass = css({
  color: stateColors.empty,
  textAlign: "center",
});
const promotionImageClass = (promotion) =>
  css(promotionClass, {
    backgroundImage: `url(${promotion.imageUrl})`,
    "@media(max-width: 575px)": {
      backgroundImage: `url(${
        promotion.imageMobileUrl ? promotion.imageMobileUrl : promotion.imageUrl
      })`,
    },
  });

export default function Promotions(props) {
  return (
    <Page title="Black Friday">
      {props.promotions.length > 0 ? (
        props.promotions.map((promotion) => (
          <div className={container}>
            <h4>{promotion?.title}</h4>
            <Link
              href={promotion.url}
              target="_blank"
              className={promotionWrapperClass}
            >
              <div className={promotionImageClass(promotion)}>
                <h1>{props.index}</h1>
              </div>
            </Link>
          </div>
        ))
      ) : (
        <h4 className={emptyClass}>
          Ops, não há promoções cadastradas no momento. =(
        </h4>
      )}
    </Page>
  );
}
