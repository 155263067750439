import { underscore } from "inflection";

export default function toQueryString(values, options = {}) {
  const { queryKey = "q", underscoreKeys = true } = options;
  let params = "";
  Object.keys(values).map((k) => {
    const name = underscoreKeys ? underscore(k) : k;
    const value = values[k];
    if (value === null || value === undefined) return;
    if (Array.isArray(value)) {
      return value.map((i) => {
        params += queryKey ? `${queryKey}[${name}][]=${i}&` : `${name}[]=${i}&`;
      });
    }
    params += queryKey ? `${queryKey}[${name}]=${value}&` : `${name}=${value}&`;
  });
  return params;
}
