import { css } from "glamor";
import React from "react";
import { Icon, Modal } from "semantic-ui-react";
import Button from "../library/Button";
import { colors } from "../library/theme";
import VimeoPlayer from "../library/VimeoPlayer";

export default function Onboarding(props) {
  const { onboardHandler, videoUrl } = props;

  return (
    <Modal open>
      <Modal.Content>
        <VimeoPlayer url={videoUrl} />
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={onboardHandler}>
          <Icon name="checkmark" />
          CONTINUAR
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
