import React from "react";
import { css } from "glamor";
import { Popup } from "semantic-ui-react";
import { toBRLCurrency } from "../../utils/formatCurrency";
import calcPaymentValue from "../../utils/calcPaymentValue";
import Button from "../library/Button";
import Link from "../library/Link";
import Icon from "../library/Icon";
import { colors, spacing, styles } from "../library/theme";
import { makeIsMobile } from "../../utils/useScreenWidth";
import formatDate from "../../utils/formatDate";

const banner = css({
  backgroudColor: colors.greyDark,
  position: "relative",
  width: "100%",
  backgroundSize: "cover !important",
  backgroundRepeat: "no-repeat !important",
  backgroundPosition: "right !important",
  borderRadius: spacing.xs,
});

const bannerContentClass = css({
  height: "100%",
  display: "flex",
  flexDirection: "column",
});

const bannerContainerClass = css({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  padding: spacing.l,
  "@media(max-width: 711px)": {
    position: "static",
    padding: spacing.m,
  },
});

const detailsPrice = css({
  margin: "0px !important",
  fontWeight: "bold",
  color: colors.primary,
});

const buyButtonWrapper = {
  margin: `${spacing.m} 0px`,
  maxWidth: 250,
};

const textWrapperClass = css({
  marginTop: "auto",
});

const fullPriceStyle = {
  display: "inline",
  fontSize: 14,
  textDecoration: "line-through",
  cursor: "pointer",
};

const buyClass = css({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const bannerText = css({
  fontSize: 14,
  color: colors.greyLighter,
  "@media(max-width: 575px)": {
    color: colors.black,
  },
});

const lockedClass = css({
  position: "absolute",
  top: "0 !important",
  left: "0 !important",
  right: "0 !important",
  bottom: "0 !important",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1,
  background: `${colors.primary}CF`,
  color: colors.white,
  fontWeight: "bold",
  textTransform: "uppercase",
  borderRadius: spacing.xs,
  "@media(max-width: 770px)": {
    zIndex: -1,
    opacity: 0,
  },
});

const cardClass = css({
  ...styles.cardShadow,
  borderRadius: spacing.xs,
  minWidth: 200,
  background: colors.white,
  marginTop: spacing.m,
});

export default function CourseBanner({
  course,
  canAccessCourse,
  courseUser,
  offers,
  user,
  createFreeEnrollmentHandler,
}) {
  const isMobile = makeIsMobile();

  const bannerClass = css(banner, {
    paddingBottom: isMobile ? 0 : "32.5%",
    background: `url(${course?.coverImageUrl})`,
  });
  const lastOffer = offers?.length && offers[offers?.length - 1];
  const displayExpirationDate =
    !user?.meta?.features?.canManageCourses &&
    !courseUser?.trial &&
    courseUser?.createdAt &&
    courseUser?.expiresAt &&
    canAccessCourse;
  const displayEnrollmentCopy =
    !user?.meta?.features?.canManageCourses &&
    course?.visible &&
    !canAccessCourse &&
    course?.isFree;
  const displayBuyOptions =
    !user?.meta?.features?.canManageCourses &&
    course?.visible &&
    !course?.isFree &&
    (!canAccessCourse || courseUser?.trial);
  const priceForMaxInstallments =
    lastOffer &&
    calcPaymentValue(
      user?.isFromPreparatoryCourse,
      lastOffer,
      lastOffer?.meta?.coupon ? lastOffer?.meta?.coupon : undefined,
      lastOffer?.maxInstallments
    );

  return course ? (
    <div className={css(isMobile ? cardClass : bannerClass)}>
      {courseUser?.paused ? (
        <div className={lockedClass}>
          <Icon name="lock" size="huge" />
        </div>
      ) : (
        <>
          <div className={bannerContainerClass}>
            <div className={bannerContentClass}>
              {displayExpirationDate && (
                <div className={textWrapperClass}>
                  <div>
                    <p className={bannerText}>
                      <strong>Data de Início: </strong>{" "}
                      {formatDate(courseUser?.createdAt, "DD/MM/YYYY")}
                    </p>
                    <p className={bannerText}>
                      <strong>Data de Expiração: </strong>{" "}
                      {formatDate(courseUser?.expiresAt, "DD/MM/YYYY")}
                    </p>
                  </div>
                </div>
              )}

              {displayEnrollmentCopy && (
                <div className={buyClass}>
                  <span className={bannerText}>Realize a sua matrícula!</span>

                  <h1 style={detailsPrice}>Curso Grátis!</h1>

                  <div style={buyButtonWrapper}>
                    <Button
                      onClick={createFreeEnrollmentHandler}
                      color="buy"
                      fluid
                    >
                      Aproveite
                    </Button>
                  </div>
                </div>
              )}

              {displayBuyOptions && (
                <div className={buyClass}>
                  <span className={bannerText}>
                    Apenas {lastOffer?.maxInstallments}x de{" "}
                  </span>

                  <h1 className={detailsPrice}>
                    {toBRLCurrency(
                      +priceForMaxInstallments /
                        (+lastOffer?.maxInstallments || 1)
                    )}{" "}
                    {lastOffer?.hasStudentDiscount &&
                      user?.isFromPreparatoryCourse && (
                        <Popup
                          content={`Você ganhou ${course.resellDiscount}% de desconto por ser aluno T2.`}
                          trigger={
                            <span style={fullPriceStyle}>
                              {toBRLCurrency(+lastOffer?.price)}
                            </span>
                          }
                        />
                      )}
                  </h1>

                  {lastOffer?.maxInstallments > 1 && (
                    <span className={bannerText}>
                      ou{" "}
                      {toBRLCurrency(
                        lastOffer?.hasStudentDiscount &&
                          user?.isFromPreparatoryCourse
                          ? +lastOffer?.resellPrice
                          : +lastOffer?.price
                      )}{" "}
                      à vista
                    </span>
                  )}

                  <div style={buyButtonWrapper}>
                    <Link
                      component={Button}
                      href={
                        course?.salesChannel === "hotmart"
                          ? course?.paymentUrl
                          : undefined
                      }
                      to={
                        course?.salesChannel === "hotmart"
                          ? undefined
                          : !!offers.length && `/courses/${course?.id}/checkout`
                      }
                      target="_blank"
                      color="green"
                      fluid
                    >
                      COMPRAR
                    </Link>
                  </div>
                </div>
              )}

              {courseUser?.trial && (
                <div className={textWrapperClass}>
                  <p className={bannerText}>
                    {courseUser.expired ? (
                      <strong>
                        O seu periodo de avaliação expirou. Matricule-se para
                        continuar assistindo as aulas!
                      </strong>
                    ) : (
                      <>
                        <strong>Seu período de avaliação vai até: </strong>{" "}
                        {formatDate(courseUser?.expiresAt, "DD/MM/YYYY")}
                      </>
                    )}
                  </p>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  ) : null;
}
