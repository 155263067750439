const getPromotions = (action) => action.entities.promotions;

export default function promotionsReducer(state = {}, action) {
  switch (action.type) {
    case "PROMOTIONS_FETCHED":
      return { ...state, ...getPromotions(action) };
    default:
      return state;
  }
}
