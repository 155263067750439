const initialState = {
  loading: false,
  questionIds: [],
};

const getQuestionsFetchedIds = (action) =>
  action.result ? action.result : initialState.questionIds;

export default function questionsListReducer(state = initialState, action) {
  switch (action.type) {
    case "QUESTIONS_SEARCH_RESET":
      return initialState;
    case "QUESTIONS_SEARCH_FETCH_REQUESTED":
      return { ...state, loading: true };
    case "QUESTIONS_SEARCH_FETCHED":
      return {
        ...state,
        loading: false,
        questionIds: getQuestionsFetchedIds(action),
      };
    default:
      return state;
  }
}
