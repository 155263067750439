import { connect } from "react-redux";
import CommentLikeForm from "./ContentLikeForm";
import { getCommentLike } from "../../selectors/likeSelectors";
import buildForm from "../library/forms/buildForm";
import { createOrDeleteContentLike } from "../../processes/commentProcesses";

const form = buildForm("contentLikeForm");

function mapStateToProps(state, ownProps) {
  return {
    likeId: getCommentLike(state, ownProps.commentId)?.id,
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: createOrDeleteContentLike,
  })(CommentLikeForm)
);
