import { css } from "glamor";
import React from "react";
import { Icon } from "semantic-ui-react";
import { colors, spacing } from "../library/theme";
import VerticalList from "../library/VerticalList";
import TrailChangeFormContainer from "./TrailChangeFormContainer";
import TrailCourseListItemContainer from "./TrailCourseListItemContainer";
import TrailSelectedCourseContainer from "./TrailSelectedCourseContainer";

const layout = css({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  height: 640,
  minHeight: 640,
  padding: spacing.xl,
  paddingLeft: spacing.offset,
  paddingRight: spacing.offset,
  background:
    "transparent linear-gradient(270deg, #180A29 0%, #2A0A4A 50%, #180A29 100%) 0% 0% no-repeat padding-box",
  "@media(max-width: 992px)": {
    height: "auto",
  },
});
const header = css({
  marginBottom: spacing.l,
  display: "flex",
  alignItems: "center",
});
const titleStyle = { color: colors.greyClear, fontSize: 22 };
const iconStyle = {
  marginRight: spacing.s,
  color: colors.secondary,
};
const container = css({
  display: "flex",
  gap: spacing.m,
  flexWrap: "wrap",
});
const content = { flex: 1 };

export default function Trail({
  trail,
  courses,
  selectedCourse,
  selectCourseHandler,
}) {
  if (!courses?.length) {
    return null;
  }

  const renderItem = (course, index) => {
    return (
      <TrailCourseListItemContainer
        key={course?.id}
        position={index + 1}
        course={course}
        selectedCourse={selectedCourse}
        selectCourseHandler={selectCourseHandler}
      />
    );
  };

  return (
    <div className={layout}>
      <TrailChangeFormContainer />
      <div className={container}>
        <div style={content}>
          <div className={header}>
            <Icon name="ordered list" size="large" style={iconStyle} />
            <p style={titleStyle}>{trail.name}</p>
          </div>
          <TrailSelectedCourseContainer course={selectedCourse} />
        </div>
        <VerticalList
          data={courses}
          listHeight="400px"
          renderItem={renderItem}
        />
      </div>
    </div>
  );
}
