import React from "react";
import { css } from "glamor";
import { spacing, uiColors } from "../library/theme";
import Question from "../questions/Question.js";
import LessonQuestionAlert from "./LessonQuestionAlert";

const container = css({
  marginTop: spacing.m,
  borderTop: `1px solid ${uiColors.border}`,
  paddingTop: spacing.m,
});

export default function LessonQuestion(props) {
  const { answered, questionRef, scrollAmount, wasAlerted } = props;

  return (
    <div className={container}>
      {!answered && !wasAlerted && scrollAmount < 100 && (
        <LessonQuestionAlert questionRef={questionRef} />
      )}
      <div ref={questionRef}>
        <Question {...props} />
      </div>
    </div>
  );
}
