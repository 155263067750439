import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchQuestionReplies } from "../../processes/replyProccess";
import Question from "./Question";

function QuestionSearchItemContainer({
  question,
  dispatch,
  currentUser,
}) {
  useEffect(() => {
    question?.id && fetchQuestionReplies(dispatch, question.id);
  }, [dispatch, question?.id]);

  return (
    <Question
      question={question}
      currentUser={currentUser}
      readOnly
    />
  );
}

export default connect()(QuestionSearchItemContainer);
