import { apiGet, apiPut } from "./helpers/api";
import { normalize, schema } from "normalizr";
import toQueryString from "../utils/toQueryString";
import { notifySubmitError } from "./notifierProcesses";
import { sessionValidate } from "./sessionProcesses";

const course = new schema.Entity("courses", {
  meta: {
    courseOffers: [new schema.Entity("courseOffers")],
  },
});

export const fetchCourse = (dispatch, courseId) => {
  if (!courseId) return;

  return apiGet(`/api/v1/courses/${courseId}?new=true`).then((response) => {
    dispatch({
      type: "COURSE_FETCHED",
      ...normalize(response.body.data, course),
    });
  });
};

export const fetchCourses = (dispatch) => {
  dispatch({ type: "COURSES_FETCH_REQUESTED" });
  return apiGet("/api/v1/courses?new=true").then((response) => {
    dispatch({
      type: "COURSES_FETCHED",
      ...normalize(response.body.data, new schema.Array(course)),
    });
  });
};

export const submitFilter = (values, dispatch) => {
  const filters = toQueryString(values);
  apiGet(`/api/v1/courses?${filters}&new=true`).then((response) => {
    dispatch({
      type: "COURSES_FETCHED",
      ...normalize(response.body.data, new schema.Array(course)),
    });
  });
};

export const courseReinstatement = (dispatch, courseId) => {
  return apiPut(`/api/v1/courses/${courseId}/reinstatement`)
    .then(() => sessionValidate(dispatch))
    .catch(() => {
      notifySubmitError(dispatch, {
        message:
          "Não foi possível completar sua ação. Por favor, tente novamente mais tarde.",
      });
    });
};

export const fetchCourseProgress = (dispatch, courseId) => {
  return apiGet(`/api/v1/courses/${courseId}/progress`).then((response) => {
    dispatch({
      type: "COURSE_PROGRESS_FETCHED",
      course: response.body.data,
    });
  });
};
