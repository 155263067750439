import { connect } from "react-redux";
import LessonSearchForm from "./LessonSearchForm";
import { submitSearchFilter } from "../../processes/lessonProcesses";
import { reduxForm } from "redux-form";
import debounce from "../../utils/debounce";
import { getLessonsByIds } from "../../selectors/lessonSelectors";

const lessonOptions = (lessons) =>
  lessons?.slice(0, 15).map((lesson) => ({
    key: `search${lesson?.id}`,
    lesson: lesson,
  }));

function mapStateToProps(state) {
  return {
    initialValues: {},
    lessons: lessonOptions(
      getLessonsByIds(state, state.coursesList.searchLessonIds)
    ),
    loading: state.coursesList.searchLessonLoading,
    courseId: state.currentCourseId,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "courseSearchLesson",
    onChange: debounce(submitSearchFilter, 750),
  })(LessonSearchForm)
);
