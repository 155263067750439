import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

const level = new schema.Entity("levels");

export const fetchLevels = (dispatch, courseId) => {
  apiGet(`/api/v1/courses/${courseId}/levels`).then((response) => {
    dispatch({
      type: "LEVELS_FETCHED",
      ...normalize(response.body.data, new schema.Array(level)),
    });
  });
};
