import React from "react";
import { css } from "glamor";
import { makeIsMobile } from "../../utils/useScreenWidth";

const layout = css({
  display: "flex",
  flexDirection: "row",
});
const mobileLayout = css({
  overflowX: "auto",
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  "::-webkit-scrollbar": {
    display: "none",
  },
});
const verticalClass = css({
  flexDirection: "column",
});

export default function Menu({
  children,
  vertical,
  className,
  style,
  ...rest
}) {
  const isMobile = makeIsMobile();
  const baseClass = css(
    layout,
    vertical && verticalClass,
    className && className,
    isMobile && mobileLayout
  );
  return (
    <div {...rest} className={baseClass} style={style}>
      {children}
    </div>
  );
}
