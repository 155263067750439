import { connect } from "react-redux";
import CourseAnnotations from "./CourseAnnotations";
import { currentCourse } from "../../selectors/courseSelectors";

const mapStateToProps = (state) => {
  return {
    course: currentCourse(state),
  };
};

export default connect(mapStateToProps)(CourseAnnotations);
