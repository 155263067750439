import React from "react";
import { compose, css } from "glamor";
import { FaRegBell } from "react-icons/fa";
import { colors, spacing } from "../library/theme";
import { Dropdown, Icon } from "semantic-ui-react";
import NotificationListItemContainer from "./NotificationListItemContainer";

const iconColor = css({
  color: colors.primary,
});
const triggerWrapper = css({
  color: colors.greyDark,
  display: "flex",
  height: 44,
  width: 44,
  alignItems: "center",
  justifyContent: "center",
  marginRight: spacing.xs,
  marginTop: spacing.xxs,
  cursor: "pointer",
  borderRadius: "50%",
  ":hover": {
    backgroundColor: "#EEF2FB",
    color: colors.primary,
  },
});
const iconClass = css({
  marginLeft: `${spacing.xxs} !important`,
});
const headerWrapper = css({
  display: "flex",
  gap: spacing.xs,
  marginBottom: spacing.m,
});
const notificationAlert = css({
  borderRadius: "50%",
  backgroundColor: colors.primary,
  height: 10,
  width: 10,
  margin: "-18px 0 0 -8px",
});
const headerIconClass = compose(
  iconColor,
  css({
    marginTop: "3px !important",
  })
);
const menuStyle = {
  padding: spacing.s,
  left: "auto",
  right: 0,
  maxHeight: "600px",
  overflowY: "auto",
  overflowX: "hidden",
};
const headerTextStyle = {
  fontWeight: "bold",
};

const NotificationTrigger = ({ onClick, unread }) => (
  <div className={triggerWrapper} onClick={onClick}>
    <Icon.Group>
      <Icon className={iconClass}>
        <FaRegBell size={22} />
      </Icon>
      {!!unread && (
        <Icon corner>
          <div className={notificationAlert} />
        </Icon>
      )}
    </Icon.Group>
  </div>
);

export default function Notifications({
  unreadCount,
  notifications,
  onOpen,
  onClose,
  open,
}) {
  return (
    <Dropdown
      trigger={<NotificationTrigger onClick={onOpen} unread={unreadCount} />}
      icon={null}
      open={open}
      onClose={onClose}
    >
      <Dropdown.Menu style={menuStyle}>
        <div className={headerWrapper}>
          <Icon className={headerIconClass}>
            <FaRegBell size={18} />
          </Icon>
          <h3 style={headerTextStyle}>Notificações</h3>
        </div>
        {notifications.length > 0 ? (
          notifications.map((notification) => (
            <NotificationListItemContainer
              key={`notification${notification.id}`}
              notification={notification}
            />
          ))
        ) : (
          <Dropdown.Item>Nenhuma notificação nova por aqui</Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
