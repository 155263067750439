import React from "react";
import Page from "../library/Page";
import Spinner from "../library/Spinner";
import { Tab } from "semantic-ui-react";
import CompanyDashboardContainer from "./CompanyDashboardContainer";
import CompanyClassroomsContainer from "./CompanyClassroomsContainer";
import CompanyStudents from "./CompanyStudents";
import CompanyPurchases from "./CompanyPurchases";
import CompanyImports from "./CompanyImports";
import CompanyHistory from "./CompanyHistory";
import Empty from "./Empty";
import { css } from "glamor";

const tabWrapperClass = css({
  ".ui.pointing.secondary.menu": {
    overflowX: "auto",
  },
});

export default function Company({ loading, company, classrooms = [] }) {
  const panes = React.useMemo(() => {
    if (loading) return [];

    return [
      {
        menuItem: "Dashboard",
        render: () => <CompanyDashboardContainer company={company} />,
      },
      {
        menuItem: "Turmas",
        render: () => <CompanyClassroomsContainer company={company} />,
      },
      {
        menuItem: "Alunos",
        render: () => (
          <CompanyStudents company={company} classrooms={classrooms} />
        ),
      },
      {
        menuItem: "Compras",
        render: () => <CompanyPurchases company={company} />,
      },
      {
        menuItem: "Importações",
        render: () => <CompanyImports company={company} />,
      },
      {
        menuItem: "Histórico",
        render: () => <CompanyHistory company={company} />,
      },
    ];
  }, [loading, company, classrooms]);

  return (
    <Page title={company?.name}>
      {loading ? (
        <Spinner wrapped />
      ) : (
        <div className={tabWrapperClass}>
          <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        </div>
      )}
    </Page>
  );
}
