import React from "react";
import { css } from "glamor";
import { Button, Link, spacing } from "../library";
import { Form, TextField } from "../library/forms";

const container = { margin: spacing.m };
const fieldTitleStyle = { color: "black", marginBottom: spacing.xxs };
const linkWrapper = {
  display: "flex",
  justifyContent: "center",
  marginTop: spacing.m,
  marginBottom: spacing.xxs,
  fontSize: 12,
  cursor: "pointer",
};
const buttonStyle = {
  width: 100,
};
const fieldWrapper = css({
  display: "flex",
  gap: spacing.s,
});
const textFieldWrapper = { flex: 1 };
const emoticonStyle = { fontSize: 18 };
const textStyle = { color: "black", fontSize: 12, lineHeight: 1.5 };

function round(progress) {
  return Math.round(progress * 100);
}

export default function CertificationExamForm(props) {
  const { handleSubmit, handlePostponeAnswer, course } = props;

  return (
    <div style={container}>
      <p style={emoticonStyle}>👏</p>
      <p style={{ ...textStyle, marginBottom: 14 }}>
        Uau! Parabéns por concluir {round(course.meta.progress)}% do curso{" "}
        <strong>{course?.name}</strong>. Queremos te acompanhar nesta reta
        final, fornecendo materiais preparatórios para o seu exame!
      </p>

      <p style={textStyle}>
        Você já fez o agendamento da sua prova? Se sim, pode compartilhar a data
        conosco?
      </p>
      <Form onSubmit={handleSubmit}>
        <p style={fieldTitleStyle}>
          <span style={{ color: "red" }}>*</span>{" "}
          <strong>Informar a data da prova</strong>
        </p>
        <div className={fieldWrapper}>
          <div style={textFieldWrapper}>
            <TextField name="scheduledAt" type="date" fluid required />
          </div>

          <Button
            style={buttonStyle}
            circular={false}
            color="blue"
            type="submit"
            fluid
          >
            Salvar
          </Button>
        </div>
        <div style={linkWrapper}>
          <Link onClick={handlePostponeAnswer}>Preencher depois</Link>
        </div>
      </Form>
    </div>
  );
}
