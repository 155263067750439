import { css } from "glamor";
import React, { useCallback, useState } from "react";
import { Button, Link, colors, spacing } from "../library";
import TermsContainer from "../terms/TermsContainer";

const buttonsWrapper = css({
  display: "flex",
  flex: 1,
  gap: spacing.m,
  marginTop: spacing.m,
  alignItems: "center",
  justifyContent: "flex-end",
});

const updateButton = {
  marginRight: 0,
};

const linkStyle = {
  color: colors.primary,
};

export default function SubmitButton({ submitting, pristine, invalid }) {
  const [openTerms, setOpenTerms] = useState(false);
  const openTermsHandler = useCallback(() => {
    setOpenTerms(true);
  }, [openTerms]);
  const closeTermsHandler = useCallback(() => {
    setOpenTerms(false);
  }, [openTerms]);

  return (
    <div className={buttonsWrapper}>
      <TermsContainer open={openTerms} onClose={closeTermsHandler} justShow />
      <Link onClick={openTermsHandler} style={linkStyle}>
        Termos de Uso
      </Link>
      <Button
        style={updateButton}
        circular={false}
        secondary
        size="large"
        loading={submitting}
        disabled={submitting || pristine || invalid}
      >
        Atualizar perfil
      </Button>
    </div>
  );
}
