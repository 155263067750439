import { css } from "glamor";
import React from "react";
import randomBetween from "../../../utils/randomBetween";
import Button from "../../library/Button";
import CircularProgress from "../../library/CircularProgress";
import Link from "../../library/Link";
import Paragraph from "../../library/Paragraph";
import { colors, spacing, styles } from "../../library/theme";
import MockReportGraph from "../../myPerformance/MockReportGraph";

const container = css({
  padding: spacing.m,
});
const generalGradeStyle = {
  margin: spacing.m,
};
const backLinkClass = css({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: spacing.m,
  "@media(max-width: 992px)": { justifyContent: "center" },
});
const titleWrapper = css(styles.roundedCorners, {
  flex: 1,
  minHeight: 200,
  padding: spacing.l,
});
const titleClass = css({
  color: colors.primaryLighter,
});
const subTitleStyle = {
  color: colors.greyShadow,
  fontSize: 18,
};
const approvationText = {
  color: colors.greyShadow,
  fontSize: 18,
};
const content = {
  textAlign: "center",
};
const levelSummaryContainer = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};
const levelSummaryStyle = {
  textAlign: "left",
  fontSize: 16,
};
const reviewAnswersStyle = {
  backgroundColor: colors.primary,
  color: "white",
};

const getSentenceByScore = (score, firstName) => {
  if (score >= 90) {
    return [
      `Você está trabalhando montando as questões do Exame?! Que baita nota, ${firstName}! Parabéns!`,
      "Tirou de letra! Que nota incrível, parabéns! Continue assim e a prova vai ficar tranquila.",
      "Que nota boa, hein?! Ainda assim, recomendamos que você veja os vídeos das questões, tá bom? Pode te ajudar ainda mais a fixar o conteúdo.",
      `Incrível! Parabéns pela nota, ${firstName}. Isso é fruto de todo o seu comprometimento com os estudos!`,
    ][randomBetween(0, 3)];
  }

  if (score >= 70 && score < 90) {
    return [
      `Booooa! Mandou bem demais. Parabéns por essa nota, ${firstName}!`,
      `A certificação está cada vez mais perto! Continue assim, ${firstName}!`,
      "Parabéns! Com essa nota, a Certificação estaria em suas mãos. Continue com esse desenvolvimento!",
      `Você está dominando o conteúdo, ${firstName}! Parabéns por esse simulado.`,
      `Mandou muuuuito bem! Parabéns, ${firstName}! Continue assim e a prova será só um detalhe.`,
    ][randomBetween(0, 4)];
  }

  if (score < 70) {
    return [
      `Não desista, ${firstName}! Vamos reforçar os pontos fracos para atingir a nota necessária.`,
      `${firstName}, com essa nota a sua aprovação não viria. Mas sem desespero! Veja a correção e acione o nosso time caso tenha dúvidas.`,
      "Não se apegue a essa nota, vamos continuar estudando! Veja os vídeos explicativos para saber onde você errou.",
      "Parabéns pela conclusão do simulado! Apesar da nota, você já está em desenvolvimento. Você está no caminho certo!",
      "Esse simulado estava difícil... veja os vídeos das perguntas que você acertou e errou. Isso pode te ajudar a compreender mais o assunto.",
      "Calma... apesar da nota, nós vamos melhorar isso! Não desanime e vamos pra cima.",
      "Parabéns por ter finalizado esse simulado! Lembre-se que você não precisa estudar sem ajuda. O nosso time está à sua disposição.",
    ][randomBetween(0, 6)];
  }
};

const renderLevelsSummary = ({ approvedLevelsNames, failedLevelsNames }) => {
  return (
    <div style={levelSummaryContainer}>
      {approvedLevelsNames && (
        <div style={levelSummaryStyle}>
          Módulos aprovados: {approvedLevelsNames}.
        </div>
      )}
      {failedLevelsNames && (
        <div style={levelSummaryStyle}>
          Módulos reprovados: {failedLevelsNames}.
        </div>
      )}
    </div>
  );
};

export default function MockResults(props) {
  const { currentUser, isFinished, mock, mockTemplate, score, levelsSummary } =
    props;
  const mockResults = mock?.results?.filter(
    (levelWithResult) => !levelWithResult?.sublevelId
  );
  const graphLabels = mockResults?.map(
    (level) => `Módulo ${level.name.split(" ")[0]}`
  );
  const graphCorrects = mockResults?.map((level) => level.countTrue);
  const graphWrongs = mockResults?.map((level) => level.countFalse);
  const graphCuttoffs = mockTemplate?.levels?.map(
    (level) => level.minScore / 100
  );
  const average = mockTemplate && mockTemplate?.minScore / 100;
  const hasMockResults = !!mock?.results?.length;
  const canViewRanking = !mock?.exam && !!mock?.mockTemplateId;

  return isFinished && hasMockResults ? (
    <div className={container}>
      <div style={content}>
        <div className={titleWrapper}>
          <Paragraph
            style={subTitleStyle}
          >{`${currentUser?.firstName}, confira o seu resultado:`}</Paragraph>
          <h1 className={titleClass}>{mock?.title}</h1>
          <div style={generalGradeStyle}>
            <CircularProgress value={score} size={100} />
          </div>
          <Paragraph style={approvationText}>
            {getSentenceByScore(score, currentUser?.firstName)}
            {mockTemplate?.courseSlug == "cfp" &&
              renderLevelsSummary(levelsSummary)}
          </Paragraph>
        </div>

        <MockReportGraph
          labels={graphLabels}
          corrects={graphCorrects}
          wrongs={graphWrongs}
          cuttoffs={graphCuttoffs}
          average={average}
        />
      </div>
    </div>
  ) : null;
}
