const getMockRankings = (action) => action.entities.mockRankings;

export default function mockRankingsReducer(state = {}, action) {
  switch (action.type) {
    case "MOCK_RANKING_FETCHED":
      return { ...state, ...getMockRankings(action) };
    default:
      return state;
  }
}
