import { css } from "glamor";
import React from "react";
import { colors, spacing } from "../library/theme";

const levelGradeClass = css({
  fontSize: 24,
  color: colors.primaryDarker,
  fontWeight: "bolder",
  marginLeft: spacing.xs,
});
const approvedGradeClass = css(levelGradeClass, {
  color: colors.secondaryDark,
});
const levelNameStyle = {
  minHeight: 40,
  display: "flex",
  alignItems: "center",
};

const getScore = (level) => {
  //Mobile app uses this snake_case prop item of results aray of mock object
  //this variable need to be serialized and return key in camelCase

  const correct = level?.countTrue;
  const total = level?.countTrue + level?.countFalse;
  return parseInt((correct / total) * 100);
};

const getMinScore = (mockTemplate, mockTemplateLevel) => {
  return !!mockTemplate?.levelMinScoreEnabled && !!mockTemplateLevel?.minScore
    ? mockTemplateLevel?.minScore
    : null;
};

export default function MockResultsLevelItem(props) {
  const { level, mockTemplate } = props;
  const mockTemplateLevel = mockTemplate?.levels?.find(
    (levelMockTemplate) => levelMockTemplate.levelId == level.id
  );
  const showStatus =
    mockTemplate?.levelMinScoreEnabled && mockTemplateLevel?.minScore;
  const score = getScore(level);
  const minScore = getMinScore(mockTemplate, mockTemplateLevel);
  const isScoreEnough = score >= minScore;

  return (
    <>
      <h4 style={levelNameStyle}>
        {level.name} {showStatus && `(${mockTemplateLevel?.minScore}% min)`}
        <span
          className={css(levelGradeClass, isScoreEnough && approvedGradeClass)}
        >
          {score}%
        </span>
      </h4>
    </>
  );
}
