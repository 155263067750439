import { css } from "glamor";
import React, { useCallback, useState } from "react";
import { Menu, Grid } from "semantic-ui-react";
import { colors, spacing } from "../library";
import TextInput from "../library/forms/TextInput";

const container = css({ marginBottom: spacing.l });

const menuWrapperClass = css({
  position: "relative",
  marginBottom: spacing.m,
  "> .ui.menu": {
    marginBotom: "none !important",
  },
});

const menuClass = css({
  flexWrap: "wrap",
  gap: spacing.xxs,
  "@media(max-width: 767px)": {
    paddingBottom: spacing.s,
  },
});

const menuItemClass = css({
  padding: `${spacing.s} ${spacing.xs} !important`,
  ".active": {
    borderColor: `${colors.primary} !important`,
    fontWeight: "bold !important",
    "@media(max-width: 767px)": {
      borderBottom: "none !important",
      color: `${colors.primary} !important`,
    },
  },
});

const paneWrapperClass = css({
  // display: "grid",
  // gridTemplateColumns: "repeat(auto-fill, minmax(260px, 1fr))",
  // gap: spacing.m,
  margin: "0 auto",
  width: "100%",
});

const TabMenuItem = ({
  paneIndex,
  setSelectedPaneIndex,
  selectedPaneIndex,
  panes,
}) => {
  const changeSelectedTab = useCallback(() => {
    setSelectedPaneIndex(paneIndex);
  }, [paneIndex]);

  return (
    <Menu.Item
      content={panes[paneIndex]?.title}
      active={selectedPaneIndex == paneIndex}
      onClick={changeSelectedTab}
      className={menuItemClass}
    />
  );
};

export default function TabMenu({ panes = [], initialPaneIndex = 0 }) {
  const [selectedPaneIndex, setSelectedPaneIndex] = useState(initialPaneIndex);

  const Pane = panes[selectedPaneIndex]?.render;

  return (
    <div className={container}>
      <div className={menuWrapperClass}>
        <Menu
          secondary
          fluid
          stackable
          pointing
          className={menuClass}
          items={panes.map((pane, index) => (
            <TabMenuItem
              key={index}
              paneIndex={index}
              panes={panes}
              selectedPaneIndex={selectedPaneIndex}
              setSelectedPaneIndex={setSelectedPaneIndex}
            />
          ))}
        />
      </div>

      <div className={paneWrapperClass}>
        <Pane />
      </div>
    </div>
  );
}
