import { css } from "glamor";
import React from "react";
import { Card, Modal, Dimmer } from "semantic-ui-react";
import Spinner from "../library/Spinner";
import { colors, spacing } from "../library/theme";
import QuestionSearchEmpty from "./QuestionSearchEmpty";
import QuestionSearchFormContainer from "./QuestionSearchFormContainer";
import QuestionSearchItemContainer from "./QuestionSearchItemContainer";

const resultCardStyle = {
  padding: spacing.m,
  margin: `${spacing.m} 0px 0px 0px`,
};
const modalContent = css({
  minHeight: "75vh",
});
const titleClass = css({
  margin: 0,
  color: colors.primary,
  fontWeight: "bold",
  marginBottom: spacing.m,
});
const questionClass = css({
  marginTop: spacing.m,
});
const blockedClass = css({
  padding: `${spacing.xxl} ${spacing.m}`,
  textAlign: "center",
});

const QuestionSearchResult = ({ questions, currentUser }) => {
  return questions.length > 0 ? (
    questions?.map((question) => (
      <Card fluid style={resultCardStyle} key={`question${question?.id}`}>
        <QuestionSearchItemContainer
          question={question}
          currentUser={currentUser}
        />
      </Card>
    ))
  ) : (
    <QuestionSearchEmpty />
  );
};

export default function QuestionSearch(props) {
  const {
    questions,
    isSearching,
    loading,
    currentUser,
    handleClose,
    hasMockCourses,
    courseLoading,
  } = props;

  return (
    <Modal open closeIcon onClose={handleClose}>
      <Modal.Content className={modalContent}>
        {courseLoading ? (
          <Dimmer active inverted>
            <Spinner text="Carregando" wrapped />
          </Dimmer>
        ) : (
          <>
            <h1 className={titleClass}>Buscar Questões</h1>

            <QuestionSearchFormContainer
              loading={loading}
              disabled={!hasMockCourses}
              {...props}
            />

            <div className={questionClass}>
              {!hasMockCourses && (
                <p className={blockedClass}>Você não pode pesquisar questões</p>
              )}

              {loading && (
                <Dimmer active inverted>
                  <Spinner text="Buscando questões" wrapped />
                </Dimmer>
              )}

              {!loading && isSearching && (
                <QuestionSearchResult
                  questions={questions}
                  currentUser={currentUser}
                />
              )}
            </div>
          </>
        )}
      </Modal.Content>
    </Modal>
  );
}
