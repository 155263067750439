import React from "react";
import Button from "../library/Button";
import Progress from "../library/Progress";
import Link from "../library/Link";
import { colors, spacing } from "../library/theme";
import { css } from "glamor";
import { makeIsMobile } from "../../utils/useScreenWidth";
import moment from "moment";
import { matchPath, useLocation } from "react-router";

const container = css({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: spacing.s,
  gap: spacing.s,
  background: colors?.white,
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  lineHeight: 1,
  borderRadius: spacing.s,
  "@media(max-width: 555px)": {
    width: "100%",
  },
});
const mobileClass = css({
  margin: `${spacing.m} 0px`,
});
const leftContent = css({
  flex: 1,
});
const titleWrapper = css({});
const titleClass = css({
  fontWeight: "600",
  color: colors.primary,
  fontSize: "14px",
  marginBottom: spacing.xxs,
});
const subtitleClass = css({
  marginTop: spacing.xxs,
  display: "block",
  fontSize: 12,
  color: colors.grey,
});

export default function CourseUnlock({
  course,
  loading,
  reinstatementHandler,
}) {
  const isMobile = makeIsMobile();

  return course ? (
    <div className={css(container, isMobile && mobileClass)}>
      <div className={leftContent}>
        <div className={titleClass}>Curso Trancado</div>
        <span className={subtitleClass}>Retome o aprendizado</span>
      </div>

      <Link
        component={Button}
        disabled={loading}
        loading={loading}
        onClick={reinstatementHandler}
        color="buy"
      >
        Destrancar curso
      </Link>
    </div>
  ) : null;
}
