import { css } from "glamor";
import React from "react";
import { colors, spacing } from "../library/theme";

const container = css({
  width: "100%",
  ":hover": {
    cursor: "pointer",
    color: colors.secondary,
  },
  "& + &": {
    borderTop: `2px solid ${colors.greyDarker}`,
    marginTop: spacing.m,
    paddingTop: spacing.m,
  },
});
const content = {
  display: "flex",
  gap: spacing.s,
};
const thumbanail = css({
  backgroundSize: "cover !important",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat !important",
  width: 84,
  minWidth: 84,
  height: 67,
  borderRadius: spacing.xs,
  border: "2px solid inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const indexStyle = {
  fontSize: 32,
  color: colors.greyLighter,
  fontWeight: "bolder",
};
const titleWrapper = {
  textAlign: "initial",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};
const title = css({
  color: colors.greyLighter,
  fontSize: 18,
  whiteSpace: "pre-line",
  lineHeight: 1,
  wordBreak: "break-word",
});
const selectedTitle = {
  color: colors.secondary,
};
const selectedIndex = {
  border: `2px solid ${colors.secondary}`,
};

function addLeadingZeros(num, totalLength = 2) {
  return String(num).padStart(totalLength, "0");
}

export default function TrailCourseListItem({
  course,
  selectedCourse,
  position,
  courseCategories,
  selectCourseHandler,
}) {
  if (!course) {
    return null;
  }

  const isSelected = selectedCourse?.id == course.id;

  return (
    <div className={container} onClick={selectCourseHandler(course)}>
      <div style={content}>
        <div
          className={css(thumbanail, isSelected && selectedIndex, {
            background: `linear-gradient(180deg, #180A2980 0%, #180A29 100%), url(${course?.imageUrl})`,
          })}
        >
          <div style={indexStyle}>{addLeadingZeros(position)}</div>
        </div>
        <div style={titleWrapper}>
          <p className={css(title, isSelected && selectedTitle)}>
            {course.name}
          </p>
          <p>{courseCategories.map((item) => item.name)[0]}</p>
        </div>
      </div>
    </div>
  );
}
