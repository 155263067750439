import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

const content = new schema.Entity("contents");

export const fetchContinueLessons = (dispatch) => {
  return apiGet("/api/v1/continue_lessons").then((response) => {
    dispatch({
      type: "CONTINUE_LESSONS_FETCHED",
      lessons: response.body.data,
    });
  });
};

export const fetchContinueMocks = (dispatch) => {
  return apiGet("/api/v1/continue_mocks").then((response) => {
    dispatch({
      type: "CONTINUE_MOCKS_FETCHED",
      mocks: response.body.data,
    });
  });
};
