import React, { useCallback } from "react";
import { connect } from "react-redux";
import { change } from "redux-form";
import Button from "../Button";

function ButtonField({
  value,
  dispatch,
  formName,
  fieldName,
  onClick = () => {},
  ...rest
}) {
  const clickHandler = useCallback(() => {
    onClick();
    dispatch(change(formName, fieldName, value));
  }, [dispatch, formName, fieldName, value, onClick]);

  return <Button type="button" {...rest} onClick={clickHandler} />;
}

export default connect()(ButtonField);
