import { css } from "glamor";
import React from "react";
import { FaRegEye } from "react-icons/fa";
import { TbFileDownload } from "react-icons/tb";
import { HiOutlineTrophy } from "react-icons/hi2";
import { Button } from "semantic-ui-react";
import formatDate from "../../utils/formatDate";
import Link from "../library/Link";
import { colors, spacing, typographyColors } from "../library/theme";
import MockProgressBar from "./MockProgressBar";
import Popup from "../library/Popup";

const container = css({
  "> td": {
    padding: `${spacing.s} ${spacing.m}`,
    border: `1px solid ${colors.greyLight}`,
    borderLeft: "none",
    borderRight: "none",
    color: colors.greyShadow,
    "@media (max-width: 768px)": {
      marginLeft: spacing.s,
      marginRight: spacing.s,
      display: "block",
      border: `1px solid ${colors.greyLight}`,
      borderTop: "none",
      borderBottom: "none",
    },
  },
  "> td:first-of-type": {
    border: `1px solid ${colors.greyLight}`,
    borderTopLeftRadius: spacing.xs,
    borderBottomLeftRadius: spacing.xs,
    borderRight: "none",
    "@media (max-width: 768px)": {
      border: `1px solid ${colors.greyLight}`,
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: spacing.xs,
      borderTopRightRadius: spacing.xs,
    },
  },
  ">td:nth-child(8), td:nth-child(9)": {
    borderLeft: `1px solid ${colors.greyLight}`,
  },
  "> td:last-of-type": {
    border: `1px solid ${colors.greyLight}`,
    borderTopRightRadius: spacing.xs,
    borderBottomRightRadius: spacing.xs,
    "@media (max-width: 768px)": {
      border: `1px solid ${colors.greyLight}`,
      borderBottomLeftRadius: spacing.xs,
      borderBottomRightRadius: spacing.xs,
      borderTopRightRadius: 0,
    },
  },
});
const disabledLinkClass = css({
  color: `${typographyColors.defaultTitle} !important`,
  cursor: "default",
  opacity: 0.5,
});

const continueButtonStyle = {
  marginRight: "0px !important",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: colors.primary,
  color: colors.white,
  borderRadius: 15,
};
const linkWrapper = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: spacing.xxs,
  fontSize: 16,
  whiteSpace: "nowrap",
});
const linkClass = css({
  color: `${typographyColors.defaultTitle} !important`,
  textDecoration: "underline",
});
const rankingLinkClass = css(linkClass, {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export default function MockListItem(props) {
  const { mock } = props;
  const completed = mock?.exam ? mock?.finishedAt : mock.progress >= 100;
  const mockLinkLabel = completed ? "Ver simulado" : "Continuar";
  const linkProps = completed
    ? { className: linkClass }
    : { component: Button, style: continueButtonStyle };
  const warningText =
    !!mock?.mockTemplateId && !mock?.exam
      ? "Este simulado não foi finalizado"
      : "Este simulado/prova não possui ranking";
  const rankingEnabled =
    !!mock?.mockTemplateId && !mock?.exam && mock?.finishedAt;

  return (
    <tr className={container}>
      <td>{mock.id}</td>
      <td>{formatDate(mock.createdAt, "DD/MM/YYYY")}</td>
      <td>
        <Link to={`/mocks/${mock.id}`}>{mock?.title}</Link>
      </td>
      <td textAlign="center">{mock.questionIds.length}</td>
      <td textAlign="center">{completed ? "Finalizado" : "Em andamento"}</td>
      <td>
        <MockProgressBar value={mock.progress} />
      </td>
      <td textAlign="center">
        {completed && mock?.average != null
          ? `${mock.average.toFixed(0)}%`
          : "--"}
      </td>
      <td>
        <div className={linkWrapper}>
          <TbFileDownload size={20} />
          <Link className={linkClass} href={mock?.exportUrl} target="_blank">
            PDF
          </Link>
        </div>
      </td>
      <td>
        <div className={linkWrapper}>
          <Popup
            position="top center"
            inverted
            content="Ranking"
            disabled={!rankingEnabled}
            trigger={
              <Link
                className={rankingLinkClass}
                to={rankingEnabled && `/mocks/${mock.id}/ranking`}
              >
                <HiOutlineTrophy
                  size={20}
                  color={rankingEnabled ? "black" : "darkgrey"}
                />
              </Link>
            }
          />
        </div>
      </td>
      <td>
        <div className={linkWrapper}>
          {completed && <FaRegEye size={20} />}
          <Link to={`/mocks/${mock.id}`} circular={false} {...linkProps}>
            {mockLinkLabel}
          </Link>
        </div>
      </td>
    </tr>
  );
}
