import { css } from "glamor";
import React from "react";
import { makeIsMobile } from "../../utils/useScreenWidth";
import { Link } from "../library";
import Logo from "../library/images/logo-small.svg";
import { colors } from "../library/theme";
import SidebarContainer from "../sidebar/SidebarContainer";
import UserSidebarContainer from "../sidebar/UserSidebarContainer";

const LOGO_SIZE = 60;

const container = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
const titleClass = css({
  margin: 0,
  color: colors.primary,
  fontWeight: "bold",
});
const logoClass = css({
  width: LOGO_SIZE,
});

export default function Header({ title }) {
  const isMobile = makeIsMobile();

  return (
    <div className={container}>
      {isMobile ? (
        <SidebarContainer />
      ) : (
        <h1 className={titleClass}>{title}</h1>
      )}
      {isMobile && (
        <Link to="/">
          <img src={Logo} className={logoClass} />
        </Link>
      )}
      <UserSidebarContainer />
    </div>
  );
}
