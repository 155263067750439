import React from "react";
import { Card } from "semantic-ui-react";
import { spacing, uiColors } from "../library/theme";
import UserHeaderContainer from "./UserHeaderContainer";
import { css } from "glamor";

const cardContentStyle = {
  display: "flex",
  flexDirection: "column",
};
const userWrapper = css({
  width: "100%",
  "& + &": {
    marginTop: spacing.xs,
    borderTop: `1px solid ${uiColors.border}`,
    paddingTop: spacing.xs,
  },
});

export default function ContentTopUsersCard(props) {
  const { topUsers } = props;

  return topUsers?.length ? (
    <Card fluid>
      <Card.Content>
        <Card.Header>Membros mais ativos</Card.Header>
      </Card.Content>
      <Card.Content style={cardContentStyle}>
        {topUsers.map((user) => (
          <div className={userWrapper} key={`MostActiveMember${user?.id}`}>
            <UserHeaderContainer user={user} shortcut />
          </div>
        ))}
      </Card.Content>
    </Card>
  ) : null;
}
