import React from "react";
import { Card } from "semantic-ui-react";
import Link from "../library/Link";
import { spacing, styles, uiColors, typographyColors } from "../library/theme";
import { css } from "glamor";
import Button from "../library/Button";

const container = {
  marginBottom: spacing.m,
};
const cardContentStyle = {
  display: "flex",
  flexDirection: "column",
  flex: 1,
};

const categoryClass = css(styles.roundedCorners, styles.shadow, {
  cursor: "pointer",
  padding: spacing.xs,
  textAlign: "center",
  backgroundColor: uiColors.border,
  fontWeight: "bold",
  "& + &": {
    marginTop: spacing.s,
  },
  ":hover": {
    backgroundColor: uiColors.backgroundDark,
    color: typographyColors.inverseTitle,
  },
});

export default function ContentTopCategoriesCard(props) {
  const { topCategories, content } = props;

  return topCategories?.length > 0 ? (
    <Card fluid style={container}>
      <Card.Content>
        <Card.Header>Assuntos em alta</Card.Header>
      </Card.Content>
      <Card.Content style={cardContentStyle}>
        {topCategories.map((category, index) => (
          <Link
            component={Button}
            to={`/contents/${content?.id}/feed?categoryId=${category.id}`}
            key={`topCategory${category?.id}${index}`}
            className={categoryClass.toString()}
            fluid
          >
            {category.name}
          </Link>
        ))}
      </Card.Content>
    </Card>
  ) : null;
}
