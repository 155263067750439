import React from "react";
import Spinner from "../library/Spinner";
import Button from "../library/Button";
import { spacing } from "../library/theme";
import { CardForm, TextField } from "../library/forms";
import Logo from "../library/images/logo.svg";
import Link from "../library/Link";

const container = {
  width: "100%",
  padding: spacing.s,
};
const formStyle = {
  width: 340,
  margin: `${spacing.xl} auto`,
};
const textStyle = {
  maxWidth: "350px",
  textAlign: "center",
  fontSize: 14,
};
const content = {
  width: 340,
  margin: `${spacing.xl} auto`,
  textAlign: "center",
};
const buttonStyle = {
  marginTop: spacing.s,
};
const logoStyle = {
  width: 200,
  cursor: "pointer",
};

export default function CertificateUser(props) {
  const { certificateData, submitting, handleSubmit, loading, error } = props;
  return (
    <div style={container}>
      <div style={content}>
        <Link
          to="/signin"
          component="img"
          style={logoStyle}
          src={Logo}
          alt="T2 Logo"
        />
        <CardForm onSubmit={handleSubmit} error={error} style={formStyle}>
          <TextField
            label="Valide seu certificado"
            name="code"
            fluid
            placeholder="Digite o código"
          />
          <Button color="blue" type="submit" style={buttonStyle}>
            Enviar
          </Button>
        </CardForm>
        <div style={content}>
          {submitting && <Spinner inline />}
          {certificateData?.error && <h2>Código Inválido</h2>}
          {!loading && certificateData?.userName && (
            <div>
              <h2>Certificado Válido</h2>
              <p style={textStyle}>
                {`${certificateData?.userName} concluiu com total aproveitamento ${certificateData?.certifiableName} com a carga horária de ${certificateData?.workloadText}`}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
