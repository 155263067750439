import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { fetchMocks } from "../../processes/mockProccess";
import { hasMockCourses } from "../../selectors/courseSelectors";
import {
  getMocksByFetchedIds,
  makeIsFirstFinishedMock,
} from "../../selectors/mockSelectors";
import { useMonitorScreen } from "../../utils/useScreenWidth";
import Mocks from "./Mocks";
import { getCurrentUser } from "../../selectors/userSelectors";

function MocksContainer(props) {
  const { dispatch, filters } = props;

  const isMonitor = useMonitorScreen();

  const fetchMocksHandler = useCallback(
    (_, data) => {
      return fetchMocks(filters, dispatch, { page: data?.activePage });
    },
    [dispatch, JSON.stringify(filters)]
  );

  useEffect(() => {
    fetchMocksHandler(dispatch);
  }, [dispatch, fetchMocksHandler]);

  return (
    <Mocks
      fetchMocksHandler={fetchMocksHandler}
      isMonitor={isMonitor}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const formSelector = formValueSelector("mocksFilterForm");
  const currentUser = getCurrentUser(state);

  return {
    filters: formSelector(state, "courseIdEq", "finishedAtNull"),
    loading: state.mockList.loading,
    totalPages: state.mockList.totalPages,
    currentPage: state.mockList.currentPage,
    mockCreated: state.mockList.mockCreated,
    hasMockCourses: hasMockCourses(state),
    hasMocks: currentUser?.hasMocks,
    mocks: getMocksByFetchedIds(state),
    isFirstMock: makeIsFirstFinishedMock(state),
  };
}

export default connect(mapStateToProps)(MocksContainer);
