import { connect } from "react-redux";
import { currentCourse } from "../../selectors/courseSelectors";
import {
  getCurrentLesson,
  getCurrentLessonId,
} from "../../selectors/lessonSelectors";
import { getCurrentMock } from "../../selectors/mockSelectors";
import { getCurrentUser } from "../../selectors/userSelectors";
import Sidebar from "./Sidebar";

const mapStateToProps = (state) => {
  const lessonId = getCurrentLessonId(state);

  return {
    user: getCurrentUser(state),
    lesson: getCurrentLesson(state, lessonId),
    currentCourse: currentCourse(state),
    mock: getCurrentMock(state),
  };
};

export default connect(mapStateToProps)(Sidebar);
