import React from "react";
import { css } from "glamor";
import LeaderboardPodium from "./LeaderboardPodium";
import LeaderboardRanking from "./LeaderboardRanking";

const container = css({
  width: "100%",
});

export default function Leaderboard({ data, highlightedId }) {
  const podiumData = data.slice(0, 3);
  const rankingData = data.slice(3);

  return !!data.length ? (
    <div className={container}>
      <LeaderboardPodium data={podiumData} highlightedId={highlightedId} />
      <LeaderboardRanking data={rankingData} highlightedId={highlightedId} />
    </div>
  ) : null;
}
