import React from "react";
import { Modal } from "semantic-ui-react";
import Link from "../library/Link";
import { css } from "glamor";
import Button from "../library/Button";
import { DropDownField } from "../library/forms";
import makeDropDownOptions from "../../utils/makeDropDownOptions";
import { colors } from "../library/theme";

const container = css({
  position: "absolute",
  top: 65,
  right: 140,
  "@media(max-width: 575px)": {
    top: 30,
  },
});
const linkStyle = {
  cursor: "pointer",
  color: colors.secondary,
};

export default function TrailChangeForm({
  openModal,
  toggleModalHandler,
  trails,
  submitHandler,
}) {
  return (
    <div className={container}>
      <Link onClick={toggleModalHandler} style={linkStyle}>
        MUDAR TRILHA
      </Link>
      <Modal
        open={openModal}
        closeIcon
        onClose={toggleModalHandler}
        size="mini"
      >
        <Modal.Header>Alterar trilha</Modal.Header>
        <Modal.Content>
          <DropDownField
            name="trailId"
            options={makeDropDownOptions(trails)}
            placeholder="Escolha sua trilha"
            fluid
          />
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={submitHandler}>
            CONFIRMAR
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}
