import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { getCurrentUser } from "../../selectors/userSelectors";
import { fetchTerms, acceptTerm } from "../../processes/termProcesses";
import { getTerm, getIsRenewal } from "../../selectors/termSelectors";
import Terms from "./Terms";
import { getConfigByName } from "../../selectors/configSelectors";

function TermsContainer(props) {
  const { term, currentUser, dispatch, open, justShow } = props;

  const active =
    (term && currentUser && !currentUser.acceptedTerms && !justShow) ||
    (open && term);

  useEffect(() => fetchTerms(dispatch), [dispatch]);

  const onClickHandler = useCallback(() => {
    acceptTerm(dispatch, term.id, currentUser.id);
  }, [dispatch, term, currentUser]);

  return active ? (
    <Terms onClickHandler={onClickHandler} open={active} {...props} />
  ) : null;
}

function mapStateToProps(state) {
  return {
    term: getTerm(state),
    isRenewal: getIsRenewal(state),
    currentUser: getCurrentUser(state),
    cookiesPolicyUrl: getConfigByName(state, "cookiesPolicyUrl"),
    privacyPolicyUrl: getConfigByName(state, "privacyPolicyUrl"),
    privacyWarningUrl: getConfigByName(state, "privacyWarningUrl"),
  };
}

export default connect(mapStateToProps)(TermsContainer);
