import React from "react";
import { css } from "glamor";
import AnnotationListItemContainer from "./AnnotationListItemContainer";
import Link from "../library/Link";
import Page from "../library/Page";
import Spinner from "../library/Spinner";
import Button from "../library/Button";
import Icon from "../library/Icon";
import { colors, spacing } from "../library/theme";

const container = css({
  width: "100%",
});
const emptyClass = css({
  textAlign: "center",
  margin: "auto",
  padding: spacing.m,
  paddingTop: spacing.xxl,
});
const errorWrapperClass = css({
  padding: spacing.m,
  paddingTop: spacing.xxl,
});

export default function AnnotationsList({
  annotations,
  lesson,
  order,
  scope,
  loading,
  error,
  handleFetchCourseAnnotations,
}) {
  return (
    <div className={container}>
      {error && !loading && (
        <div className={errorWrapperClass}>
          <p>Erro ao carregar anotações</p>

          <Button onClick={handleFetchCourseAnnotations}>
            <Icon name="refresh" /> Recarregar anotações
          </Button>
        </div>
      )}

      {!error && (
        <>
          {loading ? (
            <Spinner wrapped text="Carregando anotações..." />
          ) : (
            <>
              {annotations.length === 0 ? (
                <div className={emptyClass}>
                  Não há anotações{lesson ? " para esta aula" : ""}.
                </div>
              ) : (
                annotations.map((annotation) => (
                  <AnnotationListItemContainer
                    key={annotation?.id}
                    annotation={annotation}
                  />
                ))
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
