import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import ContentFeed from "./ContentFeed";
import { getFetchedContentComments } from "../../selectors/commentSelectors";
import { fetchContentComments } from "../../processes/commentProcesses";
import { getCurrentUser } from "../../selectors/userSelectors";
import { getFormValues } from "redux-form";
import { getContentById } from "../../selectors/contentSelectors";

function ContentFeedContainer(props) {
  const { dispatch, content, filters, initialCategoryId } = props;
  const { id } = content;

  const fetchCommentsHandler = useCallback(
    (_, data) => {
      fetchContentComments(dispatch, id, filters, data?.activePage);
    },
    [dispatch, id, filters]
  );

  useEffect(() => {
    const initialCategory = {
      categoriesIdIn: initialCategoryId ? [initialCategoryId] : undefined,
    };
    const initialFilters = {
      ...initialCategory,
    };
    fetchContentComments(dispatch, id, initialFilters);
  }, [dispatch, id, initialCategoryId]);

  return <ContentFeed fetchCommentsHandler={fetchCommentsHandler} {...props} />;
}

function mapStateToProps(state, ownProps) {
  const { contentId } = ownProps.match.params;
  const categoryFormValues = getFormValues("contentCategoryForm");
  const categoryMobileFormValues = getFormValues("contentCategoryMobileForm");
  const filters = {
    ...categoryFormValues(state),
    ...categoryMobileFormValues(state),
  };
  const initialCategoryId = Number(
    new URLSearchParams(ownProps.location.search).get("categoryId")
  );

  return {
    content: getContentById(state, contentId),
    filters,
    loading: state.contentFeed.loading,
    totalPages: state.contentFeed.totalPages,
    currentPage: state.contentFeed.currentPage,
    comments: getFetchedContentComments(state),
    currentUser: getCurrentUser(state),
    path: ownProps.location.pathname,
    initialCategoryId,
    goBack: ownProps.history.goBack,
  };
}

export default connect(mapStateToProps)(ContentFeedContainer);
