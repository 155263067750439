import { css } from "glamor";
import React from "react";
import { Progress } from "semantic-ui-react";
import { colors, spacing } from "../library/theme";

const container = css({
  display: "flex",
  alignItems: "center",
  gap: spacing.xs,
  flex: 1,
  minWidth: 118,
});
const progressWrapper = css({
  minWidth: 72,
  height: 8,
  "@media (max-width: 768px)": { width: "100%" },
});
const progressClass = css({
  margin: `0 !important`,
  ".ui.progress .bar": {
    background: colors.correct,
  },
});

export default function CourseProgress({
  value,
  fluid,
  numberPosition = "right",
}) {
  return (
    <div className={container}>
      <div className={css(progressWrapper, fluid && { width: "100%" })}>
        {numberPosition == "top" && (
          <span>{`${(value ? value : 0).toFixed(0)}%`} completo</span>
        )}
        <Progress
          percent={value}
          size="tiny"
          className={progressClass.toString()}
        />
      </div>
      {numberPosition == "right" && (
        <span>{`${(value ? value : 0).toFixed(0)}%`}</span>
      )}
    </div>
  );
}
