import { css } from "glamor";
import React, { useCallback, useState } from "react";
import { Popup } from "semantic-ui-react";
import { spacing } from "./theme";

const container = css({
  display: "flex",
  position: "relative",
  marginLeft: spacing.s,
  height: 20,
  width: 100,
  borderRadius: spacing.xxs,
  background:
    "linear-gradient(90deg, rgba(0,255,49,1) 0%, rgba(255,246,0,1) 50%, rgba(189,11,11,1) 100%)",
});

const indicatorStyle = {
  position: "absolute",
  top: 0,
  height: 10,
  width: 10,
  clipPath: "polygon(100% 0, 0 0, 50% 100%)",
  backgroundColor: "black",
  zIndex: 3,
};

export default function DifficultyBar(props) {
  const { value, indicatorPopup } = props;
  const [openPopup, setOpenPopup] = useState(false);
  const togglePopupHandler = useCallback(() => {
    setOpenPopup(!openPopup);
  }, [openPopup]);

  return (
    <div
      className={container}
      onMouseEnter={togglePopupHandler}
      onMouseLeave={togglePopupHandler}
    >
      <Popup
        trigger={
          <div style={{ ...indicatorStyle, left: 95 * (value / 100) }} />
        }
        content={indicatorPopup}
        position="top center"
        open={openPopup}
      />
    </div>
  );
}
