import { css } from "glamor";
import React, { useCallback } from "react";
import { Tab } from "semantic-ui-react";
import { Page, Spinner, colors, spacing } from "../library";

import { replace } from "connected-react-router";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import RouteLeavingGuard from "../library/RouteLeavingGuard";

const content = {
  marginTop: spacing.l,
  backgroundColor: colors.greyClear,
  padding: `${spacing.s} ${spacing.s} ${spacing.l} ${spacing.s}`,
  borderRadius: spacing.m,
};

const tabOptions = {
  stackable: true,
  secondary: true,
  pointing: true,
  className: css({
    minHeight: "auto !important",
    paddingTop: "0px !important",
    ".ui.menu .item": {
      paddingTop: "0px !important",
      fontWeight: "normal",
      "@media(max-width: 770px)": {
        paddingTop: "12px !important",
      },
    },
    ".ui.secondary.menu": {
      paddingTop: spacing.s,
      borderBottom: "none !important",
    },
    ".ui.menu .active.item": {
      color: `${colors.primary} !important`,
      borderColor: `${colors.primary} !important`,
      "@media(max-width: 770px)": {
        paddingTop: "12px !important",
        borderBottomColor: "transparent !important",
        borderLeftColor: `${colors?.primary} !important`,
        borderLeft: `3px solid ${colors.primary} !important`,
      },
    },
  }),
};

const panes = [
  {
    menuItem: "Informações pessoais",
    key: "personal",
  },
  {
    menuItem: "Endereço",
    key: "address",
  },
  {
    menuItem: "Alterar minha senha",
    key: "change-password",
  },
];

export default function ProfileEditPage(props) {
  const { currentUser, shouldBlockNavigation, children } = props;

  const location = useLocation();
  const dispatch = useDispatch();
  const tab = location?.pathname?.split("/")[3] || "personal";
  const activeIndex = panes?.findIndex(({ key }) => key == tab);
  const tabChangeHandler = useCallback(
    (_, { activeIndex, panes }) => {
      dispatch(replace(`/profile/edit/${panes[activeIndex]?.key}`));
    },
    [dispatch]
  );

  const shouldBlockNavigationHandler = useCallback(
    () => shouldBlockNavigation,
    [shouldBlockNavigation]
  );

  return (
    <Page title="Editar perfil">
      <RouteLeavingGuard
        shouldBlockNavigation={shouldBlockNavigationHandler}
        header="Certeza que deseja sair?"
        content="Atenção!! Ao sair sem salvar todos os dados preenchidos serão perdidos, tem certeza que deseja sair?"
      />
      {currentUser ? (
        <>
          <Tab
            activeIndex={activeIndex}
            menu={tabOptions}
            panes={panes}
            onTabChange={tabChangeHandler}
          />
          <div style={content}>{children}</div>
        </>
      ) : (
        <Spinner wrapped text="Carregando..." />
      )}
    </Page>
  );
}
