import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchCertificateLessons,
  fetchCertificateCourses,
} from "../../processes/certificateProcesses";
import { certificateCourses } from "../../selectors/courseSelectors";
import { certificateLessons } from "../../selectors/lessonSelectors";
import Certificates from "./Certificates";
import { getCurrentUserId } from "../../selectors/userSelectors";

function CertificatesContainer(props) {
  const { userId, dispatch } = props;

  useEffect(() => {
    fetchCertificateCourses(userId, dispatch);
    fetchCertificateLessons(userId, dispatch);
  }, [dispatch]);

  return <Certificates {...props} />;
}

function mapStateToProps(state) {
  const userId = getCurrentUserId(state);

  const courses = certificateCourses(state);
  const lessons = certificateLessons(state);

  const certificateCoursesLoading =
    state.certificates.courseCertificatesLoading;
  const certificateLessonsLoading =
    state.certificates.lessonCertificatesLoading;

  return {
    loading: certificateCoursesLoading || certificateLessonsLoading,
    userId,
    courses,
    lessons,
  };
}

export default connect(mapStateToProps)(CertificatesContainer);
