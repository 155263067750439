import { apiPost } from "./helpers/api";

export const upsertLead = (values, dispatch, props) => {
  return apiPost("/api/v1/public/leads")
    .send(values)
    .then((response) => {
      const { isFromPreparatoryCourse } = response.body.data;

      dispatch({
        type: "CHECKOUT_LEAD_CREATED",
        isFromPreparatoryCourse,
      });
    });
};
