import { connect } from "react-redux";
import { getCourseCategoriesWithBuyableCourses } from "../../selectors/configSelectors";
import { getCurrentUser } from "../../selectors/userSelectors";
import Courses from "./Courses";

function mapStateToProps(state) {
  return {
    user: getCurrentUser(state),
    loading: state.coursesList.loading,
    courseCategories: getCourseCategoriesWithBuyableCourses(state),
  };
}

export default connect(mapStateToProps)(Courses);
