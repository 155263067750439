import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { searchCoupon } from "../../processes/couponProcesses";
import { couponFound } from "../../selectors/couponSelectors";
import buildForm from "../library/forms/buildForm";
import SearchCouponForm from "./SearchCouponForm";
import { formValueSelector, initialize, submit } from "redux-form";
import { useGetQueryParam } from "../../utils/useGetQueryParam";

const FORM_NAME = "searchCoupon";
const form = buildForm(FORM_NAME);

function SearchCouponFormContainer(props) {
  const { dispatch, initialized } = props;

  const [showCouponForm, setShowCouponForm] = useState(false);
  const openShowCouponForm = useCallback(() => {
    setShowCouponForm(true);
  }, []);

  const { paramValue: couponCode } = useGetQueryParam("coupon");
  const code = props.offer?.meta?.couponCode || couponCode;
  const submittingCoupon = code && props.submitting;

  useEffect(() => {
    if (code) {
      dispatch(initialize(FORM_NAME, { code }));
    }
  }, [dispatch, code]);

  useEffect(() => {
    if (code && initialized) {
      dispatch(submit(FORM_NAME));
    }
  }, [code, initialized, dispatch]);

  return (
    <SearchCouponForm
      showCouponForm={showCouponForm}
      openShowCouponForm={openShowCouponForm}
      submittingCoupon={submittingCoupon}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const valueSelector = formValueSelector(FORM_NAME);
  const code = valueSelector(state, "code");

  return {
    initialValues: {},
    hasCoupon: couponFound(state, code),
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: searchCoupon,
  })(SearchCouponFormContainer)
);
