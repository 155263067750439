import { css } from "glamor";
import React from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Accordion, Card, Label, Progress } from "semantic-ui-react";
import formatSeconds from "../../utils/formatSeconds";
import zeroPad from "../../utils/zeroPad";
import LessonListItemContainer from "./LessonListItemContainer";
import { colors, spacing, styles } from "../library/theme";
import SubLevelListItemContainer from "./SubLevelListItemContainer";

const layoutClass = css({
  overflow: "hidden",
  "& + &": { marginTop: spacing.xxs },
});
const titleClass = css({
  display: "flex",
});
const progressStyle = {
  height: 8,
};
const progressBarClass = css({
  width: 60,
  margin: 0,
  height: "8px !important",
  borderRadius: "4px !important",
  backgroundColor: `${colors.greyLight} !important`,
  ".ui.progress .bar": {
    height: "8px !important",
    borderRadius: "4px !important",
    backgroundColor: `${colors.correct} !important`,
  },
});
const blackTextStyle = {
  color: colors.greyShadow,
};
const cardStyle = { ...styles.cardShadow, flex: 1, margin: spacing.xxs };
const cardContentStyle = { padding: spacing.m };
const contentWrapperClass = css({
  display: "flex",
  justifyContent: "space-between",
  gap: spacing.s,
  flexWrap: "wrap",
  ":hover": {
    cursor: "pointer",
    "> div > h3, > div:last-child > div > svg": {
      color: colors.primary,
    },
  },
});
const leftContentStyle = { flex: 1, minWidth: 250 };
const rightContentStyle = {
  display: "flex",
  gap: spacing.m,
  alignItems: "center",
};
const progressWrapperStyle = {
  display: "flex",
  alignItems: "center",
  gap: spacing.s,
  minWidth: 137,
};
const contentClass = css(styles.transition, {
  marginTop: spacing.l,
  height: "auto",
});
const hiddenClass = css(styles.transition, {
  margin: 0,
  opacity: 0,
  height: 0,
  overflow: "hidden",
});
const trialLabelStyle = {
  backgroundColor: colors.correct,
  color: colors.white,
  fontSize: '1em',
  padding: "3px 6px",
  marginLeft: 5,
};
const levelNameWrapper = {
  display: "flex",
  alignItems: "center",
};

const progressCalc = (watched, total) => {
  if (!total || !watched || +total <= 0) {
    return 0;
  }

  return Math.round((+watched / +total) * 100);
};

export default function LevelListItem({
  level,
  sublevels,
  lessons,
  handleClick,
  active,
  lessonsCount,
  lessonsWatchedCount,
  duration,
  isTrialEnrollment,
}) {
  const progress = progressCalc(lessonsWatchedCount, lessonsCount);

  const DropdownIcon = active ? IoIosArrowUp : IoIosArrowDown;

  return (
    <Accordion className={layoutClass.toString()}>
      <div className={titleClass.toString()} onClick={handleClick}>
        <Card style={cardStyle}>
          <Card.Content style={cardContentStyle}>
            <div className={contentWrapperClass}>
              <div style={leftContentStyle}>
                <div style={levelNameWrapper}>
                  <span>Módulo</span>
                  {isTrialEnrollment && level.trialEnabled && (
                    <Label style={trialLabelStyle}>DISPONÍVEL</Label>
                  )}
                </div>
                <h3>{level?.name}</h3>
              </div>

              <div style={rightContentStyle}>
                <div>
                  <span>Duração</span>
                  <h3 style={blackTextStyle}>{formatSeconds(+duration)}</h3>
                </div>
                <div>
                  <span>Aulas</span>
                  <h3 style={blackTextStyle}>{zeroPad(+lessonsCount, 2)}</h3>
                </div>
                <div style={progressWrapperStyle}>
                  <div style={progressStyle}>
                    <Progress
                      percent={progress}
                      className={progressBarClass.toString()}
                    />
                  </div>
                  {progress}%
                  <DropdownIcon size={22} />
                </div>
              </div>
            </div>
            <div className={css(contentClass, !active && hiddenClass)}>
              <span>Assuntos</span>
              {sublevels?.map((sublevel) => (
                <SubLevelListItemContainer key={sublevel.id} level={sublevel} />
              ))}
              {lessons?.map((lesson) => (
                <LessonListItemContainer key={lesson.id} lesson={lesson} />
              ))}
            </div>
          </Card.Content>
        </Card>
      </div>
    </Accordion>
  );
}
