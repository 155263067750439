import React from "react";
import { Container, Icon } from "semantic-ui-react";
import { spacing, stateColors } from "../library/theme";

const emptyStyle = {
  marginTop: spacing.xl,
  color: stateColors.empty,
};

export default function EmptyNetworking() {
  return (
    <Container textAlign="center">
      <Icon size="massive" name="users" style={emptyStyle} />
      <h3 style={emptyStyle}>Nenhum usuário por aqui...</h3>
    </Container>
  );
}
