import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchLessonFiles } from "../../processes/lessonFileProcesses";
import LessonFiles from "./LessonFiles";
import { getFilesFromLesson } from "../../selectors/lessonFileSelectors";
import { getCurrentCourseId } from "../../selectors/courseSelectors";

function LessonFilesContainer(props) {
  const { dispatch, currentCourseId, lessonId } = props;
  useEffect(
    () => fetchLessonFiles(dispatch, currentCourseId, lessonId),
    [dispatch, currentCourseId, lessonId]
  );
  if (!props.files.length) return null;
  return <LessonFiles {...props} />;
}

function mapStateToProps(state, ownProps) {
  const lessonId = ownProps.lessonId;

  return {
    files: getFilesFromLesson(state, lessonId),
    currentCourseId: getCurrentCourseId(state),
    lessonId,
  };
}

export default connect(mapStateToProps)(LessonFilesContainer);
