import { createSelector } from "reselect";
import { getCurrentCourseId } from "./courseSelectors";

const getContinueState = (state) => state.continue;
const getKey = (_, key) => key;

export const getContinueValueByKey = createSelector(
  getContinueState,
  getKey,
  (continueState, key) => continueState[key]
);

export const getCurrentContinueLesson = createSelector(
  getContinueState,
  getCurrentCourseId,
  ({ lessons = [] }, currentCourseId) =>
    lessons
      ?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      ?.find((lesson) => lesson.courseId === currentCourseId)
);
