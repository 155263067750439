import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getCourseById } from "../../../selectors/courseSelectors";
import MockResume from "./MockResume";
import { replace } from "connected-react-router";
import { getResumeMock } from "../../../selectors/mockSelectors";
import { fetchMocks } from "../../../processes/mockProccess";

function MockResumeContainer(props) {
  const { dispatch } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchMocks({ finishedAtNull: true }, dispatch, {}).then(() => {
      setLoading(false);
    });
  }, [dispatch]);

  return <MockResume loading={loading} {...props} />;
}

function mapStateToProps(state) {
  const mock = getResumeMock(state);

  return {
    courseLoading: state.coursesList.loading,
    course: getCourseById(state, mock?.courseId),
    mock,
  };
}

export default connect(mapStateToProps)(MockResumeContainer);
