import { css } from "glamor";
import React from "react";
import { Page, Spinner, colors, spacing, styles } from "../library";
import Leaderboard from "../library/leaderboard/Leaderboard";

const container = css({
  padding: spacing.m,
});
const spinnerClass = css({
  ":before": {
    borderColor: `black !important`,
  },
});
const titleWrapper = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: spacing.m,
});
const titleClass = css(styles.alternativeFontFamily, {
  display: "flex",
  alignItems: "center",
  gap: spacing.s,
  fontSize: 24,
  fontWeight: "bold",
  color: colors.secondary,
  lineHeight: 1.5,
});

export default function MockRankingPage(props) {
  const { loading, mock, mocks } = props;

  return (
    <Page>
      <div className={container}>
        <div className={titleWrapper}>
          <div className={titleClass}>Ranking</div>
          <div className={titleClass}>{mock?.title}</div>
        </div>

        <div>
          {loading ? (
            <Spinner className={spinnerClass} wrapped />
          ) : (
            <Leaderboard data={mocks} highlightedId={mock?.id} />
          )}
        </div>
      </div>
    </Page>
  );
}
