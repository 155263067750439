import { apiGet } from "./helpers/api";

export const fetchCertificateUser = (values, dispatch) => {
  const { code } = values;
  dispatch({ type: "CERTIFICATE_USER_FETCH_REQUESTED" });

  return apiGet(`/api/v1/certificate_users/${code}`)
    .then((response) =>
      dispatch({ type: "CERTIFICATE_USER_FETCHED", data: response.body.data })
    )
    .catch((err) => {
      dispatch({
        type: "CERTIFICATE_USER_ERROR",
        data: { error: err.response.body.errors },
      });
    });
};
