import { css } from "glamor";
import React from "react";
import { IoDocumentTextOutline } from "react-icons/io5";
import { Pagination, Popup } from "semantic-ui-react";
import { makeIsMobile } from "../../utils/useScreenWidth";
import {
  Button,
  Link,
  Page,
  Spinner,
  colors,
  spacing,
  styles,
} from "../library";
import MocksPageImage from "../library/images/mocks-page.png";
import SmallMocksPageImage from "../library/images/small-mocks-page.png";
import MockListItemMobileContainer from "../mobile/mocks/MockListItemContainer";
import SurveyModalContainer from "../surveys/SurveyModalContainer";
import EmptyMocks from "./EmptyMocks";
import FilterFormContainer from "./FilterFormContainer";
import MockListItemContainer from "./MockListItemContainer";

const container = css({
  textAlign: "center",
});

const table = css({
  width: "100%",
  borderCollapse: "separate",
  borderSpacing: `0 ${spacing.s}`,
});

const headerCell = css({
  backgroundColor: colors.white,
  fontWeight: "normal",
  color: colors.greyShadow,
  border: "none",
  "@media (max-width: 768px)": {
    display: "block",
    width: "100%",
  },
});

const activePaginationItem = {
  ...styles.cardShadow,
  borderRadius: "10px",
  background: colors.white,
};

const paginationClass = css({
  paddingBottom: spacing.m,
  borderBottom: "none !important",
  ".ui.secondary.pointing.menu .item": {
    border: "none",
    ":hover": {
      ...activePaginationItem,
      background: "transparent",
      border: `1px solid ${colors.grey}`,
    },
  },
  ".ui.secondary.pointing.menu .active.item": activePaginationItem,
}).toString();
const paginationWrapper = css({
  display: "flex",
  justifyContent: "center",
});

const buttonsWrapper = css({
  display: "flex",
  gap: spacing.s,
  flexWrap: "wrap",
  justifyContent: "right",
  position: "relative",
  bottom: 70,
  height: 0,
  "@media (max-width: 768px)": {
    position: "static",
    height: "auto",
    paddingBottom: spacing.m,
  },
  "@media(max-width: 575px)": {
    justifyContent: "center",
  },
});

const buttonStyle = {
  minWidth: 200,
  marginRight: "0px !important",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: spacing.xxs,
};

const newMockButtonStyle = {
  ...buttonStyle,
  minWidth: 180,
  paddingTop: spacing.s,
  paddingBottom: spacing.s,
  backgroundColor: colors.primary,
  color: colors.white,
  borderRadius: 15,
  fontSize: 18,
};

const mocksImageContainer = css({
  position: "relative",
  paddingBottom: "46.25%",
  backgroundImage: `url(${MocksPageImage})`,
  backgroundPosition: "center",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  width: "100%",
  height: "500px",
});

const mocksImageContentWrapper = css({
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  top: "50%",
  right: "50%",
  transform: "translate(50%, -50%)",
  textAlign: "center",
  "> h2": {
    fontSize: 25,
  },
});

const mocksTextWrapper = css({
  display: "flex",
  flexDirection: "column",
  gap: spacing.s,
  marginTop: spacing.s,
  width: 300,
  textAlign: "center",
  marginBottom: 80,
  "> p": {
    color: "black !important",
  },
});

const mobileListWrapper = css({
  marginTop: spacing.m,
});

const disabledLinkClass = css({
  opacity: 0.5,
});

const MockList = ({
  mocks,
  currentPage,
  fetchMocksHandler,
  totalPages,
  hasMockCourses,
}) => {
  const isMobile = makeIsMobile();

  return mocks.length > 0 ? (
    <>
      {isMobile ? (
        <div className={mobileListWrapper}>
          {mocks.map((mock) => (
            <MockListItemMobileContainer key={mock.id} mock={mock} />
          ))}
        </div>
      ) : (
        <table className={table}>
          <thead>
            <tr>
              <th className={headerCell}>#</th>
              <th className={headerCell}>Data</th>
              <th className={headerCell}>Curso</th>
              <th className={headerCell} textAlign="center">
                Nº de Questões
              </th>
              <th className={headerCell}>Status</th>
              <th className={headerCell}>Progresso</th>
              <th className={headerCell} textAlign="center">
                Nota
              </th>
              <th className={headerCell} textAlign="right"></th>
            </tr>
          </thead>
          <tbody>
            {mocks.map((mock) => (
              <MockListItemContainer key={mock.id} mock={mock} />
            ))}
          </tbody>
        </table>
      )}
      <div className={paginationWrapper}>
        <Pagination
          className={paginationClass}
          firstItem={null}
          lastItem={null}
          pointing
          secondary
          activePage={currentPage}
          onPageChange={fetchMocksHandler}
          totalPages={totalPages}
        />
      </div>
    </>
  ) : (
    <EmptyMocks hasMockCourses={hasMockCourses} />
  );
};

export default function Mocks({
  mocks,
  currentPage,
  totalPages,
  fetchMocksHandler,
  loading,
  isFirstMock,
  hasMockCourses,
  isMonitor,
  hasMocks = true,
  mockCreated,
}) {
  const firstMockCreated = hasMocks || mockCreated;
  const monitorButtonStyle = isMonitor ? newMockButtonStyle : buttonStyle;
  const currentMocksImageContainer = css(
    mocksImageContainer,
    firstMockCreated && {
      backgroundImage: `url(${SmallMocksPageImage})`,
      paddingBottom: "16.25%",
      height: "",
    }
  );
  const isMobile = makeIsMobile();

  return (
    <Page title="Simulados" icon={IoDocumentTextOutline} className={container}>
      <div className={buttonsWrapper}>
        {!isMonitor && (
          <Popup
            content={<p>Necessário acesso a algum curso!</p>}
            disabled={hasMockCourses}
            position="bottom center"
            trigger={
              <Link
                className={css(!hasMockCourses && disabledLinkClass)}
                component={Button}
                to={hasMockCourses ? "/mocks/new" : "/mocks"}
                secondary
                circular={false}
                style={monitorButtonStyle}
                fluid={isMobile}
                size={isMobile && "large"}
              >
                CRIAR SIMULADO
              </Link>
            }
          />
        )}
        <Link
          component={Button}
          to="/questions"
          circular={false}
          style={monitorButtonStyle}
          disabled={!hasMockCourses}
          fluid={isMobile}
          size={isMobile && "large"}
        >
          {isMonitor ? "Buscar Questões" : "BUSCAR QUESTÕES"}
        </Link>
      </div>

      {isMonitor && (
        <div className={currentMocksImageContainer}>
          <div className={mocksImageContentWrapper}>
            {!firstMockCreated && (
              <>
                {" "}
                <h2>Vamos criar seu primeiro simulado?</h2>
                <div className={mocksTextWrapper}>
                  <p>Você pode criar simulados e provas ilimitados.</p>
                  <p>
                    Para sua melhor experiência escolha um ambiente silencioso e
                    reserve tempo suficiente para horário de prova.
                  </p>
                </div>
              </>
            )}

            {!hasMockCourses && <p>* Necessário acesso a algum curso!</p>}
            <Link
              component={Button}
              to="/mocks/new"
              style={newMockButtonStyle}
              disabled={!hasMockCourses}
            >
              Criar Simulado
            </Link>
          </div>
        </div>
      )}

      <FilterFormContainer />
      <SurveyModalContainer type="first_mock" condition={isFirstMock} />
      {loading ? (
        <Spinner wrapped text="Carregando simulados" />
      ) : (
        <MockList
          mocks={mocks}
          currentPage={currentPage}
          fetchMocksHandler={fetchMocksHandler}
          totalPages={totalPages}
          hasMockCourses={hasMockCourses}
        />
      )}
    </Page>
  );
}
