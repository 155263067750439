import { useEffect, useRef } from "react";

export default function useBeforeUnload(handler, shouldRemoveListener) {
  const enabled = typeof handler === "function";

  const handlerRef = useRef(handler);
  useEffect(() => {
    handlerRef.current = handler;
  });

  useEffect(() => {
    if (enabled) {
      const listener = (event) => {
        const returnValue = handlerRef.current(event);

        if (typeof returnValue === "string") {
          event.preventDefault();
          // Handle legacy `event.returnValue` and `return` activation.
          return (event.returnValue = returnValue);
        }

        // Chrome doesn't support `event.preventDefault()` on `BeforeUnloadEvent`.
        if (event.defaultPrevented) {
          return (event.returnValue = "");
        }
      };

      if (shouldRemoveListener) {
        window.removeEventListener("beforeunload", listener);
      } else {
        window.addEventListener("beforeunload", listener);
      }

      return () => {
        window.removeEventListener("beforeunload", listener);
      };
    }
  }, [enabled, shouldRemoveListener]);
}
