import React from "react";
import { css } from "glamor";
import { colors, spacing } from "../theme";

const container = css({
  display: "grid",
  gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
  overflow: "auto",
  whiteSpace: "nowrap",
  marginLeft: "auto",
  marginRight: "auto",
  "@media (min-width: 575px)": {
    width: 360,
  },
});
const headerCell = css({
  backgroundColor: "#EDEDED",
  textAlign: "center",
  height: `${spacing.l}`,
  fontWeight: "normal",
  color: colors.black,
  border: "none",
});
const firstHeaderCell = css(headerCell, {
  borderTopLeftRadius: `${spacing.xxs}`,
  borderBottomLeftRadius: `${spacing.xxs}`,
});
const lastHeaderCell = css(headerCell, {
  borderTopRightRadius: `${spacing.xxs}`,
  borderBottomRightRadius: `${spacing.xxs}`,
});
const tableClass = css({
  borderCollapse: "separate",
  borderSpacing: `0 ${spacing.s}`,
});
const rowClass = css({
  margin: "0 auto",
  textAlign: "center",
  "> td": {
    padding: `${spacing.s} ${spacing.m}`,
    color: colors.greyShadow,
  },
  "@media (min-width: 575px)": {
    width: 360,
  },
});
const highlightedRowStyle = {
  backgroundColor: `${colors.primary}1A`,
  borderRadius: spacing.xxs,
};

export default function LeaderboardRanking({ data = [], highlightedId }) {
  return !!data.length ? (
    <div className={container}>
      <table className={tableClass}>
        <thead>
          <tr>
            <th className={firstHeaderCell}>Rank</th>
            <th className={headerCell}>Nome</th>
            <th className={lastHeaderCell}>Nota</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr
              className={css(
                rowClass,
                highlightedId == item?.id && highlightedRowStyle
              )}
            >
              <td>{item.position}º</td>
              <td>{item.userName}</td>
              <td textAlign="center">{item.score}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : null;
}
