import React from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { hasMockCourses } from "../../../selectors/courseSelectors";
import {
  getMocksByFetchedIds,
  makeIsFirstFinishedMock,
} from "../../../selectors/mockSelectors";
import { useMonitorScreen } from "../../../utils/useScreenWidth";
import { getCurrentUser } from "../../../selectors/userSelectors";
import Mocks from "./Mocks";

function MocksContainer(props) {
  const isMonitor = useMonitorScreen();

  return <Mocks isMonitor={isMonitor} {...props} />;
}

function mapStateToProps(state) {
  const formSelector = formValueSelector("mocksFilterForm");
  const currentUser = getCurrentUser(state);

  return {
    filters: formSelector(state, "courseIdEq", "finishedAtNull"),
    loading: state.mockList.loading,
    totalPages: state.mockList.totalPages,
    currentPage: state.mockList.currentPage,
    mockCreated: state.mockList.mockCreated,
    hasMockCourses: hasMockCourses(state),
    hasMocks: currentUser?.hasMocks,
    mocks: getMocksByFetchedIds(state),
    isFirstMock: makeIsFirstFinishedMock(state),
  };
}

export default connect(mapStateToProps)(MocksContainer);
