import React from "react";
import { MdListAlt } from "react-icons/md";
import { colors, spacing } from "../library/theme";
import { css } from "glamor";

const container = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  padding: spacing.m,
  textAlign: "center",
  width: "100%",
});
const icon = {
  color: colors.primaryDarker,
  marginBottom: spacing.s,
};
const title = {
  marginBottom: spacing.xxs,
};

export default function EmptyCourseFiles() {
  return (
    <div className={container}>
      <MdListAlt size={64} style={icon} />
      <h4 style={title}>Lista Vazia</h4>
      <span>Esse curso não possuí nenhum arquivo</span>
    </div>
  );
}
