import { reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import ImportStudentsForm from "./ImportStudentsForm";
import { getCompanyClassroomsByProps } from "../../selectors/classroomSelectors";
import { importStudents } from "../../processes/companyProcesses";

const FORM_NAME = "importStudentsForm";

function mapStateToProps(state, ownProps) {
  const {
    courseId: initialCourseId,
    classroomId: initialClassroomId,
    company,
  } = ownProps;

  const formSelector = formValueSelector(FORM_NAME);
  const courseId = formSelector(state, "courseId");

  return {
    initialValues: {
      courseId: initialCourseId,
      classroomId: initialClassroomId,
    },
    classrooms: getCompanyClassroomsByProps(state, {
      companyId: company.id,
      courseId: courseId || initialCourseId,
    }),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    onSubmit: importStudents,
  })(ImportStudentsForm)
);
