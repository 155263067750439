import React, { useCallback, useRef } from "react";
import Button from "../Button";
import { spacing } from "../theme";

const doFocus = (e) => e.target.focus();

const fieldStyle = {
  display: "flex",
};
const hiddenStyle = {
  opacity: 0,
};
const buttonStyle = {
  minWidth: "180px",
  height: "36px",
};

export default function ImageInput(props) {
  const { placeholder = "Arquivo", value, onFileChange, ...rest } = props;

  const onChangeHandler = useCallback(
    (e) => {
      e.preventDefault();
      onFileChange(e.target.files);
      e.target.value = "";
    },
    [onFileChange]
  );

  const icon = props.icon || "file";
  const inputRef = useRef();

  const buttonClickHandler = useCallback(() => {
    inputRef.current.click();
  }, [inputRef]);

  return (
    <div style={fieldStyle}>
      <Button
        icon={value ? "checkmark" : icon}
        content={`Enviar ${placeholder}`}
        type="button"
        color={value ? "primary" : "gray"}
        onClick={buttonClickHandler}
        style={buttonStyle}
      />
      <input
        ref={inputRef}
        accept={props.accept || "image/*,.pdf"}
        {...rest}
        hidden
        style={hiddenStyle}
        onClick={doFocus}
        onChange={onChangeHandler}
      />
    </div>
  );
}
