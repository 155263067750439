import { css } from "glamor";
import React from "react";
import { colors, spacing, styles } from "../library/theme";
import { makeIsMobile } from "../../utils/useScreenWidth";
import Link from "../library/Link";
import Modal from "../library/Modal";
import Dropdown from "../library/Dropdown";
import { BiCog } from "react-icons/bi";
import { TbCertificate } from "react-icons/tb";
import { RiArrowDownSLine } from "react-icons/ri";
import { TbLogout } from "react-icons/tb";
import { LuFileText } from "react-icons/lu";

const AVATAR_SIZE = "34px";
const AVATAR_SIZE_MOBILE = "50px";
const AVATAR_SIZE_BIG = "100px";
const SIDEBAR_WIDTH = "300px !important";

const titleClass = css({
  margin: 0,
  color: colors.greyDarker,
});
const triggerClass = css(styles.alternativeFontFamily, {
  width: 184,
  display: "inline-flex",
  color: colors.secondary,
  alignItems: "center",
  gap: spacing.xs,
  fontWeight: "bold",
  padding: `${spacing.xs} ${spacing.s}`,
  lineHeight: 1.2,
});
const avatarClass = css(styles.roundedCorners, {
  height: AVATAR_SIZE,
  width: AVATAR_SIZE,
  borderRadius: "50%",
  cursor: "pointer",
  display: "block",
  margin: "auto 0",
});
const mobileAvatarClass = css(avatarClass, {
  height: AVATAR_SIZE_MOBILE,
  width: AVATAR_SIZE_MOBILE,
});
const modalClass = css({
  margin: "0 !important",
  width: SIDEBAR_WIDTH,
  borderRadius: "0 !important",
}).toString();
const modalContentClass = css({
  height: "100vh",
  width: SIDEBAR_WIDTH,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: spacing.m,
  padding: spacing.l,
});
const userMenuClass = css(modalContentClass, {
  background: colors.white,
  marginLeft: "auto !important",
  minHeight: "100vh",
  height: "100%",
});
const userMenuOptions = css({
  display: "flex",
  flexDirection: "column",
  gap: spacing.l,
  alignItems: "center",
  width: "100%",
  ">a": {
    fontSize: 20,
    justifyContent: "center",
  },
});
const userMenuItem = css(styles.alternativeFontFamily, {
  width: "100%",
  display: "flex !important",
  alignItems: "center",
  justifyContent: "start",
  gap: spacing.xxs,
  color: `${colors.greyShadow} !important`,
  "> svg": {
    color: colors.primary,
  },
});

export default function UserSidebar({
  user,
  visible,
  toggleVisibility,
  signOutHandler,
}) {
  const isMobile = makeIsMobile();

  return user ? (
    <Modal
      open={visible && isMobile}
      onOpen={toggleVisibility}
      onClose={toggleVisibility}
      centered={false}
      dimmer="blurring"
      className={modalClass}
      trigger={
        <Dropdown
          icon={null}
          trigger={
            <>
              {isMobile ? (
                <img src={user.avatarUrl} className={mobileAvatarClass} />
              ) : (
                <div className={triggerClass}>
                  <img src={user.avatarUrl} className={avatarClass} />{" "}
                  {user.firstName} {user.lastName}
                  <RiArrowDownSLine size={20} />
                </div>
              )}
            </>
          }
        >
          <Dropdown.Menu>
            <Link component={Dropdown.Item} to="/profile/edit/personal">
              <div className={userMenuItem}>
                <BiCog size={20} /> Editar Perfil
              </div>
            </Link>
            <Link component={Dropdown.Item} to="/certificates">
              <div className={userMenuItem}>
                <TbCertificate size={20} /> Meus Certificados
              </div>
            </Link>
            <Link component={Dropdown.Item} to="/invoices">
              <div className={userMenuItem}>
                <LuFileText size={20} /> Minhas Notas Fiscais
              </div>
            </Link>
          </Dropdown.Menu>
        </Dropdown>
      }
      as={() => (
        <div className={userMenuClass}>
          <Link to="/profile/edit/personal">
            <img
              src={user.avatarUrl}
              className={css(avatarClass, {
                height: AVATAR_SIZE_BIG,
                width: AVATAR_SIZE_BIG,
              })}
            />
          </Link>
          <h4 className={titleClass}>{user?.name}</h4>
          <div className={userMenuOptions}>
            <Link to="/profile/edit/personal" className={userMenuItem}>
              <BiCog size={24} /> Editar Perfil
            </Link>
            <Link to="/certificates" className={userMenuItem}>
              <TbCertificate size={24} /> Meus Certificados
            </Link>
            <Link to="/invoices" className={userMenuItem}>
              <LuFileText size={24} /> Minhas Notas Fiscais
            </Link>
            <Link onClick={signOutHandler} className={userMenuItem}>
              <TbLogout size={24} /> Sair
            </Link>
          </div>
        </div>
      )}
    />
  ) : null;
}
