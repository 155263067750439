export const spacing = {
  xxs: "4px",
  xs: "8px",
  s: "12px",
  m: "20px",
  l: "32px",
  xl: "52px",
  xxl: "84px",
  offset: "10%",
};

export const colors = {
  // TO DO: Deprecate old color scheme
  brand: "#0E185F",
  brandDark: "#180A29",
  white: "#fff",
  black: "#030404",
  brown: "#b78751",
  danger: "#de3f2e",
  warning: "#e07900",
  success: "#10ac3a",
  decorationSoft: "#fcc8b9",
  decorationHard: "#aadeca",
  shadow: "rgba(0, 0, 0, 0.1)",
  overlay: "rgba(2, 13, 66, 0.40)",
  dark: "#333",

  // NEW COLOR SCHEME

  // MOCK COLORS
  correct: "#06d6a0",
  wrong: "#ff595e",
  average: "#006FFF",

  //BRAND COLORS
  primaryClear: "#8EDEFD",
  primaryLight: "#3AC4FC",
  primaryLighter: "#20B6FC",
  primary: "#009EDE",
  primaryDark: "#1174A0",
  primaryDarker: "#095373",
  primaryShadow: "#0A2028",

  secondaryClear: "#009EDE",
  secondaryLighter: "#095373",
  secondaryLight: "#095373",
  secondary: "#0A2028",
  secondaryDark: "#001116",
  secondaryDarker: "#001116",
  secondaryShadow: "#000",

  //UTILITY COLORS
  successClear: "#D9F3D0",
  successLighter: "#CDF5AA",
  successLight: "#9FF257",
  success: "#69E200",
  successDark: "#57A314",
  successDarker: "#367400",
  successShadow: "#226019",

  warningClear: "#FFFCE8",
  warningLighter: "#FFF8C9",
  warningLight: "#F7EEA9",
  warning: "#FADD00",
  warningDark: "#B9A300",
  warningDarker: "#766A14",
  warningShadow: "#584401",

  dangerClear: "#FFF0F0",
  dangerLighter: "#FFBABA",
  dangerLight: "#FF6E6E",
  danger: "#FF0000",
  dangerDark: "#AD0000",
  dangerDarker: "#710000",
  dangerShadow: "#390000",

  infoClear: "#EAFEFF",
  infoLighter: "#CBFBFF",
  infoLight: "#9EF6FF",
  info: "#00D5FF",
  infoDark: "#1890FF",
  infoDarker: "#0D5E7D",
  infoShadow: "#104E69",

  //NEUTRAL COLORS
  greyClear: "#F8F8FA",
  greyLighter: "#F0F1F6",
  greyLight: "#D9DCDE",
  grey: "#BDC3C7",
  greyDark: "#909AA0",
  greyDarker: "#5C666D",
  greyShadow: "#393D41",

  blueDark: '#182d36',
};

export const decorationColors = {
  decorationBrand: colors.brand,
  decorationBrandDark: colors.brandDark,
  decorationSoft: colors.decorationSoft,
  decorationHard: colors.decorationHard,
  decorationBrown: colors.brown,
};

export const utilityColors = {
  information: colors.brand,
  danger: colors.danger,
  success: colors.success,
  warning: colors.warning,
  shadow: colors.shadow,
  overlay: colors.overlay,
};

export const typographyColors = {
  defaultTitle: colors.greyShadow,
  defaultBody: colors.greyDarker,
  inverseTitle: colors.greyClear,
  inverseBody: colors.greyClear,
  blackLetters: colors.black,
};

export const uiColors = {
  background: colors.brand,
  backgroundDark: colors.brandDark,
  backgroundDarker: colors.greyDarker,
  backgroundDarkest: colors.greyShadow,
  backgroundLightest: colors.greyClear,
  backgroundLight: colors.greyLight,
  border: colors.greyLight,
  borderInverse: colors.white,
  borderDark: colors.dark,
  fieldBackground: colors.white,
  contentCards: colors.greyLighter,
};

export const stateColors = {
  focus: colors.brand,
  hover: colors.greyDark,
  empty: colors.greyLight,
  filled: colors.greyLighter,
  disabled: colors.greyClear,
};

export const buttonColors = {
  primary: colors.primary,
  textPrimary: colors.primaryClear,

  secondary: colors.secondary,
  textSecondary: colors.secondaryClear,

  default: colors.grey,
  textDefault: colors.greyClear,

  danger: colors.greyLighter,
  textDanger: colors.greyDark,

  warning: colors.greyLighter,
  textWarning: colors.greyDark,

  info: colors.greyLighter,
  textInfo: colors.greyDark,
};

export const styles = {
  defaultFontFamily: {
    fontFamily: "Montserrat, sans-serif !important",
    fontWeight: "400",
  },
  alternativeFontFamily: {
    fontFamily: "FamiliarPro, sans-serif !important",
  },
  roundedCorners: { borderRadius: spacing.xs },
  border: {
    boxShadow: `0 0 0 1px ${uiColors.border} inset`,
  },
  cardShadow: { boxShadow: "0px 3px 6px #3A5E9429" },
  shadow: { boxShadow: `0 10px 10px ${utilityColors.shadow}` },
  optionShadow: { boxShadow: `5px 5px 5px ${utilityColors.shadow}` },
  transition: { transition: "all 150ms ease-in" },
  bodyText: {
    color: typographyColors.defaultBody,
    fontSize: 13,
    letterSpacing: 0,
    lineHeight: 2,
  },
  wrappingText: {
    lineHeight: 2,
  },
  bodyTextBold: {
    fontWeight: 600,
  },
  bodyTextItalic: {
    fontStyle: "italic",
  },
  bodyTextLarge: {
    fontSize: `16px !important`,
  },
  monospace: {
    fontFamily: "monospace",
    letterSpacing: "normal",
  },
  bodyTextSmall: {
    fontSize: 12,
  },
  whiteText: {
    color: typographyColors.inverseTitle,
    textShadow: `0px 1px 2px ${utilityColors.shadow}`,
  },
  whiteBodyText: {
    color: typographyColors.inverseBody,
    textShadow: `0px 1px 2px ${utilityColors.shadow}`,
  },
};
