import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";
import toQueryString from "../utils/toQueryString";

const mockTemplateSchema = new schema.Entity("mockTemplates");

export const fetchMockTemplates = (dispatch) => {
  return apiGet("/api/v1/mock_templates").then((response) => {
    dispatch({
      type: "MOCK_TEMPLATES_FETCHED",
      ...normalize(response.body.data, new schema.Array(mockTemplateSchema)),
    });
  });
};

export const fetchExamMockTemplates = (dispatch) => {
  const filters = toQueryString({ typeEq: "exam" });

  return apiGet(`/api/v1/mock_templates?${filters}`).then((response) => {
    dispatch({
      type: "MOCK_TEMPLATES_FETCHED",
      ...normalize(response.body.data, new schema.Array(mockTemplateSchema)),
    });
  });
};

export const fetchMockTemplate = (dispatch, mockTemplateId) => {
  return apiGet(`/api/v1/mock_templates/${mockTemplateId}`).then((response) => {
    dispatch({
      type: "MOCK_TEMPLATE_FETCHED",
      ...normalize(response.body.data, mockTemplateSchema),
    });
  });
};
