import React from "react";
import { css } from "glamor";
import { Grid, Header } from "semantic-ui-react";
import formatSeconds from "../../utils/formatSeconds";

const container = css({
  display: "grid",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
  height: "100%",
  "& > h1": {
    marginTop: "0 !important",
    marginBottom: "0 !important",
  },
});

export default function LessonSummary({
  lessonsCount,
  workloadTotalTime,
  workloadWatchedTime,
}) {
  return (
    <Grid
      columns="equal"
      celled="internally"
      textAlign="center"
      verticalAlign="middle"
      centered
      padded
      doubling
      stackable
    >
      <Grid.Row>
        <Grid.Column>
          <Header as="h1" color="blue">
            <p>Total de Aulas</p>
            {lessonsCount}
          </Header>
        </Grid.Column>
        <Grid.Column>
          <Header as="h1" color="blue">
            <p>Total de Horas</p>
            {formatSeconds(workloadTotalTime)}
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Header as="h1" color="green">
            <p>Total de Horas Assistidas</p>
            {formatSeconds(workloadWatchedTime)}
          </Header>
        </Grid.Column>
        <Grid.Column>
          <Header as="h1" color="red">
            <p>Total de Horas Não Assistidas</p>
            {formatSeconds(workloadTotalTime - workloadWatchedTime)}
          </Header>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
