import { createSelector } from "reselect";
import { getMock } from "./mockSelectors";
import { getReplies } from "./replySelectors";

const getAnswers = (state) => state.entities.answers;
const getProps = (_, props) => props;

export const getAnswer = createSelector(
  getAnswers,
  getProps,
  (answers, props) =>
    Object.values(answers).filter(
      (answer) =>
        (props?.lessonId
          ? answer.lessonId === props.lessonId
          : answer.mockId === props.mockId) &&
        answer.questionId === props.questionId
    )[0]
);

export const getReplyAnswered = createSelector(
  getAnswer,
  getReplies,
  (answer, replies) => replies[answer?.replyId]
);

export const getAnswersFromMock = createSelector(
  getAnswers,
  getMock,
  (answers, mock) =>
    Object.values(answers).filter((answer) => answer.mockId === mock?.id)
);

export const getMockAnswersByValue = createSelector(
  getAnswersFromMock,
  getReplies,
  getProps,
  (answers, replies, value = true) => {
    const collection = [];
    answers.map((answer) => {
      const reply = replies[answer.replyId];
      if (reply?.value === value) {
        collection.push(reply);
      }
    });
    return collection;
  }
);
