import { css } from "glamor";
import React from "react";
import { AiFillCaretLeft } from "react-icons/ai";
import LessonSidebarContentsContainer from "../lessonSidebarContents/LessonSidebarContentsContainer";
import CourseProgress from "../courses/CourseProgress";
import Button from "../library/Button";
import Link from "../library/Link";
import { colors, spacing, styles } from "../library/theme";
import LessonSearchFormContainer from "../lessons/LessonSearchFormContainer";

const LARGE_SIDEBAR_WIDTH = "320px";

const container = {
  minHeight: "100vh",
  backgroundColor: colors.greyLighter,
  minWidth: LARGE_SIDEBAR_WIDTH,
  maxWidth: LARGE_SIDEBAR_WIDTH,
};
const content = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: spacing.m,
  zIndex: 2,
};
const back = css({
  color: colors.primaryShadow,
  display: "flex",
  alignItems: "center",
  gap: spacing.xxs,
  ":hover": {
    color: colors.primary,
    cursor: "pointer",
  },
});
const title = {
  color: colors.greyShadow,
  fontWeight: "normal",
  padding: `0 ${spacing.m}`,
};
const progressWrapper = {
  padding: `${spacing.s} ${spacing.m}`,
};
const lessonSearchWrapper = css({
  padding: spacing.s,
});

export default function LessonSidebar(props) {
  const { currentCourse, showSidebar, toggleSidebar } = props;

  return (
    <div style={container}>
      <div style={content}>
        <Link
          to={`/courses/${currentCourse?.id}`}
          className={back}
          component="div"
        >
          <AiFillCaretLeft size={14} /> VOLTAR PARA NAVEGAÇÃO
        </Link>
        {showSidebar && (
          <Button onClick={toggleSidebar} icon="close" size="small"></Button>
        )}
      </div>

      <h2 style={title}>{currentCourse?.name}</h2>

      {!!currentCourse?.meta && (
        <div style={progressWrapper}>
          <CourseProgress progress={currentCourse?.meta?.progress} />
        </div>
      )}

      <div className={lessonSearchWrapper}>
        <LessonSearchFormContainer />
      </div>

      <LessonSidebarContentsContainer />
    </div>
  );
}
