import React, { useCallback } from "react";
import { connect } from "react-redux";
import Onboarding from "./Onboarding";
import { getCurrentUser } from "../../selectors/userSelectors";
import { getConfiguration } from "../../selectors/configSelectors";
import { updateProfile } from "../../processes/profileProcesses";

function OnboardingContainer(props) {
  const { dispatch, currentUser, videoUrl } = props;
  const showOnboarding = currentUser && !currentUser?.onboardedAt && videoUrl;

  const onboardHandler = useCallback(() => {
    const now = new Date();

    updateProfile({ onboardedAt: now }, dispatch, { skipRedirectToRoot: true });
  }, [dispatch]);

  return showOnboarding ? (
    <Onboarding onboardHandler={onboardHandler} {...props} />
  ) : null;
}

function mapStateToProps(state) {
  const onboardVideo = getConfiguration(state, "onboard_video");

  return {
    currentUser: getCurrentUser(state),
    videoUrl: onboardVideo?.value,
  };
}

export default connect(mapStateToProps)(OnboardingContainer);
