import { css } from "glamor";
import React from "react";
import Link from "../library/Link";
import { colors, spacing } from "../library/theme";
import Logo from "../library/images/logo-alternative.svg";
import LogoYouTube from "../library/images/logo-youtube.svg";
import LogoInstagram from "../library/images/logo-instagram.svg";
import LogoLinkedin from "../library/images/logo-linkedin.svg";
import LogoTikTok from "../library/images/logo-tiktok.svg";
import LogoTwitter from "../library/images/logo-twitter.svg";
import LogoWhatsApp from "../library/images/logo-whatsapp.svg";
import LogoFacebook from "../library/images/logo-facebook.svg";

const container = css({
  lineHeight: 1.1,
  borderTop: `4px solid ${colors.greyLighter}`,
  margin: `auto -${spacing.l} 0 -${spacing.l}`,
});
const topFooter = css({
  display: "flex",
  justifyContent: "space-between",
  gap: spacing.m,
  padding: spacing.l,
  flexWrap: "wrap",
});
const bottomFooter = css({
  backgroundColor: colors.greyLighter,
  display: "flex",
  gap: spacing.m,
  alignItems: "center",
  padding: `${spacing.m} ${spacing.l}`,
  flexWrap: "wrap",
  "@media (max-width: 575px)": {
    paddingBottom: 100,
  },
});
const linksWrapper = css({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  gap: spacing.l,
});
const linkClass = css({
  display: "block",
  color: `${colors.greyDark} !important`,
  marginTop: spacing.s,
  ":hover": {
    textDecoration: "underline",
  },
});
const logoStyle = {
  width: "100%",
};
const socialMediaLogoStyle = {
  height: "20px",
  width: "20px",
  cursor: "pointer",
};
const mainColumnClass = css({
  width: 216,
});
const subColumnClass = css({
  margin: spacing.s,
  minWidth: 175,
});
const locationWrapperClass = css({
  marginTop: spacing.m,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  gap: spacing.m,
});

export default function Footer() {
  return (
    <div className={container}>
      <div className={topFooter}>
        <div className={mainColumnClass}>
          <img src={Logo} style={logoStyle} />

          <div className={locationWrapperClass}>
            <p>T2 Educação S/A - CNPJ 24.970.216/0001-48</p>
            <p>Av. Paulista, 2202 - São Paulo suporte@t2.com.br</p>
          </div>
        </div>

        <div className={subColumnClass}>
          <strong>Cursos por objetivo</strong>
          <Link
            target="_blank"
            className={linkClass}
            href="https://t2.com.br/cursos/cpa10/"
          >
            Passar na CPA10
          </Link>
          <Link
            target="_blank"
            className={linkClass}
            href="https://t2.com.br/cursos/cpa20/"
          >
            Passar na CPA20
          </Link>
          <Link
            target="_blank"
            className={linkClass}
            href="https://t2.com.br/cursos/cea/"
          >
            Passar na CEA
          </Link>
          <Link
            target="_blank"
            className={linkClass}
            href="https://t2.com.br/cursos/ancord/"
          >
            Passar na ANCORD
          </Link>
          <Link
            target="_blank"
            className={linkClass}
            href="https://t2.com.br/cursos/cfp/"
          >
            Passar na CFP
          </Link>
          <Link
            target="_blank"
            className={linkClass}
            href="https://t2.com.br/cursos/pqo/"
          >
            Passar na PQO
          </Link>
        </div>
        <div className={subColumnClass}>
          <strong>Links úteis</strong>
          <Link
            target="_blank"
            className={linkClass}
            href="https://t2.com.br/quem-somos/"
          >
            Quem somos
          </Link>
          <Link
            target="_blank"
            className={linkClass}
            href="https://t2.com.br/politicas-de-reembolso/"
          >
            Políticas de Reembolso
          </Link>
          <Link
            target="_blank"
            className={linkClass}
            href="https://t2.com.br/politica-de-privacidade/"
          >
            Políticas de Privacidade
          </Link>
          <Link
            target="_blank"
            className={linkClass}
            href="https://t2.com.br/links/"
          >
            Links
          </Link>
        </div>
        <div className={subColumnClass}>
          <strong>Atendimento</strong>
          <Link
            target="_blank"
            className={linkClass}
            href="https://t2.com.br/contato/"
          >
            Assessoria de comunicação
          </Link>
          <Link
            target="_blank"
            className={linkClass}
            href="https://t2.com.br/perguntas-frequentes/"
          >
            Perguntas Frequentes
          </Link>
          <Link
            target="_blank"
            className={linkClass}
            href="https://t2.com.br/contato/"
          >
            Banco de Talentos
          </Link>
          <Link
            target="_blank"
            className={linkClass}
            href="https://t2.com.br/contato/"
          >
            Fale conosco
          </Link>
        </div>
      </div>

      <div className={bottomFooter}>
        <div>
          <h2>Siga a T2</h2>
        </div>
        <div className={linksWrapper}>
          <Link href="https://t2.com.br/youtube" target="_blank">
            <img src={LogoYouTube} style={socialMediaLogoStyle} />
          </Link>
          <Link href="https://t2.com.br/instagram" target="_blank">
            <img src={LogoInstagram} style={socialMediaLogoStyle} />
          </Link>
          <Link href="https://t2.com.br/linkedin" target="_blank">
            <img src={LogoLinkedin} style={socialMediaLogoStyle} />
          </Link>
          <Link href="https://t2.com.br/tiktok" target="_blank">
            <img src={LogoTikTok} style={socialMediaLogoStyle} />
          </Link>
          <Link href="https://t2.com.br/twitter" target="_blank">
            <img src={LogoTwitter} style={socialMediaLogoStyle} />
          </Link>
          <Link href="https://t2.com.br/whatsapp" target="_blank">
            <img src={LogoWhatsApp} style={socialMediaLogoStyle} />
          </Link>
          <Link href="https://t2.com.br/facebook" target="_blank">
            <img src={LogoFacebook} style={socialMediaLogoStyle} />
          </Link>
        </div>
      </div>
    </div>
  );
}
