import React from "react";
import { Table, Tab, Card, Statistic, Popup } from "semantic-ui-react";
import Link from "../library/Link";
import Button from "../library/Button";
import formatDate from "../../utils/formatDate";
import { spacing } from "../library/theme";
import { css } from "glamor";
import NewClassroomModal from "./NewClassroomModal";
import { useSelector } from "react-redux";
import {
  getMocksAverageByCompanyId,
  getCompanyClassroomsByProps,
} from "../../selectors/classroomSelectors";
import {
  getTotalStudents,
  getMocksAverage,
} from "../../selectors/companySelectors";
import Empty from "./Empty";
import AddStudentModal from "./AddStudentModal";

const nameColumnStyle = {
  width: "100%",
};
const createdAtColumnStyle = {
  paddingRight: spacing.m,
};

const topTenTitle = css({
  marginBottom: spacing.m,
});

const topTenTitleWrapper = css({
  padding: spacing.m,
  flexGrow: "unset !important",
});

const actionsWrapper = css({
  display: "flex",
  gap: spacing.s,
});

const CompanyClassroomsCourseCard = ({ course, company }) => {
  const classrooms = useSelector((state) =>
    getCompanyClassroomsByProps(state, {
      companyId: company?.id,
      courseId: course?.id,
    })
  );
  const mocksAverage = useSelector((state) =>
    getMocksAverage(state, {
      companyId: company?.id,
      courseId: course?.id,
    })
  );
  const totalStudents = useSelector((state) =>
    getTotalStudents(state, {
      companyId: company?.id,
      courseId: course?.id,
    })
  );

  return (
    <Card fluid style={{ overflowX: "auto" }}>
      <Card.Header className={topTenTitleWrapper}>
        <h3>{course.name}</h3>
      </Card.Header>
      <Card.Content>
        <Card.Group
          itemsPerRow={3}
          stackable
          centered
          items={[
            {
              key: "exam",
              header: "Média das avaliações direcionadas",
              description: (
                <Statistic size="small">
                  <Statistic.Value>
                    {mocksAverage ? `${mocksAverage}%` : "--"}
                  </Statistic.Value>
                </Statistic>
              ),
            },
            {
              key: "available",
              header: "Créditos Disponíveis (Matrículas)",
              description: (
                <Statistic size="small">
                  <Statistic.Value>
                    {course?.creditsLeft || "--"}
                  </Statistic.Value>
                </Statistic>
              ),
            },
            {
              key: "used",
              header: "Alunos",
              description: (
                <Statistic size="small">
                  <Statistic.Value>{totalStudents || "--"}</Statistic.Value>
                </Statistic>
              ),
            },
          ]}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: spacing.l,
          }}
        >
          <h3 style={{ margin: 0 }}>Turmas</h3>

          <div className={actionsWrapper}>
            <NewClassroomModal company={company} courseId={course.id} />
          </div>
        </div>

        <Table unstackable style={{ minWidth: 500 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Criada Em</Table.HeaderCell>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>Alunos</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {classrooms.map((classroom) => (
              <Table.Row>
                <Table.Cell style={createdAtColumnStyle}>
                  {formatDate(classroom.createdAt, "DD/MM/YYYY")}
                </Table.Cell>
                <Table.Cell style={nameColumnStyle}>
                  {classroom.name}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {classroom.studentCount}
                </Table.Cell>
                <Table.Cell style={{ display: "flex" }}>
                  <Popup
                    content="Importar Alunos"
                    position="top center"
                    trigger={
                      <span>
                        <AddStudentModal
                          company={company}
                          courseId={course.id}
                          classroomId={classroom.id}
                          disabled={course?.creditsLeft === 0}
                        />
                      </span>
                    }
                  />
                  <Popup
                    content="Abrir Dashboard"
                    position="top right"
                    trigger={
                      <Link
                        component={Button}
                        to={`/classrooms/${classroom.id}`}
                        icon="chart line"
                      />
                    }
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {classrooms.length === 0 && <Empty />}
      </Card.Content>
    </Card>
  );
};

export default function CompanyClassrooms({
  company,
  classrooms = [],
  mocksAverage,
  totalStudents,
}) {
  return (
    <React.Fragment>
      {company?.meta?.courses?.length === 0 ? <Empty /> : null}
      {company?.meta?.courses?.map((course) => (
        <CompanyClassroomsCourseCard
          company={company}
          course={course}
          classrooms={classrooms}
        />
      ))}
    </React.Fragment>
  );
}
