import { normalize, schema } from "normalizr";
import { apiGet } from "./helpers/api";

const userFileSchema = new schema.Entity("userFiles");

export const fetchUserFiles = (dispatch) => {
  return apiGet("/api/v1/user_files").then((response) => {
    dispatch({
      type: "USER_FILES_FETCHED",
      ...normalize(response.body.data, [userFileSchema]),
    });
  });
};

export const userFileFetched = (dispatch, data) => {
  dispatch({
    type: "USER_FILE_FETCHED",
    ...normalize(data, userFileSchema),
  });
};
