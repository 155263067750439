const initialState = {};
const getCoupons = (action) => action.entities.coupons;

export default function couponsReducer(state = initialState, action) {
  switch (action.type) {
    case "COUPONS_RESETED":
      return initialState;
    case "COUPONS_FETCHED":
      return { ...state, ...getCoupons(action) };
    default:
      return state;
  }
}
