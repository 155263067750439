const getComments = (action) => action.entities.comments;

const getComment = (state, commentId) => (commentId ? state[commentId] : {});

const editComment = (state, commentId, props) => ({
  ...state,
  [commentId]: { ...state[commentId], ...props },
});

export default function commentsReducer(state = {}, action) {
  const comment = getComment(state, action.commentId);
  switch (action.type) {
    case "COMMENT_CREATED":
    case "COMMENT_UPDATED":
    case "COMMENT_REPLY_CREATED":
    case "COMMENTS_HIGHLIGHTED_FETCHED":
    case "COMMENTS_HIGHLIGHTED_FROM_TEAM_FETCHED":
    case "COMMENTS_HIGHLIGHTED_BY_CATEGORY_FETCHED":
    case "COMMENTS_FETCHED":
      return { ...state, ...getComments(action) };
    case "COMMENT_DELETED":
      if (!state[action.commentId]) return;
      delete state[action.commentId];
      return { ...state };
    case "COMMENT_LIKE_INCREASED":
      return editComment(state, comment.id, {
        likeableCount: comment.likeableCount + 1,
      });
    case "COMMENT_LIKE_DECREASED":
      return editComment(state, comment.id, {
        likeableCount: comment.likeableCount - 1,
      });
    default:
      return state;
  }
}
