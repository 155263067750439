import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import SurveyModal from "./SurveyModal";
import { getFirstSurveyFromType } from "../../selectors/surveysSelectors";
import { getCurrentUser } from "../../selectors/userSelectors";
import { fetchSurveys } from "../../processes/surveyProcesses";
import { fetchSurveysUsers } from "../../processes/surveysUsersProcesses";

function SurveyModalContainer(props) {
  const { dispatch, survey, currentUser, condition, loading } = props;
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    fetchSurveysUsers(dispatch);
    fetchSurveys(dispatch);
  }, [dispatch]);

  const closeModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  useEffect(() => {
    if (survey) {
      setOpenModal(true);
    }
  }, [survey]);

  return condition && survey && currentUser && !loading ? (
    <SurveyModal
      survey={survey}
      openModal={openModal}
      closeModal={closeModal}
      {...props}
    />
  ) : null;
}

const mapStateToProps = (state, ownProps) => {
  const { type } = ownProps;

  return {
    currentUser: getCurrentUser(state),
    survey: getFirstSurveyFromType(state, type),
    loading: state.surveyUtils.loading,
  };
};

export default connect(mapStateToProps)(SurveyModalContainer);
