import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { canAccessTrialEnrollment } from "../../selectors/courseSelectors";
import {
  getCurrentLevel,
  getLessonsFromLevel,
  getLevelDuration,
  getLevelLessons,
} from "../../selectors/lessonSelectors";
import { getLessonsUsersFromLessons } from "../../selectors/lessonsUserSelectors";
import { getSublevelsFromLevel } from "../../selectors/levelSelectors";
import LevelListItem from "./LevelListItem";

function LevelListItemContainer(props) {
  const { currentLevel, level, currentIndex } = props;
  const isActive = currentLevel
    ? currentLevel?.sublevelId === level.id || currentLevel?.id === level.id
    : currentIndex === 0;
  const [active, setActive] = useState(isActive);
  const handleClick = useCallback(() => setActive(!active), [active]);

  useEffect(() => setActive(isActive), [isActive]);

  return <LevelListItem {...props} handleClick={handleClick} active={active} />;
}

function mapStateToProps(state, ownProps) {
  const { level } = ownProps;
  const levelLessons = getLevelLessons(state, level?.id);
  const levelLessonsUsers = getLessonsUsersFromLessons(state, levelLessons);

  return {
    sublevels: getSublevelsFromLevel(state, ownProps),
    currentLevel: getCurrentLevel(state),
    lessons: getLessonsFromLevel(state, ownProps),
    duration: getLevelDuration(state, ownProps),
    lessonsWatchedCount: levelLessonsUsers?.length,
    lessonsCount: levelLessons?.length,
    isTrialEnrollment: canAccessTrialEnrollment(state, level?.courseId),
  };
}

export default connect(mapStateToProps)(LevelListItemContainer);
