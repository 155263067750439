function logger(event) {
  window.dataLayer.push(event);
}

export default function logEvent(eventName, params) {
  switch (eventName) {
    case "purchase":
      const event = {
        event: "purchase",
        ecommerce: {
          transaction_id: params.transactionId,
          value: params.value,
          currency: "BRL",
          items: [
            {
              index: 0,
              item_id: params.offerId,
              item_name: params.offerName,
              coupon: params.couponCode,
              discount: params.discount,
              price: params.offerPrice,
              item_brand: "T2",
              quantity: 1,
            },
          ],
        },
      };

      logger(event);
      break;
  }
}
