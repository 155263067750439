import { css } from "glamor";
import React from "react";
import { Button } from "semantic-ui-react";
import { colors, spacing } from "../library/theme";

const FIXED_SIZE = 38;
const buttonStyle = (buttonColor = colors.white) => {
  const color =
    buttonColor == colors.white ? colors.greyShadow : colors.greyClear;

  return {
    color,
    width: FIXED_SIZE,
    height: FIXED_SIZE,
    padding: 0,
    borderRadius: "50%",
    marginRight: spacing.xxs,
    marginBottom: spacing.xxs,
    background: buttonColor,
  };
};
const activeClass = css({
  border: `3px solid ${colors.primary} !important`,
  ".ui.button:disabled": { opacity: "1 !important" },
});

export default function QuestionPaginationItem({
  index,
  currentIndex,
  goToIndexHandler,
  answered,
  showAnswer,
}) {
  const answeredButtonColor = answered?.value ? colors.correct : colors.wrong;
  const buttonColor = showAnswer ? answeredButtonColor : colors.primaryLighter;
  const isActive = currentIndex === index;
  return (
    <Button
      className={isActive && activeClass.toString()}
      style={buttonStyle(answered ? buttonColor : undefined)}
      onClick={goToIndexHandler}
      disabled={isActive}
      circular
    >
      {index + 1}
    </Button>
  );
}
