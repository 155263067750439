import React, { useState } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { fetchMocks } from "../../processes/mockProccess";
import {
  coursesForMenuOptions,
  hasMockCourses,
  getMockCourses,
} from "../../selectors/courseSelectors";
import debounce from "../../utils/debounce";
import FilterForm from "./FilterForm";

function FilterFormContainer(props) {
  const [selectedCourse, setSelectedCourse] = useState({});

  return (
    <FilterForm
      selectedCourse={selectedCourse}
      setSelectedCourse={setSelectedCourse}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  return {
    initialValues: {},
    courseMenuOptions: coursesForMenuOptions(state),
    courses: getMockCourses(state),
    hasMockCourses: hasMockCourses(state),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "mocksFilterForm",
    onChange: debounce(fetchMocks, 500),
  })(FilterFormContainer)
);
