import React, { useCallback, useEffect, useState } from "react";
import { ActionCableConsumer } from "react-actioncable-provider";
import { connect } from "react-redux";
import {
  getNotificationsOrderedByUnread,
  getUnreadNotifications,
} from "../../selectors/notificationSelectors";
import Notifications from "./Notifications";
import { getUnreadNotificationsCount } from "../../selectors/notificationsUserSelectors";
import {
  fetchNotificationsUsers,
  notificationsUserFetched,
  readAllNotifications,
} from "../../processes/notificationsUserProcesses";

function NotificationsContainer(props) {
  const { dispatch, unreadNotifications } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchNotificationsUsers(dispatch);
  }, [dispatch]);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
    if (unreadNotifications.length > 0) {
      readNotifications(dispatch);
    }
  }, [dispatch, unreadNotifications, readNotifications]);

  const readNotifications = useCallback(() => {
    readAllNotifications(dispatch);
  }, [dispatch]);

  const onReceived = useCallback(
    (payload) => {
      notificationsUserFetched(dispatch, payload?.data);
    },
    [dispatch]
  );

  return (
    <>
      <ActionCableConsumer
        channel={{ channel: "NotificationsChannel" }}
        onReceived={onReceived}
      />
      <Notifications {...props} onOpen={onOpen} onClose={onClose} open={open} />
    </>
  );
}

function mapStateToProps(state) {
  return {
    notifications: getNotificationsOrderedByUnread(state),
    unreadCount: getUnreadNotificationsCount(state),
    unreadNotifications: getUnreadNotifications(state),
  };
}

export default connect(mapStateToProps)(NotificationsContainer);
