import React from "react";
import { connect } from "react-redux";
import { getAllBanners } from "../../selectors/bannerSelectors";
import Banners from "./Banners";

function BannersContainer(props) {
  return <Banners {...props} />;
}

function mapStateToProps(state) {
  return {
    banners: getAllBanners(state),
  };
}

export default connect(mapStateToProps)(BannersContainer);
