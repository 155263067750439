const getTerms = (action) => action.entities.terms;

export default function termsReducer(state = {}, action) {
  switch (action.type) {
    case "TERMS_FETCHED":
      return { ...state, ...getTerms(action) };
    default:
      return state;
  }
}
