import { css } from "glamor";
import React, { useCallback, useState } from "react";
import { FaRegEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  Dropdown,
  Header,
  Icon,
  Image,
  Input,
  Tab,
  Table,
} from "semantic-ui-react";
import { baseUrl } from "../../processes/helpers/api";
import { getSession } from "../../processes/sessionProcesses";
import { getCompanyStudentsBySearch } from "../../selectors/classroomSelectors";
import formatDate from "../../utils/formatDate";
import Button from "../library/Button";
import Link from "../library/Link";
import { spacing, styles, typographyColors } from "../library/theme";
import CustomTableHeaderCell from "../classrooms/CustomTableHeaderCell";
import AddStudentModal from "./AddStudentModal";
import Empty from "./Empty";

const tableWrapper = css({
  display: "grid",
  gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
  overflow: "auto",
  whiteSpace: "nowrap",
});
const tabPaneClass = css({
  padding: `${spacing.s} 0px ${spacing.l} 0px !important`,
  border: "none !important",
  boxShadow: "none !important",
  "> div > div": styles.alternativeFontFamily,
}).toString();
const inputClass = css({
  minHeight: 50,
  minWidth: 300,
});
const headerClass = css({
  display: "flex",
  gap: spacing.m,
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: spacing.m,
  "@media(max-width: 575px)": {
    "> div": {
      width: "100%",
    },
  },
});
const linkWrapper = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: spacing.xxs,
  fontSize: 16,
  whiteSpace: "nowrap",
};
const linkClass = css({
  color: `${typographyColors.defaultTitle} !important`,
  textDecoration: "underline",
});
const imageStyle = { aspectRatio: "1 / 1" };
const addWrapperClass = css({
  flex: 1,
  display: "flex",
  justifyContent: "flex-end",
  "@media(max-width: 575px)": {
    flex: "unset",
  },
});

export default function CompanyStudents({ company, classrooms = [] }) {
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("name");
  const [order, setOrder] = useState("asc");
  const toggleOrder = useCallback(() => {
    setOrder(order == "asc" ? "desc" : "asc");
  }, [order]);
  const searchChangeHandler = useCallback((_, { value }) => {
    setSearch(value);
  }, []);
  const session = getSession();
  const users = useSelector((state) =>
    getCompanyStudentsBySearch(state, {
      companyId: company?.id,
      search,
      sort,
      order,
    })
  );

  const tableHeaderOptions = { sort, order, toggleOrder, setSort };

  return (
    <Tab.Pane attached={false} className={tabPaneClass}>
      <div className={headerClass}>
        <Input
          iconPosition="left"
          placeholder="Digite um nome ou email"
          onChange={searchChangeHandler}
          className={inputClass}
        >
          <Icon name="search" />
          <input />
        </Input>

        <div className={addWrapperClass}>
          <AddStudentModal triggerTitle="Adicionar aluno" company={company} />
        </div>
      </div>

      <div className={tableWrapper}>
        <Table unstackable compact="very" celled={false}>
          <Table.Header>
            <Table.Row>
              <CustomTableHeaderCell
                content="Nome"
                columnName="name"
                textAlign="left"
                {...tableHeaderOptions}
              />
              <CustomTableHeaderCell
                content="Email"
                columnName="email"
                textAlign="left"
                {...tableHeaderOptions}
              />
              <CustomTableHeaderCell
                content="Último acesso"
                columnName="lastSeenAt"
                {...tableHeaderOptions}
              />
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {users?.map((user) => (
              <Table.Row key={user.id}>
                <Table.Cell>
                  <Header as="h4" image>
                    <Image
                      style={imageStyle}
                      src={user.avatarUrl}
                      rounded
                      size="mini"
                    />
                    <Header.Content>
                      {user.name.split(" ")[0]}
                      <Header.Subheader>
                        {user.name.split(" ")[1]}
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
                <Table.Cell textAlign="center">
                  {formatDate(user.lastSeenAt, "DD/MM/YYYY")}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {!users?.length && <Empty />}
      </div>
    </Tab.Pane>
  );
}
