import { css } from "glamor";
import React from "react";
import Button from "../library/Button";
import { colors, spacing } from "../library/theme";

const container = css({
  marginTop: spacing.l,
  textAlign: "center",
});

const buttonStyle = {
  minWidth: 150,
  padding: spacing.m,
  borderRadius: 25,
  backgroundColor: colors.primary,
  color: colors.white,
  fontSize: 16
};

export default function MockModalButton(props) {
  const {
    handleSubmit,
    pristine,
    submitting,
    invalid,
    hasMockCourses,
    buttonText = "Text",
  } = props;

  return (
    <div className={container}>
      <Button
        style={buttonStyle}
        type="button"
        onClick={handleSubmit}
        disabled={pristine || submitting || !hasMockCourses || invalid}
        secondary
      >
        {buttonText}
      </Button>
    </div>
  );
}
