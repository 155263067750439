const getClassrooms = (action) => action.entities.classrooms;

export default function classroomsReducer(state = {}, action) {
  switch (action.type) {
    case "USER_FETCHED":
    case "COMPANY_FETCHED":
    case "CLASSROOMS_FETCHED":
      return { ...state, ...getClassrooms(action) };
    case "CLASSROOM_FETCHED": {
      const classroom = state[action.result];
      if (!classroom) return state;

      return {
        ...state,
        [classroom.id]: {
          ...classroom,
          ...getClassrooms(action)[classroom.id],
        },
      };
    }
    default:
      return state;
  }
}
