import React from "react";
import { Card } from "semantic-ui-react";
import { css } from "glamor";
import { CheckBoxFieldArray, Form } from "../library/forms";
import Spinner from "../library/Spinner";
import { FieldArray } from "redux-form";
import { spacing } from "../library/theme";
import Button from "../library/Button";

const container = {
  minHeight: 236,
  position: "relative",
};
const loadingStyle = { opacity: 0.5, pointerEvents: "none" };
const spinnerClass = css({
  position: "absolute",
  top: spacing.xs,
  right: spacing.s,
});
const content = {
  paddingTop: 0,
  flex: 1,
};
const buttonWrapperStyle = {
  marginTop: spacing.s,
};

export default function ContentCategoryForm(props) {
  const { handleSubmit, loading, contentCategories } = props;

  return contentCategories?.length > 0 ? (
    <Card fluid style={container}>
      <Card.Content>
        <Card.Header>Filtrar por Tags</Card.Header>
      </Card.Content>
      {loading && (
        <div className={spinnerClass}>
          <Spinner inline size="tiny" active />
        </div>
      )}
      <Card.Content style={content}>
        <Form onSubmit={handleSubmit}>
          <div className={css(loading && loadingStyle)}>
            <FieldArray
              name="categoriesIdIn"
              component={CheckBoxFieldArray}
              options={contentCategories}
            />
          </div>
          <div style={buttonWrapperStyle}>
            <Button type="submit" fluid>
              Pesquisar
            </Button>
          </div>
        </Form>
      </Card.Content>
    </Card>
  ) : null;
}
