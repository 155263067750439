import { css } from "glamor";
import React, { useEffect, useState } from "react";
import { spacing, uiColors, colors } from "../theme";
import CheckBoxFieldArrayItemContainer from "./CheckBoxFieldArrayItemContainer";
import Label from "./Label";
import Link from "../Link";
import { Checkbox } from "semantic-ui-react";

const container = css({
  "& + &": {
    marginTop: spacing.m,
    paddingTop: spacing.m,
    borderTop: `1px solid ${uiColors.border}`,
  },
});
const layout = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xs,
    paddingTop: spacing.s,
  },
  label: {
    display: "block",
    marginBottom: spacing.xxs,
    fontSize: 16,
  },
  link: {
    cursor: "pointer",
    color: colors.primary,
  },
  checkboxLabel: {
    cursor: "pointer",
    marginBottom: spacing.m,
    color: colors.dark,
    fontWeight: 600,
    fontSize: 16,
  },
};

export default function CheckBoxFieldArray({
  label,
  options,
  chosenOption,
  placeholder,
  selectAllLabel,
  ...rest
}) {
  const { fields } = rest;
  const [isAllSelected, setIsAllSelected] = useState(false);

  const selectAllHandler = React.useCallback(() => {
    if (!fields || !options) return;
    setIsAllSelected(true);
    fields.removeAll();
    options.map((item) => fields.push(item.id));
  }, [fields, options]);

  const deselectAllHandler = React.useCallback(() => {
    if (!fields) return;
    setIsAllSelected(false);
    fields.removeAll();
  }, [fields]);

  const checkboxClickHandler = isAllSelected
    ? deselectAllHandler
    : selectAllHandler;

  useEffect(() => {
    setIsAllSelected(false);
    deselectAllHandler();
  }, [chosenOption]);

  useEffect(() => {
    const selectedFieldsLength = fields?.getAll()?.length;

    if (options.length > 0 && selectedFieldsLength == options.length) {
      setIsAllSelected(true);
    }
  }, [fields?.getAll(), options]);

  return (
    <div className={container}>
      {label && (
        <Label
          style={layout.label}
          required={rest.required}
          disabled={rest.disabled}
        >
          {label}
        </Label>
      )}
      <div style={layout.wrapper}>
        {options.length ? (
          <>
            {selectAllLabel && (
              <Checkbox
                style={layout.checkboxLabel}
                onClick={checkboxClickHandler}
                checked={isAllSelected}
                label={selectAllLabel}
              />
            )}
            {options.map((item, index) => (
              <CheckBoxFieldArrayItemContainer
                key={index}
                item={item}
                setIsAllSelected={setIsAllSelected}
                {...rest}
              />
            ))}
          </>
        ) : (
          <p>{`${placeholder || "Nenhuma opção disponível"}`}</p>
        )}
      </div>
    </div>
  );
}
