import React, { useEffect } from "react";
import { setSession } from "../../processes/sessionProcesses";
import Spinner from "../library/Spinner";
import { useURLQueryParams } from "../../utils/useURLQueryParams";

export default function ImpersonateContainer(props) {
  let query = useURLQueryParams();

  useEffect(() => {
    const accessToken = query.get("headers[access-token]");
    const client = query.get("headers[client]");
    const uid = query.get("headers[uid]");
    const currentUserId = query.get("current_user_id");

    if (accessToken && client && uid && currentUserId) {
      setSession({
        headers: {
          "access-token": accessToken,
          uid,
          client,
        },
        body: { data: { id: Number(currentUserId) } },
      });
    }

    window.location.replace("/");
  }, [query]);

  return <Spinner />;
}
