import React from "react";
import { css } from "glamor";
import { colors, spacing } from "../library/theme";
import truncateString from "../../utils/truncateString";
import { FaCirclePlay } from "react-icons/fa6";
import Link from "../library/Link";
import Icon from "../library/Icon";
import { Progress } from "semantic-ui-react";
import { makeIsMobile } from "../../utils/useScreenWidth";

const container = css({
  backgroundColor: "#0A2028",
  color: colors.white,
  padding: spacing.m,
  display: "flex",
  alignItems: "center",
  gap: spacing.m,
  borderRadius: spacing.xs,
  flexWrap: "wrap",
});
const titleWrapperClass = css({
  flex: 1,
  minWidth: 200,
  lineHeight: 1.3,
});
const textClass = css({
  color: colors.white,
});
const titleClass = css({
  color: colors.white,
  fontWeight: "bold",
});
const progressClass = css({
  "> .ui.progress": {
    backgroundColor: "#E5E5E5 !important",
    marginTop: spacing.xs,
    marginBottom: spacing.xxs,
    width: 300,
  },
  "> .ui.progress .bar": {
    backgroundColor: "#526AE3 !important",
  },
  "@media (max-width: 768px)": {
    "> .ui.progress": {
      width: "100%",
    },
    flex: 1,
  },
});
const linkClass = css({
  backgroundColor: colors.white,
  height: 32,
  width: 32,
  borderRadius: "50%",
  ":hover": {
    backgroundColor: "inherit",
  },
});
const leftContentClass = css({
  display: "flex",
  alignItems: "center",
  gap: spacing.m,
  flex: 1,
});
const rightContentClass = css({
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  gap: spacing.m,
  minWidth: 200,
  "@media (max-width: 768px)": {
    flex: 1,
  },
});

export default function ContinueCard({
  title,
  subtitle,
  link,
  time,
  progress,
  progressValue,
}) {
  const isMobile = makeIsMobile();

  return (
    <div className={container}>
      <div className={leftContentClass}>
        <div className={titleWrapperClass}>
          <h4 className={titleClass}>{title}</h4>
          <p className={textClass}>{subtitle}</p>
        </div>
        {isMobile && (
          <Link to={link} className={linkClass}>
            <FaCirclePlay size={32} color="#009EDE" />
          </Link>
        )}
      </div>

      <div className={rightContentClass}>
        <div className={progressClass}>
          {time && (
            <p className={textClass}>
              <Icon name="clock outline" /> {time}
            </p>
          )}

          {progress && <p className={textClass}>{progress}</p>}

          <Progress percent={progressValue} size="tiny" />
        </div>

        {!isMobile && (
          <Link to={link} className={linkClass}>
            <FaCirclePlay size={32} color="#009EDE" />
          </Link>
        )}
      </div>
    </div>
  );
}
