import React from "react";
import { Input } from "semantic-ui-react";
import { css } from "glamor";
import { colors } from "../theme";

const container = css({
  "> input": {
    backgroundColor: `${colors.greyLighter} !important`,
    border: "none !important",
    borderRadius: "10px",
  },
});

export default function TextInput({ invalid, ...rest }) {
  return <Input className={container.toString()} {...rest} />;
}
