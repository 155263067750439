import { createSelector } from "reselect";
import {
  getUnenrolledCourses,
  getAllCourses,
  getAllValidCourses,
} from "./courseSelectors";
import { getCurrentUser } from "./userSelectors";
import orderByPosition from "./helpers/orderByPosition";

const getConfigs = (state) => state.entities.configs;
const getConfigName = (_, name) => name;
const getIds = (_, name, ids) => ids;
const getStateId = (_, id) => id;

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

export const getConfig = createSelector(
  getConfigs,
  getConfigName,
  (configs, name) => {
    const config = configs[name];
    return config && Object.values(config);
  }
);

export const getConfigByName = createSelector(
  getConfigs,
  getConfigName,
  (configs, name) => configs[name]
);

export const getCitiesByStateId = createSelector(
  getConfigs,
  getStateId,
  (configs, id) =>
    Object.values(configs["cities"])?.filter((city) => city?.stateId == id)
);

export const getConfigByNameAndIds = createSelector(
  getConfig,
  getIds,
  (config, ids) => {
    return (
      config &&
      ids &&
      Object.values(config).filter((item) => ids.includes(item.id))
    );
  }
);

export const getConfigByNameAndId = createSelector(
  getConfigByNameAndIds,
  (config) => (config && config.length > 0 ? config[0] : null)
);

export const getTopContentCategories = createSelector(getConfigs, (configs) => {
  return Object.values(configs["contentCategories"])?.slice(0, 3);
});

export const getCourseCategories = createSelector(getConfigs, (configs) => {
  const config = configs["courseCategories"];

  return config && Object.values(config).sort(orderByPosition);
});

export const getCourseCategoriesWithBuyableCourses = createSelector(
  getCourseCategories,
  getUnenrolledCourses,
  (categories, courses) =>
    categories
      ?.filter(({ slug }) =>
        courses.some(
          (course) => course.categories.includes(slug) && course?.visible
        )
      )
      .sort(orderByPosition)
);

export const getCourseCategoriesWithUserCourse = createSelector(
  getCourseCategories,
  getAllValidCourses,
  (categories, courses) =>
    categories
      ?.filter(({ slug }) => {
        if (courses.some(({ categories = [] }) => categories.includes(slug))) {
          return true;
        }

        return false;
      })
      .sort(orderByPosition)
);

export const getCourseCategoriesForList = createSelector(
  getCourseCategoriesWithBuyableCourses,
  getCourseCategoriesWithUserCourse,
  (buyableCourseCategories, userCourseCategories) => {
    return buyableCourseCategories
      ?.concat(userCourseCategories)
      .filter(onlyUnique)
      .sort(orderByPosition);
  }
);

export const getCourseCategoriesBySlugs = createSelector(
  getCourseCategories,
  getConfigName,
  (courseCategories, slugs) => {
    return courseCategories?.filter((courseCategory) =>
      slugs?.includes(courseCategory.slug)
    );
  }
);

export const getConfiguration = createSelector(
  getConfigs,
  getConfigName,
  (configs, name) => {
    const config = configs["configs"];

    return config && Object.values(config)?.find((item) => item.key == name);
  }
);

export const getCountryCodeOptions = createSelector(getConfigs, (configs) => {
  const phoneCountryCodes = configs["phoneCountryCodes"] || {};

  return Object.values(phoneCountryCodes)?.map((item) => ({
    key: `phoneCountryCode${item.id}`,
    text: `${item.name} +${item.code}`,
    value: item.id,
    flag: item.unLocode,
    code: item.code,
  }));
});
