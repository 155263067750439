import React from "react";
import { css } from "glamor";
import Container from "../library/Container";
import { CardForm, TextField, TextAreaField } from "../library/forms";
import Button from "../library/Button";
import { spacing } from "../library/theme";

const buttonWrapper = css({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: spacing.m,
});
const section = { marginTop: spacing.m };

export default function Support(props) {
  return (
    <Container padded>
      <CardForm onSubmit={props.handleSubmit} error={props.error}>
        <h1>Suporte</h1>
        <p>Entre em contato com a nossa equipe.</p>
        <div style={section}>
          <TextField
            name="subject"
            type="text"
            label="Assunto"
            required
            fluid
          />
          <TextAreaField
            name="message"
            label="Mensagem"
            rows={10}
            required
            fluid
          />
        </div>
        <div className={buttonWrapper}>
          <Button color="primary" disabled={props.pristine || props.submitting}>
            Enviar Mensagem
          </Button>
        </div>
      </CardForm>
    </Container>
  );
}
