import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import FilterForm from "./FilterForm";
import { submitFilter } from "../../processes/courseProcesses";
import debounce from "../../utils/debounce";
import { getConfig } from "../../selectors/configSelectors";

function mapStateToProps(state) {
  return {
    initialValues: {},
    courseCategories: getConfig(state, "courseCategories"),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "categoriesFilterForm",
    onChange: debounce(submitFilter, 500),
  })(FilterForm)
);
