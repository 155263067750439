import { css } from "glamor";
import React, { useRef, useCallback, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { getItem, setItem } from "../../utils/localStorage";
import { colors, spacing, uiColors } from "./theme";
import Spinner from "./Spinner";

const VIDEO_PREFERENCES = "videoPreferences";
const container = css({
  background: colors.greyClear,
  position: "relative",
  aspectRatio: "16 / 9",
  width: "100%",
});
const videoClass = css({
  overflow: "hidden",
  border: "none",
  width: "100%",
});
const roundedTopClass = {
  borderTopLeftRadius: `${spacing.xs} !important`,
  borderTopRightRadius: `${spacing.xs} !important`,
};

const PROGRESS_INTERVAL = 5000;

const updateVideoPreferences = (key, value) => {
  setItem(VIDEO_PREFERENCES, {
    ...getItem(VIDEO_PREFERENCES),
    [key]: value,
  });
};

export default function VimeoPlayer({
  url,
  onProgress,
  onFinish,
  options,
  progress,
  controls = true,
  playing,
  roundedTop,
  skipPreferences,
}) {
  const playerRef = useRef();
  const [videoUrl, setVideoUrl] = useState(url);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (skipPreferences) return;

    const player = playerRef.current?.getInternalPlayer();
    const handlePlaybackRateChange = ({ playbackRate }) => {
      updateVideoPreferences("playbackRate", playbackRate);
    };
    const handleQualityChange = ({ quality }) => {
      updateVideoPreferences("quality", quality);
    };
    player?.on("playbackratechange", handlePlaybackRateChange);
    player?.on("qualitychange", handleQualityChange);

    return () => {
      player?.off("playbackratechange", handlePlaybackRateChange);
      player?.off("qualitychange", handleQualityChange);
    };
  }, [playerRef, skipPreferences]);

  const handleDuration = useCallback(
    (duration) => {
      const playedSeconds = duration * progress;
      playedSeconds && playerRef.current.seekTo(playedSeconds);
    },
    [playerRef, progress]
  );

  const onPlayHandler = useCallback(() => {
    const player = playerRef.current?.getInternalPlayer();
    const videoPreferences = getItem(VIDEO_PREFERENCES);
    if (videoPreferences?.playbackRate) {
      player?.setPlaybackRate(videoPreferences?.playbackRate);
    }
    if (videoPreferences?.quality) {
      player?.setQuality(videoPreferences?.quality);
    }
  }, [playerRef]);

  useEffect(() => {
    // Hack for not change URL's too fast before loading the previous content
    // and broke the player
    setIsLoading(true);
    setVideoUrl(null);

    const timeout = setTimeout(() => {
      if (!playerRef?.current?.player?.isLoading) {
        setVideoUrl(url);
        setIsLoading(false);
        clearTimeout(timeout);
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [url]);

  return (
    <div className={css(container, roundedTop && roundedTopClass)}>
      {isLoading ? (
        <Spinner />
      ) : (
        <ReactPlayer
          ref={playerRef}
          url={videoUrl}
          onPlay={onPlayHandler}
          className={css(videoClass, roundedTop && roundedTopClass)}
          onProgress={onProgress}
          onEnded={onFinish}
          onDuration={handleDuration}
          progressInterval={PROGRESS_INTERVAL}
          options={options}
          width="100%"
          height="100%"
          controls={controls}
          playing={playing}
        />
      )}
    </div>
  );
}
