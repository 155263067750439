import { normalize, schema } from "normalizr";
import { change } from "redux-form";
import { apiGet } from "./helpers/api";

const FORM_NAME = "payment";

const postalCodeAddress = new schema.Entity("postalCodeAddresses", {
  meta: { city: new schema.Entity("cities") },
});

export const searchAddressForPayment = (dispatch, postalCode) => {
  const postalCodeLength = postalCode?.replace(/[^0-9]+/g, "")?.length == 8;

  if (!postalCode || !postalCodeLength) return;

  dispatch({ type: "CHECKOUT_START_ADDRESS_LOADING" });

  return apiGet(`/api/v1/public/postal_codes/${postalCode}`)
    .then((response) => {
      const data = response.body.data;
      const { neighborhood, stateId, street, cityId } = data;

      dispatch({
        type: "CHECKOUT_ADDRESS_FETCHED",
        zipCode: postalCode,
        street,
        neighborhood,
        cityId,
        stateId,
      });
      dispatch({
        type: "CITIES_FETCHED",
        ...normalize(data, postalCodeAddress),
      });
      dispatch(change(FORM_NAME, "customerData[street]", street));
      dispatch(change(FORM_NAME, "customerData[neighborhood]", neighborhood));
      dispatch(change(FORM_NAME, "customerData[stateId]", stateId));
      dispatch(change(FORM_NAME, "customerData[cityId]", cityId));
      dispatch({ type: "CHECKOUT_END_ADDRESS_LOADING" });
    })
    .catch((err) => {
      dispatch({ type: "CHECKOUT_ADDRESS_FETCHED", zipCode: postalCode });
      dispatch({ type: "CHECKOUT_END_ADDRESS_LOADING" });
    });
};

export const searchAddressForProfileEdit = (
  dispatch,
  { postalCode, formName }
) => {
  if (!postalCode || postalCode?.length < 8) return;
  dispatch(change(formName, "searchingAddress", true));

  return apiGet(`/api/v1/public/postal_codes/${postalCode}`)
    .then((response) => {
      const { neighborhood, stateId, street, cityId } = response.body.data;

      dispatch({
        type: "CITIES_FETCHED",
        ...normalize(response.body.data, postalCodeAddress),
      });
      dispatch(change(formName, "searchingAddress", false));
      dispatch(change(formName, "searchedAddressZip", postalCode));
      if (street) {
        dispatch(change(formName, "address", street));
      }
      if (neighborhood) {
        dispatch(change(formName, "addressNeighborhood", neighborhood));
      }
      if (stateId) {
        dispatch(change(formName, "stateId", stateId));
      }
      if (cityId) {
        dispatch(change(formName, "cityId", cityId));
      }
      dispatch(change(formName, "searchedAddress", street));
      dispatch(change(formName, "searchedAddressNeighborhood", neighborhood));
      dispatch(change(formName, "searchedStateId", stateId));
      dispatch(change(formName, "searchedCityId", cityId));
    })
    .catch((err) => {
      dispatch(change(formName, "searchingAddress", false));
    });
};
