import { normalize, schema } from "normalizr";
import { apiPost } from "./helpers/api";
import { fetchCourse } from "./courseProcesses";
import { sessionValidate } from "./sessionProcesses";
import { notifySubmitSucceeded, notifySubmitError } from "./notifierProcesses";

export const createEnrollment = (
  dispatch,
  {
    courseId,
    free,
    trial,
    notificationMessage = "Seu curso já está disponível!",
  }
) => {
  return apiPost("/api/v1/courses_users")
    .send({ trial, free, courseId })
    .then(() => {
      sessionValidate(dispatch);
      fetchCourse(dispatch, courseId);
      notifySubmitSucceeded(dispatch, notificationMessage);
    });
};
