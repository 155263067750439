const getCourseOffers = (action) => action.entities.courseOffers;

export default function courseOffersReducer(state = {}, action) {
  switch (action.type) {
    case "COURSE_FETCHED":
    case "COURSES_FETCHED":
    case "COURSE_OFFER_FETCHED":
    case "COURSE_OFFERS_FETCHED":
      return { ...state, ...getCourseOffers(action) };
    default:
      return state;
  }
}
