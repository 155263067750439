import React, { useCallback } from "react";
import { connect } from "react-redux";
import CourseMenuItem from "./CourseMenuItem";
import { change } from "redux-form";

function CourseMenuItemContainer(props) {
  const { dispatch, course, setSelectedCourse } = props;
  
  const changeSelectedCourse = useCallback(() => {
    setSelectedCourse(course);
    dispatch(change("mocksFilterForm", "courseIdEq", course.id));
  }, [course, setSelectedCourse]);

  return (
    <CourseMenuItem changeSelectedCourse={changeSelectedCourse} {...props} />
  );
}

export default connect()(CourseMenuItemContainer);
