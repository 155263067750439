const initialState = {
  userIds: [],
  totalPages: 0,
  loading: false,
};

const getUsersFetchedIds = (action) =>
  action.result ? action.result : initialState.userIds;

export default function networkingReducer(state = initialState, action) {
  switch (action.type) {
    case "USERS_FETCH_REQUESTED":
      return { ...state, loading: true };
    case "USERS_FETCHED":
      return {
        ...state,
        userIds: getUsersFetchedIds(action),
        totalPages: action.totalPages,
        loading: false,
      };
    default:
      return state;
  }
}
