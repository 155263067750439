import { connect } from "react-redux";
import { getAllPromotions } from "../../selectors/promotionSelectors";
import Promotions from "./Promotions";

function mapStateToProps(state) {
  return {
    promotions: getAllPromotions(state),
  };
}

export default connect(mapStateToProps)(Promotions);
