import { connect } from "react-redux";
import MockReportPanel from "./MockReportPanel";
import { getQuestionsFromMock } from "../../selectors/questionSelectors";
import {
  getAnswersFromMock,
  getMockAnswersByValue,
} from "../../selectors/answerSelector";
import { getCurrentMock } from "../../selectors/mockSelectors";

function mapStateToProps(state) {
  const questionsCount = getQuestionsFromMock(state)?.length;
  const questionsAnsweredCount = getAnswersFromMock(state)?.length;
  const correctAnswersCount = getMockAnswersByValue(state)?.length;
  const wrongAnswersCount = getMockAnswersByValue(state, false)?.length;

  return {
    questionsCount,
    questionsAnsweredCount,
    correctAnswersCount,
    wrongAnswersCount,
    mockAverage: parseInt(getCurrentMock(state)?.average),
    isFinished: questionsCount === questionsAnsweredCount,
  };
}

export default connect(mapStateToProps)(MockReportPanel);
