import React from "react";
import { css } from "glamor";
import { spacing } from "../library";

const container = css({
  padding: `${spacing.l} ${spacing.m}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export default function CourseCheckoutEmptyOffer() {
  return (
    <div className={container}>
      Sem ofertas no momento para o curso escolhido...
    </div>
  );
}
