import React from "react";
import { Grid, Header } from "semantic-ui-react";

export default function MockSummary({
  questionsCount,
  answeredQuestionsCount,
  repliesInfoCount,
}) {
  return (
    <Grid
      columns="equal"
      textAlign="center"
      verticalAlign="middle"
      celled="internally"
      centered
      padded
      doubling
      stackable
    >
      <Grid.Column>
        <Header as="h1" color="blue">
          <p>Total de Questões</p>
          {questionsCount}
        </Header>
      </Grid.Column>
      <Grid.Column>
        <Header as="h1" color="blue">
          <p>Questões Respondidas</p>
          {answeredQuestionsCount}
        </Header>
      </Grid.Column>
      <Grid.Column>
        <Header as="h1" color="green">
          <p>Respostas Corretas</p>
          {repliesInfoCount.true || 0}
        </Header>
      </Grid.Column>
      <Grid.Column>
        <Header as="h1" color="red">
          <p>Respostas Erradas</p>
          {repliesInfoCount.false || 0}
        </Header>
      </Grid.Column>
      <Grid.Column>
        <Header as="h1" color="blue">
          <p>Nota Geral</p>
          {repliesInfoCount.true > 0
            ? ((repliesInfoCount.true / answeredQuestionsCount) * 100).toFixed(
                0
              )
            : 0}
          %
        </Header>
      </Grid.Column>
    </Grid>
  );
}
