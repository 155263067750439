const initialState = {
  courseCertificatesLoading: false,
  lessonCertificatesLoading: false,
  certificateCourses: [],
  certificateLessons: [],
};

export default function certificatesReducer(state = initialState, action) {
  switch (action.type) {
    case "CERTIFICATE_COURSES_FETCH_REQUESTED":
      return { ...state, courseCertificatesLoading: true };
    case "CERTIFICATE_COURSES_FETCHED":
      return {
        ...state,
        courseCertificatesLoading: false,
        certificateCourses: action.result.certificateCourses,
      };
    case "CERTIFICATE_LESSONS_FETCH_REQUESTED":
      return { ...state, lessonCertificatesLoading: true };
    case "CERTIFICATE_LESSONS_FETCHED":
      return {
        ...state,
        lessonCertificatesLoading: false,
        certificateLessons: action.result.certificateLessons,
      };
    default:
      return state;
  }
}
