import React from "react";
import { Card } from "semantic-ui-react";
import { spacing } from "../library/theme";
import MockReportGraph from "./MockReportGraph";
import MockSummary from "./MockSummary";

const marginBottom = { marginBottom: spacing.m };

export default function MyPerformanceMockCard(props) {
  const { mock = {} } = props.report;
  const {
    questionsCount = 0,
    levelsInfo = [],
    answeredQuestionsCount = 0,
    repliesInfoCount = {},
  } = mock;
  const labels = levelsInfo?.map(
    (level) => `Módulo ${level.name.split(" ")[0]}`
  );
  const corrects = levelsInfo?.map(
    (level) => level.repliesInfoCount?.true ?? 0
  );
  const wrongs = levelsInfo?.map((level) => level.repliesInfoCount?.false ?? 0);

  return (
    <Card fluid padded>
      <Card.Content>
        <Card.Header>Simulados</Card.Header>
      </Card.Content>
      {props.course?.mocksEnabled ? (
        <Card.Content>
          <div style={marginBottom}>
            <MockSummary
              questionsCount={questionsCount}
              answeredQuestionsCount={answeredQuestionsCount}
              repliesInfoCount={repliesInfoCount}
            />
          </div>
          <MockReportGraph
            labels={labels}
            corrects={corrects}
            wrongs={wrongs}
          />
        </Card.Content>
      ) : (
        <Card.Content textAlign="center" padded>
          <p>Simulados não disponível para este curso</p>
        </Card.Content>
      )}
    </Card>
  );
}
