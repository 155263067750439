import { css } from "glamor";
import React from "react";
import CourseProgress from "../courses/CourseProgress";
import Button from "../library/Button";
import Link from "../library/Link";
import { colors, spacing, styles } from "../library/theme";

const container = css({
  flex: 1,
  display: "flex",
  color: colors.greyClear,
  minHeight: 400,
});
const card = css(styles.roundedCorners, styles.shadow, {
  flex: 1,
  backgroundSize: "cover !important",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat !important",
  display: "flex",
  padding: spacing.m,
  alignItems: "end",
  position: "relative",
});
const contentWrapper = { width: "100%" };
const titleWrapper = {
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: spacing.s,
  marginBottom: spacing.s,
};
const positionStyle = { fontSize: 48, fontWeight: "bolder", lineHeight: 1 };
const courseTitleWrapper = {
  flex: 1,
  minWidth: 275,
};
const courseTitle = {
  textTransform: "uppercase",
  fontSize: 18,
  color: colors.greyClear,
  lineHeight: 1.5,
};
const courseCategoryWrapper = {
  display: "flex",
  justifyContent: "space-between",
  gap: spacing.s,
};
const courseCategory = {
  lineHeight: 1,
};
const playStyle = {
  height: 40,
  width: 40,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const iconWrapper = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

function addLeadingZeros(num, totalLength = 2) {
  return String(num).padStart(totalLength, "0");
}

export default function TrailSelectedCourse({
  course,
  position,
  courseCategories,
  canAccessCourse,
}) {
  if (!course) {
    return null;
  }

  return (
    <div className={container}>
      <div
        className={css(card, {
          background: `linear-gradient(180deg, #180A2980 0%, #180A29 100%), url(${course?.imageUrl})`,
        })}
      >
        <div style={iconWrapper}>
          {canAccessCourse ? (
            <Link
              component={Button}
              to={`/courses/${course.id}`}
              style={playStyle}
              target="_blank"
              secondary
              icon="play"
              fluid
            />
          ) : (
            <Link
              component={Button}
              href={course?.visible}
              target="_blank"
              secondary={course?.visible}
              fluid
              icon="lock"
              disabled={!course?.visible}
            >
              COMPRAR
            </Link>
          )}
        </div>
        <div style={contentWrapper}>
          <div style={titleWrapper}>
            <p style={positionStyle}>{addLeadingZeros(position)}</p>
            <div style={courseTitleWrapper}>
              <p style={courseTitle}>{course?.name}</p>
              {(!!courseCategories.length || !!course.workload) && (
                <div style={courseCategoryWrapper}>
                  {!!courseCategories.length && (
                    <p style={courseCategory}>
                      {courseCategories.map((item) => item.name)[0]}
                    </p>
                  )}
                  {!!course.workload && <p>{course.workload}</p>}
                </div>
              )}
            </div>
          </div>
          <CourseProgress
            progress={course?.meta?.progress}
            showCounters={false}
          />
        </div>
      </div>
    </div>
  );
}
