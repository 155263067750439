import { css } from "glamor";
import React, { useEffect, useCallback } from "react";
import { createTextMask } from "redux-form-input-masks";
import { Grid, Icon } from "semantic-ui-react";
import Button from "../library/Button";
import Link from "../library/Link";
import PhoneField from "../library/forms/PhoneField";
import { Form, TextField } from "../library/forms";
import { colors, spacing, styles } from "../library/theme";
import LobbyLayout from "../lobby/LobbyLayout";
import EmailField from "../library/forms/EmailField";
import GiftIcon from "../library/images/icon_gift.svg";
import GreenCheck from "../library/images/icon_green_check.svg";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import { useDebounceEffect } from "../../utils/useDebounceEffect";

const linkClass = css({
  display: "block",
  marginTop: spacing.l,
  color: colors.infoDark,
  fontSize: 18,
  fontWeight: "bold",
});
const buttonMargin = css({ margin: `${spacing.l} 0` });
const phoneNumberMask = createTextMask({
  pattern: "5599999999999",
  guide: false,
  allowEmpty: true,
});
const successWrapper = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "calc(100vh - 100px)",
  gap: spacing.m,
  padding: `${spacing.m} 0px`,
};
const successImage = css({
  width: 168,
  "@media (max-width: 992px)": {
    marginBottom: spacing.m,
    width: 100,
  },
});
const successTitle = css(styles.alternativeFontFamily, {
  fontSize: 40,
  color: colors.black,
  marginBottom: 0,
  "@media (max-width: 992px)": {
    fontSize: 24,
  },
});
const successContent = css(styles.alternativeFontFamily, {
  maxWidth: 620,
  fontSize: 24,
  color: colors.dark,
  textAlign: "center",
  padding: `0 ${spacing.m}`,
  lineHeight: 1.2,
  "@media (max-width: 992px)": {
    fontSize: 16,
  },
});
const cardClass = css({
  margin: `${spacing.xl} auto 0`,
  width: "100%",
  maxWidth: "450px !important",
  alignSelf: "center",
  padding: `${spacing.l} ${spacing.xl}`,
  borderRadius: spacing.m,
  backgroundColor: "white",
  textAlign: "center",
  marginRight: spacing.l,
  padding: spacing.l,
  position: "absolute",
  top: 20,
  right: 108,
  right: "5%",
  "@media (max-width: 992px)": {
    margin: "auto",
    position: "initial !important",
  },
});
const titleWrapper = {
  textAlign: "left",
  display: "flex",
  gap: spacing.s,
  marginBottom: spacing.m,
};
const textClass = css(styles.alternativeFontFamily, {
  color: colors.black,
  fontWeight: 500,
  lineHeight: 1,
});
const titleClass = css(textClass, {
  lineHeight: 1,
  color: colors.black,
  fontSize: 28,
  fontWeight: "bold",
  "@media (max-width: 992px)": {
    fontSize: 22,
  },
});
const callClass = css(styles.alternativeFontFamily, {
  color: "white",
  position: "absolute",
  top: 200,
  left: 108,
  left: "5%",
  "@media (max-width: 992px)": {
    position: "initial",
    textAlign: "center",
    margin: `${spacing.l} 0px ${spacing.m} 0px`,
  },
});
const mainTitleClass = css(styles.alternativeFontFamily, {
  fontSize: "96px",
  fontWeight: "bold",
  marginBottom: spacing.l,
  lineHeight: 1,
  "@media (max-width: 992px)": { fontSize: "59px", marginBottom: spacing.m },
});
const subtitleClass = css(styles.alternativeFontFamily, {
  fontSize: "48px",
  lineHeight: 1,
  marginBottom: 0,
  "@media (max-width: 992px)": { fontSize: "30px" },
});
const giftClass = css({
  width: 25,
  marginLeft: spacing.s,
});

const validationMessages = {
  taken: `* Este e-mail já está associado a uma conta. <a href="https://app.t2.com.br/signin">Clique aqui</a> para fazer login. Após entrar, clique em 'Teste Grátis' no curso que deseja experimentar.`,
};

export default function SignUpForm(props) {
  const { trialCustomOption, name, email, phone } = props;
  const lobbyClass = css(
    {
      backgroundImage: trialCustomOption?.background,
      backgroundPosition: "center 102px",
      textShadow: "2px 3px 5px rgba(0,0,0,0.5)",
      "@media (max-width: 992px)": {
        backgroundImage: "none",
        background: trialCustomOption?.mobileBackground,
        backgroundPosition: "center",
      },
    },
    props.submitSucceeded && {
      backgroundImage: "none",
      background: "white",
      "@media (max-width: 992px)": {
        backgroundImage: "none",
        background: "white",
      },
    }
  );

  const { boot, update } = useIntercom();

  const updateIntercom = useCallback(() => {
    update({ customAttributes: { name, email, phone } });
  }, [name, email, phone]);

  useEffect(() => boot(), [boot]);
  useDebounceEffect(updateIntercom, 1000, [updateIntercom]);

  return (
    <IntercomProvider appId="gzfho0t0">
      <LobbyLayout className={lobbyClass}>
        {props.submitSucceeded ? (
          <div style={successWrapper}>
            <img src={GreenCheck} className={successImage} />
            <h2 className={successTitle}>Cadastro realizado!</h2>
            <p className={successContent}>
              Siga as instruções enviadas em seu e-mail para confirmar e acessar
              a sua conta.
              <Link to="/signin" className={linkClass}>
                Voltar para o login
              </Link>
            </p>
          </div>
        ) : (
          <div
            className={css({
              "@media (max-width: 992px)": {
                padding: `0px ${spacing.m} ${spacing.xl} ${spacing.m}`,
              },
            })}
          >
            <div className={callClass}>
              <div className={mainTitleClass}>{trialCustomOption?.title}</div>
              <div className={subtitleClass}>Experimente</div>
              <div className={subtitleClass}>Gratuitamente</div>
              <div className={subtitleClass}>por 3 dias</div>
            </div>

            <Form
              onSubmit={props.handleSubmit}
              error={props.error}
              className={cardClass}
            >
              <div style={titleWrapper}>
                <img src={GiftIcon} className={giftClass} />
                <div>
                  <p className={textClass}>Inicie agora mesmo sua</p>
                  <p className={titleClass}>Experiência gratuita</p>
                </div>
              </div>
              <div
                className={css({
                  "> div + div": { marginTop: `${spacing.xs} !important` },
                })}
              >
                <TextField
                  name="name"
                  placeholder="Nome completo"
                  required
                  fluid
                />
                <PhoneField
                  name="phone"
                  countryCodeOptions={props.countryCodeOptions}
                  countryCodeFieldName="phoneCountryCodeId"
                  required
                  fluid
                />
                <EmailField
                  name="email"
                  placeholder="E-mail"
                  validationMessages={validationMessages}
                  required
                />
                <TextField
                  name="password"
                  type="password"
                  placeholder="Senha"
                  required
                  fluid
                />
                <TextField
                  name="confirmPassword"
                  type="password"
                  placeholder="Repita a senha"
                  required
                  fluid
                />
              </div>
              <div
                className={css(
                  buttonMargin,
                  trialCustomOption && { marginBottom: 0 }
                )}
              >
                <Button
                  color="black"
                  loading={props.submitting}
                  disabled={props.pristine || props.submitting}
                  size="big"
                  fluid
                >
                  {trialCustomOption ? "Entrar" : "Criar conta"}
                </Button>
              </div>
            </Form>
          </div>
        )}
      </LobbyLayout>
    </IntercomProvider>
  );
}
