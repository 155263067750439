import { createSelector } from "reselect";

const getCourseOffers = (state) => state.entities.courseOffers;
const getId = (_, id) => id;

export const getAllCourseOffers = createSelector(
  getCourseOffers,
  (courseOffers) => Object.values(courseOffers)
);

export const getCourseOffersByCourseId = createSelector(
  getAllCourseOffers,
  getId,
  (offers, id) => offers.filter((offer) => offer.courseId == id)
);

export const getCourseOffersDefaultByCourseId = createSelector(
  getAllCourseOffers,
  getId,
  (offers, id) =>
    offers.filter((offer) => offer.courseId == id && offer?.default)
);

export const getCourseOfferById = createSelector(
  getCourseOffers,
  getId,
  (offers, id) => offers[id]
);
