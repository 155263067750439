import { css, select } from "glamor";
import React from "react";
import { AiOutlineCheckCircle, AiOutlineClose } from "react-icons/ai";
import { GrUndo } from "react-icons/gr";
import { Icon, Popup } from "semantic-ui-react";
import Button from "../library/Button";
import { colors, spacing, styles } from "../library/theme";

const container = css({
  position: "relative",
});
const selectedClass = css({
  "> p": {
    border: `2px solid ${colors.primary}`,
    borderLeft: "none",
  },
  borderColor: colors.primary,
  backgroundColor: colors.white,
  borderLeftWidth: 0,
  transition: "all 0.3s",
});
const selectedLetters = {
  color: colors.white,
  transition: "all 0.3s",
};
const selectedDiv = css({
  backgroundColor: colors.primary,
  color: colors.white,
  transition: "all 0.3s",
  "> h3": selectedLetters,
});
const replyContainer = css(
  styles.shadow,
  styles.roundedCorners,
  {
    display: "flex",
    backgroundColor: colors.white,
    overflow: "hidden",
    cursor: "pointer",
    marginTop: spacing.xs,
  },
  select(`&:hover`, selectedClass),
  select(`&:hover > div`, selectedDiv)
);
const replyValue = css({
  display: "flex",
  minWidth: spacing.xl,
  maxWidth: spacing.xl,
  flex: 1,
  backgroundColor: colors.grey,
  justifyContent: "center",
  alignItems: "center",
});
const replyValueText = css({
  color: colors.greyShadow,
  fontWeight: "600",
});
const replyText = css(styles.bodyTextLarge, {
  color: colors.greyShadow,
  display: "flex",
  padding: `${spacing.l} 60px ${spacing.l} ${spacing.s}`,
  flex: 1,
  border: `2px solid ${colors.white}`,
  borderLeftWidth: 0,
  borderTopRightRadius: spacing.xs,
  borderBottomRightRadius: spacing.xs,
  transition: "all 0.3s",
  "@media (max-width: 575px)": {
    lineHeight: "normal",
    padding: `${spacing.s} 60px ${spacing.s} ${spacing.s}`,
  },
});

const disabledClass = css({ pointerEvents: "none" });
const correctClass = css(
  {
    color: colors.correct,
  },
  select(`& .${replyValue}`, {
    backgroundColor: colors.correct,
  }),
  select(`& .${replyText}`, {
    borderColor: colors.correct,
  })
);
const wrongClass = css(
  { color: colors.danger },
  select(`& .${replyValue}`, {
    backgroundColor: colors.dangerLight,
  }),
  select(`& .${replyText}`, {
    borderColor: colors.dangerLight,
  })
);
const rightContent = css({
  position: "absolute",
  top: "50%",
  right: spacing.s,
  transform: "translate(0, -50%)",
});
const discartedClass = css({
  opacity: 0.5,
  "> p": {
    textDecoration: "line-through",
  },
});

const numberToCharCode = (num) => String.fromCharCode(65 + num);

export default function QuestionReply({
  index,
  reply,
  replied,
  answered,
  selectReplyHandler,
  readOnly,
  showAnswer,
  isFinished,
  isExam,
  isDiscarted,
  toggleDiscardReplyHandler,
  showPercentage,
}) {
  const isAnswer = reply?.id === answered?.id;
  const isSelected = replied?.id === reply?.id && !answered;
  const isCorrect = answered && reply?.value && showAnswer;
  const answerValue = answered?.value ? correctClass : wrongClass;
  const answerClass = answered && showAnswer ? answerValue : selectedClass;
  const showAverage = (!!answered && (!isExam || isFinished)) || readOnly;

  const statusClass = css(
    (isCorrect || (readOnly && reply.value)) && correctClass,
    isAnswer && !readOnly && answerClass,
    ((isExam ? isFinished : answered) || readOnly || isDiscarted) &&
      disabledClass,
    isDiscarted && discartedClass
  );
  const className = css(replyContainer, statusClass);
  const replyTextClass = css(replyText, isSelected && selectedClass);
  const replyValueDiv = css(
    replyValue,
    (isSelected || (isAnswer && !showAnswer)) && selectedDiv
  );
  const replyValueLetters = css(replyValueText, isSelected && selectedLetters);

  return (
    <div className={container}>
      <div
        className={className}
        onClick={
          !answered || (isExam && !isFinished) ? selectReplyHandler : undefined
        }
      >
        <div className={replyValueDiv}>
          <h3 className={replyValueLetters}>
            {isCorrect ? (
              <AiOutlineCheckCircle size={28} />
            ) : (
              numberToCharCode(index)
            )}
          </h3>
        </div>
        <p className={replyTextClass}>{reply.title}</p>
      </div>
      <div className={rightContent}>
        {showAverage ? (
          <Popup
            position="right center"
            content="Não há dados suficientes para calcular as estatísticas da alternativa"
            disabled={reply?.average !== null}
            trigger={
              reply?.average === null ? (
                <Icon
                  inverted
                  name="question circle"
                  size="large"
                  color="grey"
                />
              ) : (
                showPercentage && `${reply.average}%`
              )
            }
          />
        ) : (
          <Popup
            position="right center"
            on={["click", "hover"]}
            content={isDiscarted ? "Desfazer" : "Marcar como errada"}
            trigger={
              !isSelected ? (
                <Button
                  toggle
                  onClick={toggleDiscardReplyHandler}
                  circular
                  icon={isDiscarted ? GrUndo : AiOutlineClose}
                />
              ) : null
            }
          />
        )}
      </div>
    </div>
  );
}
