import React from "react";
import { Icon } from "semantic-ui-react";
import { spacing, uiColors } from "../library/theme";
import Button from "../library/Button";

const container = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: spacing.m,
  paddingBottom: spacing.m,
  borderBottom: `1px solid ${uiColors.border}`,
};
const buttonStyle = {
  fontSize: "12px",
};

export default function QuestionNavigation(props) {
  const {
    currentIndex,
    questionsLength,
    goToNextQuestionHandler,
    goToPrevQuestionHandler,
    answerHandler,
    replied,
    isAnswered,
    lesson,
    isExam,
    isFinished,
    finishExamMockHandler,
    isComplete,
    isNextAnswered,
    isLastQuestion,
    hasMockCourses,
  } = props;
  return (
    <div style={container}>
      <div>
        {!lesson && (
          <Button
            onClick={goToPrevQuestionHandler}
            disabled={currentIndex === 0}
            size="small"
            circular
            style={buttonStyle}
          >
            <Icon name="angle left" />
            ANTERIOR
          </Button>
        )}
      </div>
      {!isFinished && (
        <>
          {isComplete && (
            <Button
              color="blue"
              size="mini"
              circular
              onClick={finishExamMockHandler}
              confirmationText="Ao finalizar o simulado, não será possível editar nem criar novas respostas."
              style={buttonStyle}
            >
              FINALIZAR SIMULADO
            </Button>
          )}
          {!isExam && (
            <Button
              onClick={answerHandler}
              disabled={!hasMockCourses || !replied || isAnswered}
              color="green"
              size="small"
              circular
              style={buttonStyle}
            >
              RESPONDER
            </Button>
          )}
        </>
      )}
      <div>
        {!lesson && (
          <Button
            primary={
              isAnswered && !isFinished && !isNextAnswered && !isLastQuestion
            }
            onClick={goToNextQuestionHandler}
            disabled={questionsLength - 1 === currentIndex}
            size="small"
            circular
            style={buttonStyle}
          >
            PRÓXIMA
            <Icon name="angle right" />
          </Button>
        )}
      </div>
    </div>
  );
}
