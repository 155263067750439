import { createSelector } from "reselect";
import filterBySearchFields from "../utils/filterBySearchFields";
import compareDate from "../utils/compareDate";
import { statusString } from "../utils/statusString";
import { getCurrentUser } from "./userSelectors";

const { allow, deny, loading } = statusString;

const DATES_TO_SORT = Object.freeze(["lastSeenAt"]);

const getClassrooms = (state) => state.entities.classrooms;
const getCompanies = (state) => state.entities.companies;
const getId = (_, id) => id;
const getProps = (_, props) => props;
const getRouteClassroomId = (state) =>
  state.router.location.pathname.split("/")[2];

export const getAllClassrooms = createSelector(getClassrooms, (classrooms) => {
  return Object.values(classrooms);
});

export const hasAnyClassroom = createSelector(
  getAllClassrooms,
  (classrooms) => {
    return classrooms.length > 0;
  }
);

export const getClassroomById = createSelector(
  getClassrooms,
  getId,
  (classrooms, id) => {
    return classrooms[id];
  }
);

export const getUsersTopTenByProps = createSelector(
  getClassrooms,
  getProps,
  (classrooms, { classroomId, value, order = "desc" }) => {
    const classroom = classrooms[classroomId];

    if (!classroom?.meta) return [];

    const isDescOrder = order == "desc";

    const ranking = classroom?.meta?.users
      ?.sort((a, b) =>
        isDescOrder ? b[value] - a[value] : a[value] - b[value]
      )
      ?.map((user, index) => {
        const rank = isDescOrder
          ? index + 1
          : classroom?.meta?.users?.length - index;

        return { rank, name: user.name, value: user[value] };
      })
      ?.slice(0, 10);

    return isDescOrder ? ranking : ranking.reverse();
  }
);

export default function sortBy(prev, next, sort, order = "asc") {
  const valueA = order == "asc" ? prev[sort] : next[sort];
  const valueB = order == "asc" ? next[sort] : prev[sort];

  if (DATES_TO_SORT.includes(sort)) return compareDate(valueA, valueB, order);
  if (typeof valueA === "string") return valueA.localeCompare(valueB);

  return valueA - valueB;
}

export const getUsersBySearch = createSelector(
  getClassrooms,
  getProps,
  (classrooms, { classroomId, search, sort = "name", order = "asc" }) => {
    const classroom = classrooms[classroomId];

    if (!classroom?.meta) return [];

    return classroom?.meta?.users
      ?.filter((user) => filterBySearchFields(user, search, ["name", "email"]))
      ?.sort((a, b) => sortBy(a, b, sort, order));
  }
);

export const getMockTemplateBySearch = createSelector(
  getClassrooms,
  getProps,
  (classrooms, { classroomId, search, sort = "name", order = "asc" }) => {
    const classroom = classrooms[classroomId];

    if (!classroom?.meta) return [];

    return classroom?.meta?.mockTemplates
      ?.filter((mockTemplate) =>
        filterBySearchFields(mockTemplate, search, ["name"])
      )
      ?.sort((a, b) => sortBy(a, b, sort, order));
  }
);

export const canAccessClassroom = createSelector(
  getCurrentUser,
  getRouteClassroomId,
  (currentUser, classroomId) => {
    if (!currentUser) return loading;

    if (currentUser?.meta?.classrooms?.includes(+classroomId)) {
      return allow;
    }

    return deny;
  }
);

export const canAccessClassrooms = createSelector(
  getCurrentUser,
  (currentUser, companyId) => {
    if (!currentUser) return loading;

    if (!!currentUser?.meta?.classrooms?.length) {
      return allow;
    }

    return deny;
  }
);

export const getClassroomsByCompanyId = createSelector(
  getClassrooms,
  getId,
  getCompanies,
  (classrooms, companyId, companies) =>
    companies[companyId]?.meta?.classrooms?.map(
      (classroomId) => classrooms[classroomId]
    )
);

export const getClassroomsByCourseId = createSelector(
  getAllClassrooms,
  getId,
  (classrooms, courseId) =>
    classrooms.filter((classroom) => classroom.courseId == courseId)
);

export const getCompanyClassroomsByProps = createSelector(
  getClassrooms,
  getProps,
  getCompanies,
  (classrooms, { companyId, courseId }, companies) => {
    return companies[companyId]?.meta?.classrooms
      ?.map((classroomId) => classrooms[classroomId])
      ?.filter((classroom) => classroom.courseId == courseId);
  }
);

export const getCompanyStudentsBySearch = createSelector(
  getClassrooms,
  getProps,
  getCompanies,
  (
    classrooms,
    { companyId, search, sort = "name", order = "asc" },
    companies
  ) => {
    const classroomsByCompany = companies[companyId]?.meta?.classrooms?.map(
      (classroomId) => classrooms[classroomId]
    );

    const uniqueUsers = new Set();

    classroomsByCompany?.forEach((classroom) => {
      if (classroom?.meta?.users) {
        classroom.meta.users.forEach((user) => {
          if (!uniqueUsers.has(user.id)) {
            uniqueUsers.add(user.id);
          }
        });
      }
    });

    const uniqueUsersArray = Array.from(uniqueUsers).map((userId) => {
      const foundUser = classroomsByCompany
        .flatMap((classroom) => classroom?.meta?.users ?? [])
        .find((user) => user.id === userId);

      return foundUser;
    });

    const filteredAndSortedUsers = uniqueUsersArray
      .filter((user) => filterBySearchFields(user, search, ["name", "email"]))
      .sort((a, b) => sortBy(a, b, sort, order));

    return filteredAndSortedUsers;
  }
);

export const getTotalSudentsByCompanyId = createSelector(
  getAllClassrooms,
  getId,
  getCompanies,
  (classrooms, companyId, companies) => {
    const companyClassrooms = classrooms.filter((classroom) => {
      return companies[companyId]?.meta?.classrooms.includes(classroom.id);
    });

    return companyClassrooms.reduce((acc, classroom) => {
      return acc + (classroom.studentCount || 0);
    }, 0);
  }
);

export const getMocksAverageByCompanyId = createSelector(
  getAllClassrooms,
  getId,
  getCompanies,
  (classrooms, companyId, companies) => {
    const companyClassrooms = classrooms.filter((classroom) => {
      return companies[companyId]?.meta?.classrooms.includes(classroom.id);
    });

    const mocksAverageSum = companyClassrooms?.reduce((acc, classroom) => {
      return acc + (classroom.meta.mocksAverage || 0);
    }, 0);

    return parseInt(mocksAverageSum / companyClassrooms?.length);
  }
);

export const getAverageProgressByCompanyId = createSelector(
  getAllClassrooms,
  getId,
  getCompanies,
  (classrooms, companyId, companies) => {
    const companyClassrooms = classrooms.filter((classroom) => {
      return companies[companyId]?.meta?.classrooms.includes(classroom.id);
    });

    const averageProgressSum = companyClassrooms?.reduce((acc, classroom) => {
      return acc + (classroom.meta.averageProgress || 0);
    }, 0);

    return parseInt(averageProgressSum / companyClassrooms?.length);
  }
);
