import React, { useCallback } from "react";
import { connect } from "react-redux";
import { goToIndex } from "../../processes/questionProccess";
import { getReplyAnswered } from "../../selectors/answerSelector";
import { showAnswer, getMock } from "../../selectors/mockSelectors";
import { getQuestionByIndex } from "../../selectors/questionSelectors";
import QuestionPaginationItem from "./QuestionPaginationItem";
import { getMockPreferenceByName } from "../../selectors/mockPreferencesSelectors";

function QuestionPaginationItemContainer(props) {
  const { dispatch, index } = props;

  const goToIndexHandler = useCallback(
    () => goToIndex(dispatch, index),
    [dispatch, index]
  );

  return (
    <QuestionPaginationItem {...props} goToIndexHandler={goToIndexHandler} />
  );
}

function mapStateToProps(state, ownProps) {
  const questionId = getQuestionByIndex(state, ownProps.index)?.id;
  const mock = getMock(state);
  const showAnswerPreference = getMockPreferenceByName(
    state,
    "showAnswerStatus"
  );
  return {
    showAnswer: showAnswer(state) && showAnswerPreference,
    currentIndex: state.mock.currentIndex,
    answered: getReplyAnswered(state, {
      questionId: questionId,
      mockId: mock?.id,
    }),
  };
}

export default connect(mapStateToProps)(QuestionPaginationItemContainer);
