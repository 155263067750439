import React, { useCallback } from "react";
import { connect } from "react-redux";
import { createMockAnswer } from "../../processes/answerProccess";
import { toggleDiscardReply, selectReply } from "../../processes/replyProccess";
import { getReplyAnswered } from "../../selectors/answerSelector";
import { getCourseFromMock } from "../../selectors/courseSelectors";
import {
  getMock,
  makeIsLastAnswer,
  showAnswer,
} from "../../selectors/mockSelectors";
import { makeDiscardReply } from "../../selectors/replySelectors";
import QuestionReply from "./QuestionReply";
import {
  getMockPreferenceByName,
  getMockPreferences,
} from "../../selectors/mockPreferencesSelectors";

function QuestionReplyContainer(props) {
  const {
    reply,
    dispatch,
    isExam,
    courseId,
    mockId,
    isLastAnswer,
    isDiscarted,
  } = props;

  const toggleDiscardReplyHandler = useCallback(() => {
    toggleDiscardReply(
      { isDiscarted, replyId: reply?.id, mockId: mockId },
      dispatch
    );
  }, [dispatch, isDiscarted, reply?.id, mockId]);

  const selectReplyHandler = useCallback(() => {
    isExam
      ? createMockAnswer(
          dispatch,
          courseId,
          mockId,
          reply?.questionId,
          reply?.id,
          isLastAnswer
        )
      : selectReply(dispatch, reply);
  }, [dispatch, reply, isExam, courseId, mockId, isLastAnswer]);

  return (
    <QuestionReply
      reply={reply}
      toggleDiscardReplyHandler={toggleDiscardReplyHandler}
      selectReplyHandler={selectReplyHandler}
      {...props}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const { reply, lesson } = ownProps;

  const mock = getMock(state);
  const isExam = !lesson && mock?.exam;
  const { showAnswerStatus, showPercentage } = getMockPreferences(state);

  return {
    isExam,
    isFinished: mock?.finishedAt,
    mockId: mock?.id,
    courseId: getCourseFromMock(state)?.id,
    showAnswer: showAnswer(state) && showAnswerStatus,
    isDiscarted: makeDiscardReply(state, reply?.id),
    showPercentage,
    answered: getReplyAnswered(state, {
      questionId: reply.questionId,
      mockId: mock?.id,
      lessonId: lesson?.id,
    }),
    isLastAnswer: makeIsLastAnswer(state),
  };
}

export default connect(mapStateToProps)(QuestionReplyContainer);
