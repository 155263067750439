import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

const promotionSchema = new schema.Entity("promotions");

export const fetchPromotions = (dispatch) => {
  apiGet("/api/v1/promotions/").then((response) => {
    dispatch({
      type: "PROMOTIONS_FETCHED",
      ...normalize(response.body.data, new schema.Array(promotionSchema)),
    });
  });
};
