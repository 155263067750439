const getLessonFiles = (action) => action.entities.lessonFiles;

export default function lessonFilesReducer(state = {}, action) {
  switch (action.type) {
    case "LESSON_FILES_FETCHED":
      return { ...state, ...getLessonFiles(action) };
    default:
      return state;
  }
}
