import { css } from "glamor";
import React from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { FaRegCirclePlay } from "react-icons/fa6";
import { IoLockClosed } from "react-icons/io5";
import { MdRadioButtonUnchecked } from "react-icons/md";
import formatSeconds from "../../utils/formatSeconds";
import Link from "../library/Link";
import { colors, spacing, styles } from "../library/theme";

const container = css(styles.transition, {
  flex: 1,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  padding: spacing.m,
  cursor: "pointer",
  color: colors.greyShadow,
  ":hover": {
    color: `${colors.primaryLighter} !important`,
    backgroundColor: `${colors.greyDark}20`,
  },
});
const textWrapper = css({
  display: "flex",
  gap: spacing.s,
  alignItems: "center",
  height: "100%",
  width: "100%",
});
const titleClass = css({
  color: colors.greyDarker,
  lineHeight: 1.5,
});
const linkIconWrapper = { minWidth: 30 };
const iconWrapper = {
  color: colors.primaryLighter,
  marginLeft: spacing.s,
  display: "flex",
  alignItems: "center",
};
const activeName = css({
  "> p": { color: colors.primary },
});
const iconClass = css({
  color: colors.greyDarker,
  minWidth: 22,
});
const primaryColor = css({
  color: colors.primary,
});
const disabledClass = css({
  pointerEvents: "none",
  "> div > p, > div > svg": {
    color: colors.grey,
  },
});
const demoClass = css({
  backgroundColor: `${colors.success}15 !important`,
  border: `1px solid ${colors.success} !important`,
  gap: spacing.s,
  "@media(max-width: 575px)": {
    flexWrap: "wrap",
  },
});

export default function LessonListItem({
  lesson,
  watched,
  canAccessLesson,
  canAccessCourse,
  courseUser,
  lessonPath,
  active,
  demoEnabled,
  hideLeftSidebarHandler,
}) {
  const Icon = canAccessLesson ? FaRegCirclePlay : IoLockClosed;

  return (
    <Link
      to={lessonPath}
      className={css(
        container,
        !canAccessLesson && disabledClass,
        demoEnabled && demoClass
      )}
      onClick={hideLeftSidebarHandler}
    >
      <div className={textWrapper}>
        <div style={linkIconWrapper}>
          <Icon size={30} className={css(iconClass, active && primaryColor)} />
        </div>
        <div className={css(titleClass, active && activeName)}>
          <p>{lesson.name}</p>
          {!!lesson?.duration && <p> {formatSeconds(lesson.duration)} </p>}
        </div>
      </div>
      {canAccessCourse && !courseUser?.paused && (
        <div className={css(iconWrapper, active && primaryColor)}>
          {watched ? (
            <AiFillCheckCircle size={22} />
          ) : (
            <MdRadioButtonUnchecked size={22} />
          )}
        </div>
      )}
    </Link>
  );
}
