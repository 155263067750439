function isLobbyRoute(path) {
  return [
    "/signin",
    "/signup",
    "/impersonate",
    "/forgotten-password",
    "/resend-confirmation-email",
    "/reset-password",
    "/valide-seu-certificado",
  ].includes(path.split("?")[0]);
}

const initialState = { redirectTo: "/" };

export default function afterSignInPathReducer(state = initialState, action) {
  switch (action.type) {
    case "AFTER_SIGN_IN_PATH":
      return {
        ...state,
        redirectTo: isLobbyRoute(action.path) ? "/" : action.path,
      };
    case "AFTER_SIGN_IN_CLEAN_UP":
      return { ...state, redirectTo: "/" };
    default:
      return state;
  }
}
