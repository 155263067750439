import React from "react";
import { css } from "glamor";
import { spacing, colors, styles } from "../library/theme";
import Link from "../library/Link";

const container = css({
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: spacing.m,
  backgroundColor: colors.greyClear,
  padding: spacing.m,
  borderRadius: spacing.xs,
});
const avatarClass = css({
  height: 60,
  width: 60,
  borderRadius: "50%",
  objectFit: "cover",
});
const greetingClass = css(styles.alternativeFontFamily, {
  color: colors.secondary,
  fontSize: "24px",
  fontWeight: "bold",
  lineHeight: "normal",
});
const textWrapper = css({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: spacing.s,
  "@media (max-width: 768px)": {
    marginTop: spacing.s,
  }
});
const jobTitleClass = css({
  color: colors.grey,
  lineHeight: "normal",
});

export default function Greeting({ user }) {
  return user ? (
    <div className={container}>
      <img className={avatarClass} src={user?.avatarUrl} />

      <div>
        <p className={greetingClass}>{`Bem-vindo(a) ${
          user?.signInCount > 1 ? "de volta" : ""
        }, ${user?.firstName}!`}</p>
        <div className={textWrapper}>
          {user?.jobTitle && <p className={jobTitleClass}>{user?.jobTitle}</p>}
          <Link to="/greetings">{`${
            user?.jobTitle ? "Editar" : "Cadastrar"
          } cargo e interesses`}</Link>
        </div>
      </div>
    </div>
  ) : null;
}
