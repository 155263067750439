import React from "react";
import { colors, spacing } from "../theme";
import { RiMedalLine } from "react-icons/ri";
import { css } from "glamor";

const container = css({
  position: "relative",
});
const podiumMedalClass = css({
  display: "flex",
  justifyContent: "center",
  marginBottom: spacing.xs,
});
const podiumNameClass = css({
  fontWeight: "bold",
  color: "white",
  marginBottom: spacing.xs,
  textAlign: "center",
});
const podiumDataClass = css({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  borderTopLeftRadius: spacing.xs,
  borderTopRightRadius: spacing.xs,
  backgroundColor: "white",
  position: "relative",
});
const podiumPositionClass = css({
  color: colors.primary,
  fontSize: spacing.m,
  fontWeight: "bolder",
});
const podiumScoreClass = css({
  textAlign: "center",
  height: spacing.l,
  width: "64px",
  color: "white",
  backgroundColor: `${colors.primary}80`,
  borderRadius: spacing.xxs,
  fontSize: "18px",
  fontWeight: "bold",
});
const highlightedScoreStyle = {
  backgroundColor: colors.primary,
};

export default function LeaderboardPodiumItem({
  color,
  height,
  width,
  highlighted,
  name,
  position,
  score,
}) {
  const medalClass = css(
    podiumMedalClass,
    color && { "> svg": { fill: color } }
  );
  const nameClass = css(podiumNameClass, width && { width });
  const dataWrapperClass = css(
    podiumDataClass,
    width && { width },
    height && { height }
  );
  const positionClass = css(podiumPositionClass, color && { color });
  const scoreClass = css(podiumScoreClass, color && { backgroundColor: color });

  return (
    <div className={container}>
      {highlighted && (
        <div className={medalClass}>
          <RiMedalLine size={30} />
        </div>
      )}
      <div className={nameClass}>{name}</div>
      <div className={dataWrapperClass}>
        <p className={positionClass}>{position}º</p>
        <p className={scoreClass}>{score}</p>
      </div>
    </div>
  );
}
