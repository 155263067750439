import { css } from "glamor";
import React from "react";
import Button from "../library/Button";
import Label from "../library/Label";
import Icon from "../library/Icon";
import Link from "../library/Link";
import { colors, spacing, styles } from "../library/theme";
import { Modal } from "semantic-ui-react";
import formatSeconds from "../../utils/formatSeconds";
import AnnotationFormContainer from "./AnnotationFormContainer";

let bounce = css.keyframes({
  "0%": { transform: "scale(0.1)", opacity: 0 },
  "60%": { transform: "scale(1.2)", opacity: 1 },
  "100%": { transform: "scale(1)" },
});

const container = css(styles.roundedCorners, styles.cardShadow, {
  backgroundColor: colors.white,
  padding: spacing.l,
  animation: `${bounce} 0.2s`,
  position: "relative",
  width: "100%",
  marginBottom: spacing.s,
});
const textClass = css({
  color: colors.greyShadow,
  lineHeight: "1.5 !important",
  width: "center",
});
const editStyle = {
  position: "absolute",
  top: spacing.s,
  right: spacing.xxs,
};
const headerClass = css({
  color: colors.black,
  borderBottom: `1px solid ${colors.greyLight}`,
  paddingBottom: spacing.s,
  marginBottom: spacing.s,
  display: "flex",
  alignItems: "center",
  gap: spacing.s,
  ">p": {
    lineHeight: 1.3,
  },
  "> button > i": {
    marginRight: "0px !important",
  },
});
const timeLabelStyle = {
  height: 25,
  width: 100,
  padding: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const timeStyle = {
  paddingLeft: spacing.xxs,
};

export default function AnnotationListItem({
  dispatch,
  annotation,
  handleDelete,
  handleTimeClick,
}) {
  const [openEdit, setOpenEdit] = React.useState(false);

  const handleClose = React.useCallback(() => setOpenEdit(false), []);
  const handleOpen = React.useCallback(() => setOpenEdit(true), []);

  return (
    <div className={container}>
      {annotation?.lessonName && (
        <div className={headerClass}>
          <p>
            <strong>Aula: </strong>
            {annotation?.lessonName}
          </p>

          <Button
            onClick={handleTimeClick}
            circular
            size="small"
            style={timeLabelStyle}
            disabled={!annotation.videoTime}
            popupText="Ir para a aula"
          >
            <Icon name="clock outline" />

            <p style={timeStyle}>
              {annotation.videoTime
                ? formatSeconds(annotation.videoTime)
                : "00:00:00"}
            </p>
          </Button>
        </div>
      )}

      <Button
        onClick={handleDelete}
        confirmationText="Tem certeza que deseja excluir esta anotação?"
        popupText="Excluir"
        icon="close"
        size="mini"
        style={editStyle}
        circular
      />

      <div
        className={textClass}
        dangerouslySetInnerHTML={{ __html: annotation.text }}
      />
    </div>
  );
}
