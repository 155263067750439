const getViews = (action) => action.entities.views;

export default function viewsReducer(state = {}, action) {
  switch (action.type) {
    case "VIEWS_FETCHED":
    case "VIEW_CLICKED":
    case "VIEW_CREATED":
      return { ...state, ...getViews(action) };
    default:
      return state;
  }
}
