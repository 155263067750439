import React from "react";
import { Comment, Pagination } from "semantic-ui-react";
import Spinner from "../library/Spinner";
import CommentFormContainer from "./CommentFormContainer";
import CommentList from "./CommentList";

const loadingStyle = {
  display: "flex",
  justifyContent: "center",
};

export default function Comments({
  lessonId,
  loading,
  comments,
  totalPages,
  currentPage,
  fetchCommentsHandler,
}) {
  return (
    <>
      <CommentFormContainer lessonId={lessonId} />
      <h3>Comentários</h3>
      <Comment.Group>
        {loading ? (
          <Spinner text="Carregando comentários" style={loadingStyle} inline />
        ) : (
          <CommentList comments={comments} />
        )}
      </Comment.Group>
      {totalPages > 1 && (
        <Pagination
          activePage={currentPage}
          onPageChange={fetchCommentsHandler}
          totalPages={totalPages}
        />
      )}
    </>
  );
}
