import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getConfig,
  getTopContentCategories,
} from "../../selectors/configSelectors";
import { getContentById } from "../../selectors/contentSelectors";
import ContentHighlight from "./ContentHighlight";
import { fetchTopUsers } from "../../processes/userProcesses";
import { getMostActiveUsers } from "../../selectors/userSelectors";
import {
  fetchHighlightedComments,
  fetchHighlightedTeamComments,
} from "../../processes/commentProcesses";
import {
  getTopContentComments,
  getTopContentTeamComments,
} from "../../selectors/commentSelectors";
import { statusString } from "../../utils/statusString";

export const ContentHighlightContainer = (props) => {
  const { dispatch, content } = props;

  useEffect(() => {
    fetchTopUsers(dispatch);
  }, [dispatch]);

  useEffect(() => {
    fetchHighlightedComments(dispatch, content?.id);
    fetchHighlightedTeamComments(dispatch, content?.id);
  }, [dispatch, content]);

  return <ContentHighlight {...props} />;
};

const mapStateToProps = (state, ownProps) => {
  const { contentId } = ownProps.match.params;

  return {
    content: getContentById(state, contentId),
    categories: getConfig(state, "contentCategories"),
    topCategories: getTopContentCategories(state),
    topUsers: getMostActiveUsers(state),
    path: ownProps.location.pathname,
    highlightComments: getTopContentComments(state),
    teamComments: getTopContentTeamComments(state),
    loading: state.content.topCommentsStatus == statusString.loading,
    goBack: ownProps.history.goBack,
  };
};

export default connect(mapStateToProps)(ContentHighlightContainer);
