import React from "react";
import { css } from "glamor";
import { spacing, buttonColors } from "../library/theme";
import { Label } from "semantic-ui-react";

const pageTitle = css({
  display: "flex",
  alignItems: "center",
  "> .ui.tiny.label, .label": {
    backgroundColor: `${buttonColors.primary} !important`,
  },
  "> p": {
    fontSize: 16,
  },
});
const titleClass = css({
  margin: `0 ${spacing.s} 0 0`,
  color: "inherit",
});

export default function ContentName({ content, large }) {
  const Title = large ? "h3" : "p";
  return (
    <div className={pageTitle}>
      <Title className={titleClass}>{content?.name}</Title>
      <Label color="blue" size="tiny">
        BETA
      </Label>
    </div>
  );
}
