import React from "react";
import { Spinner } from "../library";
import { Form } from "../library/forms";
import PaymentMethodBankSlip from "./PaymentMethodBankSlip";
import PaymentMethodCreditCard from "./PaymentMethodCreditCard";
import PaymentMethodPix from "./PaymentMethodPix";
import RouteLeavingGuard from "../library/RouteLeavingGuard";
import BillingTypeField from "./BillingTypeField";
import { spacing } from "../library/theme";
import { css } from "glamor";

const CREDIT_CARD = "CREDIT_CARD";
const BOLETO = "BOLETO";
const PIX = "PIX";

const formStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  height: "100%",
};
const formWrapperStyle = {
  height: "100%",
};
const errorMessageClass = css({
  margin: `${spacing.s} ${spacing.l} 0px ${spacing.l} !important`,
});
const spinnerWrapper = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

const getPaymentMethodFormFields = (props) => {
  switch (props?.billingType) {
    case BOLETO:
      return <PaymentMethodBankSlip {...props} />;
    case CREDIT_CARD:
      return <PaymentMethodCreditCard {...props} />;
    case PIX:
      return <PaymentMethodPix {...props} />;
    default:
      return null;
  }
};

export default function PaymentForm(props) {
  const {
    handleSubmit,
    billingType,
    submitting,
    submitSucceeded,
    reset,
    discountConfirmed,
    confirmDiscount,
    changeLeadHandler,
    user,
    leadCreated,
    handleCartAbandonment,
    dispatch,
    error,
  } = props;
  const selectedBillingTypeFields = getPaymentMethodFormFields(props);

  return (
    <Form
      onSubmit={handleSubmit}
      style={formStyle}
      wrapperStyle={formWrapperStyle}
      error={error}
      noMessages
    >
      {!submitSucceeded && (
        <RouteLeavingGuard
          onConfirmHandler={handleCartAbandonment}
          header="Certeza que deseja sair?"
          content="Tem certeza que deseja desistir da compra?"
        />
      )}

      {submitting ? (
        <div style={spinnerWrapper}>
          <Spinner wrapped />
        </div>
      ) : (
        <>
          {!billingType && <BillingTypeField {...props} />}

          {selectedBillingTypeFields}
        </>
      )}
    </Form>
  );
}
