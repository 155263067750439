import React from "react";
import { connect } from "react-redux";
import Company from "./Company";
import { getCompanyById } from "../../selectors/companySelectors";
import { getClassroomsByCompanyId } from "../../selectors/classroomSelectors";
import { fetchCompany } from "../../processes/companyProcesses";

export const CompanyContainer = (props) => {
  const { dispatch, companyId } = props;

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    fetchCompany(dispatch, companyId).finally(() => setLoading(false));
  }, [dispatch, companyId]);

  return <Company {...props} loading={loading} />;
};

const mapStateToProps = (state, ownProps) => {
  const companyId = ownProps.match.params.id;

  return {
    company: getCompanyById(state, companyId),
    classrooms: getClassroomsByCompanyId(state, companyId),
    companyId,
  };
};

export default connect(mapStateToProps)(CompanyContainer);
