import { css } from "glamor";
import React from "react";
import { IoDocumentTextOutline } from "react-icons/io5";
import { Pagination, Header, Image } from "semantic-ui-react";
import {
  Button,
  Link,
  Page,
  Spinner,
  colors,
  spacing,
  styles,
} from "../../library";
import MocksPageImage from "../../library/images/mocks-page.png";
import SmallMocksPageImage from "../../library/images/small-mocks-page.png";
import SurveyModalContainer from "../../surveys/SurveyModalContainer";
import EmptyMocks from "../../mocks/EmptyMocks";
import FilterFormContainer from "../../mocks/FilterFormContainer";
import MockListItemContainer from "../../mocks/MockListItemContainer";
import GraduationCapIcon from "../../library/images/graduation-cap-icon.svg";
import SliderIcon from "../../library/images/slider-icon.svg";
import Paragraph from "../../library/Paragraph";
import MockCreateLinkButton from "./MockCreateLinkButton";
import MockResumeContainer from "./MockResumeContainer";

const container = css({
  textAlign: "center",
  padding: spacing.m,
});
const titleWrapper = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: spacing.m,
});
const titleClass = css(styles.alternativeFontFamily, {
  display: "flex",
  alignItems: "center",
  gap: spacing.s,
  fontSize: 24,
  fontWeight: "bold",
  color: colors.secondary,
  lineHeight: 1.5,
});
const mocksImageContainer = css({
  position: "relative",
  paddingBottom: "46.25%",
  backgroundImage: `url(${MocksPageImage})`,
  backgroundPosition: "center",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  width: "100%",
  height: "500px",
});
const buttonsWrapper = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: spacing.m,
  marginBottom: spacing.m,
});

export default function Mocks({
  mocks,
  currentPage,
  totalPages,
  fetchMocksHandler,
  loading,
  isFirstMock,
  hasMockCourses,
  isMonitor,
  hasMocks = true,
  mockCreated,
  resumeMock,
}) {
  const firstMockCreated = hasMocks || mockCreated;
  const currentMocksImageContainer = css(
    mocksImageContainer,
    firstMockCreated && {
      backgroundImage: `url(${SmallMocksPageImage})`,
      paddingBottom: "16.25%",
      height: "",
    }
  );

  return (
    <div className={container}>
      <div className={titleWrapper}>
        <div className={titleClass}>Simulados</div>
        <Link
          component={Button}
          to="/mobile/questions"
          color="outline"
          circular={false}
          size="small"
        >
          Buscar questões
        </Link>
      </div>

      <div className={buttonsWrapper}>
        <MockCreateLinkButton
          to="/mobile/mocks/custom/new"
          image={SliderIcon}
          title="Simulado Personalizado"
          subtitle={`
            Personalize seus simulados escolhendo o módulo que deseja
            reponder, o número de questões e outras opções do questionário.
          `}
        />
        <MockCreateLinkButton
          to="/mobile/mocks/exam/new"
          image={GraduationCapIcon}
          title="Simular Prova"
          subtitle={`
            Faça a prova semelhante ao exame do curso selecionado, com o mesmo
            número de questões e os pesos por módulo da prova.
          `}
        />
      </div>

      <MockResumeContainer />

      <Link
        to="/mobile/mocks/list"
        component={Button}
        circular={false}
        color="outline"
        size="big"
        fluid
      >
        Ver todos os simulados
      </Link>
    </div>
  );
}
