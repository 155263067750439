export const initializeIntercom = (boot, currentUser) =>
  boot({
    userId: currentUser.id,
    name: currentUser.name,
    email: currentUser.email,
    createdAt: currentUser.createdAt,
    phone: currentUser.phone,
  });

export const updateIntercom = (update, intercomAttributes) =>
  update({ customAttributes: intercomAttributes });
