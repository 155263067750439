import React from "react";
import { colors, spacing } from "../library/theme";
import Link from "../library/Link";
import Button from "../library/Button";
import Progress from "../library/Progress";
import { css } from "glamor";
import { makeIsMobile } from "../../utils/useScreenWidth";
import moment from "moment";
import { matchPath, useLocation } from "react-router";

const container = css({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: spacing.s,
  gap: spacing.s,
  background: colors.white,
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  lineHeight: 1,
  borderRadius: spacing.s,
});
const mobileClass = css({
  margin: `${spacing.m} 0px`,
});
const alternativeClass = css({
  marginTop: "auto",
  flexDirection: "column",
  minWidth: "auto",
  boxShadow: "none",
  border: `1px solid ${colors.greyLighter}`,
  "@media(min-width: 555px)": {
    width: "auto",
  },
});
const progressWrapper = css({
  flex: 1,
  width: "100%",
});
const counterWrapper = css({
  marginBottom: spacing.xs,
});
const counterDays = css({
  fontWeight: "600",
  color: colors.primary,
  marginRight: spacing.xs,
  fontSize: "14px",
});
const counterText = css({
  fontSize: 10,
  color: colors.grey,
});
const progressStyle = { margin: 0 };

export default function CourseTrialCountdown({
  course,
  alternative,
  countdownEnabled,
  courseUser,
}) {
  const isMobile = makeIsMobile();
  const remainingDaysPercentage =
    courseUser?.remainingDays / course?.trialPeriod;
  const progress = (1 - remainingDaysPercentage) * 100;

  return course && !courseUser?.expired ? (
    <div
      className={css(
        container,
        isMobile && mobileClass,
        alternative && alternativeClass
      )}
    >
      <div className={progressWrapper}>
        <div className={counterWrapper}>
          <span className={counterDays}>
            {courseUser?.remainingDays}{" "}
            {courseUser?.remainingDays > 1 ? "Dias" : "Dia"}
          </span>
          <span className={counterText}>Final do seu teste gratuito</span>
        </div>
        <Progress
          size="tiny"
          color="orange"
          percent={progress}
          style={progressStyle}
        />
      </div>
      <Link
        component={Button}
        to={`/courses/${course?.id}/checkout`}
        color="buy"
        size={!alternative && "mini"}
        fluid={alternative}
      >
        Comprar
      </Link>
    </div>
  ) : null;
}
