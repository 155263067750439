export default function compareDate(valueA, valueB, order = "asc") {
  const isDateInstance = valueA instanceof Date && valueB instanceof Date;
  if (isDateInstance) return valueA - valueB;

  const distantFutureTimestamp = (order == "asc" ? 1 : -1) * Infinity;
  const dateA = valueA ? new Date(valueA) : distantFutureTimestamp;
  const dateB = valueB ? new Date(valueB) : distantFutureTimestamp;

  return dateA - dateB;
}
