import { reduxForm, formValueSelector, change } from "redux-form";
import { connect } from "react-redux";
import CheckoutLeadForm from "./CheckoutLeadForm";
import { upsertLead } from "../../processes/leadProcesses";
import { getItem, setItem } from "../../utils/localStorage";
import { getCountryCodeOptions } from "../../selectors/configSelectors";

const FORM_NAME = "leadForm";
const BRAZIL_PHONE_COUNTRY_CODE_ID = 31;

const removeUndefinedProps = (object) => JSON.parse(JSON.stringify(object));

const saveValues = (values) => {
  const cleanValues = removeUndefinedProps(values);
  setItem(FORM_NAME, cleanValues);
};

function mapStateToProps(state) {
  const values = formValueSelector(FORM_NAME)(
    state,
    "name",
    "email",
    "emailConfirmation",
    "phone",
    "phoneCountryCodeId"
  );

  return {
    initialValues: {
      ...getItem(FORM_NAME),
      phoneCountryCodeId:
        getItem(FORM_NAME)?.phoneCountryCodeId || BRAZIL_PHONE_COUNTRY_CODE_ID,
    },
    countryCodeOptions: getCountryCodeOptions(state),
    ...values,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    onSubmit: upsertLead,
    onChange: saveValues,
  })(CheckoutLeadForm)
);
