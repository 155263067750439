import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Continue from "./Continue";
import { getContinueValueByKey } from "../../selectors/continueSelectors";
import {
  fetchContinueMocks,
  fetchContinueLessons,
} from "../../processes/continueProcesses";

const sortByUpdatedAt = (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt);

function ContinueContainer(props) {
  const { dispatch } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchContent = async () => {
      await fetchContinueLessons(dispatch);
      await fetchContinueMocks(dispatch);
      setLoading(false);
    };

    fetchContent();
  }, [dispatch]);

  return loading ? null : <Continue {...props} />;
}

const mapStateToProps = (state) => {
  const lessons = getContinueValueByKey(state, "lessons").sort(sortByUpdatedAt);
  const mocks = getContinueValueByKey(state, "mocks").sort(sortByUpdatedAt);

  return { lessons, mocks };
};

export default connect(mapStateToProps)(ContinueContainer);
