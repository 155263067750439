const initialState = {
  mockCreated: false,
  loading: true,
  mockIds: [],
  rankingIds: [],
  currentPage: 1,
  totalPages: 0,
};

const getMocksFetchedIds = (action) =>
  action.result ? action.result : initialState.mockIds;

export default function mockListReducer(state = initialState, action) {
  switch (action.type) {
    case "MOCKS_FETCH_REQUESTED":
      return { ...state, loading: true };
    case "MOCKS_FETCHED":
      return {
        ...state,
        mockIds: getMocksFetchedIds(action),
        totalPages: action.totalPages,
        currentPage: action.currentPage,
        loading: false,
      };
    case "MOCK_CREATED":
      return {
        ...state,
        mockCreated: true,
      };
    case "MOCK_RANKING_FETCHED":
      return {
        ...state,
        rankingIds: getMocksFetchedIds(action),
      };
    default:
      return state;
  }
}
