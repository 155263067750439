const getCourses = (action) => action.entities.courses;

export default function coursesReducer(state = {}, action) {
  switch (action.type) {
    case "COURSE_FETCHED":
    case "COURSES_FETCHED":
      return { ...state, ...getCourses(action) };
    case "COURSE_PROGRESS_FETCHED":
      const course = state[action.course?.id];
      if (!course) return state;

      return {
        ...state,
        [course.id]: {
          ...course,
          meta: {
            ...course.meta,
            progress: action.course.progress,
          },
        },
      };
    default:
      return state;
  }
}
