import { LOCATION_CHANGE } from "connected-react-router";
import { matchPath } from "react-router-dom";

let initialState = null;
try {
  initialState = Number(localStorage.getItem("lastVisitedLessonId"));
} catch (e) {
  // fail silently
}

export default function currentLessonReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      const match = matchPath(action.payload.location.pathname, {
        path: "/courses/:courseId/lessons/:lessonId",
        exact: true,
      });
      const lessonId =
        match && match.params.lessonId && Number(match.params.lessonId);

      if (lessonId) localStorage.setItem("lastVisitedLessonId", lessonId);

      try {
        initialState = Number(localStorage.getItem("lastVisitedLessonId"));
      } catch (e) {
        // fail silently
      }

      return lessonId || initialState;
    default:
      return state;
  }
}
