const getCreditCardTokens = (action) => action.entities.creditCardTokens;

export default function creditCardTokensReducer(state = {}, action) {
  switch (action.type) {
    case "CREDIT_CARD_TOKENS_FETCHED":
      return { ...state, ...getCreditCardTokens(action) };
    default:
      return state;
  }
}
