import React, { useEffect } from "react";
import { reduxForm, change, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import SignUpForm from "./SignUpForm";
import { signUp } from "../../processes/signUpProcesses";
import { setItem, getItem } from "../../utils/localStorage";
import { getCountryCodeOptions } from "../../selectors/configSelectors";
import { fetchPublicConfigs } from "../../processes/configProcesses";
import SignUpWithTrialForm from "./SignUpWithTrialForm";
import Cpa10Bg from "../library/images/trial_bg_cpa10.png";
import Cpa20Bg from "../library/images/trial_bg_cpa20.png";
import CeaBg from "../library/images/trial_bg_cea.png";
import AncordBg from "../library/images/trial_bg_ancord.png";
import CfpBg from "../library/images/trial_bg_cfp.png";
import PqoBg from "../library/images/trial_bg_pqo.png";
import { emailTaken } from "../../processes/signUpProcesses";

const formName = "signUpForm";
const BRAZIL_PHONE_COUNTRY_CODE_ID = 31;
const trialCustomOptions = {
  cpa10: {
    title: "CPA10",
    background: `url(${Cpa10Bg})`,
    mobileBackground:
      "linear-gradient(359.71deg, #03415A -50.56%, #009EDE 88.05%)",
  },
  cpa20: {
    title: "CPA20",
    background: `url(${Cpa20Bg})`,
    mobileBackground: "linear-gradient(180deg, #3A00DF 0%, #000000 183.16%)",
  },
  cea: {
    title: "CEA",
    background: `url(${CeaBg})`,
    mobileBackground:
      "linear-gradient(180deg, #FEE575 37.43%, #B2A03E 201.87%)",
  },
  aai: {
    title: "ANCORD",
    background: `url(${AncordBg})`,
    mobileBackground: "linear-gradient(180deg, #36EFC7 0%, #0AA68C 102.67%)",
  },
  cfp: {
    title: "CFP",
    background: `url(${CfpBg})`,
    mobileBackground:
      "linear-gradient(332.74deg, #968125 19.52%, #FEE575 93.09%)",
  },
  pqo: {
    title: "PQO",
    background: `url(${PqoBg})`,
    mobileBackground: "linear-gradient(180deg, #e6125c 19.52%, #FEE575 93.09%)",
  },
};

function SignUpFormContainer(props) {
  const { dispatch, enrollment } = props;

  const searchParams = new URLSearchParams(location.search);
  const enrollmentCourseSlug = searchParams.get("enrollment");
  const trialCustomOption = trialCustomOptions[enrollmentCourseSlug];

  useEffect(() => {
    fetchPublicConfigs(dispatch);
  }, [dispatch]);

  useEffect(() => {
    const storedEnrollment = getItem("enrollment");

    if (enrollment && enrollment !== storedEnrollment) {
      setItem("enrollment", enrollment);
    }

    if (enrollment || storedEnrollment) {
      dispatch(change(formName, "enrollment", enrollment || storedEnrollment));
    }
  }, [dispatch, enrollment]);

  return !!trialCustomOption ? (
    <SignUpWithTrialForm trialCustomOption={trialCustomOption} {...props} />
  ) : (
    <SignUpForm {...props} />
  );
}

function mapStateToProps(state) {
  const { router } = state;
  const signupFormValueSelector = formValueSelector(formName);
  const { name, phone, email } = signupFormValueSelector(
    state,
    "name",
    "phone",
    "email"
  );

  return {
    initialValues: {
      phoneCountryCodeId: BRAZIL_PHONE_COUNTRY_CODE_ID,
    },
    enrollment: router?.location?.query?.enrollment,
    origin: router?.location?.query?.pec === "true" ? "pec" : "web",
    redirectTo: state.afterSignInPath.redirectTo,
    countryCodeOptions: getCountryCodeOptions(state),
    name,
    phone,
    email,
  };
}

const asyncValidate = (values, dispatch, props, blurredField) => {
  return new Promise(async (resolve, reject) => {
    const errors = {};

    const isEmailTaken = await emailTaken(values.email);
    if (isEmailTaken) errors.email = "taken";

    if (Object.keys(errors).length > 0) {
      reject(errors);
    } else {
      resolve();
    }
  });
};

export default connect(mapStateToProps)(
  reduxForm({
    form: formName,
    onSubmit: signUp,
    asyncValidate,
  })(SignUpFormContainer)
);
