export const setItem = (key, value) => {
  let parsedValue = value;

  if (value === Object(value)) {
    parsedValue = JSON.stringify(value);
  }

  return localStorage.setItem(key, parsedValue);
};

export const getItem = (key) => {
  const value = localStorage.getItem(key);

  if (isJsonString(value)) {
    return JSON.parse(value);
  }

  return value;
};

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const removeItem = (key) => localStorage.removeItem(key);

export function updateStorageListItem(key, position, props) {
  const storage = getItem(key);
  const storageItem = storage && storage[position];

  setItem(key, {
    ...storage,
    [position]: {
      ...storageItem,
      ...props,
    },
  });
}
