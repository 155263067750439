import React from "react";
import { Grid } from "semantic-ui-react";
import ContentCommentFormContainer from "./ContentCommentFormContainer";
import { spacing } from "../library/theme";
import Divider from "../library/Divider";
import ContentCategoryListItemContainer from "./ContentCategoryListItemContainer";
import ContentPage from "./ContentPage";
import ContentTopCategoriesCard from "./ContentTopCategoriesCard";
import ContentTopUsersCard from "./ContentTopUsersCard";
import Spinner from "../library/Spinner";

const dividerStyle = {
  margin: `${spacing.l} 0`,
};
const loadingStyle = {
  display: "flex",
  justifyContent: "center",
};

export default function ContentCategory({
  content,
  categories,
  path,
  topCategories,
  topUsers,
  loading,
  goBack,
}) {
  return (
    <ContentPage content={content} path={path} goBack={goBack}>
      <Grid centered>
        <Grid.Column computer={12} tablet={12} mobile={16}>
          <ContentCommentFormContainer contentId={content?.id} />
          <Divider style={dividerStyle} />
          {loading ? (
            <Spinner text="Carregando posts" style={loadingStyle} inline />
          ) : (
            <>
              {categories?.length > 0 &&
                categories.map((category) => (
                  <ContentCategoryListItemContainer
                    key={`category${category?.id}Carousel`}
                    content={content}
                    category={category}
                  />
                ))}
            </>
          )}
        </Grid.Column>
        <Grid.Column computer={4} tablet={4} mobile={16}>
          <ContentTopCategoriesCard
            content={content}
            topCategories={topCategories}
          />
          <ContentTopUsersCard topUsers={topUsers} />
        </Grid.Column>
      </Grid>
    </ContentPage>
  );
}
