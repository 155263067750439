import { normalize, schema } from "normalizr";
import { apiGet } from "./helpers/api";

const contentCategories = new schema.Entity("contentCategories");
const courseCategories = new schema.Entity("courseCategories");
const stateSchema = new schema.Entity("states");
const citySchema = new schema.Entity("cities");
const configSchema = new schema.Entity("configs");
const phoneCountryCodeSchema = new schema.Entity("phoneCountryCodes");

const valuesSchema = new schema.Values(
  {
    categories: new schema.Array(contentCategories),
    courseCategories: new schema.Array(courseCategories),
    states: new schema.Array(stateSchema),
    cities: new schema.Array(citySchema),
    configs: new schema.Array(configSchema),
    phoneCountryCodes: new schema.Array(phoneCountryCodeSchema),
  },
  (input, parent, key) => `${key}`
);

export const fetchPublicConfigs = (dispatch) => {
  return apiGet("/api/v1/public/config").then((response) => {
    const data = response.body.data[0];
    dispatch({
      type: "PUBLIC_CONFIGS_FETCHED",
      ...{
        ...normalize(data, valuesSchema),
        cookiesPolicyUrl: data.cookiesPolicyUrl,
        privacyPolicyUrl: data.privacyPolicyUrl,
        privacyWarningUrl: data.privacyWarningUrl,
        defaultGateway: data.gateway,
      },
    });
  });
};

export const fetchConfigs = (dispatch) => {
  return apiGet("/api/v1/config").then((response) => {
    const data = response.body.data[0];
    dispatch({
      type: "CONFIGS_FETCHED",
      ...{
        ...normalize(data, valuesSchema),
        cookiesPolicyUrl: data.cookiesPolicyUrl,
        privacyPolicyUrl: data.privacyPolicyUrl,
        privacyWarningUrl: data.privacyWarningUrl,
        defaultGateway: data.gateway,
      },
    });
  });
};

export const fetchCities = (dispatch, stateId) => {
  if (!stateId) return;

  return apiGet(`/api/v1/public/states/${stateId}/cities`).then((response) => {
    dispatch({
      type: "CITIES_FETCHED",
      ...normalize({ cities: response.body.data }, valuesSchema),
    });
  });
};
