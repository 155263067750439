import React, { useState, useMemo, useCallback } from "react";
import { css } from "glamor";
import { Select, Flag } from "semantic-ui-react";
import { spacing, colors } from "../theme";

const dropdownClass = css({
  borderTopLeftRadius: `${spacing.xs} !important`,
  borderBottomLeftRadius: `${spacing.xs} !important`,
  backgroundColor: `${colors.greyLighter} !important`,
  borderColor: `${colors.greyLighter} !important`,
  borderRightColor: `${colors.grey} !important`,
  minWidth: "110px !important",
  "> i": { top: "auto !important" },
  ".ui.selection.dropdown .menu": {
    width: "215px !important",
    maxHeight: "256px !important",
  },
});
const redBorderClass = css({
  borderColor: "red !important",
  borderRightColor: "red !important",
});
const dropdownTriggerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: spacing.xxs,
};

export default function CountryCodeInput(props) {
  const { input, error, options } = props;
  const [searching, setSearching] = useState(false);

  const searchHandler = useCallback((options, query = "") => {
    return options.filter(({ text = "" }) => {
      if (text.toLowerCase().includes(query.toLowerCase())) return true;
    });
  }, []);

  const selected = useMemo(() => {
    return options.find(({ value }) => value == input.value);
  }, [options, input.value]);

  const openHandler = useCallback(() => setSearching(true), []);
  const closeHandler = useCallback(() => setSearching(false), []);
  const changeHandler = useCallback(
    (e, { value }) => input.onChange(value),
    [input.onChange]
  );

  return (
    <Select
      search={searchHandler}
      onOpen={openHandler}
      onClose={closeHandler}
      noResultsMessage="Nenhum resultado"
      placeholder={null}
      error={error}
      options={options}
      onChange={changeHandler}
      trigger={
        <span style={dropdownTriggerStyle}>
          {!searching && (
            <>
              <Flag name={selected?.flag} size="big" />
              <span>+{selected?.code}</span>
            </>
          )}
        </span>
      }
      className={css(dropdownClass, error && redBorderClass)}
      {...props}
    />
  );
}
