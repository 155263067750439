import React from "react";
import { connect } from "react-redux";
import SubLevelListItem from "./SubLevelListItem";
import { getLessonsFromLevel } from "../../selectors/lessonSelectors";

function SubLevelListItemContainer(props) {
  return props.lessons.length ? <SubLevelListItem {...props} /> : null;
}

function mapStateToProps(state, ownProps) {
  return {
    lessons: getLessonsFromLevel(state, ownProps),
  };
}

export default connect(mapStateToProps)(SubLevelListItemContainer);
