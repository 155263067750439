import React, { useMemo } from "react";
import TabMenu from "../library/TabMenu";
import ContinueLessonCard from "./ContinueLessonCard";
import ContinueMockCard from "./ContinueMockCard";
import { css } from "glamor";

const container = css({
  "@media (max-width: 768px)": {
    maxWidth: "calc(100vw - 64px)",
  },
});
const titleStyle = {
  fontWeight: "bold",
};

export default function Continue({ lessons, mocks }) {
  const panes = useMemo(() => {
    const result = [];

    if (lessons?.length > 0) {
      result.push({
        title: "Aulas",
        render: () => <ContinueLessonCard lesson={lessons[0]} />,
      });
    }

    if (mocks?.length > 0) {
      result.push({
        title: "Simulados",
        render: () => <ContinueMockCard mock={mocks[0]} />,
      });
    }

    return result;
  }, [lessons, mocks]);

  if (panes.length === 0) {
    return null;
  }

  return panes.length === 0 ? null : (
    <div className={container}>
      <h3 style={titleStyle}>Continue de onde parou</h3>

      <TabMenu panes={panes} />
    </div>
  );
}
