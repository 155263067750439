import { createSelector } from "reselect";

const getLikes = (state) => state.entities.likes;
const getLikeableId = (_, id) => id;

export const getCommentLike = createSelector(
  getLikes,
  getLikeableId,
  (likes, likeableId) =>
    Object.values(likes).filter(
      (like) => like.likeableType == "Comment" && like.likeableId == likeableId
    )[0]
);
