import React from "react";
import { Card } from "semantic-ui-react";
import Button from "../library/Button";
import { Form, TextAreaField } from "../library/forms";
import RichTextField from "../library/forms/RichTextField";
import { colors, spacing, styles } from "../library/theme";

const container = {
  ...styles.border,
  paddingTop: 0,
  marginBottom: 0,
  borderRadius: spacing.xs,
  border: "none",
  boxShadow: "none",
};
const buttonStyle = {
  marginTop: spacing.s,
  marginLeft: "auto",
  backgroundColor: colors.primary,
  color: colors.greyClear,
};

export default function AnnotationForm(props) {
  return (
    <Card style={container} fluid>
      <Card.Content>
        <Form onSubmit={props.handleSubmit}>
          <RichTextField
            name="text"
            initialContent={props?.annotation?.text}
            placeholder="Faça uma anotação..."
          />

          <Button
            size="small"
            style={buttonStyle}
            disabled={props.submitting || props.pristine}
          >
            {props?.annotation?.id ? "Atualizar" : "Criar"}
          </Button>
        </Form>
      </Card.Content>
    </Card>
  );
}
