import { css } from "glamor";
import React from "react";
import { Table } from "semantic-ui-react";
import { Page, Spinner, colors, spacing, styles } from "../library";
import Hint from "../library/Hint";

const contentContainer = css({
  borderRadius: spacing.m,
  padding: spacing.m,
  background: colors.white,
  "> table": {
    border: "none !important",
    boxShadow: "none !important",
  },
});
const emptyText = {
  padding: spacing.xxl,
  textAlign: "center",
};
const linkClass = css({
  color: colors.primary,
  display: "flex",
  justifyContent: "end",
  paddingRight: spacing.m,
});

const tableHeaderCell = css({
  backgroundColor: `${colors.greyLight} !important`,
  ":first-child": {
    borderTopLeftRadius: `${spacing.xs} !important`,
    borderBottomLeftRadius: `${spacing.xs} !important`,
  },
  ":last-child": {
    borderTopRightRadius: `${spacing.xs} !important`,
    borderBottomRightRadius: `${spacing.xs} !important`,
  },
}).toString();
const titleClass = css(styles.alternativeFontFamily, {
  display: "flex",
  alignItems: "center",
  gap: spacing.s,
  fontSize: 24,
  fontWeight: "bold",
  color: colors.secondary,
  lineHeight: 1.5,
});
const titleContainer = css({ display: "flex", flexDirection: "column" });

const InvoicesStatus = ({ loading }) =>
  loading ? (
    <Spinner wrapped />
  ) : (
    <p style={emptyText}>Você ainda não tem notas fiscais.</p>
  );

function Title() {
  return (
    <div className={titleContainer}>
      <span className={titleClass}>Minhas Notas Fiscais</span>
      <Hint>
        * Alguns links podem não funcionar. Caso isto ocorra, entre em contato
        com nosso suporte.
      </Hint>
    </div>
  );
}

export default function Invoice(props) {
  const { invoices, loading } = props;

  return (
    <Page title={<Title />}>
      {invoices?.length ? (
        <div className={contentContainer}>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className={tableHeaderCell}>
                  Produto
                </Table.HeaderCell>
                <Table.HeaderCell
                  className={tableHeaderCell}
                ></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {invoices.map((invoice) => (
                <Table.Row key={invoice.link}>
                  <Table.Cell>{invoice.productName}</Table.Cell>
                  <Table.Cell>
                    <div>
                      <a
                        href={invoice.link}
                        target="_blank"
                        className={linkClass}
                      >
                        Download
                      </a>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      ) : (
        <InvoicesStatus loading={loading} />
      )}
    </Page>
  );
}
