import { css } from "glamor";
import React, { useState, useCallback } from "react";
import { Checkbox, Modal } from "semantic-ui-react";
import Button from "../library/Button";
import { spacing } from "../library/theme";

const checkboxListClass = css({
  "> div + div": {
    marginTop: spacing.s,
  },
});

const preferenceList = [
  { name: "showReport", label: "Exibir estatísticas" },
  { name: "showVideo", label: "Exibir comentário em vídeo" },
  { name: "showAnswerStatus", label: "Exibir resposta" },
  { name: "showPercentage", label: "Exibir porcentagem de escolha" },
  { name: "showDifficulty", label: "Exibir dificuldade" },
];

const CheckboxList = ({ values, onChange, list }) => {
  return (
    <div className={checkboxListClass}>
      {list.map((value) => {
        return (
          <Checkbox
            toggle
            checked={values[value.name]}
            onChange={onChange(value.name)}
            label={value.label}
          />
        );
      })}
    </div>
  );
};

export default function MockPreferences({ togglePreference, mockPreferences }) {
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => setOpen(!open), [open]);

  return open ? (
    <Modal open closeIcon onClose={toggleOpen} size="mini">
      <Modal.Header>Preferências do Simulado</Modal.Header>
      <Modal.Content>
        <CheckboxList
          list={preferenceList}
          values={mockPreferences}
          onChange={togglePreference}
        />
      </Modal.Content>
    </Modal>
  ) : (
    <Button onClick={toggleOpen}>Preferências</Button>
  );
}
