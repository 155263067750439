import React from "react";
import { connect } from "react-redux";
import TopTenCard from "../library/TopTenCard";
import { getUsersTopTenByProps } from "../../selectors/companySelectors";

const mapStateToProps = (state, { companyId, value, order }) => {
  return {
    items: getUsersTopTenByProps(state, {
      companyId,
      value,
      order,
    }),
  };
};

export default connect(mapStateToProps)(TopTenCard);
