export default function notificationsUsersReducer(state = {}, action) {
  switch (action.type) {
    case "NOTIFICATIONS_FETCHED":
    case "NOTIFICATION_FETCHED":
    case "NOTIFICATION_UPDATED":
    case "NOTIFICATIONS_USERS_FETCHED":
    case "NOTIFICATIONS_USER_FETCHED":
    case "NOTIFICATIONS_USERS_UPDATED":
      return { ...state, ...action.entities.notificationsUsers };
    default:
      return state;
  }
}
