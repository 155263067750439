import { apiPost } from "./helpers/api";

export const payloadWebhookSender = (values) => {
  return apiPost("/integrations/integromat/payload_webhook_sender")
    .send(values)
    .then(() => {});
};

export const integromat = {
  payloadWebhookSender,
};
