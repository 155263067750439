import React from "react";
import { css } from "glamor";
import { utilityColors } from "../theme";

const errorMessage = css({
  color: `${utilityColors.danger}!important`,
});

const successMessage = css({
  color: utilityColors.success,
  fill: utilityColors.success,
  display: "flex",
});

export default function FieldMessage(props) {
  const { message, type = "error", ...rest } = props;
  return (
    <h5
      className={type === "error" ? errorMessage : successMessage}
      dangerouslySetInnerHTML={{ __html: message }}
      {...rest}
    ></h5>
  );
}
