import { apiGet, apiPost, apiPut } from "./helpers/api";
import { normalize, schema } from "normalizr";
import { SubmissionError } from "redux-form";
import { replace } from "connected-react-router";
import toQueryString from "../utils/toQueryString";
import { fetchCourse } from "./courseProcesses";

const mockSchema = new schema.Entity("mocks");
const mockRankingSchema = new schema.Entity("mockRankings");

export const fetchMocks = (values, dispatch, { page = 1 }) => {
  const filters = toQueryString(values);

  dispatch({ type: "MOCKS_FETCH_REQUESTED" });
  return apiGet(`/api/v1/mocks?${filters}page=${page}`).then((response) => {
    dispatch({
      type: "MOCKS_FETCHED",
      ...normalize(response.body.data, new schema.Array(mockSchema)),
      totalPages: response.body.totalPages,
      currentPage: response.body.currentPage,
    });
  });
};

export const finishMock = (dispatch, mockId) => {
  return apiPut(`/api/v1/mocks/${mockId}`).then((response) =>
    dispatch({
      type: "MOCK_UPDATED",
      ...normalize(response.body.data, mockSchema),
    })
  );
};

export const fetchMock = (dispatch, mockId) => {
  apiGet(`/api/v1/mocks/${mockId}`).then((response) => {
    dispatch({
      type: "MOCK_FETCHED",
      ...normalize(response.body.data, mockSchema),
    });
  });
};

export const createMock = (values, dispatch, props) => {
  dispatch({ type: "MOCK_SUBMITTED" });
  return apiPost("/api/v1/mocks/")
    .send(values)
    .then((response) => {
      dispatch({
        type: "MOCK_CREATED",
        ...normalize(response.body.data, mockSchema),
      });
      fetchCourse(dispatch, values.courseId);
      dispatch(
        replace(`${props?.routePrefix || ""}/mocks/${response.body.data.id}`)
      );
      props.reset();
    })
    .catch((err) => {
      throw new SubmissionError({ _error: err.response.body.errors });
    });
};

export const fetchMockRanking = (dispatch, mockId) => {
  return apiGet(`/api/v1/mocks/${mockId}/ranking`).then((response) => {
    dispatch({
      type: "MOCK_RANKING_FETCHED",
      ...normalize(response.body.data, [mockRankingSchema]),
      position: response.body.position,
    });
  });
};
