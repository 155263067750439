const getCoursesUsers = (action) => action.entities.coursesUsers;

export default function coursesUsersReducer(state = {}, action) {
  switch (action.type) {
    case "USER_FETCHED":
      return { ...state, ...getCoursesUsers(action) };
    default:
      return state;
  }
}
