import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ContinueLessonCard from "../continue/ContinueLessonCard";
import { getCurrentContinueLesson } from "../../selectors/continueSelectors";
import { fetchContinueLessons } from "../../processes/continueProcesses";

function CourseContinueLessonContainer(props) {
  const { dispatch } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchContinueLessons(dispatch).then(() => setLoading(false));
  }, [dispatch]);

  return loading ? null : <ContinueLessonCard {...props} />;
}

const mapStateToProps = (state) => ({
  lesson: getCurrentContinueLesson(state),
});

export default connect(mapStateToProps)(CourseContinueLessonContainer);
