import { statusString } from "../utils/statusString";

const initialState = {
  contentState: "initial",
  contentIds: [],
  categories: {},
  mostActiveUserIds: [],
  topComments: [],
  topTeamComments: [],
  topCommentsStatus: statusString.loading,
};

const getFetchedIds = (action) => (action.result ? action.result : []);

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    case "CONFIGS_FETCHED":
      let categories = {};
      action.result.categories.id.map((category) => {
        categories[category] = { status: statusString.loading };
      });
      return {
        ...state,
        categories,
      };
    case "CONTENTS_FETCH_REQUESTED":
      return {
        ...state,
        contentState: statusString.loading,
      };
    case "CONTENTS_FETCHED":
      return {
        ...state,
        contentState: statusString.resolved,
        contentIds: getFetchedIds(state),
      };
    case "REQUEST_FETCH_COMMENTS_HIGHLIGHTED":
      return {
        ...state,
        topCommentsStatus: statusString.loading,
      };
    case "COMMENTS_HIGHLIGHTED_FETCHED":
      return {
        ...state,
        topComments: getFetchedIds(action),
        topCommentsStatus: statusString.resolved,
      };
    case "COMMENTS_HIGHLIGHTED_FROM_TEAM_FETCHED":
      return { ...state, topTeamComments: getFetchedIds(action) };
    case "REQUEST_FETCH_COMMENTS_HIGHLIGHTED_BY_CATEGORY":
      if (!action.categoryId) {
        return state;
      }
      return {
        ...state,
        categories: {
          ...state.categories,
          [action.categoryId]: {
            ...state.categories[action.categoryId],
            status: statusString.loading,
          },
        },
      };
    case "COMMENTS_HIGHLIGHTED_BY_CATEGORY_FETCHED":
      return {
        ...state,
        categories: {
          ...state.categories,
          [action.categoryId]: {
            topContentCommentIds: getFetchedIds(action),
            status: statusString.resolved,
          },
        },
      };
    case "MOST_ACTIVE_USERS_FETCHED":
      return {
        ...state,
        mostActiveUserIds: getFetchedIds(action),
      };
    default:
      return state;
  }
}
