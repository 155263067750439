import React, { useCallback } from "react";
import { connect } from "react-redux";
import { getCurrentUser } from "../../selectors/userSelectors";
import CourseListItem from "./CourseListItem";
import { getCourseOffersDefaultByCourseId } from "../../selectors/courseOfferSelectors";
import { createEnrollment } from "../../processes/coursesUserProcesses";
import { courseReinstatement } from "../../processes/courseProcesses";
import { getCourseUserByCourseId } from "../../selectors/coursesUserSelectors";
import { canAccessCourse } from "../../selectors/courseSelectors";

function CourseListItemContainer(props) {
  const { dispatch, course } = props;

  const trialEnrollHandler = useCallback(() => {
    createEnrollment(dispatch, { courseId: course?.id, trial: true });
  }, [dispatch, course?.id]);

  const createFreeEnrollmentHandler = useCallback(() => {
    createEnrollment(dispatch, { courseId: course?.id, free: true });
  }, [dispatch, course?.id]);

  const reinstatementHandler = React.useCallback(() => {
    courseReinstatement(dispatch, course?.id);
  }, [dispatch, course?.id]);

  return (
    <CourseListItem
      trialEnrollHandler={trialEnrollHandler}
      createFreeEnrollmentHandler={createFreeEnrollmentHandler}
      reinstatementHandler={reinstatementHandler}
      {...props}
    />
  );
}

function mapStateToProps(state, { course }) {
  const user = getCurrentUser(state);

  return {
    canAccessCourse: canAccessCourse(state, course?.id),
    courseUser: getCourseUserByCourseId(state, course?.id),
    offers: getCourseOffersDefaultByCourseId(state, course?.id),
    user,
  };
}

export default connect(mapStateToProps)(CourseListItemContainer);
