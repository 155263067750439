import React from "react";
import { createTextMask } from "redux-form-input-masks";
import { Form, Grid } from "semantic-ui-react";
import makeDropDownOptions from "../../utils/makeDropDownOptions";
import { DropDownField, TextField } from "../library/forms";

import { css } from "glamor";
import { colors, spacing } from "../library";
import ProfileEditPage from "./ProfileEditPage";
import SubmitButton from "./SubmitButton";

const cepMask = createTextMask({
  pattern: "99999-999",
  guide: false,
  allowEmpty: true,
});

const container = css({
  padding: `${spacing.l} !important`,
  background: colors.white,
  borderRadius: spacing.s,
  marginBottom: `${spacing.m} !important`,
});

export default function ProfileEditAddressForm(props) {
  const {
    searchingAddress,
    states,
    cities,
    handleSubmit,
    error,
    stateId,
    searchedStateId,
  } = props;

  return (
    <ProfileEditPage
      currentUser={props?.currentUser}
      shouldBlockNavigation={props?.dirty && !props?.submitSucceeded}
    >
      <Form onSubmit={handleSubmit} error={error}>
        <div className={container}>
          <Grid>
            <Grid.Column computer={8} mobile={16}>
              <TextField
                name="addressZip"
                label="CEP"
                {...cepMask}
                placeholder="CEP"
                loading={searchingAddress}
                required
                fluid
              />
            </Grid.Column>
            <Grid.Column computer={8} mobile={16}>
              <TextField
                name="address"
                label="Endereço"
                placeholder="Rua, Avenida, logradouro"
                loading={searchingAddress}
                disabled={searchingAddress}
                required
                fluid
              />
            </Grid.Column>
            <Grid.Column computer={6} mobile={16}>
              <TextField
                name="addressNeighborhood"
                label="Bairro"
                placeholder="Seu Bairro"
                loading={searchingAddress}
                disabled={searchingAddress}
                required
                fluid
              />
            </Grid.Column>
            <Grid.Column computer={4} mobile={16}>
              <TextField
                name="addressNumber"
                label="Número"
                placeholder="Número"
                required
                fluid
              />
            </Grid.Column>
            <Grid.Column computer={6} mobile={16}>
              <TextField
                name="addressComp"
                label="Complemento"
                placeholder="Complemento"
                fluid
              />
            </Grid.Column>
            <Grid.Column computer={8} mobile={16}>
              <DropDownField
                name="stateId"
                label="Estado"
                options={makeDropDownOptions(states)}
                loading={searchingAddress}
                disabled={searchingAddress}
                required
                selection
                search
                fluid
              />
            </Grid.Column>
            <Grid.Column computer={8} mobile={16}>
              <DropDownField
                label="Cidade"
                name="cityId"
                options={makeDropDownOptions(cities)}
                required
                loading={searchingAddress}
                disabled={searchingAddress || !stateId}
                selection
                search
                fluid
              />
            </Grid.Column>
          </Grid>
        </div>

        <SubmitButton {...props} />
      </Form>
    </ProfileEditPage>
  );
}
