import { createSelector } from "reselect";

const getCurrentCourseId = (state) => state.currentCourseId;

export const getSelectedCourseId = (state) =>
  state.report.selectedCourseId || state.currentCourseId;

const getData = (state) => state.report.data;

export const getReport = createSelector(
  getData,
  getSelectedCourseId,
  (data, courseId) => data[courseId]
);

export const getCurrentReport = createSelector(
  getData,
  getCurrentCourseId,
  (data, currentCourseId) => data[currentCourseId]
);

export const getCurrentCourseGrade = createSelector(
  getCurrentReport,
  (data) => {
    if (data) {
      const { repliesInfoCount = {}, answeredQuestionsCount = {} } = data?.mock;
      return repliesInfoCount?.true > 0
        ? `${((repliesInfoCount?.true / answeredQuestionsCount) * 100).toFixed(
            0
          )}%`
        : "0%";
    }
    return;
  }
);
