import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchMock, fetchMockRanking } from "../../processes/mockProccess";
import { getMock, getMocksForRanking } from "../../selectors/mockSelectors";
import MockRankingPage from "./MockRankingPage";
import { replace } from "connected-react-router";

function MockRankingPageContainer(props) {
  const { dispatch, mockId } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    mockId && fetchMock(dispatch, mockId);
  }, [dispatch, mockId]);

  useEffect(() => {
    setLoading(true);
    fetchMockRanking(dispatch, mockId)
      .then(() => {
        setLoading(false);
      })
      .catch(() => dispatch(replace("/")));
  }, [dispatch]);

  return <MockRankingPage loading={loading} {...props} />;
}

function mapStateToProps(state, ownProps) {
  const { mockId } = ownProps.match.params;
  const mock = getMock(state, mockId);

  return {
    mockId,
    mock,
    mocks: getMocksForRanking(state, mock?.mockTemplateId),
  };
}

export default connect(mapStateToProps)(MockRankingPageContainer);
