import React, { useState, useEffect } from "react";
import { spacing, uiColors } from "../library/theme";
import Carousel from "../library/Carousel";
import ContentCommentListItemContainer from "./ContentCommentListItemContainer";
import { makeIsMobile } from "../../utils/useScreenWidth";
import { css } from "glamor";

const container = css({ paddingBottom: spacing.l });
const computerItemsWrapper = css(container, {
  display: "flex",
  flexDirection: "row",
  gap: spacing.m,
});
const indicator = {
  background: uiColors.border,
  width: spacing.s,
  height: spacing.s,
  display: "inline-block",
  margin: `0 ${spacing.xs} -${spacing.s} ${spacing.xs}`,
  borderRadius: "50%",
};
const selectedIndicator = {
  background: uiColors.background,
};

const customIndicator = (onClickHandler, isSelected, index, label) => {
  if (isSelected) {
    return (
      <li
        style={{ ...indicator, ...selectedIndicator }}
        aria-label={`Selected: ${label} ${index + 1}`}
        title={`Selected: ${label} ${index + 1}`}
      />
    );
  }
  return (
    <li
      style={indicator}
      onClick={onClickHandler}
      onKeyDown={onClickHandler}
      value={index}
      key={index}
      role="button"
      tabIndex={0}
      title={`${label} ${index + 1}`}
      aria-label={`${label} ${index + 1}`}
    />
  );
};

export default function ContentCarousel({ items }) {
  const isMobile = makeIsMobile();
  const [itemList, setItemList] = useState([]);

  useEffect(() => {
    isMobile
      ? setItemList(items)
      : setItemList(
          [...items].reduce((acc, curr, idx, arr) => {
            if (idx % 2 === 0) {
              acc.push([curr, arr[idx + 1] ?? arr[0]]);
            }
            return acc;
          }, [])
        );
  }, [items, isMobile]);

  return (
    <Carousel renderIndicator={customIndicator}>
      {itemList.map((item) =>
        Array.isArray(item) ? (
          <div
            className={computerItemsWrapper}
            key={`contentCarousselCategory${item?.categoryIds?.join()}Id${
              item?.id
            }`}
          >
            <ContentCommentListItemContainer comment={item[0]} banner />
            {items?.length > 1 && (
              <ContentCommentListItemContainer comment={item[1]} banner />
            )}
          </div>
        ) : (
          <div
            className={container}
            key={`contentCarousselCategory${item?.categoryIds?.join()}Id${
              item?.id
            }`}
          >
            <ContentCommentListItemContainer comment={item} banner />
          </div>
        )
      )}
    </Carousel>
  );
}
