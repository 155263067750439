import React from "react";
import Button from "../library/Button";
import { Form, TextAreaField } from "../library/forms";
import { css } from "glamor";
import { spacing } from "../library/theme";

const container = css({
  width: "100%",
  textAlign: "right",
  paddingTop: spacing.s,
});
const buttonsWrapper = css({
  marginTop: spacing.s,
  display: "flex",
  justifyContent: "end",
  gap: spacing.xs,
});
const replyPadding = css({
  paddingLeft: 62,
  "@media(max-width: 575px)": {
    paddingLeft: 0,
  },
});

export default function ContentCommentEditForm(props) {
  return (
    <div className={css(container, props.reply && replyPadding)}>
      <Form>
        <TextAreaField
          rows={4}
          name="text"
          placeholder={props.comment.text}
          width="100%"
          fluid="true"
        />
        <div className={buttonsWrapper}>
          <Button type="button" onClick={props.toogleIsEditMode}>
            Cancelar
          </Button>
          <Button type="button" onClick={props.handleSubmit} color="blue">
            Salvar
          </Button>
        </div>
      </Form>
    </div>
  );
}
