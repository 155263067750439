export const parseSocialMediaUrl = (url, icon) => {
  const hasHttps = /^[a-zA-Z]+:\/\//.test(url);
  const parseIfAtInBegining = /^@/.test(url) ? url.slice(1, url.length) : url;
  const checkBegining = /(^(www.|instagram.com|twitter.com))/.test(url);

  if (url && !hasHttps) {
    switch (icon) {
      case "envelope": {
        return `mailto:${url}`;
      }
      case "instagram": {
        return `https://${
          checkBegining ? "" : "www.instagram.com/"
        }${parseIfAtInBegining}`;
      }
      case "twitter": {
        return `https://${
          checkBegining ? "" : "www.twitter.com/"
        }${parseIfAtInBegining}`;
      }
      default: {
        return `https://${url}`;
      }
    }
  }

  return url;
};
