import { css } from "glamor";
import React, { createRef, useCallback, useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import Button from "./Button";
import { spacing } from "./theme";

const layout = css({
  flex: 1,
  width: "100%",
  overflowY: "hidden",
  textAlign: "center",
  padding: `${spacing.s} 0`,
  minWidth: 300,
  "> button": {
    "&:hover": {
      transform: "scale(1.2)",
      cursor: "pointer",
      transition: "all 0.2s",
    },
  },
});
const list = css({
  cursor: "pointer",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  overflowY: "scroll",
  whiteSpace: "nowrap",
  transition: "all 0.3s",
  willChange: "transform",
  userSelect: "none",
  cursor: "pointer",
  scrollbarWidth: "none",
  scrollBehavior: "smooth",
  margin: `${spacing.m} 0`,
  "::-webkit-scrollbar": {
    display: "none",
  },
});
const actionUpStyle = {
  height: 40,
  width: 40,
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
  margin: "0 auto",
};
const actionDownStyle = {
  ...actionUpStyle,
  alignItems: "center",
};
const iconDisabled = {
  cursor: "auto",
  color: "transparent",
  background: "transparent",
  borderColor: "transparent",
  pointerEvents: "none",
};

export default function VerticalList({
  data = [],
  renderItem,
  listHeight = "400px",
  scrollOffset = 120,
}) {
  const listRef = createRef();
  const [isInitialPosition, setIsInitialPosition] = useState(true);
  const [isScrollEnded, setIsScrollEnded] = useState(false);

  const moveToTop = useCallback(() => {
    if (!listRef.current) return;
    listRef.current.scrollTo(0, listRef.current.scrollTop - scrollOffset);
  }, [listRef]);

  const moveToBottom = useCallback(() => {
    if (!listRef.current) return;
    listRef.current.scrollTo(0, listRef.current.scrollTop + scrollOffset);
  }, [listRef]);

  useEffect(() => {
    if (!listRef.current) return;
    const listEl = listRef.current;
    let isScrolling = false;
    let startY = 0;

    listEl.addEventListener("scroll", () => {
      setIsInitialPosition(listEl.scrollTop === 0);
      setIsScrollEnded(
        listEl.scrollTop + listEl.offsetHeight === listEl.scrollHeight
      );
    });

    listEl.addEventListener("mousedown", (e) => {
      isScrolling = true;
      startY = e.pageY - listEl.offsetTop;
    });

    listEl.addEventListener("mouseup", () => {
      isScrolling = false;
    });

    listEl.addEventListener("mouseleave", () => {
      isScrolling = false;
    });

    listEl.addEventListener("mousemove", (e) => {
      if (!isScrolling) return;
      const y = e.pageY - listEl.offsetTop;
      const walk = y - startY;
      listEl.scrollTop = listEl.scrollTop - walk;
    });

    return () => {
      listEl.removeEventListener("scroll", () => {});
      listEl.removeEventListener("mousedown", () => {});
      listEl.removeEventListener("mouseup", () => {});
      listEl.removeEventListener("mouseleave", () => {});
      listEl.removeEventListener("mousemove", () => {});
    };
  }, [listRef]);

  return (
    <div className={layout}>
      <Button
        secondary
        circular
        onClick={moveToTop}
        style={isInitialPosition ? iconDisabled : actionUpStyle}
        icon={<Icon name="angle up" size="big" />}
      />
      <div ref={listRef} className={css(list, { height: listHeight })}>
        {data.map(renderItem)}
      </div>
      <Button
        secondary
        circular
        onClick={moveToBottom}
        style={isScrollEnded ? iconDisabled : actionDownStyle}
        icon={<Icon name="angle down" size="big" />}
      />
    </div>
  );
}
