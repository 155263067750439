import { hot } from "react-hot-loader/root";
import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import lmsReducer from "../reducers/lmsReducer";
import applyDevTools from "../lib/applyDevTools";
import Routes from "../routes";
import { getSession } from "../processes/sessionProcesses";
import applyGlobalStyles from "../components/library/applyGlobalStyles";
import { IntercomProvider } from "react-use-intercom";

Bugsnag.start({
  apiKey: "07c30316b1b619894e3d94b68debe561",
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const currentUserId = getSession()?.currentUserId;
const initialState = { currentUserId };
const browserHistory = createBrowserHistory();
const store = createStore(
  lmsReducer(browserHistory),
  initialState,
  compose(
    applyMiddleware(routerMiddleware(browserHistory)),
    applyDevTools(window.__REDUX_DEVTOOLS_EXTENSION__),
  ),
);

applyGlobalStyles();

const INTERCOM_APP_ID = !window.location.pathname.includes('/mobile') && "gzfho0t0";

function LMS() {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <ErrorBoundary>
        <Provider store={store}>
          <Routes history={browserHistory} />
        </Provider>
      </ErrorBoundary>
    </IntercomProvider>
  );
}

if (module.hot) {
  module.hot.accept("../routes", () => {
    LMS();
  });
}

export default hot(LMS);
