import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import MockRankingModal from "./MockRankingModal";
import {
  getCurrentMock,
  getMocksForRanking,
} from "../../selectors/mockSelectors";
import { fetchMockRanking } from "../../processes/mockProccess";

function MockRankingModalContainer(props) {
  const { dispatch, mock, history } = props;
  const [loading, setLoading] = useState(false);
  const finishedAt = mock?.finishedAt;
  const exam = mock?.exam;

  const handleClose = useCallback(() => {
    history?.goBack();
  }, [history?.goBack]);

  useEffect(() => {
    if (finishedAt && !exam) {
      setLoading(true);
      fetchMockRanking(dispatch, mock?.id).then(() => {
        setLoading(false);
      });
    } else handleClose();
  }, [dispatch, finishedAt, exam, handleClose]);

  return (
    <MockRankingModal handleClose={handleClose} loading={loading} {...props} />
  );
}

function mapStateToProps(state) {
  const mock = getCurrentMock(state);

  return {
    mock,
    mocks: getMocksForRanking(state, mock?.mockTemplateId),
  };
}

export default connect(mapStateToProps)(MockRankingModalContainer);
