import React from "react";
import { css } from "glamor";
import { uiColors } from "../library/theme";

const container = css({
  display: "flex",
  borderRadius: 4,
  flexDirection: "column",
  alignItems: "center",
  background: "inherit",
});
const circularProgress = css({
  position: "relative",
  height: 65,
  width: 65,
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "::before": {
    content: '""',
    position: "absolute",
    height: 50,
    width: 50,
    borderRadius: "50%",
    backgroundColor: uiColors.fieldBackground,
  },
});
const progressValue = css({
  position: "relative",
  fontSize: 15,
  fontWeight: 600,
});
const getColor = (value, useHex = false) => {
  if (value < 20) return useHex ? "#767676" : "grey";
  if (value >= 20 && value < 50) return useHex ? "#f2711c" : "orange";
  if (value >= 50 && value < 90) return useHex ? "#fbbd08" : "yellow";
  return useHex ? "#21ba45" : "green";
};

export default function CircularProgress({ value = 0, size = 65 }) {
  const innerSize = size - size * 0.1;
  const fontSize = size * 0.25;

  const circularProgressClass = css(circularProgress, {
    height: size,
    width: size,
    "::before": {
      height: innerSize,
      width: innerSize,
    },
    background: `conic-gradient(${getColor(value, true)} ${
      value * 3.6
    }deg, #ededed 0deg)`,
  });
  const innerClass = css(progressValue, {
    fontSize: fontSize,
    color: getColor(value, true),
  });

  return (
    <div className={container}>
      <div className={circularProgressClass}>
        <span className={innerClass}>{value}%</span>
      </div>
    </div>
  );
}
