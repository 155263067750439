import { connect } from "react-redux";
import { updateProfile } from "../../processes/profileProcesses";
import { getCurrentUser } from "../../selectors/userSelectors";
import buildForm from "../library/forms/buildForm";
import ProfileEditChangePasswordForm from "./ProfileEditChangePasswordForm";

const form = buildForm("changePasswordProfileEdit");

function mapStateToProps(state) {
  const currentUser = getCurrentUser(state);

  return {
    currentUser,
    initialValues: currentUser,
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: updateProfile,
  })(ProfileEditChangePasswordForm)
);
