import { useLayoutEffect, useState } from "react";

export default function useScreenVerticalOffset() {
  const [pageYOffset, setPageYOffset] = useState(true);

  useLayoutEffect(() => {
    function updateSize() {
      setPageYOffset(window.pageYOffset);
    }
    window.addEventListener("scroll", updateSize);
    updateSize();
    return () => window.removeEventListener("scroll", updateSize);
  }, [pageYOffset]);

  return pageYOffset;
}
