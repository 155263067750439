const LOCALE = "pt-BR";

export const toBRLCurrency = (amount = 0.0) => {
  if (+amount == 0 || +amount == NaN) {
    return "R$ 0,00";
  }

  return new Intl.NumberFormat(LOCALE, {
    style: "currency",
    currency: "BRL",
    currencyDisplay: "symbol",
  }).format(amount);
};
