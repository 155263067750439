import { normalize, schema } from "normalizr";
import { post } from "superagent";
import { apiPost, apiGet, apiDel, baseUrl, apiPut } from "./helpers/api";
import { createLike, deleteLike } from "./likeProcesses";
import { notifySubmitSucceeded } from "./notifierProcesses";
import middleware from "./helpers/railsMiddleware";
import { underscore } from "inflection";
import toQueryString from "../utils/toQueryString";

export const commentReplySchema = new schema.Entity("comments", {
  meta: { replies: [new schema.Entity("comments")] },
});

export const commentSchema = new schema.Entity("comments", {
  meta: { replies: [commentReplySchema] },
});

const fetchedComments = (dispatch, body) => {
  dispatch({
    type: "COMMENTS_FETCHED",
    ...normalize(body.data, new schema.Array(commentSchema)),
    totalPages: body.totalPages,
    currentPage: body.currentPage,
  });
};

const createComment = (dispatch, comment) => {
  dispatch({
    type: "COMMENT_CREATED",
    ...normalize(comment, commentSchema),
  });
};

export const fetchLessonComments = (dispatch, courseId, lessonId, page = 1) => {
  dispatch({ type: "COMMENTS_FETCH_REQUESTED" });
  apiGet(
    `/api/v1/courses/${courseId}/lessons/${lessonId}/comments?page=${page}`
  ).then((response) => {
    fetchedComments(dispatch, response.body);
  });
};

export const createLessonComment = (values, dispatch, props) => {
  return apiPost(
    `/api/v1/courses/${props.currentCourseId}/lessons/${props.lessonId}/comments`
  )
    .send(values)
    .then((response) => {
      createComment(dispatch, { ...response.body.data, pristine: true });
      props.reset();
      notifySubmitSucceeded(
        dispatch,
        "Seu comentário será exibido depois que for aprovado."
      );
    });
};

const isFile = (obj) => obj && !!obj.lastModified;

export const createContentComment = (values, dispatch, props) => {
  const req = post(
    `${baseUrl}/api/v1/contents/${props.contentId}/comments`
  ).use(middleware);

  Object.keys(values).map((k) => {
    const value = values[k];
    if (value === null) return;
    const name = `comment[${underscore(k).replace("_url", "")}]${
      Array.isArray(value) ? "[]" : ""
    }`;
    isFile(value) ? req.attach(name, value) : req.field(name, value);
  });

  return req.then((response) => {
    createComment(dispatch, response.body.data);
    props.reset();
  });
};

export const contentCommentSubmitFilter = (values, dispatch, props) => {
  const { contentId } = props;
  const filters = toQueryString(values);
  if (!contentId) return;
  dispatch({ type: "CONTENTS_COMMENTS_FETCH_REQUESTED" });
  return apiGet(`/api/v1/contents/${contentId}/comments?${filters}`).then(
    (response) => {
      fetchedComments(dispatch, response.body);
    }
  );
};

export const fetchContentComments = (
  dispatch,
  contentId,
  filters,
  page = 1
) => {
  if (!contentId) return;
  const queryFilters = filters ? toQueryString(filters) : "";
  dispatch({ type: "CONTENTS_COMMENTS_FETCH_REQUESTED" });
  apiGet(
    `/api/v1/contents/${contentId}/comments?${queryFilters}page=${page}`
  ).then((response) => {
    fetchedComments(dispatch, response.body);
  });
};

export const createContentCommentReply = (values, dispatch, props) => {
  return apiPost(
    `/api/v1/contents/${props.contentId}/comments/${props.comment.id}/comments`
  )
    .send(values)
    .then((response) => {
      dispatch({
        type: "COMMENT_REPLY_CREATED",
        ...normalize({ ...response.body.data, pristine: true }, commentSchema),
      });
      props.reset();
    });
};

export const deleteComment = (dispatch, commentId) => {
  apiDel(`/api/v1/comments/${commentId}`).then(() => {
    dispatch({ type: "COMMENT_DELETED", commentId });
    notifySubmitSucceeded(dispatch, "Seu comentário foi apagado.");
  });
};

export const createCommentLike = (dispatch, props) => {
  return createLike(dispatch, "Comment", props.commentId).then(() =>
    dispatch({ type: "COMMENT_LIKE_INCREASED", commentId: props.commentId })
  );
};

export const deleteCommentLike = (dispatch, props) => {
  return deleteLike(dispatch, props.likeId).then(() =>
    dispatch({ type: "COMMENT_LIKE_DECREASED", commentId: props.commentId })
  );
};

export const createOrDeleteContentLike = (_, dispatch, props) => {
  props.likeId
    ? deleteCommentLike(dispatch, props)
    : createCommentLike(dispatch, props);
};

export const fetchComment = (dispatch, commentId) => {
  return apiGet(`/api/v1/comments/${commentId}`).then((response) => {
    dispatch({
      type: "COMMENTS_FETCHED",
      ...normalize(response.body.data, commentSchema),
    });
  });
};

export const fetchHighlightedCommentsByCategory = (
  dispatch,
  contentId,
  categoryId
) => {
  if (!contentId || !categoryId) return;
  dispatch({
    type: "REQUEST_FETCH_COMMENTS_HIGHLIGHTED_BY_CATEGORY",
    categoryId,
  });
  return apiGet(
    `/api/v1/contents/${contentId}/comments?q[categories_id_in][]=${categoryId}&ordered_by_like=true&per_page=10&page=1`
  ).then((response) => {
    dispatch({
      type: "COMMENTS_HIGHLIGHTED_BY_CATEGORY_FETCHED",
      categoryId,
      ...normalize(response.body.data, [commentSchema]),
    });
  });
};

export const fetchHighlightedComments = (dispatch, contentId) => {
  if (!contentId) return;
  dispatch({ type: "REQUEST_FETCH_COMMENTS_HIGHLIGHTED" });
  return apiGet(
    `/api/v1/contents/${contentId}/comments?ordered_by_like=true&q[from_students]=true&per_page=10&page=1`
  ).then((response) => {
    dispatch({
      type: "COMMENTS_HIGHLIGHTED_FETCHED",
      ...normalize(response.body.data, [commentSchema]),
    });
  });
};

export const fetchHighlightedTeamComments = (dispatch, contentId) => {
  if (!contentId) return;
  return apiGet(
    `/api/v1/contents/${contentId}/comments?ordered_by_like=true&q[from_team]=true&per_page=10&page=1`
  ).then((response) => {
    dispatch({
      type: "COMMENTS_HIGHLIGHTED_FROM_TEAM_FETCHED",
      ...normalize(response.body.data, [commentSchema]),
    });
  });
};

export const updateComment = (values, dispatch, props) => {
  return apiPut(`/api/v1/comments/${props.comment.id}`)
    .send(values)
    .then((response) => {
      dispatch({
        type: "COMMENT_UPDATED",
        ...normalize(response.body.data, commentSchema),
      });
      props.toogleIsEditMode();
    });
};
