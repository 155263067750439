const getCompanies = (action) => action.entities.companies;

export default function companiesReducer(state = {}, action) {
  switch (action.type) {
    case "USER_FETCHED":
      return { ...state, ...getCompanies(action) };
    case "COMPANY_FETCHED": {
      const company = state[action.result];
      if (!company) return state;

      return {
        ...state,
        [company.id]: {
          ...company,
          ...getCompanies(action)[company.id],
        },
      };
    }
    default:
      return state;
  }
}
