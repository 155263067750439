export default function formatSeconds(seconds) {
  return seconds
    ? `${getHours(seconds)}:${getMinutes(seconds)}:${getSeconds(seconds)}`
    : "--";
}

export const getHours = (seconds) =>
  preprendDigit(Math.floor(seconds / 3600).toFixed(0));
const getMinutes = (seconds) =>
  preprendDigit(Math.floor((seconds % 3600) / 60).toFixed(0));
const getSeconds = (seconds) =>
  preprendDigit(((seconds % 3600) % 60).toFixed(0));
const preprendDigit = (number) => ("0" + number).slice(-2);
