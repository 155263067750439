import { connect } from "react-redux";
import { getTrialCourseForCountdown } from "../../selectors/courseSelectors";
import CourseTrialCountdown from "./CourseTrialCountdown";
import { getCourseUserByCourseId } from "../../selectors/coursesUserSelectors";

function mapStateToProps(state, ownProps) {
  const course = ownProps?.course || getTrialCourseForCountdown(state);

  return {
    course,
    courseUser: getCourseUserByCourseId(state, course?.id),
  };
}

export default connect(mapStateToProps)(CourseTrialCountdown);
