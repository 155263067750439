import { css } from "glamor";
import React from "react";
import { Icon } from "semantic-ui-react";
import Link from "../library/Link";
import { colors, spacing, styles } from "../library/theme";

const iconName = (type) => {
  switch (type) {
    case "PDF":
      return "file pdf outline";
    case "MP3":
      return "file audio outline";
    case "XLSX":
      return "file excel outline";
    case "DOC":
      return "file outline";
    case "MIND":
      return "sitemap";
    default:
      return "cloud download";
  }
};

const listItem = css(styles.transition, {
  borderRadius: spacing.xs,
  padding: spacing.s,
  display: "flex",
  gap: spacing.s,
  alignItems: "center",
  width: "100%",
  backgroundColor: colors.greyClear,
  color: `${colors.greyShadow} !important`,
  border: `1px solid ${colors.greyClear}`,
  ":hover": {
    color: `${colors.primary} !important`,
    border: `1px solid ${colors.primary}`,
  },
  "& + &": {
    marginTop: spacing.xxs,
  },
});

export default function LessonFiles(props) {
  return (
    <>
      {props.files.map((file, index) => {
        return (
          <Link
            key={index}
            href={file.url}
            target="_blank"
            className={listItem}
          >
            <div>
              <Icon name={iconName(file.fileType)} />{" "}
              {file.name || `Arquivo ${index + 1}`}
            </div>
          </Link>
        );
      })}
    </>
  );
}
