import memoize from "fast-memoize";
import React from "react";
import { Field } from "redux-form";
import RichTextInput from "./RichTextInput";
import isLength from "validator/lib/isLength";
import buildFormField from "./buildFormField";

const FormField = buildFormField(RichTextInput, (input, meta, rest) => {
  return {
    ...input,
    autoComplete: "off",
    ...rest,
    invalid: `${meta.touched && (meta.invalid || rest.invalid)}`,
  };
});

// this needs to be memoizable!
const buildValidator = memoize((required, minSize, maxSize, other) => {
  return [
    (value) => {
      if (required && !value) {
        return "required";
      }
      if (minSize && !isLength(value, minSize)) {
        return "minSize";
      }
      if (maxSize && !isLength(value, maxSize)) {
        return "maxSize";
      }
    },
  ].concat(other || []);
});

export default function RichTextField(props) {
  const { required, validate, minSize, maxSize, ...rest } = props;

  return (
    <Field
      {...rest}
      component={FormField}
      validate={buildValidator(
        required,
        Number(minSize),
        Number(maxSize),
        validate
      )}
    />
  );
}
