import { css } from "glamor";
import React from "react";
import { colors, Link, spacing, Icon } from "../library";
import { ButtonField } from "../library/forms";
import BankSlipImg from "../library/images/bankslip-badge.svg";
import CreditCardImg from "../library/images/cc-badge.svg";
import PixImg from "../library/images/pix-badge.svg";
import { RiCheckboxCircleFill } from "react-icons/ri";
import CourseCheckoutPrivacyWarning from "../courseCheckout/CourseCheckoutPrivacyWarning";

const PAYMENT_FORM_NAME = "payment";
const BILLING_TYPE_FIELD_NAME = "billingType";
const CREDIT_CARD = "CREDIT_CARD";
const BOLETO = "BOLETO";
const PIX = "PIX";

const mainTitleClass = css({
  color: colors.black,
  fontWeight: "bold",
  lineHeight: 1.3,
  fontSize: "41px",
  marginBottom: 0,
  "@media(max-width: 575px)": {
    paddingTop: spacing.m,
  },
});
const methodsWrapperStyle = {
  marginTop: spacing.l,
};
const billingTypeContainerClass = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: spacing.s,
  height: 62,
  cursor: "pointer",
  textAlign: "left",
  background: colors.white,
  border: `1px solid ${colors.greyLight}`,
  borderRadius: spacing.s,
  padding: spacing.s,
  marginBottom: spacing.s,
  ":hover": {
    "> svg": {
      color: `${colors.primaryClear} !important`,
    },
  },
});
const selectedBillingTypeContainerClass = css({
  background: colors.primary,
  borderColor: colors.primary,
  "> div": {
    "> img": {
      filter: "brightness(0) invert(1)",
    },
    "> h3": {
      color: colors.white,
    },
  },
  ":hover": {
    "> svg": {
      color: `${colors.white} !important`,
    },
  },
});
const billingTypeTitleClass = css({
  display: "flex",
  alignItems: "center",
  gap: spacing.s,
  flex: 1,
});
const billingTypeButtonStyle = {
  marginTop: spacing.l,
};
const paymentMethodTitle = {
  marginBottom: 0,
};
const exitWrapper = {
  textAlign: "center",
};
const backWrapper = {
  textAlign: "left",
  paddingTop: spacing.s,
};
const linkStyle = {
  cursor: "pointer",
};
const billingTypeSelectContainer = css({
  padding: spacing.l,
  flex: 1,
  display: "flex",
  flexDirection: "column",
});
const privacyWarningWrapperStyle = {
  marginTop: "auto",
};
const bookmarkClass = css({
  position: "relative",
  height: "100px",
  width: "24px",
  padding: "0px",
  marginLeft: "40px",
  transform: "rotate(270deg) skew(0deg)",
  borderLeft: "12px solid red",
  borderRight: "12px solid red",
  borderBottom: "12px solid transparent",
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: "5px",
  writingMode: "vertical-rl",
  lineHeight: 0.1,
  paddingTop: spacing.s,
  borderColor: colors.correct,
  borderBottomColor: "transparent",
  color: colors.white,
  "*": {
    boxSizing: "border-box",
  },
  ":before": {
    boxSizing: "border-box",
  },
  ":after": {
    boxSizing: "border-box",
  },
});
const selectedBookmarkClass = css({
  color: colors.primary,
  borderColor: colors.white,
  borderBottomColor: colors.primary,
});

function BillingTypeSelectOption({
  title,
  description,
  image,
  value,
  discountText,
  selectedBillingType,
  setSelectedBillingType,
  ...rest
}) {
  const selected = selectedBillingType == value;
  const setSelectedBillingTypeHandler = React.useCallback(() => {
    setSelectedBillingType(value);
  }, [value]);

  return (
    <div
      onClick={setSelectedBillingTypeHandler}
      className={css(
        billingTypeContainerClass,
        selected && selectedBillingTypeContainerClass
      )}
    >
      <div className={billingTypeTitleClass}>
        <img src={image} width="35px" />
        <h3 style={paymentMethodTitle}>{title}</h3>
        {discountText && (
          <div
            className={css(bookmarkClass, selected && selectedBookmarkClass)}
          >
            {discountText}
          </div>
        )}
      </div>

      <RiCheckboxCircleFill size={22} color="white" />
    </div>
  );
}

export default function BillingTypeField(props) {
  const { offer } = props;

  const [selectedBillingType, setSelectedBillingType] = React.useState(null);

  return (
    <div className={billingTypeSelectContainer}>
      <h1 className={mainTitleClass}>Como você prefere pagar?</h1>
      <div style={methodsWrapperStyle}>
        <BillingTypeSelectOption
          title="Cartão de Crédito"
          image={CreditCardImg}
          value={CREDIT_CARD}
          selectedBillingType={selectedBillingType}
          setSelectedBillingType={setSelectedBillingType}
        />
        <BillingTypeSelectOption
          title="Boleto"
          image={BankSlipImg}
          value={BOLETO}
          selectedBillingType={selectedBillingType}
          setSelectedBillingType={setSelectedBillingType}
        />
        <BillingTypeSelectOption
          title="Pix"
          image={PixImg}
          value={PIX}
          discountText={offer?.pixDiscount > 0 && "10% off"}
          selectedBillingType={selectedBillingType}
          setSelectedBillingType={setSelectedBillingType}
        />

        <ButtonField
          formName={PAYMENT_FORM_NAME}
          fieldName={BILLING_TYPE_FIELD_NAME}
          value={selectedBillingType}
          onClick={props?.confirmDiscount}
          fluid
          color="black"
          icon="shopping cart"
          content="AVANÇAR"
          size="big"
          style={billingTypeButtonStyle}
          confirmationText={
            props?.discountConfirmed
              ? undefined
              : "Você tem direito ao desconto de aluno da T2!"
          }
          confirmButton="Usar desconto"
          cancelButton="Cancelar"
          disabled={!selectedBillingType}
        />
        {!props?.submitSucceeded && !props?.user && props?.leadCreated && (
          <div style={backWrapper}>
            <Link style={linkStyle} onClick={props?.changeLeadHandler}>
              <Icon name="angle left" /> Etapa Anterior
            </Link>
          </div>
        )}
      </div>

      <div style={privacyWarningWrapperStyle}>
        <CourseCheckoutPrivacyWarning />
      </div>
    </div>
  );
}
