import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import buildForm from "../library/forms/buildForm";
import ContentCommentForm from "./ContentCommentForm";
import { createContentComment } from "../../processes/commentProcesses";
import { getCurrentUser } from "../../selectors/userSelectors";
import { formValueSelector } from "redux-form";
import { getConfig } from "../../selectors/configSelectors";

const formName = "contentCommentForm";
const form = buildForm(formName);

function ContentCommentFormContainer(props) {
  const [showCategoryField, setShowCategoryField] = useState(false);

  useEffect(() => {
    props.submitting && setShowCategoryField(false);
  }, [props.submitting]);

  const toogleCategoryField = useCallback(
    (e) => {
      e.target.focus();
      setShowCategoryField(!showCategoryField);
    },
    [showCategoryField]
  );

  return (
    <ContentCommentForm
      showCategoryField={showCategoryField}
      toogleCategoryField={toogleCategoryField}
      {...props}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const { contentId } = ownProps;
  const contentCommentFormSelector = formValueSelector(formName);

  return {
    initialValues: {
      categoryIds: [],
    },
    contentText: contentCommentFormSelector(state, "text"),
    currentUser: getCurrentUser(state),
    contentId,
    formName,
    showUrlPreview: !!!contentCommentFormSelector(state, "image"),
    contentCategories: getConfig(state, "contentCategories"),
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: createContentComment,
  })(ContentCommentFormContainer)
);
