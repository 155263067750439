import { LOCATION_CHANGE } from "connected-react-router";
import { matchPath } from "react-router";

const initialState = {
  currentMockId: null,
  currentIndex: 0,
  selectedReplies: {},
  mockPosition: null,
  questionsLoading: true,
  answersLoading: true,
};

export default function mockReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      const match =
        matchPath(action.payload.location.pathname, {
          path: "/mocks/:mockId",
        }) ||
        matchPath(action.payload.location.pathname, {
          path: "/mobile/mocks/:mockId",
        });
      const currentMockId =
        match && match.params.mockId && Number(match.params.mockId);

      return { ...initialState, currentMockId };
    case "CURRENT_INDEX_CHANGED":
      return {
        ...state,
        currentIndex: action.index,
      };
    case "REPLY_SELECTED":
      return {
        ...state,
        selectedReplies: {
          ...state.selectedReplies,
          [action.reply.questionId]: action.reply,
        },
      };
    case "MOCK_RESETTED":
      return initialState;
    case "MOCK_RANKING_FETCHED":
      return { ...state, mockPosition: action.position };
    case "MOCK_INITIAL_QUESTION":
      return { ...state, currentIndex: action.index };
    case "QUESTIONS_FETCHED":
      return { ...state, questionsLoading: false };
    case "ANSWERS_FETCHED":
      return { ...state, answersLoading: false };
    default:
      return state;
  }
}
