import React from "react";
import { Page, Spinner } from "../library";
import CourseList from "./CourseList";

export function CoursesData(props) {
  const { loading, courseCategories, justOwned, justNotOwned } = props;

  return loading ? (
    <Spinner wrapped />
  ) : (
    <>
      {courseCategories && (
        <CourseList courseCategories={courseCategories} justNotOwned={justNotOwned} justOwned={justOwned} />
      )}
    </>
  );
}

export default function Courses(props) {
  return (
    <Page title="Loja">
      <CoursesData justNotOwned {...props} />
    </Page>
  );
}
