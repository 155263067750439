import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { signIn } from "../../processes/sessionProcesses";
import SignInForm from "./SignInForm";

function SignInFormContainer(props) {
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    if (props?.location?.search.includes("confirmed")) {
      setConfirmed(true);
    }
  }, [props?.location?.search]);

  return <SignInForm confirmed={confirmed} {...props} />;
}

function mapStateToProps(state) {
  return {
    initialValues: {},
    redirectTo: state.afterSignInPath.redirectTo,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "signInForm",
    onSubmit: signIn,
  })(SignInFormContainer)
);
