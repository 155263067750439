import { css } from "glamor";
import React from "react";
import { AiFillCaretLeft } from "react-icons/ai";
import { colors, Link, Page, spacing } from "../library";
import Spinner from "../library/Spinner";
import Button from "../library/Button";
import Icon from "../library/Icon";
import Annotations from "../annotations/Annotations";

const backClass = css({
  color: colors.primaryDarker,
  maxWidth: "1080px",
  margin: `0px auto ${spacing.m} 0px`,
  display: "flex",
  alignItems: "center",
  gap: spacing.xxs,
  ":hover": {
    color: colors.primary,
    cursor: "pointer",
  },
});

export default function CourseAnnotations({ course }) {
  return (
    <Page title={course?.name ? `ANOTAÇÕES - ${course?.name}` : "ANOTAÇÕES"}>
      <Link to={`/courses/${course?.id}`} className={backClass} component="div">
        <AiFillCaretLeft size={14} /> VOLTAR
      </Link>

      <Annotations showCreate={false} />
    </Page>
  );
}
