import { css } from "glamor";
import React, { useCallback, useState } from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { TbInfoCircle } from "react-icons/tb";
import { FieldArray } from "redux-form";
import { Header, Icon, Message, Modal } from "semantic-ui-react";
import { getItem, setItem } from "../../utils/localStorage";
import makeDropDownOptions from "../../utils/makeDropDownOptions";
import Link from "../library/Link";
import Paragraph from "../library/Paragraph";
import {
  CheckBoxField,
  CheckBoxFieldArray,
  DropDownField,
  Form,
} from "../library/forms";
import Label from "../library/forms/Label";
import { colors, spacing } from "../library/theme";
import MockModalButton from "./MockModalButton";

const modalClass = css({
  padding: `${spacing.l} ${spacing.xl}`,
  maxWidth: 900,
}).toString();
const modalTitleClass = css({
  textAlign: "center",
  marginBottom: spacing.l,
  fontWeight: "bold",
});
const messageContainer = css({
  backgroundColor: colors.greyLighter,
  borderRadius: 10,
  height: 150,
  marginTop: spacing.m,
  marginBottom: spacing.m,
  "@media (max-width: 768px)": {
    height: "auto",
    padding: spacing.xxs,
  },
});
const closeWrapper = css({
  display: "flex",
  justifyContent: "end",
  padding: spacing.s,
  paddingBottom: 0,
});
const closeStyle = {
  cursor: "pointer",
};
const infoContainer = css({ display: "flex", alignItems: "center" });
const iconWrapper = css({
  paddingLeft: spacing.m,
});
const iconStyle = {
  color: colors.black,
};
const infoTextContainer = css({
  padding: spacing.s,
});
const headerStyle = { marginBottom: spacing.xxs };
const paragraphStyle = {
  color: colors.black,
  lineHeight: 1.3,
};
const highlightedTextStyle = css({
  color: colors.primaryDarker,
});
const levelWrapper = {
  marginTop: spacing.m,
  paddingTop: spacing.m,
  paddingBottom: spacing.m,
};
const container = css({
  marginTop: 0,
  border: 0,
});
const fieldsWrapper = css({
  "> div > label, > div > div > label": {
    color: colors.greyShadow,
    fontWeight: 600,
  },
});
const questionQuantityWrapper = css({
  paddingBottom: spacing.m,
  width: "50%",
  whiteSpace: "nowrap",
  "@media (max-width: 768px)": {
    width: "100%",
  },
});
const totalQuestionsStyle = {
  color: colors.primaryDark,
  marginTop: spacing.xs,
};
const messageClass = css({
  display: "flex",
  alignItems: "center",
  gap: spacing.xs,
});
const examSettingsTitleStyle = {
  marginBottom: spacing.xxs,
  display: "block",
  color: colors.dark,
  fontWeight: 600,
  marginBottom: spacing.m,
  whiteSpace: "nowrap",
  fontSize: 16,
};
const labelLayout = { fontSize: 16 };
const checkboxClass = css({ "> label": { fontSize: "16px !important" } });

const quantityOptions = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "30", value: 30 },
  { text: "40", value: 40 },
  { text: "50", value: 50 },
  { text: "60", value: 60 },
  { text: "70", value: 70 },
  { text: "80", value: 80 },
  { text: "90", value: 90 },
  { text: "100", value: 100 },
  { text: "110", value: 110 },
  { text: "120", value: 120 },
  { text: "130", value: 130 },
  { text: "140", value: 140 },
];

function FirstMockMessage({ isMobile }) {
  const hideMessage = getItem("hideFirstMockMessage");
  const [showMessage, setShowMessage] = useState(!hideMessage);

  const hideFirstMockHandler = useCallback(() => {
    setItem("hideFirstMockMessage", true);
    setShowMessage(false);
  }, []);

  return (
    showMessage && (
      <div className={messageContainer}>
        <div className={closeWrapper}>
          <Icon
            onClick={hideFirstMockHandler}
            style={closeStyle}
            name="close"
          />
        </div>

        <div className={infoContainer}>
          {!isMobile && (
            <div className={iconWrapper}>
              <TbInfoCircle style={iconStyle} size="50px" />
            </div>
          )}
          <div className={infoTextContainer}>
            <Header as="h4" style={headerStyle}>
              Está com dúvida no seu primeiro simulado?
            </Header>
            <Paragraph style={paragraphStyle}>
              Recomendamos que escolha o{" "}
              <strong className={highlightedTextStyle}>Primeiro módulo</strong>{" "}
              e <strong className={highlightedTextStyle}>apenas 10</strong>{" "}
              questões, desta forma você entenderá como o simulado funciona e
              também conseguirá calcular o tempo que dedicará para uma prova
              mais eficaz.
            </Paragraph>
          </div>
        </div>
      </div>
    )
  );
}

export default function MockModalForm(props) {
  const {
    course,
    onlyCalcRequired,
    onlyCalcNotRequired,
    courses,
    coursesReady,
    reinstatementHandler,
    handleClose,
    levelsForDropdownOptions,
    isMobile,
    hasMocks,
    courseUser,
    mockCreated,
  } = props;

  return (
    <Modal className={modalClass} open closeIcon onClose={handleClose}>
      <h2 className={modalTitleClass}>Simulado Personalizado</h2>

      {!hasMocks && !mockCreated && <FirstMockMessage isMobile={isMobile} />}

      <Form
        onSubmit={props.handleSubmit}
        error={props.error}
        className={container}
      >
        <div className={fieldsWrapper}>
          <DropDownField
            name="courseId"
            innerLabel="CURSO"
            options={makeDropDownOptions(courses)}
            noMessages
            disabled={!coursesReady}
            loading={!coursesReady}
            fluid
            required
          />
          {!courses?.length && coursesReady && (
            <Message
              content={
                <div className={messageClass}>
                  <AiOutlineExclamationCircle size={18} /> Atenção! Você não tem
                  cursos disponíveis para criar simulados.
                </div>
              }
              color="yellow"
              size="small"
            />
          )}
          {courseUser?.paused && (
            <Message
              content={
                <div className={messageClass}>
                  <AiOutlineExclamationCircle size={18} />
                  Atenção! O seu curso está trancado. Destranque-o para criar
                  novos simulados.{" "}
                  <Link onClick={reinstatementHandler}>
                    Clique aqui para destrancar.
                  </Link>
                </div>
              }
              color="red"
              size="small"
            />
          )}
          {courseUser?.trial && (
            <>
              {course?.meta?.mocksCount >= 1 ? (
                <Message
                  content={
                    <div className={messageClass}>
                      <AiOutlineExclamationCircle size={18} />
                      Atenção! Você não tem mais simulados disponíveis no
                      período de teste deste curso.
                    </div>
                  }
                  color="red"
                  size="small"
                />
              ) : (
                <Message
                  content={
                    <div className={messageClass}>
                      <AiOutlineExclamationCircle size={18} />
                      Atenção! Você só tem um simulado disponível no período de
                      teste deste curso.
                    </div>
                  }
                  color="blue"
                  size="small"
                />
              )}
            </>
          )}
          <div style={levelWrapper}>
            <FieldArray
              label="Módulos"
              name="levelIds"
              component={CheckBoxFieldArray}
              options={levelsForDropdownOptions}
              chosenOption={course}
              placeholder="Nenhum curso selecionado..."
              selectAllLabel="Selecionar todos"
            />
            {course && (
              <div style={totalQuestionsStyle}>
                Total de Questões: {course.questionsCount}
              </div>
            )}
          </div>
          <div className={questionQuantityWrapper}>
            <DropDownField
              name="quantity"
              label="Quantidade de Questões"
              labelLayout={labelLayout}
              options={quantityOptions}
              placeholder="10, 20, 30..."
              fluid
            />
          </div>
        </div>

        <Label style={examSettingsTitleStyle}>Configurações da prova</Label>

        {course && course.hasQuestionHighlighted && (
          <CheckBoxField
            className={checkboxClass}
            label="Apenas questões recorrentes"
            name="onlyHighlighted"
          />
        )}
        {course && course.hasQuestionCalcRequired && (
          <CheckBoxField
            className={checkboxClass}
            label="Apenas questões que requerem cálculo"
            name="onlyCalcRequired"
            disabled={onlyCalcNotRequired}
          />
        )}
        {course && course.hasQuestionCalcRequired && (
          <CheckBoxField
            className={checkboxClass}
            label="Apenas questões que não requerem cálculo"
            name="onlyCalcNotRequired"
            disabled={onlyCalcRequired}
          />
        )}
        {course?.hasQuestionIsFcc && (
          <CheckBoxField
            className={checkboxClass}
            label="Apenas questões FCC - Fundação Carlos Chagas"
            name="onlyIsFcc"
          />
        )}
        <CheckBoxField
          className={checkboxClass}
          label="Apenas questões que ainda não respondi"
          name="notAnswered"
        />
        <CheckBoxField
          className={checkboxClass}
          label="Reforçar meus pontos fracos com questões que eu errei"
          name="onlyAnsweredWrong"
        />
        <CheckBoxField
          className={checkboxClass}
          label="Modo prova"
          name="exam"
          hint="Você só poderá ver o resultado das questões após responder todas."
        />
        <MockModalButton buttonText="Iniciar Simulado" {...props} />
      </Form>
    </Modal>
  );
}
