import React from "react";
import ContinueCard from "./ContinueCard";

function calcMinutes(seconds) {
  return Math.ceil(seconds / 60);
}

export default function ContinueLessonCard({ lesson }) {
  return lesson ? (
    <ContinueCard
      title={lesson.courseName}
      subtitle={lesson.name}
      time={`${calcMinutes(
        lesson.duration * (lesson.progress / 100)
      )} de ${calcMinutes(lesson.duration)} min`}
      link={`/courses/${lesson.courseId}/lessons/${lesson.id}`}
      progressValue={lesson.progress}
    />
  ) : null;
}
