import React from "react";
import { Form } from "../library/forms";
import Button from "../library/Button";
import { css } from "glamor";
import { spacing } from "../library/theme";

const smallClass = css({
  height: 21.7,
  padding: `${spacing.xxs} ${spacing.s} !important`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export default function FollowForm(props) {
  return (
    <Form onSubmit={props.handleSubmit}>
      <Button
        type="submit"
        size="mini"
        className={css(props.small && smallClass).toString()}
        color={!!props.isFollower ? "blue" : "green"}
        disabled={props.submitting}
      >
        {!!props.isFollower ? "Seguindo" : "Seguir"}
      </Button>
    </Form>
  );
}
