import { apiGet, apiPost } from "./helpers/api";
import { normalize, schema } from "normalizr";

const termSchema = new schema.Entity("terms");

export const fetchTerms = (dispatch) => {
  apiGet("/api/v1/terms").then((response) => {
    dispatch({
      type: "TERMS_FETCHED",
      ...normalize(response.body.data, new schema.Array(termSchema)),
    });
  });
};

export const acceptTerm = (dispatch, termId, userId) => {
  return apiPost("/api/v1/users_terms")
    .send({ termId })
    .then(() =>
      dispatch({
        type: "USER_TERMS_ACCEPTED",
        userId,
      })
    );
};
