import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Banner from "./Banner";
import { getViewByViewable } from "../../selectors/viewSelectors";
import { clickView, createView } from "../../processes/viewProcesses";
import useOnClick from "../../utils/useOnClick";

function BannerContainer(props) {
  const { dispatch, banner, view, viewsLoading } = props;

  const url = !isLightbox && banner?.url;
  const isLightbox = banner?.type == "lightbox";
  const linkTarget = banner?.external ? "_blank" : "_self";

  const bannerRef = useRef(null);
  const [openLightbox, setOpenLightbox] = useState(false);

  const toggleLightbox = useCallback(() => {
    if (!isLightbox) return;
    setOpenLightbox(!openLightbox);
  }, [isLightbox, openLightbox]);

  useEffect(() => {
    if (!viewsLoading && !view) {
      createView(dispatch, {
        viewable_id: banner.id,
        viewable_type: "Banner",
      });
    }
  }, [dispatch, viewsLoading, banner, view]);

  const bannerClickHandler = useCallback(() => {
    if (!openLightbox) toggleLightbox();
    clickView(dispatch, view.id).finally(() => {
      if (!isLightbox) open(url, linkTarget);
    });
  }, [
    dispatch,
    view,
    isLightbox,
    toggleLightbox,
    openLightbox,
    url,
    linkTarget,
  ]);

  useOnClick(bannerClickHandler, bannerRef);

  return (
    <Banner
      isLightbox={isLightbox}
      openLightbox={openLightbox}
      toggleLightbox={toggleLightbox}
      linkTarget={linkTarget}
      ref={bannerRef}
      {...props}
    />
  );
}

function mapStateToProps(state, { banner }) {
  return {
    viewsLoading: state.viewList.loading,
    view: getViewByViewable(state, {
      viewableType: "Banner",
      viewableId: banner.id,
    }),
  };
}

export default connect(mapStateToProps)(BannerContainer);
