import { css } from "glamor";
import React, { useCallback, useState } from "react";
import { Menu } from "semantic-ui-react";
import {
  colors,
  Icon,
  Button,
  spacing,
} from "../library";
import TextInput from "../library/forms/TextInput";
import { useSelector } from "react-redux";
import { getCourseListByParams } from "../../selectors/courseSelectors";
import CourseListItemContainer from "../courses/CourseListItemContainer";

const FREE_SLUG = "free";

const container = css({ marginBottom: spacing.l });

const menuWrapperClass = css({
  position: "relative",
  marginBottom: spacing.m,
  "> .ui.menu": {
    marginBotom: "none !important",
  },
});

const menuClass = css({
  flexWrap: "wrap",
  gap: spacing.xxs,
  "@media(max-width: 767px)": {
    paddingBottom: spacing.s,
  },
});

const menuItemClass = css({
  padding: `${spacing.s} ${spacing.xs} !important`,
  ".active": {
    borderColor: `${colors.primary} !important`,
    fontWeight: "bold !important",
    "@media(max-width: 767px)": {
      borderBottom: "none !important",
      color: `${colors.primary} !important`,
    },
  },
});

const freeMenuItemClass = css({
  ".active": {
    borderColor: `#38CD58 !important`,
  },
});

const searchStyle = {
  marginBottom: spacing.m,
  marginLeft: "auto",
  height: 34,
};

const expandSearchButton = {
  position: "absolute",
  right: 0,
  top: 0,
  backgroundColor: colors.white,
  height: 34,
  width: 34,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const listClass = css({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(260px, 1fr))",
  gap: spacing.m,
  margin: "0 auto",
  width: "100%",
});

const CourseCategoryButton = (props) => {
  const { courseCategory, selectedCategory, setSelectedCategory } = props;
  const isFreeCourseCategory = courseCategory?.slug == FREE_SLUG;
  const changeSelectedCategory = useCallback(() => {
    setSelectedCategory(courseCategory);
  }, [courseCategory, setSelectedCategory]);

  return (
    <Menu.Item
      content={courseCategory?.name}
      active={courseCategory?.id == selectedCategory?.id}
      onClick={changeSelectedCategory}
      className={css(menuItemClass, isFreeCourseCategory && freeMenuItemClass)}
    />
  );
};

export default function CoursesList({ courseCategories = [], justOwned, justNotOwned }) {
  const [expandSearch, setExpandSearch] = useState(false);
  const toggleExpandSearchHandler = useCallback(
    () => setExpandSearch(!expandSearch),
    [expandSearch]
  );

  const [selectedCategory, setSelectedCategory] = useState(courseCategories[0]);

  const [search, setSearch] = useState("");
  const searchChangeHandler = useCallback(
    (_, { value }) => setSearch(value),
    []
  );

  const courses = useSelector((state) =>
    getCourseListByParams(state, {
      slug: selectedCategory?.slug,
      search,
      justOwned,
      justNotOwned,
    })
  );

  return (
    <div className={container}>
      <div className={menuWrapperClass}>
        <Menu
          secondary
          fluid
          stackable
          pointing
          className={menuClass}
          items={courseCategories.map((courseCategory) => (
            <CourseCategoryButton
              courseCategory={courseCategory}
              courseCategories={courseCategories}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          ))}
        />

        <Button
          icon
          onClick={toggleExpandSearchHandler}
          style={expandSearchButton}
        >
          <Icon name="search" />
        </Button>
      </div>

      {expandSearch && (
        <TextInput
          fluid
          placeholder="Pesquise por nome..."
          onChange={searchChangeHandler}
          style={searchStyle}
        />
      )}

      {!!courses.length ? (
        <div className={listClass}>
          {courses.map((course, index) => (
            <CourseListItemContainer key={index} course={course} />
          ))}
        </div>
      ) : null}
    </div>
  );
}
