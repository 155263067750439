import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { signOut } from "../../processes/sessionProcesses";
import { getCurrentUser } from "../../selectors/userSelectors";
import UserSidebar from "./UserSidebar";

export const UserSidebarContainer = (props) => {
  const { dispatch } = props;

  const [visible, setVisble] = useState(false);
  const toggleVisibility = useCallback(() => setVisble(!visible), [visible]);
  const signOutHandler = useCallback(() => signOut(dispatch), [dispatch]);

  return (
    <UserSidebar
      visible={visible}
      toggleVisibility={toggleVisibility}
      signOutHandler={signOutHandler}
      {...props}
    />
  );
};

const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
});

export default connect(mapStateToProps)(UserSidebarContainer);
