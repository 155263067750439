const getMocks = (action) => action.entities.mocks;

export default function mocksReducer(state = {}, action) {
  switch (action.type) {
    case "MOCK_CREATED":
    case "MOCK_FETCHED":
    case "MOCK_UPDATED":
    case "MOCKS_FETCHED":
    case "MOCK_RANKING_FETCHED":
    case "REPORT_FETCHED":
      return { ...state, ...getMocks(action) };
    default:
      return state;
  }
}
