import { css } from "glamor";
import React from "react";
import { IoDocumentTextOutline } from "react-icons/io5";
import { Pagination } from "semantic-ui-react";
import {
  Button,
  Link,
  Page,
  Spinner,
  colors,
  spacing,
  styles,
} from "../../library";
import MocksPageImage from "../../library/images/mocks-page.png";
import SmallMocksPageImage from "../../library/images/small-mocks-page.png";
import SurveyModalContainer from "../../surveys/SurveyModalContainer";
import EmptyMocks from "../../mocks/EmptyMocks";
import FilterFormContainer from "../../mocks/FilterFormContainer";
import MockListItemContainer from "./MockListItemContainer";

const container = css({
  textAlign: "center",
  padding: spacing.m,
});
const titleWrapper = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: spacing.m,
});
const titleClass = css(styles.alternativeFontFamily, {
  display: "flex",
  alignItems: "center",
  gap: spacing.s,
  fontSize: 24,
  fontWeight: "bold",
  color: colors.secondary,
  lineHeight: 1.5,
});
const table = css({
  width: "100%",
  borderCollapse: "separate",
  borderSpacing: `0 ${spacing.s}`,
});
const headerCell = css({
  backgroundColor: colors.white,
  fontWeight: "normal",
  color: colors.greyShadow,
  border: "none",
  "@media (max-width: 768px)": {
    display: "block",
    width: "100%",
  },
});
const activePaginationItem = {
  ...styles.cardShadow,
  borderRadius: "10px",
  background: colors.white,
};
const paginationClass = css({
  paddingBottom: spacing.m,
  borderBottom: "none !important",
  ".ui.secondary.pointing.menu .item": {
    border: "none",
    ":hover": {
      ...activePaginationItem,
      background: "transparent",
      border: `1px solid ${colors.grey}`,
    },
  },
  ".ui.secondary.pointing.menu .active.item": activePaginationItem,
});
const paginationWrapper = css({
  display: "flex",
  justifyContent: "center",
});
const buttonsWrapper = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: spacing.s,
  marginBottom: spacing.m,
});
const mocksImageContainer = css({
  position: "relative",
  paddingBottom: "46.25%",
  backgroundImage: `url(${MocksPageImage})`,
  backgroundPosition: "center",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  width: "100%",
  height: "500px",
});
const mocksImageContentWrapper = css({
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  top: "50%",
  right: "50%",
  transform: "translate(50%, -50%)",
  textAlign: "center",
  "> h2": {
    fontSize: 25,
  },
});
const mocksTextWrapper = css({
  display: "flex",
  flexDirection: "column",
  gap: spacing.s,
  marginTop: spacing.s,
  width: 300,
  textAlign: "center",
  marginBottom: 80,
  "> p": {
    color: "black !important",
  },
});
const createMockClass = css({
  display: "flex",
  alignItems: "center",
  border: `1px solid ${colors.grey}`,
  borderRadius: 10,
  height: 150,
  cursor: "pointer",
  ...styles.transition,
  ":hover": {
    outline: `1px solid ${colors.primary}`,
    transform: "scale(1.05)",
    ...styles.optionShadow,
  },
});
const createMockIconWrapper = css({
  backgroundColor: colors.greyLighter,
  padding: spacing.xl,
  paddingLeft: spacing.l,
  borderRadius: "10px 50% 50% 10px",
  width: 200,
  height: 150,
  zIndex: -1,
});
const createMockImageStyle = {
  position: "absolute",
  transform: "translate(-10px, -12px)",
};
const createMockHeaderStyle = {
  marginBottom: spacing.s,
  color: colors.black,
};
const createMockParagraphStyle = {
  color: colors.black,
  lineHeight: 1.3,
};
const listWrapper = css({
  marginTop: spacing.m,
});

const MockPaginatedList = ({
  mocks,
  currentPage,
  fetchMocksHandler,
  totalPages,
  hasMockCourses,
}) => {
  return mocks.length > 0 ? (
    <div className={listWrapper}>
      {mocks.map((mock) => (
        <MockListItemContainer
          key={mock.id}
          mock={mock}
          routePrefix="/mobile"
        />
      ))}

      <div className={paginationWrapper}>
        <Pagination
          className={paginationClass}
          firstItem={null}
          lastItem={null}
          pointing
          secondary
          activePage={currentPage}
          onPageChange={fetchMocksHandler}
          totalPages={totalPages}
        />
      </div>
    </div>
  ) : (
    <EmptyMocks hasMockCourses={hasMockCourses} />
  );
};

export default function MockList({
  mocks,
  currentPage,
  totalPages,
  fetchMocksHandler,
  loading,
  isFirstMock,
  hasMockCourses,
  isMonitor,
  hasMocks = true,
  mockCreated,
}) {
  return (
    <div className={container}>
      <div className={titleWrapper}>
        <div className={titleClass}>Simulados</div>
        <Link
          component={Button}
          to="/mobile/questions"
          color="outline"
          circular={false}
          size="small"
        >
          Buscar questões
        </Link>
      </div>

      <FilterFormContainer />
      <SurveyModalContainer type="first_mock" condition={isFirstMock} />
      {loading ? (
        <Spinner wrapped text="Carregando simulados" />
      ) : (
        <MockPaginatedList
          mocks={mocks}
          currentPage={currentPage}
          fetchMocksHandler={fetchMocksHandler}
          totalPages={totalPages}
          hasMockCourses={hasMockCourses}
        />
      )}
    </div>
  );
}
