import { createSelector } from "reselect";
import { canAccessContent } from "../selectors/contentSelectors";
import { statusString } from "../utils/statusString";

export const isNetworkingEnabled = createSelector(
  canAccessContent,
  (canAccessContent) => canAccessContent === statusString.allow
);

export const canAccessNetworking = createSelector(
  canAccessContent,
  (canAccessContent) => canAccessContent
);
