import { css } from "glamor";
import React from "react";
import Button from "../library/Button";
import Link from "../library/Link";
import { Form, TextField } from "../library/forms";
import { colors, spacing } from "../library/theme";
import LobbyLayout from "../lobby/LobbyLayout";
import EmailField from "../library/forms/EmailField";

const linkClass = css({
  color: colors.infoDark,
});
const buttonMargin = css({ margin: `${spacing.m} 0` });

const cardClass = css({
  margin: `${spacing.xl} auto 0`,
  width: "100%",
  maxWidth: "450px !important",
  alignSelf: "center",
  padding: `${spacing.l} ${spacing.xl}`,
  borderRadius: spacing.m,
  backgroundColor: "white",
  textAlign: "center",
  "@media(max-width: 515px)": {
    padding: `${spacing.l} ${spacing.m}`,
    margin: `${spacing.xl} ${spacing.m}`,
  },
});

export default function RecoveryPasswordForm(props) {
  return (
    <LobbyLayout centered>
      <div className={cardClass}>
        <Form
          onSubmit={props.handleSubmit}
          error={props.error}
          success={
            props.submitSucceeded && {
              header: "E-mail enviado!",
              content:
                "Enviamos as instruções para recuperar sua senha para seu e-mail",
            }
          }
        >
          <EmailField
            name="email"
            placeholder="E-mail"
            hint="Enviaremos as instruções para recuperar sua senha através do seu e-mail
          cadastrado."
            required
          />
          <div className={buttonMargin}>
            <Button
              secondary
              loading={props.submitting}
              disabled={props.pristine || props.submitting}
              size="big"
              fluid
            >
              Recuperar senha
            </Button>
          </div>
          <Link to="/signin" component="u" className={linkClass}>
            Voltar para o login
          </Link>
        </Form>
      </div>
    </LobbyLayout>
  );
}
