import { apiGet, apiPut } from "./helpers/api";
import { normalize, schema } from "normalizr";

const notificationSchema = new schema.Entity("notifications");
const notificationsUserSchema = new schema.Entity("notificationsUsers", {
  meta: {
    notification: notificationSchema,
  },
});

export const notificationsUserFetched = (dispatch, notificationUser) =>
  dispatch({
    type: "NOTIFICATIONS_USER_FETCHED",
    ...normalize(notificationUser, notificationsUserSchema),
  });

export const fetchNotificationsUsers = (dispatch) => {
  return apiGet("/api/v1/notifications_users").then((response) =>
    dispatch({
      type: "NOTIFICATIONS_USERS_FETCHED",
      ...normalize(
        response.body.data,
        new schema.Array(notificationsUserSchema)
      ),
    })
  );
};

export const readAllNotifications = (dispatch) => {
  return apiPut("/api/v1/notifications_users/read").then(() => {
    return fetchNotificationsUsers(dispatch);
  });
};
