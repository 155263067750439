import { normalize, schema } from "normalizr";
import { apiPost, apiPut, apiGet, apiDel } from "./helpers/api";

const annotation = new schema.Entity("annotations");

export const fetchAnnotations = (dispatch, courseid, lessonId) => {
  apiGet(`/api/v1/courses/${courseid}/lessons/${lessonId}/annotations`).then(
    (response) => {
      dispatch({
        type: "ANNOTATIONS_FETCHED",
        ...normalize(response.body.data, new schema.Array(annotation)),
      });
    }
  );
};

export const fetchCourseAnnotations = (dispatch, courseId) => {
  dispatch({ type: "COURSE_ANNOTATIONS_FETCH_REQUESTED" });

  return apiGet(`/api/v1/courses/${courseId}/annotations`).then((response) => {
    dispatch({
      type: "COURSE_ANNOTATIONS_FETCHED",
      ...normalize(response.body.data, new schema.Array(annotation)),
    });
  });
};

export const upsertAnnotation = (values, dispatch, props) => {
  const mutationCallback = values.id ? updateAnnotation : createAnnotation;

  return mutationCallback(values, dispatch, props);
};

export const updateAnnotation = (values, dispatch, props) => {
  return apiPut(`/api/v1/annotations/${values.id}`)
    .send({ annotation: values })
    .then((response) => {
      dispatch({
        type: "ANNOTATION_UPDATED",
        ...normalize(response.body.data, annotation),
      });
      props.reset();
      props.handleClose();
    });
};

export const createAnnotation = (values, dispatch, props) => {
  return apiPost(
    `/api/v1/courses/${values.courseId}/lessons/${values.lessonId}/annotations`
  )
    .send({ annotation: values })
    .then((response) => {
      dispatch({
        type: "ANNOTATION_CREATED",
        ...normalize(response.body.data, annotation),
      });
      props.reset();
      props.handleClose();
    });
};

export const deleteAnnotation = (dispatch, annotation) => {
  const { id, courseid, lessonId } = annotation;
  apiDel(
    `/api/v1/courses/${courseid}/lessons/${lessonId}/annotations/${id}`
  ).then(() => {
    dispatch({
      type: "ANNOTATION_DELETED",
      id,
    });
  });
};
