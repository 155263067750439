import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { change, formValueSelector } from "redux-form";
import { css } from "glamor";
import Button from "../Button";
import { spacing, styles } from "../theme";
import { Image } from "semantic-ui-react";

const container = css({
  position: "relative",
  maxWidth: 120,
});
const buttonStyle = {
  position: "absolute",
  padding: 0,
  borderRadius: "100%",
  height: 30,
  width: 30,
  top: spacing.xxs,
  right: spacing.xxs,
  margin: 0,
  zIndex: 1,
};

function freeMemory(url) {
  url && window.URL.revokeObjectURL(url);
}

export default function ImageFieldPreviewer({ formName, formFieldName }) {
  const formSelector = formValueSelector(formName);
  const value = useSelector((state) => formSelector(state, formFieldName));

  useEffect(() => () => freeMemory(objectUrl), [objectUrl]);

  let objectUrl = "";
  let previewUrl = "";
  if (typeof value === "string") {
    previewUrl = value;
  } else if (value) {
    objectUrl = previewUrl = URL.createObjectURL(value);
  }

  const dispatch = useDispatch();

  const clearHandler = useCallback(() => {
    dispatch(change(formName, formFieldName, null));
  }, [dispatch]);

  return previewUrl ? (
    <div className={container}>
      <Button
        type="button"
        icon="close"
        style={buttonStyle}
        onClick={clearHandler}
      />
      <Image src={previewUrl} size="small" rounded />
    </div>
  ) : null;
}
