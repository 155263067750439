import { createSelector } from "reselect";
import { getCurrentUser } from "./userSelectors";

const getTrails = (state) => state.entities.trails;

export const getAllTrails = createSelector(getTrails, (trails) =>
  Object.values(trails)
);

export const getCurrentTrail = createSelector(
  getAllTrails,
  getCurrentUser,
  (trails, user) => trails.find(({ id }) => id === user?.trailId) || trails[0]
);
