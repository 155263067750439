import React from "react";
import { Form, SearchField } from "../library/forms";

export default function CourseSearchLessonForm(props) {
  const { loading } = props;

  return (
    <Form onSubmit={props.handleSubmit} error={props.error}>
      <SearchField
        name="nameOrKeywordsCont"
        placeholder="Pesquisar por aula"
        loading={loading}
        fluid
      />
    </Form>
  );
}
