import React from "react";
import ContinueCard from "./ContinueCard";
import formatDate from "../../utils/formatDate";

export default function ContinueMockCard({ mock }) {
  return mock ? (
    <ContinueCard
      title={mock.title}
      subtitle={`${mock.questionsCount} Questões - Iniciado em ${formatDate(
        mock.createdAt,
        "DD/MM/yyyy"
      )}`}
      progress={`${parseInt(mock.progress)}% Concluído`}
      link={`/mocks/${mock.id}`}
      progressValue={parseInt(mock.progress)}
    />
  ) : null;
}
