import { createSelector } from "reselect";
import orderByPosition from "../utils/orderByPosition";

const getCourseFiles = (state) => state.entities.courseFiles;
const getId = (_, id) => id;

export const getAllCourseFiles = createSelector(getCourseFiles, (files) =>
  Object.values(files)
);

export const getFilesByCourseId = createSelector(
  getAllCourseFiles,
  getId,
  (files, id) =>
    files.filter((file) => file.courseId == id).sort(orderByPosition)
);

export const makeHasFilesByCourseId = createSelector(
  getAllCourseFiles,
  getId,
  (files, id) => !!files.filter((file) => file.courseId == id).length
);
