import { css } from "glamor";
import React, { createRef, useEffect, useCallback, useState } from "react";
import { Icon } from "semantic-ui-react";
import { colors, spacing } from "./theme";

const layout = css({
  overflowY: "hidden",
  marginBottom: spacing.xl,
});
const header = css({
  marginBottom: spacing.m,
  marginLeft: spacing.offset,
  marginRight: spacing.offset,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
const titleClass = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
const list = css({
  cursor: "pointer",
  width: "100%",
  display: "flex",
  position: "relative",
  overflowX: "scroll",
  overflowY: "hidden",
  whiteSpace: "nowrap",
  transition: "transform 0.3s ease",
  willChange: "transform",
  userSelect: "none",
  cursor: "pointer",
  paddingLeft: spacing.offset,
  paddingRight: spacing.offset,
  scrollbarWidth: "none",
  "::-webkit-scrollbar": {
    display: "none",
  },
});
const iconStyle = {
  marginRight: spacing.s,
  color: colors.primaryShadow,
};
const controls = css({ display: "flex" });
const controlIconClass = css({
  color: colors.primaryShadow,
  marginLeft: spacing.m,
  marginRight: spacing.m,
  "&:hover": {
    transform: "scale(1.2)",
    cursor: "pointer",
    transition: "all 0.2s",
  },
});
const iconDisabled = {
  cursor: "auto",
  color: colors.greyLight,
  pointerEvents: "none",
};
const transitionStyle = { opacity: 0, transition: "opacity 0.3s ease-out" };

const SCROLL_OFFSET = 340;

export default function HorizontalList({
  data = [],
  renderItem,
  title,
  hideHeader,
}) {
  const listRef = createRef();
  const [isInitialPosition, setIsInitialPosition] = useState(true);
  const [isScrollEnded, setIsScrollEnded] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (isAnimating) return;

    setIsAnimating(true);
    const timeout = setTimeout(() => {
      setIsAnimating(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, [data]);

  const moveToLeft = useCallback(() => {
    if (!listRef.current) return;
    listRef.current.scrollTo({
      left: listRef.current.scrollLeft + SCROLL_OFFSET,
      behavior: "smooth",
    });
  }, [listRef]);

  const moveToRight = useCallback(() => {
    if (!listRef.current) return;
    listRef.current.scrollTo({
      left: listRef.current.scrollLeft - SCROLL_OFFSET,
      behavior: "smooth",
    });
  }, [listRef]);

  useEffect(() => {
    if (!listRef.current) return;
    const listEl = listRef.current;
    let isScrolling = false;
    let startX = 0;

    listEl.addEventListener("scroll", () => {
      setIsInitialPosition(listEl.scrollLeft === 0);
      setIsScrollEnded(
        listEl.scrollLeft + listEl.offsetWidth === listEl.scrollWidth
      );
    });

    listEl.addEventListener("mousedown", (e) => {
      isScrolling = true;
      startX = e.pageX - listEl.offsetLeft;
    });

    listEl.addEventListener("mouseup", () => {
      isScrolling = false;
    });

    listEl.addEventListener("mouseleave", () => {
      isScrolling = false;
    });

    listEl.addEventListener("mousemove", (e) => {
      if (!isScrolling) return;
      const x = e.pageX - listEl.offsetLeft;
      const walk = x - startX;
      listEl.scrollLeft = listEl.scrollLeft - walk;
    });

    return () => {
      listEl.removeEventListener("scroll", () => {});
      listEl.removeEventListener("mousedown", () => {});
      listEl.removeEventListener("mouseup", () => {});
      listEl.removeEventListener("mouseleave", () => {});
      listEl.removeEventListener("mousemove", () => {});
    };
  }, [listRef]);

  const leftIconClass = css(
    controlIconClass,
    isInitialPosition && iconDisabled
  );
  const rightIconClass = css(controlIconClass, isScrollEnded && iconDisabled);

  return (
    <div className={layout}>
      {!hideHeader && (
        <div className={header}>
          <div className={titleClass}>
            <Icon name="student" size="large" style={iconStyle} />
            <h2>{title}</h2>
          </div>

          <div className={controls}>
            <Icon
              name="angle left"
              size="large"
              className={leftIconClass}
              onClick={moveToRight}
            />
            <Icon
              name="angle right"
              size="large"
              className={rightIconClass}
              onClick={moveToLeft}
            />
          </div>
        </div>
      )}
      <div ref={listRef} className={css(list, isAnimating && transitionStyle)}>
        {data.map(renderItem)}
      </div>
    </div>
  );
}
