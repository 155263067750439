import React, { useCallback } from "react";
import { Table, Icon } from "semantic-ui-react";
import { spacing, colors } from "../library/theme";
import { css } from "glamor";

const cellWrapperClass = css({
  cursor: "pointer !important",
  ":hover": {
    "> div, > div > p": {
      color: `${colors.primary} !important`,
      transition: "color 0.2s ease-in-out !important",
    },
  },
});
const selectedClass = css({
  color: `${colors.primaryDarker} !important`,
  "> p": { color: `${colors.primaryDarker} !important` },
});
const cellClass = css({
  display: "flex !important",
  alignItems: "center !important",
  gap: spacing.xxs,
  "> p": {
    color: `${colors.black} !important`,
    fontWeight: "bold !important",
    whiteSpace: "break-spaces !important",
    lineHeight: "1.3 !important",
  },
  "> i": { height: "auto !important" },
});

export default function CustomTableHeaderCell({
  content,
  columnName,
  sort,
  order,
  toggleOrder,
  setSort,
  textAlign = "center",
}) {
  const isSelected = sort == columnName;
  const orderIconSufix = order == "asc" ? "up" : "down";
  const sortIcon = isSelected ? orderIconSufix : null;

  const clickHandler = useCallback(() => {
    if (sort == columnName) {
      isSelected && toggleOrder();
    } else {
      setSort(columnName);
      order == "desc" && toggleOrder();
    }
  }, [isSelected, toggleOrder, setSort, columnName, order]);

  const sortableCellClass = css(cellClass, isSelected && selectedClass, {
    justifyContent: textAlign,
    textAlign,
  });

  return (
    <Table.HeaderCell className={cellWrapperClass} onClick={clickHandler}>
      <div className={sortableCellClass}>
        <p>{content}</p>
        <Icon name={sortIcon ? `sort ${sortIcon}` : "sort"} />
      </div>
    </Table.HeaderCell>
  );
}
