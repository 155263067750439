const getLikes = (action) => action.entities.likes;

export default function likesReducer(state = {}, action) {
  let newState;
  switch (action.type) {
    case "LIKES_FETCHED":
    case "LIKE_CREATED":
      return { ...state, ...getLikes(action) };
    case "LIKE_DELETED":
      newState = { ...state };
      if (state[action.id]) {
        delete newState[action.id];
      }
      return newState;
    default:
      return state;
  }
}
