import { connect } from "react-redux";
import { getCourseCategoriesBySlugs } from "../../selectors/configSelectors";
import TrailCourseListItem from "./TrailCourseListItem";

function mapStateToProps(state, ownProps) {
  const { course } = ownProps;

  return {
    courseCategories: getCourseCategoriesBySlugs(state, course?.categories),
  };
}

export default connect(mapStateToProps)(TrailCourseListItem);
