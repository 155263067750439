import React from "react";
import { Form, DropDownField } from "../library/forms";
import makeDropDownOptions from "../../utils/makeDropDownOptions";

export default function FilterForm(props) {
  return (
    <Form onSubmit={props.handleSubmit} error={props.error}>
      <DropDownField
        placeholder="Filtrar por categoria"
        name="courseCategoriesIdIn"
        options={makeDropDownOptions(props.courseCategories)}
        clearable
        selection
        fluid
      />
    </Form>
  );
}
