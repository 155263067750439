import { css } from "glamor";
import React from "react";
import Button from "../library/Button";
import Link from "../library/Link";
import { Form, TextField } from "../library/forms";
import { colors, spacing } from "../library/theme";
import LobbyLayout from "../lobby/LobbyLayout";
import EmailField from "../library/forms/EmailField";

const submitButton = {
  margin: `${spacing.l} 0`,
};
const linkClass = css({
  color: colors.infoDark,
});
const cardClass = css({
  margin: `${spacing.xl} auto 0`,
  width: "100%",
  maxWidth: "450px !important",
  alignSelf: "center",
  padding: `${spacing.l} ${spacing.xl}`,
  borderRadius: spacing.m,
  backgroundColor: "white",
  textAlign: "center",
});

export default function ResendConfirmationEmailForm(props) {
  const submitMessage = props.error ? { content: props.error } : null;

  return (
    <LobbyLayout>
      <Form
        onSubmit={props.handleSubmit}
        success={submitMessage}
        className={cardClass}
      >
        <EmailField
          name="email"
          placeholder="E-mail"
          hint="Enviaremos o link para confirmar sua conta através do seu e-mail."
          disabled={props.submitSucceeded}
          required
        />

        <Button
          style={submitButton}
          secondary
          loading={props.submitting}
          disabled={props.pristine || props.submitting || props.submitSucceeded}
          size="big"
          fluid
        >
          Reenviar e-mail de confirmação
        </Button>

        <Link to="/signin" component="u" className={linkClass}>
          Voltar para o login
        </Link>
      </Form>
    </LobbyLayout>
  );
}
