import { useEffect } from "react";

export default function useOnClick(handler, ref) {
  useEffect(() => {
    const component = ref.current;
    component.addEventListener("click", handler);

    return () => {
      component.removeEventListener("click", handler);
    };
  }, [handler]);
}
