const getQuestions = (action) => action.entities.questions;

export default function questionsReducer(state = {}, action) {
  switch (action.type) {
    case "QUESTIONS_SEARCH_FETCHED":
    case "QUESTIONS_FETCHED":
    case "LESSONS_FETCHED":
      return { ...state, ...getQuestions(action) };
    default:
      return state;
  }
}
