const getReplies = (action) => action.entities.replies;

export default function repliesReducer(state = {}, action) {
  switch (action.type) {
    case "REPLIES_FETCHED":
      return { ...state, ...getReplies(action) };
    default:
      return state;
  }
}
