import { css } from "glamor";
import React from "react";
import { Header, Image } from "semantic-ui-react";
import { Link, colors, spacing, styles } from "../../library";
import GraduationCapIcon from "../../library/images/graduation-cap-icon.svg";
import SliderIcon from "../../library/images/slider-icon.svg";
import Paragraph from "../../library/Paragraph";

const createMockClass = css(styles.transition, {
  display: "flex",
  alignItems: "center",
  border: `1px solid ${colors.grey}`,
  borderRadius: spacing.m,
  height: 150,
  cursor: "pointer",
  overflow: "hidden",
  ":hover": {
    outline: `1px solid ${colors.primary}`,
    transform: "scale(1.05)",
    ...styles.optionShadow,
  },
});
const createMockIconWrapper = css({
  backgroundColor: "#DEE5F680",
  padding: spacing.xl,
  paddingLeft: spacing.l,
  borderRadius: "10px 50% 50% 10px",
  width: 200,
  height: 150,
  zIndex: -1,
});
const createMockImageStyle = {
  position: "absolute",
  transform: "translate(-10px, -12px)",
};
const createMockHeaderStyle = {
  color: colors.black,
  fontWeight: "bold",
  textAlign: "left",
  marginBottom: 0,
};
const createMockParagraphStyle = {
  marginTop: 0,
  color: colors.black,
  lineHeight: 1.3,
  textAlign: "left",
  lineHeight: spacing.m,
};
const createMockTextContainer = css({ padding: spacing.s });

export default function MockCreateLinkButton({ to, image, title, subtitle }) {
  return (
    <Link to={to} className={createMockClass}>
      <div className={createMockIconWrapper}>
        <Image style={createMockImageStyle} src={SliderIcon} />
      </div>

      <div className={createMockTextContainer}>
        <Header as="h4" style={createMockHeaderStyle}>
          {title}
        </Header>
        <Paragraph style={createMockParagraphStyle}>{subtitle}</Paragraph>
      </div>
    </Link>
  );
}
