import React from "react";
import { css } from "glamor";
import { colors, spacing, uiColors } from "../library/theme";
import { Accordion } from "semantic-ui-react";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import LessonListItemContainer from "./LessonListItemContainer";

const accordionClass = css({
  backgroundColor: colors.white,
  marginTop: "0 !important",
  borderTop: `solid 1px ${uiColors.border}`,
});

const accordionTitleClass = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: `${spacing.m} !important`,
  paddingRight: `${spacing.m} !important`,
  backgroundColor: "#ececec",
});

const titleClass = css({
  color: colors.blueDark,
  textTransform: "uppercase",
  fontWeight: 500,
});

const accordionContentClass = css({
  borderTop: `solid 1px ${uiColors.border}`,
  paddingTop: "0 !important",
});

const iconWrapper = { minWidth: 26 };

const iconClass = css({
  display: "inline",
  color: colors.blueDark,
  verticalAlign: "middle",
});

export default function SubLevelListItem({
  level,
  lessons,
  handleAccordionClick,
  accordionActive,
}) {
  const AccordionIcon = accordionActive ? BiChevronDown : BiChevronRight;

  return (
    <Accordion className={accordionClass}>
      <Accordion.Title
        className={accordionTitleClass}
        onClick={handleAccordionClick}
      >
        <p className={titleClass}>{level.name}</p>
        <div style={iconWrapper}>
          <AccordionIcon size={26} className={iconClass} />
        </div>
      </Accordion.Title>

      <Accordion.Content
        className={accordionContentClass}
        active={accordionActive}
      >
        {lessons?.map((lesson) => (
          <LessonListItemContainer key={lesson.id} lesson={lesson} />
        ))}
      </Accordion.Content>
    </Accordion>
  );
}
