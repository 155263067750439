import { connect } from "react-redux";
import CourseContents from "./CourseContents";
import { getLevelsFromCurrentCourse } from "../../selectors/levelSelectors";

function mapStateToProps(state) {
  return {
    levels: getLevelsFromCurrentCourse(state),
    loading: state.coursesList.loading,
  };
}

export default connect(mapStateToProps)(CourseContents);
