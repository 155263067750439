import React from "react";

export const allowList = [
  "/reset-password",
  "/signup",
  "/impersonate",
  "/valide-seu-certificado",
  "/resend-confirmation-email",
  "/forgotten-password",
  "/courses/:courseId/checkout",
  "/courseOffers/:courseOfferId",
];

export default function RequireSignIn(props) {
  const { history, location } = props;
  React.useEffect(() => {
    const isAllowed = allowList.some((path) => {
      if (path.includes(":")) {
        const regex = new RegExp(
          "^" + path.replace(/:[^\s/]+/g, "[^\\s/]+") + "$"
        );
        return regex.test(location.pathname);
      } else {
        return path === location.pathname;
      }
    });

    if (!isAllowed) {
      history.push(`/signin`);
    }
  }, [history, location.pathname]);

  return <div />;
}
