const initialState = {
  states: {},
  cities: {},
  cookiesPolicyUrl: "",
  privacyPolicyUrl: "",
  contentCategories: [],
  phoneCountryCodes: {},
  defaultGateway: "PAGARME",
};

const getConfigs = (action) => action.entities;

export default function configsReducer(state = initialState, action) {
  switch (action.type) {
    case "CITIES_FETCHED":
      return {
        ...state,
        cities: { ...state.cities, ...action.entities.cities },
      };
    case "PUBLIC_CONFIGS_FETCHED":
    case "CONFIGS_FETCHED":
      return {
        ...state,
        ...getConfigs(action),
        cookiesPolicyUrl: action.cookiesPolicyUrl,
        privacyPolicyUrl: action.privacyPolicyUrl,
        privacyWarningUrl: action.privacyWarningUrl,
        defaultGateway: action.defaultGateway,
      };
    default:
      return state;
  }
}
