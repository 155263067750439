import { css } from "glamor";
import React, { useCallback, useEffect, useState } from "react";
import { spacing, colors, styles } from "../library/theme";
import Icon from "../library/Icon";
import { makeIsMobile } from "../../utils/useScreenWidth";
import Button from "../library/Button";
import Link from "../library/Link";
import Spinner from "../library/Spinner";
import VimeoPlayer from "../library/VimeoPlayer";
import ScaleupPlayer from "../library/ScaleupPlayer";

const countdownClass = css({
  aspectRatio: "16 / 9",
  width: "100%",
  backgroundColor: colors.brand,
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "> h1, > h2": {
    color: colors.white,
  },
});
const playerWrapperClass = css({
  borderTopLeftRadius: spacing.xs,
  borderTopRightRadius: spacing.xs,
  background: colors.greyClear,
  width: "100%",
  aspectRatio: "16 / 9",
});
const videoActionsClass = css({
  display: "flex",
  alignItems: "start",
  gap: spacing.m,
  flexFlow: "wrap",
  padding: spacing.m,
  backgroundColor: colors.greyClear,
  "@media(max-width: 575px)": { justifyContent: "center" },
});
const videoNavWrapper = css({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginLeft: "auto",
  gap: spacing.s,
  "@media(max-width: 575px)": { flex: 1, justifyContent: "center" },
});
const videoNavButtonStyle = {
  backgroundColor: "transparent",
  border: `1px solid ${colors.primaryShadow}`,
  color: colors.primaryShadow,
  textTransform: "uppercase",
};
const isPlayingStyle = {
  minWidth: 144,
};
const watchedButtonStyle = {
  textTransform: "uppercase !important",
  borderRadius: "25px !important",
  backgroundColor: "auto",
};
const unwatchedIconStyle = {
  backgroundColor: `${colors.secondary} !important`,
  color: colors.primary,
};

export default function (props) {
  const {
    progress,
    handleProgress,
    isPlaying,
    toogleIsPlaying,
    countdown,
    user,
    course,
    lesson,
    prevLesson,
    nextLesson,
    isWatched,
    handleWatch,
    isFinished,
    handleFinish,
    canAccessCourse,
    playerOption,
  } = props;
  const isMobile = makeIsMobile();
  const showTogglePlayingButton =
    !course?.videoControl && lesson?.player != "scaleup";

  const hasScaleupUrl = !!lesson?.scaleupUrl || !!lesson?.demoScaleupUrl;
  const showScaleupPlayer = hasScaleupUrl && playerOption == "scaleup";

  return (
    <div>
      {isFinished ? (
        <>
          {countdown > 0 && (
            <div className={countdownClass}>
              <h2>Próxima em...</h2>
              <h1>{countdown}</h1>
            </div>
          )}
        </>
      ) : (
        <div className={playerWrapperClass}>
          {showScaleupPlayer ? (
            <ScaleupPlayer
              url={
                canAccessCourse ? lesson?.scaleupUrl : lesson?.demoScaleupUrl
              }
              onProgress={handleProgress}
              onFinish={handleFinish}
              progress={progress}
              controls={course?.videoControl}
              watermarkText={`${user?.name} - ${user?.document}`}
            />
          ) : (
            <VimeoPlayer
              url={canAccessCourse ? lesson?.videoUrl : lesson?.demoUrl}
              onProgress={handleProgress}
              onFinish={handleFinish}
              progress={progress}
              controls={course?.videoControl}
              playing={isPlaying}
              roundedTop
            />
          )}
        </div>
      )}

      <div className={videoActionsClass}>
        <div className={videoNavWrapper}>
          <Link
            to={`/courses/${course?.id}/lessons/${prevLesson?.id}`}
            component={Button}
            disabled={!prevLesson}
            style={videoNavButtonStyle}
            size={isMobile ? "large" : "mini"}
          >
            <Icon name="caret left" />
            {!isMobile && "Aula Anterior"}
          </Link>

          {course?.watchedButtonEnabled && canAccessCourse && (
            <Button
              onClick={handleWatch}
              disabled={isWatched}
              secondary={!isWatched}
              style={watchedButtonStyle}
              size={isMobile ? "small" : "large"}
            >
              {isWatched && <Icon name="check" style={unwatchedIconStyle} />}
              {isWatched ? "Aula Concluída" : "Concluir Aula"}
            </Button>
          )}

          <Link
            to={`/courses/${course?.id}/lessons/${nextLesson?.id}`}
            component={Button}
            disabled={!nextLesson}
            style={videoNavButtonStyle}
            size={isMobile ? "large" : "mini"}
          >
            {!isMobile && "Próxima Aula"}
            <Icon name="caret right" />
          </Link>
        </div>

        {showTogglePlayingButton && (
          <Button onClick={toogleIsPlaying} style={isPlayingStyle}>
            <Icon name={isPlaying ? "play" : "pause"} />
            {isPlaying ? "Pausar" : "Reproduzir"}
          </Button>
        )}
      </div>
    </div>
  );
}
