import { css } from "glamor";
import React, { useCallback } from "react";
import Button from "../library/Button";
import Link from "../library/Link";
import { Form, TextField } from "../library/forms";
import { colors, spacing } from "../library/theme";
import LobbyLayout from "../lobby/LobbyLayout";

const linkClass = css({
  color: colors.infoDark,
});
const buttonMargin = css({ margin: `${spacing.l} 0` });
const cardClass = css({
  margin: `${spacing.xl} auto 0`,
  width: "100%",
  maxWidth: "450px !important",
  alignSelf: "center",
  padding: `${spacing.l} ${spacing.xl}`,
  borderRadius: spacing.m,
  backgroundColor: "white",
  textAlign: "center",
});

export default function SignInForm(props) {
  const skipActionHandler = useCallback((e) => {
    e.preventDefault();
  }, []);

  return (
    <LobbyLayout>
      <Form
        onSubmit={props.handleSubmit}
        error={props.error}
        className={cardClass}
        success={
          props.submitSucceeded && {
            header: "Deu certo!",
            content: "Sua senha foi alterada",
          }
        }
      >
        <TextField
          name="password"
          type="password"
          label="Nova Senha"
          placeholder="**************"
          required
          fluid
        />
        <TextField
          name="passwordConfirmation"
          type="password"
          label="Confirme a Nova Senha"
          placeholder="**************"
          onPaste={skipActionHandler}
          onDrop={skipActionHandler}
          required
          fluid
        />
        <div className={buttonMargin}>
          <Button
            secondary
            disabled={props.pristine || props.submitting}
            size="big"
            fluid
          >
            Criar nova senha
          </Button>
        </div>
        <Link to="/signin" component="u" className={linkClass}>
          Voltar para o login
        </Link>
      </Form>
    </LobbyLayout>
  );
}
