import { createSelector } from "reselect";
import { statusString } from "../utils/statusString";
import { getCurrentUser } from "./userSelectors";

const { allow, deny, loading } = statusString;

const getCompanies = (state) => state.entities.companies;
const getClassrooms = (state) => state.entities.classrooms;
const getRouteCompanyId = (state) =>
  state.router.location.pathname.split("/")[2];
const getId = (_, id) => id;
const getProps = (_, props) => props;

export const getAllCompanies = createSelector(getCompanies, (companies) =>
  Object.values(companies)
);

export const canAccessCompany = createSelector(
  getCurrentUser,
  getRouteCompanyId,
  (currentUser, companyId) => {
    if (!currentUser) return loading;

    if (currentUser?.meta?.companies?.includes(+companyId)) {
      return allow;
    }

    return deny;
  }
);

export const getCompanyById = createSelector(
  getCompanies,
  getId,
  (companies, id) => companies[id]
);

export const hasCompanies = createSelector(
  getAllCompanies,
  (companies) => !!companies.length
);

export const getUsersBySearch = createSelector(
  getCompanies,
  getProps,
  getClassrooms,
  (
    companies,
    { companyId, search, sort = "name", order = "asc" },
    classrooms
  ) => {
    const company = companies[companyId];

    if (!company?.meta) return [];

    return classroom?.meta?.users
      ?.filter((user) => filterBySearchFields(user, search, ["name", "email"]))
      ?.sort((a, b) => sortBy(a, b, sort, order));
  }
);

export const getUsersTopTenByProps = createSelector(
  getCompanies,
  getProps,
  getClassrooms,
  (companies, { companyId, value, order = "desc" }, classrooms) => {
    const company = companies[companyId];

    if (!company?.meta) return [];

    const users = company?.meta?.classrooms?.flatMap(
      (classroomId) => classrooms[classroomId]?.meta?.users || []
    );

    const isDescOrder = order == "desc";

    const ranking = users
      ?.sort((a, b) =>
        isDescOrder ? b[value] - a[value] : a[value] - b[value]
      )
      ?.map((user, index) => {
        const rank = isDescOrder ? index + 1 : users?.length - index;

        return { rank, name: user.name, value: user[value] };
      })
      ?.slice(0, 10);

    return isDescOrder ? ranking : ranking.reverse();
  }
);

export default function sortBy(prev, next, sort, order = "asc") {
  const valueA = order == "asc" ? prev[sort] : next[sort];
  const valueB = order == "asc" ? next[sort] : prev[sort];

  if (DATES_TO_SORT.includes(sort)) return compareDate(valueA, valueB, order);
  if (typeof valueA === "string") return valueA.localeCompare(valueB);

  return valueA - valueB;
}

export const getMocksAverage = createSelector(
  getClassrooms,
  getProps,
  getCompanies,
  (classrooms, { companyId, courseId }, companies) => {
    const companyClassrooms = Object.values(classrooms).filter(
      (classroom) =>
        companies[companyId]?.meta?.classrooms.includes(classroom.id) &&
        classroom.courseId === courseId
    );

    const mocksAverageSum = companyClassrooms?.reduce((acc, classroom) => {
      return acc + (classroom.meta.mocksAverage || 0);
    }, 0);

    return parseInt(mocksAverageSum / companyClassrooms?.length);
  }
);

export const getTotalStudents = createSelector(
  getClassrooms,
  getProps,
  getCompanies,
  (classrooms, { companyId, courseId }, companies) => {
    const companyClassrooms = Object.values(classrooms).filter((classroom) => {
      const company = companies[companyId];

      return (
        company?.meta?.classrooms.includes(classroom.id) &&
        classroom.courseId == courseId
      );
    });

    return companyClassrooms?.reduce((acc, classroom) => {
      return acc + (classroom.studentCount || 0);
    }, 0);
  }
);
