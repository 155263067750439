import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import MockRanking from "./MockRanking";
import {
  getCurrentMock,
  getMocksForRanking,
} from "../../../selectors/mockSelectors";
import { fetchMockRanking } from "../../../processes/mockProccess";

function MockRankingContainer(props) {
  const { dispatch, mock, history } = props;
  const [loading, setLoading] = useState(false);
  const finishedAt = mock?.finishedAt;
  const exam = mock?.exam;

  useEffect(() => {
    if (finishedAt && !exam) {
      setLoading(true);
      fetchMockRanking(dispatch, mock?.id).then(() => {
        setLoading(false);
      });
    } else history?.goBack();
  }, [dispatch, finishedAt, history?.goBack]);

  return <MockRanking loading={loading} {...props} />;
}

function mapStateToProps(state) {
  const mock = getCurrentMock(state);

  return {
    mock,
    mocks: getMocksForRanking(state, mock?.mockTemplateId),
  };
}

export default connect(mapStateToProps)(MockRankingContainer);
