import React from "react";
import { Form } from "../library/forms";
import SearchField from "../library/forms/SearchField";

export default function QuestionSearchForm(props) {
  return (
    <Form onSubmit={props.handleSubmit} error={props.error}>
      <SearchField
        placeholder="Digite o título ou código da questão"
        name="search"
        clearable
        fluid
        loading={props.loading}
        disabled={props.disabled}
      />
    </Form>
  );
}
