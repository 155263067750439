import { createSelector } from "reselect";
import { orderByFirstName } from "../utils/orderByName";

export const getCurrentUserId = (state) => state.currentUserId;
const getUsers = (state) => state.entities.users;
const getNetworkingUserIds = (state) => state.networking.userIds;
const getContentMostActiveUserIds = (state) => state.content.mostActiveUserIds;
const getId = (_, id) => id;
const getCurrentCourseId = (state) => state.currentCourseId;

export const getCurrentUser = createSelector(
  getUsers,
  getCurrentUserId,
  (users, id) => users[id]
);

export const getNetworkingUsers = createSelector(
  getUsers,
  getNetworkingUserIds,
  getCurrentUserId,
  (users, ids, id) => {
    return Object.values(users)
      .sort(orderByFirstName)
      .filter((user) => ids.includes(user.id) && user.id !== id);
  }
);

export const getUserById = createSelector(
  getUsers,
  getId,
  (users, id) => users[id]
);

export const getUserCourseNames = createSelector(getUserById, (user) =>
  user?.courseNames && user.courseNames.length > 1 ? user.courseNames : null
);

export const getFollow = createSelector(
  getCurrentUser,
  getId,
  (currentUser, userId) => !!currentUser?.followedIds?.includes(userId)
);

export const getMostActiveUsers = createSelector(
  getUsers,
  getContentMostActiveUserIds,
  (users, mostActiveUserIds) =>
    mostActiveUserIds?.map((userId) => users[userId])
);

export const getUserCourseCertificationExam = createSelector(
  getCurrentUser,
  getCurrentCourseId,
  (user, courseId) =>
    user.meta.certificationExams.find(
      (certificationExam) => certificationExam.courseId == courseId
    )
);
