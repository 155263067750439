import { css } from "glamor";
import React, { useMemo } from "react";
import { HiOutlineChartBar } from "react-icons/hi";
import { matchPath } from "react-router";
import Link from "../library/Link";
import Popup from "../library/Popup";
import {
  activeItemClass,
  disabledColorClass,
  expandedItemClass,
  sidebarItemClass,
} from "./AppSidebar";

const enabledLinkContentStyle = { fontSize: "1rem", color: "rgba(0,0,0,.87)" };
const disabledLinkContentStyle = { fontWeight: "bold" };

export default function MyPerformanceSidebarItem(props) {
  const { hasMockCourses, isExpanded, pathname, isMobile } = props;
  const linkClass = css(
    sidebarItemClass,
    !hasMockCourses && disabledColorClass,
    isExpanded && expandedItemClass,
    !!matchPath(pathname, { path: "/my-performance" }) && activeItemClass
  );

  const content = useMemo(
    () => (
      <p>
        {hasMockCourses ? (
          <span style={enabledLinkContentStyle}>Meu Desempenho</span>
        ) : (
          <>
            {!isExpanded && (
              <span style={disabledLinkContentStyle}>Meu Desempenho: </span>
            )}
            Necessário acesso a algum curso!
          </>
        )}
      </p>
    ),
    [isExpanded, hasMockCourses]
  );

  return (
    <Popup
      content={content}
      disabled={(hasMockCourses && isExpanded) || isMobile}
      position="right center"
      trigger={
        <Link
          to={hasMockCourses ? "/my-performance" : pathname}
          className={linkClass}
        >
          <HiOutlineChartBar size={20} /> {isExpanded ? "Meu Desempenho" : null}
        </Link>
      }
    />
  );
}
