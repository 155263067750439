import React, { useCallback } from "react";
import { connect } from "react-redux";
import { getReplyByComment } from "../../selectors/commentSelectors";
import CommentListItem from "./CommentListItem";
import { deleteComment } from "../../processes/commentProcesses";

function CommentListItemContainer(props) {
  const { dispatch, comment } = props;

  const deleteHandler = useCallback(() => {
    deleteComment(dispatch, comment.id);
  }, [dispatch, comment]);

  return <CommentListItem deleteHandler={deleteHandler} {...props} />;
}

function mapStateToProps(state, ownProps) {
  const { comment } = ownProps;

  return {
    replies: getReplyByComment(state, comment.id),
    currentUserId: state.currentUserId,
  };
}

export default connect(mapStateToProps)(CommentListItemContainer);
