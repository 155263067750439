const initialState = {
  commentIds: [],
  totalPages: 0,
  currentPage: 1,
  loading: false,
};

const getCommentsFetchedIds = (action) =>
  action.result ? action.result : initialState.commentIds;

export default function commentListReducer(state = initialState, action) {
  switch (action.type) {
    case "COMMENTS_FETCH_REQUESTED":
      return { ...state, loading: true };
    case "COMMENTS_FETCHED":
      return {
        ...state,
        commentIds: getCommentsFetchedIds(action),
        totalPages: action.totalPages,
        currentPage: action.currentPage,
        loading: false,
      };
    case "COMMENT_CREATED":
      return {
        ...state,
        commentIds: [...state.commentIds, action.result],
      };
    default:
      return state;
  }
}
