import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { createLessonRating } from "../../processes/ratingsProcesses";
import { getRatingByProps } from "../../selectors/ratingSelectors";
import LessonRatingForm from "./LessonRatingForm";
import { fetchRatings } from "../../processes/ratingsProcesses";

const RATINGABLE_TYPE = "Lesson";

function LessonRatingFormContainer(props) {
  const {
    dispatch,
    rating,
    ratingableType,
    ratingableId,
    ratingQuestionId,
    userId,
    open,
  } = props;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      !rating &&
      ratingableType &&
      ratingableId &&
      ratingQuestionId &&
      userId
    ) {
      setLoading(true);
      fetchRatings(dispatch, {
        ratingableTypeEq: ratingableType,
        ratingableIdEq: ratingableId,
        ratingQuestionIdEq: ratingQuestionId,
        userIdEq: userId,
      }).then(() => {
        setLoading(false);
      });
    }
  }, [
    dispatch,
    rating,
    ratingableType,
    ratingableId,
    ratingQuestionId,
    userId,
  ]);

  return loading || rating || !open ? null : <LessonRatingForm {...props} />;
}

function mapStateToProps(state, { ratingQuestionId }) {
  const ratingableId = state.currentLessonId;
  const userId = state.currentUserId;

  return {
    initialValues: {
      ratingableType: RATINGABLE_TYPE,
      ratingableId,
      ratingQuestionId,
    },
    rating: getRatingByProps(state, {
      ratingableType: RATINGABLE_TYPE,
      ratingableId,
      ratingQuestionId,
      userId,
    }),
    ratingableType: RATINGABLE_TYPE,
    ratingableId,
    ratingQuestionId,
    userId,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    onSubmit: createLessonRating,
  })(LessonRatingFormContainer)
);
