import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchMock, finishMock } from "../../processes/mockProccess";
import {
  getCourseFromMock,
  hasMockCourses,
} from "../../selectors/courseSelectors";
import { getMockPreferenceByName } from "../../selectors/mockPreferencesSelectors";
import {
  getMock,
  makeIsComplete,
  showAnswer,
} from "../../selectors/mockSelectors";
import Mock from "./Mock";
import { replace } from "connected-react-router";
import { getQuestionsFromMock } from "../../selectors/questionSelectors";
import { getAnswersFromMock } from "../../selectors/answerSelector";

function MockContainer(props) {
  const {
    dispatch,
    mock,
    isComplete,
    questions,
    answers,
    questionsLoading,
    answersLoading,
  } = props;
  const { mockId } = props.match.params;
  const [showSidebar, setShowSidebar] = useState(false);
  const toggleSidebar = useCallback(() => {
    setShowSidebar(!showSidebar);
  }, [showSidebar]);

  const [initiated, setInitiated] = useState(false);

  useEffect(() => {
    if (!mock) fetchMock(dispatch, mockId);
  }, [dispatch, mockId, mock]);

  useEffect(() => {
    if (isComplete && !mock?.finishedAt && !mock?.exam) {
      finishMock(dispatch, mock?.id).then(() => {
        dispatch(replace(`/mocks/${mock?.id}/results`));
      });
    }
  }, [dispatch, isComplete, mock]);

  useEffect(() => {
    if (
      !initiated &&
      questions.length > 0 &&
      !questionsLoading &&
      !answersLoading
    ) {
      dispatch({
        type: "MOCK_INITIAL_QUESTION",
        index: !!mock?.finishedAt
          ? 0
          : Math.max(
              0,
              questions.findIndex((question) => {
                return !answers.some(
                  (answer) => answer.questionId === question.id
                );
              })
            ),
      });

      setInitiated(true);
    }
  }, [
    dispatch,
    initiated,
    mock?.finishedAt,
    questions,
    answers,
    questionsLoading,
    answersLoading,
  ]);

  return (
    <Mock showSidebar={showSidebar} toggleSidebar={toggleSidebar} {...props} />
  );
}

function mapStateToProps(state) {
  const mock = getMock(state);

  return {
    mock,
    showAnswer: showAnswer(state),
    showReport: getMockPreferenceByName(state, "showReport"),
    course: getCourseFromMock(state),
    isComplete: makeIsComplete(state),
    hasMockCourses: hasMockCourses(state),
    questions: getQuestionsFromMock(state),
    answers: getAnswersFromMock(state),
    questionsLoading: state.mock.questionsLoading,
    answersLoading: state.mock.answersLoading,
  };
}

export default connect(mapStateToProps)(MockContainer);
