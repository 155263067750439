const initialState = {
  loading: false,
  commentIds: [],
  currentPage: 1,
  totalPages: 0,
};

const getFetchedIds = (action) => (action.result ? action.result : []);

export default function contentFeedReducer(state = initialState, action) {
  switch (action.type) {
    case "CONTENTS_COMMENTS_FETCH_REQUESTED":
      return { loading: true };
    case "COMMENTS_FETCHED":
      return {
        ...state,
        commentIds: getFetchedIds(action),
        totalPages: action.totalPages,
        currentPage: action.currentPage,
        loading: false,
      };
    case "COMMENT_CREATED":
      return {
        ...state,
        commentIds: [...state.commentIds, action.result],
      };
    default:
      return state;
  }
}
