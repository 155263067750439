import { normalize, schema } from "normalizr";
import { apiGet } from "./helpers/api";

const courseOffers = new schema.Entity("courseOffers");

export function fetchCourseOffers(dispatch, courseId) {
  return apiGet(`/api/v1/public/courses/${courseId}/course_offers`).then(
    (response) => {
      dispatch({
        type: "COURSE_OFFERS_FETCHED",
        ...normalize({ ...response.body.data }, [courseOffers]),
      });
    }
  );
}

export function fetchCourseOffer(dispatch, courseOfferId) {
  if (!courseOfferId) return;

  return apiGet(`/api/v1/public/course_offers/${courseOfferId}`).then(
    (response) => {
      dispatch({
        type: "COURSE_OFFER_FETCHED",
        ...normalize({ ...response.body.data }, courseOffers),
      });
    }
  );
}
