import React from "react";
import Page from "../library/Page";
import { Card } from "semantic-ui-react";
import NetworkingUserListItem from "./NetworkingUserListItem";
import { Pagination } from "semantic-ui-react";
import { spacing } from "../library/theme";
import Spinner from "../library/Spinner";
import EmptyNetworking from "./EmptyNetworking";
import FilterFormContainer from "./FilterFormContainer";

const listStyle = {
  padding: `${spacing.m} 0`,
};
const loadingStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: spacing.m,
};

export default function Networking(props) {
  const { loading, fetchUsersHandler, activePage, totalPages, users } = props;

  return (
    <Page title="Networking">
      <FilterFormContainer />
      {loading ? (
        <Spinner style={loadingStyle} inline text="Carregando usuários..." />
      ) : (
        <>
          <Card.Group itemsPerRow={5} style={listStyle} doubling stackable>
            {users.map((user, index) => (
              <NetworkingUserListItem key={index} user={user} />
            ))}
          </Card.Group>
          {users.length > 0 ? (
            <Pagination
              ellipsisItem={null}
              siblingRange={1}
              boundaryRange={0}
              activePage={activePage}
              onPageChange={fetchUsersHandler}
              totalPages={totalPages}
            />
          ) : (
            <EmptyNetworking />
          )}
        </>
      )}
    </Page>
  );
}
