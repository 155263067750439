import { css } from "glamor";
import React, { useEffect, useState } from "react";
import { Label } from "semantic-ui-react";
import formatSeconds from "../../utils/formatSeconds";
import { colors } from "../library/theme";

const labelClass = (isFinished) =>
  !!isFinished &&
  css({
    color: `${colors.greyLighter} !important`,
    backgroundColor: `${colors.correct} !important`,
  });

const periodInSeconds = (start, end) => {
  const endDate = end ? new Date(end) : new Date();
  return (endDate.getTime() - new Date(start).getTime()) / 1000;
};

export default function MockTimer({
  createdAt,
  finishedAt,
  showTimer,
  showLabel,
  className,
  textStyle,
}) {
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (!finishedAt) {
      const updateTimer = setInterval(() => {
        setTimer(periodInSeconds(createdAt));
      }, 1000);

      return () => {
        clearInterval(updateTimer);
      };
    }

    setTimer(periodInSeconds(createdAt, finishedAt));
  }, [createdAt, finishedAt]);

  return (
    <div className={className}>
      {showLabel && (
        <Label
          className={labelClass(finishedAt).toString()}
          circular
          size="large"
        >
          {finishedAt ? "Concluído" : "Em progresso"}
        </Label>
      )}
      {showTimer && (
        <p style={textStyle}>
          <strong>Tempo de prova:</strong> {formatSeconds(timer)}
        </p>
      )}
    </div>
  );
}
