import React from "react";
import { Grid, Card } from "semantic-ui-react";
import makeDropDownOptions from "../../utils/makeDropDownOptions";
import { Form, DropDownField } from "../library/forms";
import Button from "../library/Button";
import SearchField from "../library/forms/SearchField";
import { spacing } from "../library/theme";

const cardStyle = {
  padding: spacing.m,
};

export default function FilterForm(props) {
  const {
    cleanFiltersHandler,
    handleSubmit,
    error = undefined,
    states,
    cities,
    isStateSelected,
  } = props;

  return (
    <Form onSubmit={handleSubmit} error={error}>
      <Card fluid style={cardStyle}>
        <Grid columns="equal">
          <Grid.Column computer={8} mobile={16}>
            <SearchField
              label="Pesquisar por nome"
              placeholder="Digite um nome"
              name="nameCont"
              fluid
            />
          </Grid.Column>
          <Grid.Column computer={4} mobile={16}>
            <DropDownField
              name="stateIdEq"
              label="Estado"
              clearable
              options={makeDropDownOptions(states)}
              selection
              fluid
            />
          </Grid.Column>
          <Grid.Column computer={4} mobile={16}>
            <DropDownField
              label="Cidade"
              name="cityIdEq"
              clearable
              options={makeDropDownOptions(cities)}
              disabled={!isStateSelected}
              selection
              fluid
            />
          </Grid.Column>
          <Grid.Column
            computer={4}
            mobile={16}
            textAlign="right"
            floated="right"
            verticalAlign="bottom"
          >
            <Button onClick={cleanFiltersHandler}>Limpar filtros</Button>
          </Grid.Column>
        </Grid>
      </Card>
    </Form>
  );
}
