import React from "react";
import { Icon, Label } from "semantic-ui-react";
import Button from "../Button";

export default function LikeButton({ count, active, disabled }) {
  return (
    <Button
      type="submit"
      as="div"
      labelPosition="right"
      size="mini"
      disabled={disabled}
    >
      <Button size="mini" color={active ? "red" : undefined}>
        <Icon name="heart" />
        Curtir
      </Button>
      <Label basic pointing="left" size="mini">
        {count}
      </Label>
    </Button>
  );
}
