import { normalize, schema } from "normalizr";
import toQueryString from "../utils/toQueryString";
import { apiGet, apiPost, apiPut } from "./helpers/api";

export const view = new schema.Entity("views");

export const fetchViews = (dispatch, filters = {}) => {
  dispatch({ type: "VIEWS_FETCH_REQUESTED" });
  const searchParams = toQueryString(filters);
  return apiGet(`/api/v1/views?${searchParams}`).then((response) => {
    dispatch({
      type: "VIEWS_FETCHED",
      ...normalize(response.body.data, [view]),
    });
  });
};

export const createView = (dispatch, params) => {
  return apiPost(`/api/v1/views`)
    .send(params)
    .then((response) => {
      dispatch({
        type: "VIEW_CREATED",
        ...normalize(response.body.data, view),
      });
    });
};

export const clickView = (dispatch, viewId) => {
  return apiPut(`/api/v1/views/${viewId}/click`).then((response) => {
    dispatch({ type: "VIEW_CLICKED", ...normalize(response.body.data, view) });
  });
};
