import React from "react";
import { OnChangeHTML } from "@remirror/react";
import { WysiwygEditor } from "@remirror/react-editors/wysiwyg";
import { css } from "glamor";

const wrapperClass = css({
  "& .remirror-list-item-checkbox": {
    minHeight: "auto !important",
  },
  "& .remirror-list-item-marker-container": {
    top: "8px !important",
  },
});

export default function RichTextInput({ placeholder, onChange }) {
  return (
    <div className={wrapperClass}>
      <WysiwygEditor placeholder={placeholder}>
        <OnChangeHTML onChange={onChange} />
      </WysiwygEditor>
    </div>
  );
}
