import React, { useCallback } from "react";
import { Field } from "redux-form";
import buildFormField from "./buildFormField";
import memoize from "fast-memoize";
import DocumentInput from "./DocumentInput";
import { cnpj, cpf } from "cpf-cnpj-validator";

const FormField = buildFormField(DocumentInput, (input, meta, rest) => {
  return {
    ...input,
    autoComplete: "off",
    maxLength: "15",
    ...rest,
    invalid: `${meta.touched && (meta.invalid || rest.invalid)}`,
  };
});

export function documentFormatter(num) {
  const onlyNumbers = num?.replace(/[^0-9]+/g, "");

  if (onlyNumbers?.length > 11) return cnpj.format(onlyNumbers);

  return cpf.format(onlyNumbers);
}

let id = 0;
function memoizedId(x) {
  if (!x.__memoizedId) x.__memoizedId = ++id;
  return { __memoizedId: x.__memoizedId };
}

const isCpfCnpjValid = (value) => {
  const onlyNumbers = value?.replace(/[^0-9]+/g, "");

  return onlyNumbers?.length > 12
    ? cnpj.isValid(onlyNumbers)
    : cpf.isValid(onlyNumbers);
};

// this needs to be memoizable!
const buildValidator = memoize(
  (required, other) => {
    return [
      (value) => {
        if (required && !value) {
          return "required";
        }
        if (!isCpfCnpjValid(value)) {
          return "invalid";
        }
        return undefined;
      },
    ].concat(other || []);
  },
  {
    serializer: (args) => {
      const argumentsWithFuncIds = Array.from(args).map((x) => {
        if (typeof x === "function") return memoizedId(x);
        return x;
      });
      return JSON.stringify(argumentsWithFuncIds);
    },
  }
);

export default function DocumentField(props) {
  const { required, validate, ...attrs } = props;
  const normalize = useCallback((value) => value?.replace(/[^0-9]+/g, ""), []);
  const format = useCallback(
    (value) => documentFormatter(value?.replace(/[^0-9]+/g, "")),
    []
  );

  return (
    <Field
      {...attrs}
      required={required}
      component={FormField}
      normalize={normalize}
      format={format}
      validate={buildValidator(required, validate)}
    />
  );
}
