import { connect } from "react-redux";
import { updateProfile } from "../../processes/profileProcesses";
import { getCurrentUser } from "../../selectors/userSelectors";
import buildForm from "../library/forms/buildForm";
import ProfileEditPersonalForm from "./ProfileEditPersonalForm";
import { getCountryCodeOptions } from "../../selectors/configSelectors";

const form = buildForm("personalProfileEdit");
const BRAZIL_PHONE_COUNTRY_CODE_ID = 31;

function mapStateToProps(state, ownProps) {
  const currentUser = getCurrentUser(state);

  return {
    currentUser,
    initialValues: {
      ...currentUser,
      phoneCountryCodeId:
        currentUser?.phoneCountryCodeId || BRAZIL_PHONE_COUNTRY_CODE_ID,
      phone: currentUser?.phone?.replace(/\D/g, ""),
    },
    imageUrl: form.getValue("imageUrl", state),
    countryCodeOptions: getCountryCodeOptions(state),
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: updateProfile,
    enableReinitialize: true,
  })(ProfileEditPersonalForm)
);
