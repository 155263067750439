import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

const trailSchema = new schema.Entity("trails");

export const fetchTrails = (dispatch) => {
  return apiGet("/api/v1/trails").then((response) => {
    dispatch({
      type: "TRAILS_FETCHED",
      ...normalize(response.body.data, [trailSchema]),
    });
  });
};
