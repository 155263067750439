import React from "react";
import { Search } from "semantic-ui-react";

export default function SearchInput(props) {
  return (
    <Search
      input={{ icon: "search", iconPosition: "left" }}
      noResultsMessage={`Nenhum resultado encontrado para "${props.value}"`}
      onSearchChange={props.onChange}
      {...props}
    />
  );
}
