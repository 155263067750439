import { Chart } from "chart.js";
import "chartjs-plugin-annotation";
import annotationPlugin from "chartjs-plugin-annotation";
import "chartjs-plugin-datalabels";
import datalabelsPlugin from "chartjs-plugin-datalabels";
import { css } from "glamor";
import React from "react";
import { Bar } from "react-chartjs-2";
import { colors } from "../library/theme";
Chart.register(annotationPlugin);

const container = css({
  height: 400,
});

const percentageParser = (value, { digits = 0 }) =>
  `${parseFloat(+value * 100).toFixed(
    Number.isInteger(+value * 100) ? 0 : digits
  )}%`;
const precisionPercentageParser = (value) =>
  percentageParser(value, { digits: 2 });
const sumArray = (array) =>
  array.reduce((acc, value) => (!!value ? acc + value : acc), 0);

const cuttoffLine = (grade, index) => {
  if (grade == 0) return { borderWidth: 0 };
  return {
    type: "line",
    borderColor: colors.primary,
    borderWidth: 3,
    xMax: index + 0.41,
    xMin: index - 0.41,
    yMax: grade,
    yMin: grade,
    order: 1,
  };
};

export default React.memo(function MockReportGraph(props) {
  const { labels, corrects, wrongs, average = 0.7, cuttoffs = [] } = props;
  const options = {
    height: 400,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      labels: {
        formatter: precisionPercentageParser,
      },
      datalabels: {
        font: {
          family: "sans-serif",
          weight: "normal",
          size: 14,
        },
        color: colors.white,
        formatter: (value, configs) => {
          if (value == 0 || configs.datasetIndex == 2) {
            return "";
          }
          return precisionPercentageParser(value);
        },
        align: "center",
        anchor: "center",
      },
      tooltip: {
        enabled: true,
        xAlign: "center",
        yAlign: "bottom",
        callbacks: {
          label: function (context) {
            const label = context.dataset.label.split(" - ")[0];
            const percentage = precisionPercentageParser(context.parsed.y);
            const dataset = !!context.dataset.error ? wrongs : corrects;
            const number = dataset[context.dataIndex];
            const questionsLabel = number > 1 ? "questões" : "questão";

            return `${label}: ${number} ${questionsLabel} (${percentage})`;
          },
        },
      },
      legend: {
        position: "bottom",
      },
      annotation: {
        annotations: cuttoffs.map(cuttoffLine).concat([
          {
            borderColor: colors.primaryLighter,
            type: "line",
            yMin: average,
            yMax: average,
            borderWidth: 3,
          },
        ]),
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          callback: percentageParser,
          beginAtZero: true,
        },
      },
    },
  };
  const total = (index) => +corrects[index] + +wrongs[index];
  const correctsPercentage = corrects?.map(
    (correct, index) => +correct / total(index) || 0
  );
  const wrongPercentage = wrongs?.map(
    (wrong, index) => +wrong / total(index) || 0
  );

  const totalCorrect = sumArray(corrects);
  const totalWrong = sumArray(wrongs);
  const hasAnswers = totalCorrect > 0 || totalWrong > 0;
  const totalCorrectPercentage = precisionPercentageParser(
    hasAnswers ? totalCorrect / (totalCorrect + totalWrong) : 0
  );
  const totalWrongPercentage = precisionPercentageParser(
    hasAnswers ? totalWrong / (totalCorrect + totalWrong) : 0
  );

  const data = {
    labels: labels,
    datasets: [
      {
        label: `Acertos - ${totalCorrect} (${totalCorrectPercentage})`,
        data: correctsPercentage,
        backgroundColor: colors.correct,
        order: 2,
        borderRadius: 4,
        borderSkipped: "middle",
      },
      {
        label: `Erros - ${totalWrong} (${totalWrongPercentage})`,
        data: wrongPercentage,
        backgroundColor: colors.wrong,
        order: 2,
        borderRadius: 4,
        borderSkipped: "middle",
        error: true,
      },
      {
        label: "% para aprovação",
        backgroundColor: colors.primaryLighter,
        order: 2,
      },
    ],
  };

  if (cuttoffs.length > 0) {
    data.datasets.push({
      label: "% mínima para aprovação",
      backgroundColor: colors.primary,
      order: 2,
    });
  }

  return (
    <div className={container}>
      <Bar plugins={[datalabelsPlugin]} data={data} options={options} />
    </div>
  );
});
