import React from "react";
import { colors, spacing } from "../theme";
import { RiMedalLine } from "react-icons/ri";
import { css } from "glamor";
import LeaderboardPodiumItem from "./LeaderboardPodiumItem";

const container = css({
  backgroundColor: colors.primary,
  width: "100%",
  height: "280px",
  borderTopLeftRadius: `${spacing.m}`,
  borderTopRightRadius: `${spacing.m}`,
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  gap: `${spacing.m}`,
});

export default function LeaderboardPodium({ data = [], highlightedId }) {
  const [firstPlace, secondPlace, thirdPlace] = data;

  return !!data.length ? (
    <div className={container}>
      {thirdPlace && (
        <LeaderboardPodiumItem
          color="#DC8F77"
          height="105px"
          width="80px"
          highlighted={thirdPlace?.id == highlightedId}
          name={thirdPlace?.userName}
          position={thirdPlace?.position}
          score={thirdPlace?.score}
        />
      )}
      <LeaderboardPodiumItem
        color="#EAB631"
        height="170px"
        width="100px"
        highlighted={firstPlace?.id == highlightedId}
        name={firstPlace?.userName}
        position={firstPlace?.position}
        score={firstPlace?.score}
      />
      {secondPlace && (
        <LeaderboardPodiumItem
          color="#A8B9C0"
          height="130px"
          width="85px"
          highlighted={secondPlace?.id == highlightedId}
          name={secondPlace?.userName}
          position={secondPlace?.position}
          score={secondPlace?.score}
        />
      )}
    </div>
  ) : null;
}
