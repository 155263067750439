import { css } from "glamor";
import React from "react";
import { AiFillCaretLeft } from "react-icons/ai";
import { Page } from "../library";
import Link from "../library/Link";
import Spinner from "../library/Spinner";
import { colors, spacing } from "../library/theme";
import CourseFiles from "./CourseFiles";
import EmptyCourseFiles from "./EmptyCourseFiles";

const lessonWrapper = {
  width: "100%",
};

const back = css({
  color: colors.primaryDarker,
  maxWidth: "1080px",
  marginLeft: spacing.s,
  marginBottom: spacing.m,
  display: "flex",
  alignItems: "center",
  gap: spacing.xxs,
  ":hover": {
    color: colors.primary,
    cursor: "pointer",
  },
});

export default function CourseFilesPage(props) {
  const { course, files, loading } = props;

  return (
    <Page title={course?.name ? `Downloads - ${course?.name}` : "Downloads"}>
      {loading ? (
        <Spinner />
      ) : (
        <div style={lessonWrapper}>
          <Link to={`/courses/${course?.id}`} className={back} component="div">
            <AiFillCaretLeft size={14} /> VOLTAR
          </Link>
          {files?.length ? <CourseFiles files={files} /> : <EmptyCourseFiles />}
        </div>
      )}
    </Page>
  );
}
