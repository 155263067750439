import React from "react";
import { Table, Tab, Card, Statistic, Popup } from "semantic-ui-react";
import Link from "../library/Link";
import Button from "../library/Button";
import formatDate from "../../utils/formatDate";
import { spacing } from "../library/theme";
import { css } from "glamor";
import NewClassroomModal from "./NewClassroomModal";
import Empty from "./Empty";

const nameColumnStyle = {
  width: "100%",
};
const createdAtColumnStyle = {
  paddingRight: spacing.m,
};

const topTenTitle = css({
  marginBottom: spacing.m,
});
const topTenTitleWrapper = css({
  padding: spacing.m,
  flexGrow: "unset !important",
});
const headerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: spacing.l,
};
const headerTitleStyle = {
  marginTop: 0,
};
const emptyWrapperStyle = {
  display: "flex",
  justifyContent: "center",
  width: "100%",
};

export default function CompanyPurchases({ company }) {
  return (
    <div>
      {company?.meta?.courses?.length === 0 ? <Empty /> : null}
      {company?.meta?.courses?.map((course) => (
        <Card fluid style={{ overflowX: "auto" }}>
          <Card.Header className={topTenTitleWrapper}>
            <h3>{course.name}</h3>
          </Card.Header>
          <Card.Content>
            <h3 style={headerTitleStyle}>Compras</h3>

            <Table unstackable style={{ overflowX: "auto" }}>
              <Table.Header>
                <Table.Row textAlign="center">
                  <Table.HeaderCell>Comprado em</Table.HeaderCell>
                  <Table.HeaderCell>Curso</Table.HeaderCell>
                  <Table.HeaderCell>
                    Qtde. Créditos (Matrículas)
                  </Table.HeaderCell>
                  <Table.HeaderCell>Qtde. Dias de Acesso</Table.HeaderCell>
                  <Table.HeaderCell>Expira em</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {company?.meta?.purchases
                  ?.filter(({ courseId }) => courseId == course.id)
                  .map((purchase) => (
                    <Table.Row textAlign="center">
                      <Table.Cell>
                        {formatDate(purchase?.createdAt, "DD/MM/YYYY")}
                      </Table.Cell>
                      <Table.Cell>{purchase?.courseName}</Table.Cell>
                      <Table.Cell>{purchase?.enrollmentsAmount}</Table.Cell>
                      <Table.Cell>{purchase?.enrollmentsLength}</Table.Cell>
                      <Table.Cell>
                        {formatDate(purchase?.expiresAt, "DD/MM/YYYY")}
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
            {!company?.meta?.purchases?.filter(
              ({ courseId }) => courseId == course.id
            )?.length && <Empty />}
          </Card.Content>
        </Card>
      ))}
    </div>
  );
}
