import moment from "moment";
import React, { useCallback } from "react";
import { connect } from "react-redux";
import { createCertificationExam } from "../../processes/certificationExamProcesses";
import { currentCourse } from "../../selectors/courseSelectors";
import { getCurrentUserId } from "../../selectors/userSelectors";
import { getItem, updateStorageListItem } from "../../utils/localStorage";
import buildForm from "../library/forms/buildForm";
import CertificationExamForm from "./CertificationExamForm";

const FORM_NAME = "certificationExamForm";
const form = buildForm(FORM_NAME);

function CertificationExamFormContainer(props) {
  const { course } = props;
  const { progress } = course.meta;

  const handlePostponeAnswer = useCallback(() => {
    const storage = getItem("certification_exam");
    const displayAt = storage && storage[course.id]?.displayAt;

    const settings = displayAt
      ? { progress: progress + 0.1 }
      : { displayAt: moment().add(7, "days") };

    updateStorageListItem("certification_exam", course.id, settings);

    props.handleModalClosure();
  }, [course.id, progress]);

  return (
    <CertificationExamForm
      handlePostponeAnswer={handlePostponeAnswer}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const course = currentCourse(state);
  const userId = getCurrentUserId(state);

  return {
    userId,
    course,
  };
}

export default connect(mapStateToProps)(
  form.connect({
    form: FORM_NAME,
    onSubmit: createCertificationExam,
  })(CertificationExamFormContainer)
);
