import React, { forwardRef, useEffect, useRef } from "react";
import { css } from "glamor";
import { spacing, styles, typographyColors } from "../library/theme";
import Link from "../library/Link";
import Button from "../library/Button";
import { Label, Icon } from "semantic-ui-react";
import { baseUrl } from "../../processes/helpers/api";
import DifficultyBar from "../library/DifficultyBar";
import { changeTagStyle } from "../../utils/changeTagStyle";

const container = css({
  paddingBottom: spacing.l,
  "> p, * > p": {
    ...styles.bodyTextLarge,
    ...styles.bodyTextBold,
    fontWeight: "500",
    color: typographyColors.blackLetters,
  },
});
const codeClass = css({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  marginBottom: spacing.m,
  "> strong": { marginRight: spacing.xxs },
});
const labelStyle = {
  marginLeft: spacing.s,
};
const editStyle = {
  marginLeft: spacing.s,
};

const Title = forwardRef(({ title }, ref) => {
  return (
    <p
      ref={ref}
      dangerouslySetInnerHTML={{
        __html: title,
      }}
    />
  );
});

export default function QuestionTitle(props) {
  const {
    question,
    answered,
    currentUser,
    showMockAnswer,
    readOnly,
    mockPreferences,
  } = props;

  const titleRef = useRef();

  useEffect(() => {
    changeTagStyle(titleRef.current, "*", "fontFamily", null);
  }, [titleRef]);

  const showDifficulty =
    (answered && showMockAnswer && mockPreferences.showDifficulty) || readOnly;

  return (
    <div className={container}>
      <p className={codeClass}>
        <strong>CÓD. DA QUESTAO:</strong> {question.id}
        {question?.highlighted && (
          <Label color="yellow" size="tiny" style={labelStyle}>
            <Icon name="star" />
            Questão recorrente
          </Label>
        )}
        {question?.calcRequired && (
          <Label color="red" size="tiny" style={labelStyle}>
            <Icon name="exclamation" />
            Requer cálculo
          </Label>
        )}
        {question?.isFcc && (
          <Label color="blue" size="tiny" style={labelStyle}>
            <Icon name="star" />
            FCC - Fundação Carlos Chagas
          </Label>
        )}
        {showDifficulty && (
          <DifficultyBar
            value={question?.errorsAverage}
            indicatorPopup={`Dificuldade: ${question?.errorsAverage}% erraram essa questão`}
          />
        )}
        {currentUser?.meta?.features?.canManageCourses && (
          <Link
            component={Button}
            size="mini"
            href={`${baseUrl}/manager/questions/${question.id}/edit`}
            target="_blank"
            style={editStyle}
          >
            Editar questão
          </Link>
        )}
      </p>
      <Title title={question.title} ref={titleRef} />
    </div>
  );
}
