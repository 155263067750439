const getUserFiles = (action) => action.entities.userFiles;

export default function userFilesReducer(state = {}, action) {
  switch (action.type) {
    case "USER_FILES_FETCHED":
    case "USER_FILE_FETCHED":
      return { ...state, ...getUserFiles(action) };
    default:
      return state;
  }
}
