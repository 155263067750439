import React from "react";
import { css } from "glamor";
import Button from "../library/Button";
import Link from "../library/Link";
import { spacing } from "../library/theme";

const container = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  transform: "translate(0, 200%)",
  textAlign: "center",
  marginLeft: 10,
  marginRight: 10,
  "> h1": {
    fontSize: 40,
  },
  "> h4": {
    fontSize: 20,
  },
});
const titleClass = css({
  marginBottom: spacing.l,
});
const buttonStyle = {
  fontSize: 15,
};

export default function NotFound(props) {
  return (
    <div className={container}>
      <h1>404</h1>
      <h4 className={titleClass}>
        Oops a página que você está procurando não foi encontrada{" "}
      </h4>
      <Link style={buttonStyle} component={Button} to="/">
        Voltar para a página inicial
      </Link>
    </div>
  );
}
