import { replace } from "connected-react-router";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  currentCourse,
  getCurrentCourseId,
  canAccessCourse,
} from "../../selectors/courseSelectors";
import {
  getCurrentLesson,
  makeIsCommentsEnabled,
  canAccessLesson,
} from "../../selectors/lessonSelectors";
import { getIsWatchedByLessonId } from "../../selectors/lessonsUserSelectors";
import { getLessonRatingQuestion } from "../../selectors/ratingSelectors";
import { getCurrentUser } from "../../selectors/userSelectors";
import Lesson from "./Lesson";
import { fetchCourseProgress } from "../../processes/courseProcesses";
import { setItem } from "../../utils/localStorage";

function LessonContainer(props) {
  const { dispatch, lesson, canAccessCourse, watched, course } = props;
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = useCallback(() => {
    setShowSidebar(!showSidebar);
  }, [showSidebar]);

  const handlePlayerOption = useCallback(
    (_, data) => {
      setItem("lessonPlayerOption", data.value);
      dispatch({ type: "PLAYER_SELECTED", option: data.value });
    },
    [dispatch]
  );

  useEffect(() => {
    if (watched && course?.id) fetchCourseProgress(dispatch, course?.id);
  }, [dispatch, watched, course?.id]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [lesson?.id]);

  return (
    <Lesson
      showSidebar={showSidebar}
      toggleSidebar={toggleSidebar}
      handlePlayerOption={handlePlayerOption}
      {...props}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps.match.params;
  const lesson = getCurrentLesson(state, id);

  return {
    lesson,
    currentUser: getCurrentUser(state),
    currentCourseId: getCurrentCourseId(state),
    watched: getIsWatchedByLessonId(state, lesson?.id),
    commentsEnabled: makeIsCommentsEnabled(state, lesson?.id),
    canAccessLesson: canAccessLesson(state, lesson?.id),
    canAccessCourse: canAccessCourse(state, lesson?.courseId),
    course: currentCourse(state),
    loading: state.coursesList.loading || !lesson,
    playerOption: state.lesson.playerOption,
    ratingQuestion: getLessonRatingQuestion(state),
  };
}

export default connect(mapStateToProps)(LessonContainer);
