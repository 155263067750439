import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { fetchTrails } from "../../processes/trailProcesses";
import { getCurrentTrailCourses } from "../../selectors/courseSelectors";
import { getCurrentTrail } from "../../selectors/trailSelectors";
import Trail from "./Trail";

function TrailContainer(props) {
  const { dispatch, courses } = props;
  const [selectedCourse, setSelectedCourse] = useState(null);

  const selectCourseHandler = useCallback(
    (course) => () => {
      setSelectedCourse(course);
    },
    []
  );

  useEffect(() => {
    setSelectedCourse(courses?.[0]);
  }, [courses?.length]);

  useEffect(() => {
    fetchTrails(dispatch);
  }, [dispatch]);

  if (!selectedCourse) {
    return null;
  }

  return (
    <Trail
      selectedCourse={selectedCourse}
      selectCourseHandler={selectCourseHandler}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  return {
    trail: getCurrentTrail(state),
    courses: getCurrentTrailCourses(state),
  };
}

export default connect(mapStateToProps)(TrailContainer);
