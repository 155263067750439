import React from "react";
import { Loader } from "semantic-ui-react";
import { spacing } from "./theme";

const wrapperStyle = {
  style: { padding: spacing.xxl, textAlign: "center" },
};

export default function Spinner({ text, wrapped, inline, ...rest }) {
  const Wrapper = wrapped ? "div" : React.Fragment;
  const props = wrapped ? wrapperStyle : {};

  return (
    <Wrapper {...props}>
      <Loader active inline={wrapped ? wrapped : inline} {...rest}>
        {text}
      </Loader>
    </Wrapper>
  );
}
