const initialState = {
  loading: true,
};

export default function viewListReducer(state = initialState, action) {
  switch (action.type) {
    case "VIEWS_FETCH_REQUESTED":
      return { ...state, loading: true };
    case "VIEWS_FETCHED":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
