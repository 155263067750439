import React, { useState, useCallback } from "react";
import { Tab, Table, Input, Icon } from "semantic-ui-react";
import { colors, spacing, styles } from "../library/theme";
import { css } from "glamor";
import { useSelector } from "react-redux";
import { getMockTemplateBySearch } from "../../selectors/classroomSelectors";
import CustomTableHeaderCell from "./CustomTableHeaderCell";

const tableWrapper = css({
  display: "grid",
  gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
  overflow: "auto",
  whiteSpace: "nowrap",
});
const tabPaneClass = css({
  padding: `${spacing.s} 0px ${spacing.l} 0px !important`,
  border: "none !important",
  boxShadow: "none !important",
  "> div > div": styles.alternativeFontFamily,
}).toString();
const inputClass = css({
  marginBottom: spacing.m,
  minWidth: 300,
  "@media(max-width: 575px)": {
    width: "100%",
  },
});

export default function ClassroomMockTemplates({ classroom }) {
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("name");
  const [order, setOrder] = useState("asc");
  const toggleOrder = useCallback(() => {
    setOrder(order == "asc" ? "desc" : "asc");
  }, [order]);
  const searchChangeHandler = useCallback((_, { value }) => {
    setSearch(value);
  }, []);
  const mockTemplates = useSelector((state) =>
    getMockTemplateBySearch(state, {
      classroomId: classroom?.id,
      search,
      sort,
      order,
    })
  );

  const tableHeaderOptions = { sort, order, toggleOrder, setSort };

  return (
    <Tab.Pane attached={false} className={tabPaneClass}>
      <Input
        iconPosition="left"
        placeholder="Digite um nome"
        onChange={searchChangeHandler}
        className={inputClass}
      >
        <Icon name="search" />
        <input />
      </Input>
      <div className={tableWrapper}>
        <Table unstackable compact="very" celled={false}>
          <Table.Header>
            <Table.Row>
              <CustomTableHeaderCell
                content="Nome"
                columnName="name"
                textAlign="left"
                {...tableHeaderOptions}
              />
              <CustomTableHeaderCell
                content="Questões"
                columnName="questionsCount"
                {...tableHeaderOptions}
              />
              <CustomTableHeaderCell
                content="Finalizados"
                columnName="mocksFinishedCount"
                {...tableHeaderOptions}
              />
              <CustomTableHeaderCell
                content="Média da turma"
                columnName="mocksAverageScore"
                {...tableHeaderOptions}
              />
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {mockTemplates?.map((mockTemplate) => (
              <Table.Row>
                <Table.Cell>{mockTemplate.name}</Table.Cell>
                <Table.Cell textAlign="center">
                  {mockTemplate.questionsCount}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {mockTemplate.mocksFinishedCount} de{" "}
                  {classroom.meta.users.length}
                </Table.Cell>

                <Table.Cell textAlign="center">
                  {mockTemplate.mocksAverageScore}%
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </Tab.Pane>
  );
}
