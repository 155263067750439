import { css } from "glamor";
import React from "react";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { Accordion } from "semantic-ui-react";
import { colors, spacing, styles, uiColors } from "../library/theme";
import SubLevelListItemContainer from "./SubLevelListItemContainer";
import LessonListItemContainer from "./LessonListItemContainer";

const layout = css({
  overflow: "hidden",
  borderRight: `solid 1px ${uiColors.border}`,
});
const content = css({
  margin: 0,
  paddingTop: "0 !important",
});
const accordionTitleContainer = css(styles.roundedCorners, {
  padding: `${spacing.m} !important`,
  lineHeight: 1,
  borderRadius: 0,
  borderTop: `solid 1px ${uiColors.border}`,
  backgroundColor: colors.white,
});
const levelNameClass = css({
  margin: 0,
  paddingRight: spacing.s,
  maxWidth: "100%",
  color: colors.blueDark,
  fontWeight: "bold",
});
const iconStyle = { color: colors.greyDark, minWidth: 26 };
const levelNameContainer = css({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  lineHeight: 1,
});
const accordionLevelIndicatorStyle = {
  color: colors.blueDark,
};

export default function LevelListItem({
  level,
  sublevels,
  lessons,
  handleClick,
  active,
}) {
  const AccordionIcon = active ? BiChevronDown : BiChevronRight;

  return (
    <Accordion className={layout.toString()}>
      <Accordion.Title
        className={accordionTitleContainer.toString()}
        onClick={handleClick}
      >
        <p style={accordionLevelIndicatorStyle}>Módulo</p>
        <div className={levelNameContainer}>
          <h5 className={levelNameClass}>{level.name}</h5>
          <AccordionIcon size={26} style={iconStyle} />
        </div>
      </Accordion.Title>
      <Accordion.Content className={content.toString()} active={active}>
        {sublevels?.map((sublevel) => (
          <SubLevelListItemContainer key={sublevel.id} level={sublevel} />
        ))}
        {lessons?.map((lesson) => (
          <LessonListItemContainer key={lesson.id} lesson={lesson} />
        ))}
      </Accordion.Content>
    </Accordion>
  );
}
