import React from "react";
import { css } from "glamor";
import { spacing } from "../library/theme";
import { Form, ImageField, TextAreaField } from "../library/forms";
import Button from "../library/Button";
import UrlPreview from "./UrlPreview";
import UserHeaderContainer from "./UserHeaderContainer";
import Spinner from "../library/Spinner";
import ImageFieldPreviewer from "../library/forms/ImageFieldPreviewer";
import { Segment } from "semantic-ui-react";
import { MdClose } from "react-icons/md";
import { FaTags } from "react-icons/fa";
import DropDownField from "../library/forms/DropDownField";
import makeDropDownOptions from "../../utils/makeDropDownOptions";

const BUTTON_SIZE = 41;
const fieldsWrapper = css({
  textAlign: "right",
});
const buttonStyle = {
  height: BUTTON_SIZE,
};
const loadingStyle = {
  display: "flex",
  justifyContent: "center",
};
const buttonsClass = css({
  marginTop: spacing.s,
  display: "flex",
  justifyContent: "space-between",
});
const previewClass = css({
  marginTop: spacing.s,
});
const leftButtons = css({
  display: "flex",
});
const imageButton = css({
  marginRight: spacing.s,
  marginTop: `-${spacing.xxs}`,
});
const categoriesWrapper = css({
  width: "100%",
  margin: `${spacing.s} 0 ${spacing.m} 0`,
});
const categoryButton = {
  height: BUTTON_SIZE,
  width: BUTTON_SIZE,
  padding: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default function ContentCommentForm({
  handleSubmit,
  currentUser,
  contentText,
  formName,
  showUrlPreview,
  submitting,
  pristine,
  contentCategories,
  showCategoryField,
  toogleCategoryField,
}) {
  return currentUser ? (
    <Segment>
      <Form onSubmit={handleSubmit}>
        <div className={fieldsWrapper}>
          <UserHeaderContainer user={currentUser} />
          <TextAreaField
            rows={3}
            name="text"
            placeholder="Escreva uma publicação..."
            width="100%"
            fluid="true"
          />
          {showCategoryField && (
            <div className={categoriesWrapper}>
              <DropDownField
                name="categoryIds"
                label="Tags"
                options={makeDropDownOptions(contentCategories)}
                placeholder="Selecione a(s) tag(s)"
                multiple
                fluid
              />
            </div>
          )}
          <div className={previewClass}>
            <ImageFieldPreviewer formName={formName} formFieldName="imageUrl" />
            {showUrlPreview && <UrlPreview text={contentText} />}
          </div>
          <div className={buttonsClass}>
            <div className={leftButtons}>
              <div className={imageButton}>
                <ImageField name="imageUrl" buttonType />
              </div>
              <Button
                style={categoryButton}
                onClick={toogleCategoryField}
                type="button"
              >
                {showCategoryField ? (
                  <MdClose size={22} />
                ) : (
                  <FaTags size={22} />
                )}
              </Button>
            </div>

            <Button
              disabled={pristine || submitting}
              content="Publicar"
              labelPosition="left"
              icon="share alternate"
              style={buttonStyle}
              primary
            />
          </div>
        </div>
      </Form>
    </Segment>
  ) : (
    <div style={loadingStyle}>
      <Spinner inline />
    </div>
  );
}
