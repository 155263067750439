import { createSelector } from "reselect";
import { getCurrentCourseId } from "./courseSelectors";
import { getLessonId } from "./lessonSelectors";

const getAnnotations = (state) => state.entities.annotations;
const getId = (_, id) => id;
const getParams = (_, params) => params;

export const getAllAnnotations = createSelector(getAnnotations, (annotations) =>
  Object.values(annotations)
);

export const getCurrentCourseAnnotations = createSelector(
  getAllAnnotations,
  getCurrentCourseId,
  (annotations, courseId) =>
    annotations.filter((annotation) => annotation.courseId == courseId)
);

export const getAnnotationsByParams = createSelector(
  getCurrentCourseAnnotations,
  getParams,
  (annotations, { lesson, scope, order }) => {
    return annotations
      .filter((annotation) => {
        if (scope === "lesson") {
          return annotation.lessonId === lesson.id;
        }

        return true;
      })
      .sort((a, b) => {
        if (order === "newer") return b.id - a.id;

        return a.id - b.id;
      });
  }
);
