import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getConfig,
  getTopContentCategories,
} from "../../selectors/configSelectors";
import {
  getContentById,
  getHighlightedCategoryCommentsLoading,
} from "../../selectors/contentSelectors";
import ContentCategory from "./ContentCategory";
import { fetchTopUsers } from "../../processes/userProcesses";
import { getMostActiveUsers } from "../../selectors/userSelectors";
import { fetchHighlightedCommentsByCategory } from "../../processes/commentProcesses";

export const ContentCategoryContainer = (props) => {
  const { dispatch, content, categories } = props;

  useEffect(() => {
    fetchTopUsers(dispatch);
  }, [dispatch]);

  useEffect(() => {
    categories.map((category) => {
      fetchHighlightedCommentsByCategory(dispatch, content?.id, category?.id);
    });
  }, [dispatch, categories, content]);

  return <ContentCategory {...props} />;
};

const mapStateToProps = (state, ownProps) => {
  const { contentId } = ownProps.match.params;

  return {
    content: getContentById(state, contentId),
    categories: getConfig(state, "contentCategories"),
    topCategories: getTopContentCategories(state),
    topUsers: getMostActiveUsers(state),
    path: ownProps.location.pathname,
    loading: getHighlightedCategoryCommentsLoading(state),
    goBack: ownProps.history.goBack,
  };
};

export default connect(mapStateToProps)(ContentCategoryContainer);
