import { connect } from "react-redux";
import buildForm from "../library/forms/buildForm";
import ContentCommentReplyForm from "./ContentCommentReplyForm";
import { createContentCommentReply } from "../../processes/commentProcesses";
import { getCurrentUser } from "../../selectors/userSelectors";

const form = buildForm("contentCommentReplyForm");

function mapStateToProps(state, ownProps) {
  const { contentId, comment } = ownProps;

  return {
    initialValues: {},
    currentUser: getCurrentUser(state),
    contentId,
    comment,
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: createContentCommentReply,
  })(ContentCommentReplyForm)
);
