import { css } from "glamor";
import React from "react";
import { Modal } from "semantic-ui-react";
import { colors } from "../library";
import CertificationExamFormContainer from "./CertificationExamFormContainer";
import PhoneFormContainer from "./PhoneFormContainer";

const bannerModal = css({
  backgroudColor: colors.greyDark,
  position: "relative",
  width: "100%",
  backgroundSize: "cover !important",
  backgroundRepeat: "no-repeat !important",
});
const modalClass = css({
  width: "400px !important",
});

export default function CertificationExamModal(props) {
  const {
    course,
    openModal,
    setOpenModal,
    handleModalClosure,
    certificationExam,
  } = props;

  const headerClass = css(bannerModal, {
    paddingBottom: "30%",
    background: `url(${course?.coverImageUrl})`,
  });

  return (
    <Modal open={openModal} className={modalClass.toString()}>
      <div className={headerClass}></div>
      {certificationExam ? (
        <PhoneFormContainer setOpenModal={setOpenModal} />
      ) : (
        <CertificationExamFormContainer
          handleModalClosure={handleModalClosure}
        />
      )}
    </Modal>
  );
}
