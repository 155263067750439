import React from "react";
import { Grid } from "semantic-ui-react";
import ContentCommentFormContainer from "./ContentCommentFormContainer";
import { spacing } from "../library/theme";
import Divider from "../library/Divider";
import ContentPage from "./ContentPage";
import ContentTopCategoriesCard from "./ContentTopCategoriesCard";
import ContentTopUsersCard from "./ContentTopUsersCard";
import ContentCarousel from "./ContentCarousel";
import Spinner from "../library/Spinner";

const dividerStyle = {
  margin: `${spacing.l} 0`,
};
const highlightStyle = {
  marginBottom: spacing.m,
};
const loadingStyle = {
  display: "flex",
  justifyContent: "center",
};

export default function ContentHighlight({
  content,
  path,
  topCategories,
  topUsers,
  highlightComments,
  teamComments,
  loading,
  goBack,
}) {
  return (
    <ContentPage content={content} path={path} goBack={goBack}>
      <Grid centered>
        <Grid.Column computer={12} tablet={12} mobile={16}>
          <ContentCommentFormContainer contentId={content?.id} />
          <Divider style={dividerStyle} />
          {loading ? (
            <Spinner text="Carregando posts" style={loadingStyle} inline />
          ) : (
            <>
              {highlightComments.length > 0 && (
                <div style={highlightStyle}>
                  <h3>Publicações em alta</h3>
                  <ContentCarousel items={highlightComments} />
                </div>
              )}
              {teamComments.length > 0 && (
                <div style={highlightStyle}>
                  <h3>O que nossa equipe publicou</h3>
                  <ContentCarousel items={teamComments} />
                </div>
              )}
            </>
          )}
        </Grid.Column>
        <Grid.Column computer={4} tablet={4} mobile={16}>
          <ContentTopCategoriesCard
            content={content}
            topCategories={topCategories}
          />
          <ContentTopUsersCard topUsers={topUsers} />
        </Grid.Column>
      </Grid>
    </ContentPage>
  );
}
