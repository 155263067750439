import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchClassroomReports,
  selectedCourseIdChanged,
} from "../../processes/reportProcesses";
import { getClassroomById } from "../../selectors/classroomSelectors";
import { getCourseById } from "../../selectors/courseSelectors";
import { getReport } from "../../selectors/reportSelectors";
import MyPerformance from "../myPerformance/MyPerformance";
import { getUserById } from "../../selectors/userSelectors";
import { fetchUser } from "../../processes/userProcesses";

function ClassroomUserPerformanceContainer(props) {
  const { dispatch, classroomId, userId, course, user } = props;
  const courseForDropdown = [{ id: course?.id, name: course?.name }];

  useEffect(() => {
    selectedCourseIdChanged(dispatch, course?.id);
  }, [dispatch, course?.id]);

  useEffect(() => {
    fetchClassroomReports(dispatch, classroomId, userId);
  }, [dispatch, classroomId, userId]);

  useEffect(() => {
    fetchUser(dispatch, userId);
  }, [dispatch, userId]);

  return (
    <MyPerformance
      title={`Desempenho de ${user?.firstName} ${user?.lastName}`}
      courses={courseForDropdown}
      backLink={`/classrooms/${classroomId}`}
      disableCourseDropdown
      {...props}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const { classroomId, userId } = ownProps.match.params;
  const classroom = getClassroomById(state, classroomId);
  const selectedCourseId = classroom?.courseId;
  const course = getCourseById(state, selectedCourseId);

  return {
    course,
    user: getUserById(state, userId),
    report: getReport(state) || {},
    classroom,
    selectedCourseId,
    classroomId,
    userId,
  };
}

export default connect(mapStateToProps)(ClassroomUserPerformanceContainer);
