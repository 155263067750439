import { connect } from "react-redux";
import buildForm from "../library/forms/buildForm";
import AnnotationForm from "./AnnotationForm";
import { upsertAnnotation } from "../../processes/annotationProcesses";
import { getCurrentCourseId } from "../../selectors/courseSelectors";

const form = buildForm("annotationForm");

function mapStateToProps(state, { annotation, lessonId }) {
  return {
    initialValues: {
      courseId: state.currentCourseId,
      lessonId: state.currentLessonId,
      videoTime: state.lesson.currentTime,
      ...annotation,
    },
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: upsertAnnotation,
  })(AnnotationForm)
);
