import { createSelector } from "reselect";

const getRatings = (state) => state.entities.ratings;
const getProps = (_, props) => props;
const getCurrentLesson = (state) =>
  state.entities.lessons[state.currentLessonId];

const getAllRatings = createSelector(getRatings, (ratings) =>
  Object.values(ratings)
);

export const getLessonRatingQuestion = createSelector(
  getAllRatings,
  getCurrentLesson,
  (ratings, lesson) => {
    return lesson
      ? lesson.meta.ratingQuestions.find((question) => {
          return !ratings.find((rating) => {
            return (
              rating.ratingableType == "Lesson" &&
              rating.ratingableId == lesson.id &&
              rating.ratingQuestionId == question.id
            );
          });
        })
      : null;
  }
);

export const getRatingByProps = createSelector(
  getAllRatings,
  getProps,
  (ratings, props = {}) => {
    return ratings.find((rating) => {
      return (
        rating.ratingableType == props.ratingableType &&
        rating.ratingableId == props.ratingableId &&
        rating.ratingQuestionId == props.ratingQuestionId &&
        rating.userId == props.userId
      );
    });
  }
);
