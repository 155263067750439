import { createSelector } from "reselect";

const getTerms = (state) => state.entities.terms;

const orderById = (a, b) => a.id - b.id;

const getAllTerms = createSelector(getTerms, (terms) => {
  return Object.values(terms).sort(orderById);
});

export const getTerm = createSelector(getAllTerms, (terms) => {
  return terms[terms.length - 1];
});

export const getAcceptedTerms = createSelector(getAllTerms, (terms) => {
  return terms.filter((term) => term.accepted);
});

export const getIsRenewal = createSelector(getAcceptedTerms, (terms) => {
  return !!terms.length;
});
