import React from "react";
import { connect } from "react-redux";
import CompanyClassrooms from "./CompanyClassrooms";
import { getTotalSudentsByCompanyId } from "../../selectors/classroomSelectors";
import { getMocksAverage } from "../../selectors/companySelectors";

const mapStateToProps = (state, { company }) => ({
  mocksAverage: getMocksAverage(state, { companyId: company?.id, courseId: 3 }),
  totalStudents: getTotalSudentsByCompanyId(state, company?.id),
});

export default connect(mapStateToProps)(CompanyClassrooms);
