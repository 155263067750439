import { css } from "glamor";
import React from "react";
import { AiFillCaretLeft } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useMonitorScreen } from "../../utils/useScreenWidth";
import { colors, spacing } from "../library/theme";
import MockProgressBar from "../mocks/MockProgressBar";
import MockTimer from "../mocks/MockTimer";
import QuestionPaginationContainer from "../questions/QuestionPaginationContainer";

const LARGE_SIDEBAR_WIDTH = "320px";

const container = css({
  minHeight: "100vh",
  backgroundColor: colors.greyLighter,
  minWidth: LARGE_SIDEBAR_WIDTH,
  maxWidth: LARGE_SIDEBAR_WIDTH,
});
const content = css({
  display: "flex",
  flexDirection: "column",
  gap: spacing.m,
  padding: spacing.m,
  backgroundColor: colors.greyLighter,
});
const back = {
  color: colors.primaryShadow,
  display: "flex",
  alignItems: "center",
  gap: spacing.xxs,
};
const progressWrapper = {
  marginBottom: spacing.s,
};
const indicatorsWrapper = {
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: spacing.s,
};
const indicator = (color) => {
  return {
    display: "inline-block",
    marginRight: spacing.s,
    height: 8,
    width: 8,
    borderRadius: 4,
    background: color,
  };
};

export default function MockSidebar({ mock }) {
  const isMonitor = useMonitorScreen();

  return (
    <div className={container}>
      <div className={content}>
        <Link to="/mocks" style={back}>
          <AiFillCaretLeft size={14} /> VOLTAR PARA SIMULADOS
        </Link>
        <MockTimer
          createdAt={mock?.createdAt}
          finishedAt={mock?.finishedAt}
          showTimer={!isMonitor}
          showLabel
        />
        <div style={progressWrapper}>
          <MockProgressBar value={mock?.progress} numberPosition="top" fluid />
        </div>
        <QuestionPaginationContainer />
        <div style={indicatorsWrapper}>
          <div>
            <div style={indicator(colors.correct)} />
            Acerto
          </div>
          <div>
            <div style={indicator(colors.wrong)} />
            Erro
          </div>
          {mock?.exam && (
            <div>
              <div style={indicator(colors.primaryLighter)} />
              Respondida
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
