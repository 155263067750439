import { css } from "glamor";
import React from "react";
import { createTextMask } from "redux-form-input-masks";
import { Icon } from "semantic-ui-react";
import Button from "../library/Button";
import Link from "../library/Link";
import { Form, TextField } from "../library/forms";
import EmailField from "../library/forms/EmailField";
import PhoneField from "../library/forms/PhoneField";
import SecurityBadge from "../library/images/security-badge.svg";
import { colors, spacing } from "../library/theme";
import LobbyLayout from "../lobby/LobbyLayout";

const linkClass = css({
  display: "block",
  marginTop: spacing.s,
  color: colors.infoDark,
});
const buttonMargin = css({ margin: `${spacing.l} 0` });
const phoneNumberMask = createTextMask({
  pattern: "5599999999999",
  guide: false,
  allowEmpty: true,
});
const successWrapper = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: spacing.m,
  padding: `${spacing.m} 0px`,
};
const successTitle = {
  color: "#3A5E94",
  marginBottom: 0,
};
const successContent = {
  color: colors.dark,
  textAlign: "center",
  padding: `0 ${spacing.m}`,
  lineHeight: 1.2,
};
const cardClass = css({
  margin: `${spacing.xl} auto 0`,
  width: "100%",
  maxWidth: "450px !important",
  alignSelf: "center",
  padding: `${spacing.l} ${spacing.xl}`,
  borderRadius: spacing.m,
  backgroundColor: "white",
  textAlign: "center",
});
const titleWrapper = {
  textAlign: "left",
  display: "flex",
  gap: spacing.s,
};
const textClass = css({
  color: colors.black,
  fontWeight: 500,
  lineHeight: 1,
});
const titleClass = css(textClass, {
  lineHeight: 1,
  color: colors.black,
  fontSize: 28,
  fontWeight: "bold",
});
const passwordValidationMessage = {
  minSize: "A senha deve conter o mínimo de 6 dígitos.",
};

const validationMessages = {
  taken: "* E-mail já cadastrado",
};

export default function SignUpForm({
  countryCodeOptions,
  handleSubmit,
  submitSucceeded,
  submitting,
  pristine,
  error,
}) {
  return (
    <LobbyLayout>
      <Form
        onSubmit={handleSubmit}
        error={error}
        className={cardClass}
        noMessages
      >
        <div style={titleWrapper}>
          <img src={SecurityBadge} />
          <div>
            <p className={textClass}>Cadastro</p>
            <p className={titleClass}>Crie sua conta</p>
          </div>
        </div>

        {submitSucceeded ? (
          <div style={successWrapper}>
            <Icon
              name="check circle outline"
              size="massive"
              color="green"
              circular
            />
            <h2 style={successTitle}>Te enviamos um e-mail de confirmação</h2>
            <p style={successContent}>
              Siga as instruções enviadas para o e-mail cadastrado para
              confirmar e acessar sua conta.
              <Link to="/signin" className={linkClass}>
                Voltar para o login
              </Link>
            </p>
          </div>
        ) : (
          <>
            <TextField name="name" placeholder="Nome" required fluid />
            <EmailField
              name="email"
              placeholder="E-mail"
              validationMessages={validationMessages}
              required
            />
            <PhoneField
              name="phone"
              placeholder="Celular (WhatsApp)"
              countryCodeOptions={countryCodeOptions}
              countryCodeFieldName="phoneCountryCodeId"
              required
              fluid
            />
            <TextField
              name="password"
              type="password"
              placeholder="Senha"
              minSize={6}
              validationMessages={passwordValidationMessage}
              required
              fluid
            />
            <TextField
              name="passwordConfirmation"
              type="password"
              placeholder="Confirmação de Senha"
              required
              fluid
            />
            <div className={buttonMargin}>
              <Button
                secondary
                loading={submitting}
                disabled={pristine || submitting}
                size="big"
                fluid
              >
                Criar conta
              </Button>
            </div>
            <Link to="/signin" component="u" className={linkClass}>
              Já tem uma conta?
            </Link>
          </>
        )}
      </Form>
    </LobbyLayout>
  );
}
