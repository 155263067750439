import React, { useEffect } from "react";
import { connect } from "react-redux";
import ContentComment from "./ContentComment";
import { fetchComment } from "../../processes/commentProcesses";
import { getCommentById } from "../../selectors/commentSelectors";

function ContentCommentContainer(props) {
  const { comment, commentId, dispatch, goBack } = props;

  useEffect(() => {
    commentId && fetchComment(dispatch, commentId);
  }, [dispatch, commentId]);

  return <ContentComment comment={comment} goBack={goBack} />;
}

function mapStateToProps(state, ownProps) {
  const { commentId } = ownProps.match.params;

  return {
    comment: getCommentById(state, commentId),
    commentId,
    goBack: ownProps.history.goBack,
  };
}

export default connect(mapStateToProps)(ContentCommentContainer);
