import { replace } from "connected-react-router";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { upsertLessonsUser } from "../../processes/lessonsUserProccess";
import {
  currentCourse,
  canAccessCourse,
} from "../../selectors/courseSelectors";
import {
  getCurrentLesson,
  getNextLesson,
  getPrevLesson,
  canAccessLesson,
} from "../../selectors/lessonSelectors";
import {
  getProgressByLessonId,
  getIsWatchedByLessonId,
} from "../../selectors/lessonsUserSelectors";
import { getCurrentUser } from "../../selectors/userSelectors";
import Video from "./Video";
import { useLocation } from "react-router-dom";
import { getLessonRatingQuestion } from "../../selectors/ratingSelectors";

function VideoContainer(props) {
  const {
    dispatch,
    lesson,
    nextLesson,
    canAccessCourse,
    course,
    progress,
    initialTime,
    requestedInitialTimeChangeAt,
    openRating,
    handleOpenRating,
    ratingQuestion,
  } = props;
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const decreaseCountdownHandler = useCallback(() => {
    if (isFinished && countdown > 0 && (!openRating || !ratingQuestion)) {
      setCountdown(countdown - 1);
    }
  }, [isFinished, countdown, openRating, ratingQuestion]);

  useEffect(() => {
    const timeout = setTimeout(decreaseCountdownHandler, 1000);
    return () => clearTimeout(timeout);
  }, [decreaseCountdownHandler]);

  useEffect(() => {
    if (isFinished && countdown <= 0 && !!nextLesson?.id) {
      setIsFinished(false);
      dispatch(replace(`/courses/${course?.id}/lessons/${nextLesson?.id}`));
    }
  }, [isFinished, countdown, nextLesson?.id, dispatch, course?.id]);

  const handleFinish = useCallback(() => {
    handleOpenRating();

    if (!!nextLesson) {
      setCountdown(3);
      setIsFinished(true);
    }
  }, [nextLesson, handleOpenRating]);

  const toogleIsPlaying = useCallback(() => {
    setIsPlaying(!isPlaying);
  }, [isPlaying]);

  const handleProgress = useCallback(
    ({ played }) => {
      if (!canAccessCourse) return;
      if (played !== 0) {
        dispatch({
          type: "CURRENT_TIME_UPDATED",
          id: lesson?.id,
          currentTime: parseInt((lesson?.duration || 0) * (played || 0)),
        });
        upsertLessonsUser(dispatch, course?.id, lesson?.id, played);
      }
    },
    [dispatch, lesson?.id, lesson?.duration, course?.id, canAccessCourse]
  );

  const handleWatch = useCallback(() => {
    if (!canAccessCourse) return;

    handleProgress({ played: 1 });
  }, [handleProgress, canAccessCourse]);

  return (
    <Video
      key={`${initialTime}${requestedInitialTimeChangeAt}`}
      isPlaying={isPlaying}
      toogleIsPlaying={toogleIsPlaying}
      handleWatch={handleWatch}
      handleProgress={handleProgress}
      isFinished={isFinished}
      handleFinish={handleFinish}
      countdown={countdown}
      {...props}
      progress={
        !!initialTime ? (initialTime || 0) / (lesson?.duration || 1) : progress
      }
    />
  );
}

function mapStateToProps(state, { lesson }) {
  return {
    lesson,
    course: currentCourse(state),
    user: getCurrentUser(state),
    isWatched: getIsWatchedByLessonId(state, lesson?.id),
    progress: getProgressByLessonId(state, lesson?.id),
    nextLesson: getNextLesson(state),
    prevLesson: getPrevLesson(state),
    canAccessLesson: canAccessLesson(state, lesson?.id),
    canAccessCourse: canAccessCourse(state, lesson?.courseId),
    playerOption: state.lesson.playerOption,
    initialTime: state.lesson.initialTime,
    ratingQuestion: getLessonRatingQuestion(state),
    requestedInitialTimeChangeAt: state.lesson.requestedInitialTimeChangeAt,
  };
}

export default connect(mapStateToProps)(VideoContainer);
