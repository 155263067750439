import React from "react";
import QuestionReplyContainer from "./QuestionReplyContainer";

export default function QuestionRepliesList({
  replies,
  replied,
  readOnly,
  lesson,
}) {
  return replies.length
    ? replies.map((reply, index) => (
        <QuestionReplyContainer
          key={index}
          index={index}
          reply={reply}
          replied={replied}
          readOnly={readOnly}
          lesson={lesson}
        />
      ))
    : null;
}
