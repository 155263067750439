import memoize from "fast-memoize";
import React from "react";
import { Field } from "redux-form";
import ImageInput from "./ImageInput";
import buildFormField from "./buildFormField";

const buildValidator = memoize((required, other) => {
  return [(value) => (required && !value ? "required" : undefined)].concat(
    other || []
  );
});

const ImageFieldComponent = buildFormField(ImageInput, (input, meta, rest) => ({
  ...input,
  invalid: meta.touched && meta.invalid,
  ...rest,
}));

export default function ImageField(props) {
  return (
    <Field
      {...props}
      type="file"
      component={ImageFieldComponent}
      validate={buildValidator(props.required, props.validate)}
    />
  );
}
