import React from "react";
import Page from "../library/Page";
import { Table } from "semantic-ui-react";
import Link from "../library/Link";
import Button from "../library/Button";
import Spinner from "../library/Spinner";
import formatDate from "../../utils/formatDate";
import { spacing } from "../library/theme";

const tableWrapper = {
  display: "grid",
  gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
  overflow: "auto",
  whiteSpace: "nowrap",
};
const nameColumnStyle = {
  width: "100%",
};
const createdAtColumnStyle = {
  paddingRight: spacing.m,
};
const dashboardButtonStyle = {
  width: 160,
};

export default function Classrooms({ classrooms }) {
  return (
    <Page title="Minhas turmas">
      {classrooms.length > 0 ? (
        <div style={tableWrapper}>
          <Table unstackable style={{ minWidth: 500 }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Criada Em</Table.HeaderCell>
                <Table.HeaderCell>Nome</Table.HeaderCell>
                <Table.HeaderCell>Alunos</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {classrooms.map((classroom) => (
                <Table.Row>
                  <Table.Cell style={createdAtColumnStyle}>
                    {formatDate(classroom.createdAt, "DD/MM/YYYY")}
                  </Table.Cell>
                  <Table.Cell style={nameColumnStyle}>
                    {classroom.name}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {classroom.studentCount}
                  </Table.Cell>
                  <Table.Cell>
                    <Link
                      primary
                      component={Button}
                      style={dashboardButtonStyle}
                      to={`/classrooms/${classroom.id}`}
                    >
                      Abrir Dashboard
                    </Link>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      ) : (
        <Spinner wrapped />
      )}
    </Page>
  );
}
