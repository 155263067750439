import { css } from "glamor";
import React from "react";
import { Dropdown } from "semantic-ui-react";
import { spacing, styles, uiColors } from "../library/theme";
import ContentCommentEditFormContainer from "./ContentCommentEditFormContainer";
import ContentCommentReplyFormContainer from "./ContentCommentReplyFormContainer";
import ContentLikeFormContainer from "./ContentLikeFormContainer";
import ContentReplyListItemContainer from "./ContentReplyListItemContainer";
import UserHeaderContainer from "./UserHeaderContainer";

const container = css(styles.roundedCorners, styles.border, {
  backgroundColor: uiColors.contentCards,
  margin: `${spacing.s} 0 0 0`,
  padding: spacing.s,
  marginTop: spacing.s,
  "& + &": {
    paddingTop: spacing.m,
  },
});
const textClass = css({
  ...styles.bodyText,
  padding: `${spacing.s} 0`,
  whiteSpace: "pre-line",
  wordBreak: "break-word",
  width: "100%",
});
const rejoinderStyle = {
  margin: `0 0 ${spacing.s} 0`,
};
const rejoinderWrapper = {
  ...styles.shadow,
  ...styles.roundedCorners,
  marginTop: spacing.s,
  backgroundColor: uiColors.fieldBackground,
  padding: spacing.s,
};
const content = css({
  paddingLeft: 62,
  "@media(max-width: 575px)": {
    paddingLeft: 0,
  },
});

export default function ContentReplyListItem({
  reply,
  currentUserId,
  deleteHandler,
  replies,
  rejoinder,
  isEditMode,
  toogleIsEditMode,
}) {
  const user = reply.meta.user;
  return (
    <div className={css(container, rejoinder && rejoinderStyle)}>
      <UserHeaderContainer
        user={user}
        createdAt={reply.createdAt}
        actions={
          user.id == currentUserId && (
            <>
              <Dropdown.Item onClick={toogleIsEditMode}>Editar</Dropdown.Item>
              <Dropdown.Item onClick={deleteHandler}>Apagar</Dropdown.Item>
            </>
          )
        }
      />
      {isEditMode ? (
        <ContentCommentEditFormContainer
          reply
          comment={reply}
          toogleIsEditMode={toogleIsEditMode}
        />
      ) : (
        <>
          <div className={content}>
            <div
              className={textClass}
              dangerouslySetInnerHTML={{
                __html: reply.text,
              }}
            ></div>
            <ContentLikeFormContainer
              commentId={reply.id}
              likesCount={reply.likeableCount}
            />
            {!rejoinder && (
              <div style={rejoinderWrapper}>
                {replies.length > 0 && (
                  <span>
                    <h4>Respostas</h4>
                    {replies.map((reply) => (
                      <ContentReplyListItemContainer
                        key={`reply${reply.id}`}
                        reply={reply}
                        rejoinder
                      />
                    ))}
                  </span>
                )}
                <ContentCommentReplyFormContainer
                  form={`ReplyRejoinder${reply?.id}Form`}
                  key={`ReplyRejoinder${reply?.id}Form`}
                  comment={reply}
                  contentId={reply.commentableId}
                  rejoinder
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
