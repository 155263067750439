import React, { useEffect } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { fetchCities } from "../../processes/configProcesses";
import { searchAddressForProfileEdit } from "../../processes/postalCodeProcesses";
import { updateProfile } from "../../processes/profileProcesses";
import { getCitiesByStateId, getConfig } from "../../selectors/configSelectors";
import { getCurrentUser } from "../../selectors/userSelectors";
import buildForm from "../library/forms/buildForm";
import ProfileEditAddressForm from "./ProfileEditAddressForm";

const FORM_NAME = "addressProfileEdit";
const form = buildForm(FORM_NAME);

function ProfileEditFormContainer(props) {
  const { stateId, dispatch } = props;

  useEffect(() => {
    stateId && fetchCities(dispatch, stateId);
  }, [dispatch, stateId]);

  return <ProfileEditAddressForm {...props} />;
}

function mapStateToProps(state) {
  const currentUser = getCurrentUser(state);
  const profileEditFormSelector = formValueSelector(FORM_NAME);
  const {
    stateId,
    addressZip,
    searchingAddress,
    searchedAddress,
    searchedAddressNeighborhood,
    searchedStateId,
    searchedCityId,
  } = profileEditFormSelector(
    state,
    "stateId",
    "addressZip",
    "searchingAddress",
    "searchedAddress",
    "searchedAddressNeighborhood",
    "searchedStateId",
    "searchedCityId"
  );

  return {
    currentUser,
    initialValues: currentUser,
    imageUrl: form.getValue("imageUrl", state),
    states: getConfig(state, "states"),
    cities: getCitiesByStateId(state, stateId),
    stateId,
    addressZip,
    searchingAddress,
    searchedAddress,
    searchedAddressNeighborhood,
    searchedStateId,
    searchedCityId,
  };
}

const asyncValidate = (values, dispatch, props) => {
  const { addressZip, searchedAddressZip } = values;
  const initialAddressZip = props?.initialValues?.addressZip;

  const alreadySearched = addressZip == searchedAddressZip;

  const isZipCodeChanged =
    addressZip != initialAddressZip ||
    (searchedAddressZip && searchedAddressZip != initialAddressZip);

  const enoughZipLength = addressZip?.replace(/[^0-9]+/g, "")?.length == 8;

  if (isZipCodeChanged && !alreadySearched && enoughZipLength) {
    searchAddressForProfileEdit(dispatch, {
      postalCode: addressZip,
      formName: FORM_NAME,
    });
  }

  return Promise.resolve(true);
};

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: updateProfile,
    asyncValidate,
  })(ProfileEditFormContainer)
);
