import React from "react";
import { Table } from "semantic-ui-react";

const getScore = (level) => {
  //Mobile app uses this snake_case prop item of results aray of mock object
  //this variable need to be serialized and return key5 in camelCase

  const correct = level?.countTrue;
  const total = level?.countTrue + level?.countFalse;
  return parseInt((correct / total) * 100);
};

const getMinScore = (mockTemplate, mockTemplateLevel) => {
  return mockTemplate?.levelMinScoreEnabled && mockTemplateLevel?.minScore
    ? mockTemplateLevel?.minScore
    : null;
};

export default function MockExamLevelItemReport(props) {
  const { level, mockTemplate } = props;
  const mockTemplateLevel = mockTemplate?.levels?.find(
    (levelMockTemplate) => levelMockTemplate.levelId == level.id
  );
  const score = getScore(level);
  const minScore = getMinScore(mockTemplate, mockTemplateLevel);
  const showStatus =
    mockTemplate?.levelMinScoreEnabled && mockTemplateLevel?.minScore;
  const isScoreEnough = score >= minScore;

  return (
    <Table.Row>
      <Table.Cell>{level?.name}</Table.Cell>
      <Table.Cell>{minScore ? `${minScore}%` : null}</Table.Cell>
      <Table.Cell
        positive={showStatus && isScoreEnough}
        negative={showStatus && !isScoreEnough}
      >
        {score}%
      </Table.Cell>
    </Table.Row>
  );
}
