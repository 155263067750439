import React from "react";
import { connect } from "react-redux";
import NotFound from "../components/notFound/NotFound";
import Spinner from "../components/library/Spinner";
import { statusString } from "../utils/statusString";

const hasAccessToProps = (testAccess) => (state) => {
  return {
    hasAccess: testAccess(state),
  };
};

export function restrictAccess(Component, testAccess) {
  return connect(hasAccessToProps(testAccess))(function RestrictedComponent({
    hasAccess,
    ...props
  }) {
    if (typeof hasAccess === "string" && hasAccess === statusString.loading) {
      return <Spinner />;
    }
    return hasAccess === statusString.allow ? (
      <Component {...props} />
    ) : (
      <NotFound />
    );
  });
}
