import React, { useEffect, useCallback } from "react";
import { connect } from "react-redux";
import Comments from "./Comments";
import { fetchLessonComments } from "../../processes/commentProcesses";
import { getFetchedComments } from "../../selectors/commentSelectors";

function CommentsContainer(props) {
  const { dispatch, currentCourseId, lessonId } = props;

  const fetchCommentsHandler = useCallback(
    (_, data) =>
      fetchLessonComments(dispatch, currentCourseId, lessonId, data.activePage),
    [dispatch, currentCourseId, lessonId]
  );

  React.useEffect(() => {
    fetchCommentsHandler(dispatch, currentCourseId, lessonId);
  }, [dispatch, fetchCommentsHandler]);

  return <Comments fetchCommentsHandler={fetchCommentsHandler} {...props} />;
}

function mapStateToProps(state, ownProps) {
  const lessonId = ownProps.lessonId;
  return {
    comments: getFetchedComments(state),
    currentCourseId: state.currentCourseId,
    lessonId,
    loading: state.commentList.loading,
    totalPages: state.commentList.totalPages,
    currentPage: state.commentList.currentPage,
  };
}

export default connect(mapStateToProps)(CommentsContainer);
