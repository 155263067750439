import React, { useCallback } from "react";
import { connect } from "react-redux";
import {
  createMockAnswer,
  createLessonAnswer,
} from "../../processes/answerProccess";
import { finishMock } from "../../processes/mockProccess";
import { goToIndex } from "../../processes/questionProccess";
import { getReplyAnswered } from "../../selectors/answerSelector";
import {
  getCourseFromMock,
  hasMockCourses,
} from "../../selectors/courseSelectors";
import { getMock, makeIsComplete } from "../../selectors/mockSelectors";
import {
  getQuestionByIndex,
  getQuestionsFromMock,
  makeCurrentQuestion,
} from "../../selectors/questionSelectors";
import QuestionNavigation from "./QuestionNavigation";
import { replace } from "connected-react-router";

function QuestionNavigationContainer(props) {
  const {
    currentIndex,
    dispatch,
    mockId,
    courseId,
    questionId,
    replied,
    lesson,
    routePrefix = "",
  } = props;

  const goToNextQuestionHandler = useCallback(
    () => goToIndex(dispatch, currentIndex + 1),
    [dispatch, currentIndex]
  );

  const finishExamMockHandler = useCallback(() => {
    finishMock(dispatch, mockId).then(() => {
      dispatch(replace(`${routePrefix}/mocks/${mockId}/results`));
    });
  }, [dispatch, mockId, routePrefix]);

  const goToPrevQuestionHandler = useCallback(() => {
    goToIndex(dispatch, currentIndex - 1);
  }, [dispatch, currentIndex]);

  const answerHandler = useCallback(
    () =>
      lesson
        ? createLessonAnswer(dispatch, lesson, replied?.id)
        : createMockAnswer(dispatch, courseId, mockId, questionId, replied?.id),
    [dispatch, courseId, mockId, questionId, replied, lesson]
  );

  return (
    <QuestionNavigation
      {...props}
      lesson={lesson}
      finishExamMockHandler={finishExamMockHandler}
      goToNextQuestionHandler={goToNextQuestionHandler}
      goToPrevQuestionHandler={goToPrevQuestionHandler}
      answerHandler={answerHandler}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const { lesson } = ownProps;
  const questionId = makeCurrentQuestion(state)?.id;
  const mock = getMock(state);
  const mockId = mock?.id;
  const replied = lesson
    ? state.lesson.selectedReply
    : state.mock.selectedReplies[questionId];
  const currentIndex = state.mock.currentIndex;
  const questionsLength = getQuestionsFromMock(state)?.length;
  const nextQuestion = getQuestionByIndex(state, currentIndex + 1);
  const isLastQuestion = currentIndex == questionsLength - 1;

  return {
    lesson,
    isLastQuestion,
    questionsLength,
    currentIndex,
    courseId: getCourseFromMock(state)?.id,
    replied,
    isNextAnswered: !!getReplyAnswered(state, {
      questionId: nextQuestion?.id,
      mockId,
      lessonId: lesson?.id,
    }),
    isAnswered: !!getReplyAnswered(state, {
      questionId,
      mockId,
      lessonId: lesson?.id,
    }),
    isFinished: mock?.finishedAt,
    isExam: mock?.exam,
    mockId,
    questionId,
    isComplete: makeIsComplete(state),
    hasMockCourses: hasMockCourses(state),
  };
}

export default connect(mapStateToProps)(QuestionNavigationContainer);
