import React from "react";
import { Modal } from "semantic-ui-react";
import Button from "../library/Button";
import Icon from "../library/Icon";
import { spacing } from "../library/theme";
import Link from "../library/Link";

const detailStyle = {
  display: "flex",
  alignItems: "baseline",
  gap: spacing.xxs,
  marginBottom: spacing.xxs,
};

export default function CompanyImportDetails({ details }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = React.useCallback(() => setOpen(true), []);
  const handleClose = React.useCallback(() => setOpen(false), []);

  return (
    <React.Fragment>
      {open && (
        <Modal
          dimmer="blurring"
          open
          onClose={handleClose}
          size="tiny"
          closeIcon
        >
          <Modal.Header>Detalhes</Modal.Header>
          <Modal.Content>
            {details?.split("\n").map((detail, index) => {
              if (!detail) return null;

              const isSuccess = detail.toLowerCase().startsWith("success:");
              const isError = detail.toLowerCase().startsWith("error:");

              if (isSuccess) {
                return (
                  <div key={index} style={detailStyle}>
                    <Icon name="check circle" color="green" />
                    <p>{detail.replace(/^success:/i, "")}</p>
                  </div>
                );
              }
              if (isError) {
                return (
                  <div key={index} style={detailStyle}>
                    <Icon name="exclamation circle" color="red" />
                    <p>{detail.replace(/^error:/i, "")}</p>
                  </div>
                );
              }
              if (!isError && !isSuccess) {
                return <p key={index}>{detail}</p>;
              }
            })}
          </Modal.Content>
        </Modal>
      )}
      <Link onClick={handleOpen} disabled={details == ""}>
        Ver detalhes
      </Link>
    </React.Fragment>
  );
}
