import { createLocation } from "history";
import React from "react";
import { useRouteMatch, withRouter } from "react-router";

export const resolveToLocation = (to, currentLocation) =>
  typeof to === "function" ? to(currentLocation) : to;

export const normalizeToLocation = (to, currentLocation) => {
  return typeof to === "string"
    ? createLocation(to, null, null, currentLocation)
    : to;
};

const isActive = ({ active, to }, routeMatch) => {
  return active === undefined && !!to ? routeMatch : active;
};

const defaultStyle = { cursor: "pointer" };

const makeClickHandler =
  (location, href, history, useReplace, onClick) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (onClick) onClick(e);
    if (href) {
      window.open(href, e.currentTarget.target || "_self");
    }
    if (location) {
      useReplace ? history.replace(location) : history.push(location);
    }
  };
function Link(props) {
  const {
    noHref,
    addActive,
    replace,
    onClick,
    component,
    to,
    href,
    active,
    history,
    location,
    style,
    match, // eslint-disable-line no-unused-vars
    staticContext, // eslint-disable-line no-unused-vars
    className,
    ...rest
  } = props;
  const Component = component || "a";
  const handleClick = React.useCallback(
    makeClickHandler(to, href, history, replace, onClick),
    [to, href, history, replace, onClick]
  );

  const toLocation = normalizeToLocation(
    resolveToLocation(to, location),
    location
  );
  const routeMatch = useRouteMatch(to);
  return (
    <Component
      href={!noHref && to ? history.createHref(toLocation) : href}
      active={addActive ? isActive(props, routeMatch) : active}
      onClick={handleClick}
      style={{ ...defaultStyle, ...style }}
      className={className}
      {...rest}
    />
  );
}
export default withRouter(Link);
