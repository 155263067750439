import React from "react";
import { Modal } from "semantic-ui-react";
import Button from "../library/Button";
import Link from "../library/Link";
import { spacing } from "../library/theme";
import ImportStudentsFormContainer from "./ImportStudentsFormContainer";

const formWrapperStyle = {
  marginTop: spacing.s,
  marginBottom: spacing.s,
};
const modalStyle = {
  overflow: "initial",
};

export default function AddStudentModal({
  company,
  courseId,
  classroomId,
  disabled,
  triggerTitle,
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = React.useCallback(() => setOpen(true), []);
  const handleClose = React.useCallback(() => setOpen(false), []);

  return (
    <React.Fragment>
      {open && (
        <Modal
          dimmer="blurring"
          open
          onClose={handleClose}
          size="tiny"
          closeIcon
          style={modalStyle}
        >
          <Modal.Header>Importar alunos via planilha</Modal.Header>
          <Modal.Content>
            <div style={formWrapperStyle}>
              <ImportStudentsFormContainer
                company={company}
                courseId={courseId}
                classroomId={classroomId}
                handleClose={handleClose}
              />
            </div>
          </Modal.Content>
        </Modal>
      )}

      <Button
        onClick={handleOpen}
        disabled={disabled}
        color="green"
        icon="user plus"
        content={triggerTitle}
      />
    </React.Fragment>
  );
}
