import React from "react";
import { css } from "glamor";
import ContentCommentListItemContainer from "./ContentCommentListItemContainer";
import { spacing, stateColors, uiColors } from "../library/theme";

const emptyText = css({
  textAlign: "center",
  color: stateColors.empty,
});
const contentComment = css({
  "& + &": {
    marginTop: spacing.m,
    paddingTop: spacing.m,
    borderTop: `1px solid ${uiColors.border}`,
  },
});

export default function ContentCommentList({ comments }) {
  return comments.length > 0 ? (
    comments.map((comment) => (
      <div
        key={`contentComment${comment.id}ListItem`}
        className={contentComment}
      >
        <ContentCommentListItemContainer comment={comment} />
      </div>
    ))
  ) : (
    <p className={emptyText}>Nenhuma publicação por aqui</p>
  );
}
