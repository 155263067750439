import React from "react";
import { css } from "glamor";
import { toBRLCurrency } from "../../utils/formatCurrency";
import truncateString from "../../utils/truncateString";
import Button from "../library/Button";
import Link from "../library/Link";
import Icon from "../library/Icon";
import Progress from "../library/Progress";
import Label from "../library/Label";
import { colors, spacing, styles } from "../library/theme";
import CourseListItemDiscountIcon from "./CourseListItemDiscountIcon";
import CourseProgress from "./CourseProgress";
import calcPaymentValue from "../../utils/calcPaymentValue";
import getContrastTextColor from "../../utils/getContrastTextColor";
import daysDiff from "../../utils/daysDiff";

const INITIAL_PROGRESS = 0.01;

const container = {
  boxSizing: "border-box",
  width: "100%",
  borderRadius: spacing.m,
  boxShadow: "10px 0px 24px 0px #00000040",
};
const aspectRatioClass = css({
  height: 0,
  width: "100%",
  paddingBottom: "136.41%",
  position: "relative",
});
const roundedBorderClass = css({
  borderRadius: spacing.m,
  overflow: "hidden",
});
const bgClass = css(aspectRatioClass, roundedBorderClass, {
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
});
const aspectRatioContent = css({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});
const content = css(aspectRatioContent, {
  background: "linear-gradient(189.3deg, rgba(10, 32, 40, 0) 8%, #0A2028 60%)",
  display: "flex",
  flexDirection: "column",
});
const details = css({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});
const detailsHeader = css({
  display: "flex",
  justifyContent: "space-between",
  gap: spacing.s,
  padding: spacing.s,
});
const detailsBody = css({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "end",
  padding: spacing.m,
});
const title = css(styles.alternativeFontFamily, {
  fontWeight: "bold",
  textAlign: "center",
  color: colors.white,
  lineHeight: 1.2,
  fontSize: "18px",
});
const description = css({
  display: "flex",
  justifyContent: "space-between",
  gap: spacing.s,
  padding: `${spacing.s} 0px`,
  color: colors.primary,
});
const remainingDescritption = css({
  textAlign: "center",
  padding: spacing.s,
  color: colors.greyLighter,
  fontSize: "15px",
});
const iconRightMargin = {
  marginRight: spacing.xxs,
  marginLeft: 0,
};
const price = css({
  backgroundColor: colors.black,
  color: `${colors.white} !important`,
  padding: spacing.s,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: spacing.xs,
  ":hover": {
    color: `${colors.primary} !important`,
    transition: "color 0.3s ease",
  },
});
const priceTextClass = css({
  whiteSpace: "pre-line",
  lineHeight: 1,
  fontWeight: "600",
});
const priceClass = css({
  fontWeight: "bold",
  fontSize: "28px",
});
const lastWordClass = css(styles.alternativeFontFamily, {
  display: "block",
  fontWeight: "bolder",
  fontSize: "36px",
  lineHeight: 1.2,
});
const biggerTitleClass = css({
  fontSize: "24px !important",
  fontWeight: "bolder",
  lineHeight: 1.2,
});
const courseProgressClass = css({
  width: "100%",
});
const progressBar = css({
  marginBottom: "0 !important",
  backgroundColor: `${colors.greyShadow} !important`,
});

const getRemainingDaysText = (days) => {
  return `${days} ${days > 1 ? "dias restantes" : "dia restante"}`;
};

export default function CourseListItem(props) {
  const {
    course,
    offers,
    canAccessCourse,
    courseUser,
    user,
    trialEnrollHandler,
    createFreeEnrollmentHandler,
    reinstatementHandler,
  } = props;

  const lastOffer = offers?.length && offers[offers?.length - 1];
  const isTrial = canAccessCourse && courseUser?.trial;
  const isFreeEnrollEnabled = !canAccessCourse && course?.isFree;
  const displayBuyOptions =
    !!offers.length &&
    !canAccessCourse &&
    !courseUser?.paused &&
    !course?.trialEnrollmentEnabled;
  const hasStudentDiscount =
    !!course?.resellDiscount &&
    lastOffer?.hasStudentDiscount &&
    user?.isFromPreparatoryCourse;
  const remainingDays = courseUser?.remainingDays;
  const remainingDaysText = getRemainingDaysText(remainingDays);
  const isCourseActive =
    canAccessCourse && !courseUser?.paused && !courseUser?.trial;
  const hasProgress = course?.meta?.progress > INITIAL_PROGRESS;
  const isNewOnCourse = isCourseActive && !hasProgress;
  const priceForMaxInstallments =
    lastOffer &&
    calcPaymentValue(
      user?.isFromPreparatoryCourse,
      lastOffer,
      lastOffer?.meta?.coupon ? lastOffer?.meta?.coupon : undefined,
      lastOffer?.maxInstallments
    );
  const titleWords = course?.name?.split(" ");
  const titleLastWord = titleWords?.pop();
  const isHotmartSale =
    course?.salesChannel == "hotmart" && !isFreeEnrollEnabled;
  const checkoutLink =
    course?.salesChannel == "hotmart"
      ? course?.paymentUrl
      : `/courses/${course?.id}/checkout`;
  const priceLink = isFreeEnrollEnabled
    ? `/courses/${course?.id}`
    : checkoutLink;
  const priceText = isFreeEnrollEnabled
    ? "Gratuito!"
    : toBRLCurrency(
        +priceForMaxInstallments / (+lastOffer?.maxInstallments || 1)
      );
  const priceInstallmentText = `${lastOffer?.maxInstallments || 1}x\nDE`;

  const textContrastColor = getContrastTextColor(course?.color);
  const linkButtonWrappperClass = css({
    width: "100%",
    "> a, > button": {
      color: `${textContrastColor} !important`,
      backgroundColor: `${course?.color} !important`,
      ":hover": { backgroundColor: `${course?.color}DD !important` },
    },
  });
  const bgImageClass = css(bgClass, {
    backgroundImage: `url(${course?.imageUrl})`,
  });
  const titleClass = css(
    title,
    !course?.highlightLastWord && biggerTitleClass,
    {
      "> span": course?.highlightLastWord ? lastWordClass : biggerTitleClass,
    }
  );
  const labelClass = css({
    backgroundColor: `${
      !!courseUser ? colors.primary : course?.color
    } !important`,
    color: `${textContrastColor} !important`,
  });
  const progressBarClass = css(progressBar, {
    ".ui.progress .bar": {
      backgroundColor: `${course?.color || colors.primary} !important`,
    },
  }).toString();

  return (
    <Link to={`/courses/${course?.id}`} style={container}>
      <div className={bgImageClass}>
        <div className={content}>
          <div className={details}>
            <div className={detailsHeader}>
              <div>
                {courseUser?.paused && <Label color="orange">Trancado</Label>}
                {(course?.trialEnrollmentEnabled || isTrial) && (
                  <Label color="red">
                    <Icon name="clock" style={iconRightMargin} /> Trial
                  </Label>
                )}
                {(isFreeEnrollEnabled ||
                  (isNewOnCourse && !course?.trialEnrollmentEnabled)) && (
                  <Label className={labelClass}>
                    {isNewOnCourse && "Novo"}
                    {isFreeEnrollEnabled && "Gratuito"}
                  </Label>
                )}
              </div>
            </div>
            <div className={detailsBody}>
              <div className={titleClass}>
                {titleWords.join(" ")}
                <span>{titleLastWord}</span>
              </div>

              {!isTrial && !courseUser?.paused && (
                <div className={description}>
                  <div>
                    <Icon name="bookmark outline" />
                    {course?.meta?.levelCount} Módulos
                  </div>
                  <div>
                    <Icon name="clock outline" />
                    {course.workload}
                  </div>
                </div>
              )}

              {isCourseActive && !course?.trialEnrollmentEnabled && (
                <div className={courseProgressClass}>
                  <Progress
                    percent={course?.meta?.progress * 100}
                    className={progressBarClass}
                    size="tiny"
                  />
                </div>
              )}

              {(isTrial || isCourseActive || courseUser?.paused) && (
                <div className={remainingDescritption}>
                  {remainingDays > 0 && remainingDaysText}
                </div>
              )}

              <div className={linkButtonWrappperClass}>
                {course?.trialEnrollmentEnabled && (
                  <Link
                    component={Button}
                    onClick={trialEnrollHandler}
                    size="big"
                    fluid
                  >
                    Teste Grátis
                  </Link>
                )}
                {isFreeEnrollEnabled && (
                  <Link
                    component={Button}
                    onClick={createFreeEnrollmentHandler}
                    size="big"
                    fluid
                  >
                    Matricule-se já
                  </Link>
                )}
                {displayBuyOptions && (
                  <Link
                    component={Button}
                    to={
                      course?.salesChannel == "hotmart"
                        ? undefined
                        : `/courses/${course?.id}/checkout`
                    }
                    href={
                      course?.salesChannel == "hotmart"
                        ? course?.paymentUrl
                        : undefined
                    }
                    target={course?.salesChannel == "hotmart" && "_blank"}
                    size="big"
                    fluid
                  >
                    Matricule-se já
                  </Link>
                )}
                {courseUser?.paused && (
                  <Button onClick={reinstatementHandler} size="big" fluid>
                    Retomar acesso
                  </Button>
                )}
                {isTrial && (
                  <Link
                    component={Button}
                    to={`/courses/${course?.id}`}
                    size="big"
                    fluid
                  >
                    {hasProgress ? "Continuar Trial" : "Iniciar Trial"}
                  </Link>
                )}
                {isCourseActive && (
                  <Link
                    component={Button}
                    to={`/courses/${course?.id}`}
                    size="big"
                    fluid
                  >
                    {hasProgress ? "Continuar" : "Iniciar"}
                  </Link>
                )}
              </div>
            </div>
          </div>

          {(displayBuyOptions ||
            course?.trialEnrollmentEnabled ||
            isFreeEnrollEnabled ||
            isTrial) && (
            <Link
              to={isHotmartSale ? undefined : priceLink}
              href={isHotmartSale ? priceLink : undefined}
              target={isHotmartSale ? "_blank" : undefined}
              className={price}
            >
              {!isFreeEnrollEnabled && hasStudentDiscount && (
                <CourseListItemDiscountIcon
                  height={70}
                  width={71}
                  discount={course?.resellDiscount}
                />
              )}

              {!isFreeEnrollEnabled && (
                <div className={priceTextClass}>{priceInstallmentText}</div>
              )}

              <div className={priceClass}>{priceText}</div>
            </Link>
          )}
        </div>
      </div>
    </Link>
  );
}
