import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { submitSearch } from "../../processes/questionProccess";
import debounce from "../../utils/debounce";
import QuestionSearchForm from "./QuestionSearchForm";

function mapStateToProps(state, props) {
  const { questionId } = props.match.params;

  return {
    initialValues: { search: questionId },
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "questionSearchForm",
    onSubmit: submitSearch,
    onChange: debounce(submitSearch, 500),
  })(QuestionSearchForm)
);
