import { createSelector } from "reselect";
import { orderByCreatedAtDesc } from "../utils/orderByCreatedAt";

const getNotificationsUsers = (state) => state.entities.notificationsUsers;
const getId = (_, id) => id;

export const getAllNotificationsUsers = createSelector(
  getNotificationsUsers,
  (notificationsUsers) =>
    Object.values(notificationsUsers).sort(orderByCreatedAtDesc)
);

export const getNotificationsUserByNotificationId = createSelector(
  getAllNotificationsUsers,
  getId,
  (notificationsUsers, id) =>
    notificationsUsers.filter(
      (notificationsUser) => notificationsUser.notificationId == id
    )[0]
);

export const getUnreadNotificationIds = createSelector(
  getAllNotificationsUsers,
  (notificationsUsers) =>
    notificationsUsers
      .filter((notificationUser) => !notificationUser.seenAt)
      .map((notificationUser) => notificationUser.notificationId)
);

export const getUnreadNotificationsCount = createSelector(
  getUnreadNotificationIds,
  (ids) => ids.length
);
