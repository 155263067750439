import memoize from "fast-memoize";
import React from "react";
import { Field } from "redux-form";
import isEmail from "validator/lib/isEmail";
import EmailInput from "./EmailInput";
import buildFormField from "./buildFormField";

const FormField = buildFormField(EmailInput, (input, meta, rest) => {
  return {
    ...input,
    ...rest,
    invalid: `${meta.touched && (meta.invalid || rest.invalid)}`,
  };
});

const required = ({ value }) => (value ? undefined : "required");

const validateFormat = ({ value }) =>
  !!value && (isEmail(value) ? undefined : "email");

const validateConfirmation = ({ value, email, name }) =>
  name !== "emailConfirmation" || value == email
    ? undefined
    : "emailConfirmation";

const composeValidators =
  (...validators) =>
  (value, { email }, _, name) =>
    validators.reduce(
      (error, validator) => error || validator({ value, email, name }),
      undefined
    );

const buildValidator = memoize(
  (isRequired, anotherValidate = new Function()) => {
    const validators = [validateFormat, validateConfirmation];

    isRequired && validators.unshift(required);

    return composeValidators(...validators, anotherValidate);
  }
);

export default function EmailField(props) {
  const { required, validate } = props;
  return (
    <Field
      {...props}
      component={FormField}
      validate={buildValidator(required, validate)}
    />
  );
}
