import { connect } from "react-redux";
import { getCourseCategoriesWithUserCourse } from "../../selectors/configSelectors";
import Courses from "./Courses";

function CoursesUserContainer(props) {
  return <Courses justOwned {...props} />;
}

function mapStateToProps(state) {
  return {
    loading: state.coursesList.loading,
    courseCategories: getCourseCategoriesWithUserCourse(state),
  };
}

export default connect(mapStateToProps)(CoursesUserContainer);
