import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { updateComment } from "../../processes/commentProcesses";
import ContentCommentEditForm from "./ContentCommentEditForm";

function mapStateToProps(state, ownProps) {
  const { comment } = ownProps;

  return {
    form: `updateComment${comment?.id}`,
    initialValues: comment,
    comment,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    onSubmit: updateComment,
  })(ContentCommentEditForm)
);
