import { css } from "glamor";
import React from "react";
import { Card, Modal } from "semantic-ui-react";
import Spinner from "../../library/Spinner";
import Link from "../../library/Link";
import { colors, spacing } from "../../library/theme";
import QuestionSearchEmpty from "../../questions/QuestionSearchEmpty";
import QuestionSearchFormContainer from "../../questions/QuestionSearchFormContainer";
import QuestionSearchItemContainer from "../../questions/QuestionSearchItemContainer";

const container = css({
  padding: spacing.m,
});
const resultCardStyle = {
  padding: spacing.m,
  margin: `${spacing.m} 0px 0px 0px`,
};
const content = css({
  minHeight: "75vh",
});
const titleClass = css({
  margin: 0,
  fontWeight: "bold",
  marginBottom: spacing.m,
  display: "flex",
  justifyContent: "space-between",
});
const questionClass = css({
  marginTop: spacing.m,
});
const blockedClass = css({
  padding: `${spacing.xxl} ${spacing.m}`,
  textAlign: "center",
});

export default function QuestionSearch(props) {
  const {
    questions,
    isSearching,
    loading,
    currentUser,
    hasMockCourses,
    courseLoading,
  } = props;

  return courseLoading ? (
    <Spinner />
  ) : (
    <div className={container}>
      <div className={content}>
        <h1 className={titleClass}>Buscar Questões</h1>

        <QuestionSearchFormContainer
          loading={loading}
          disabled={!hasMockCourses}
          {...props}
        />

        <div className={questionClass}>
          {!hasMockCourses && (
            <p className={blockedClass}>Você não pode pesquisar questões</p>
          )}

          {loading ? (
            <Spinner text="Buscando questões" wrapped />
          ) : (
            isSearching && (
              <>
                {questions.length > 0 ? (
                  questions?.map((question) => (
                    <Card
                      fluid
                      style={resultCardStyle}
                      key={`question${question?.id}`}
                    >
                      <QuestionSearchItemContainer
                        question={question}
                        currentUser={currentUser}
                      />
                    </Card>
                  ))
                ) : (
                  <QuestionSearchEmpty />
                )}
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
}
