export default function makeDropDownOptions(collection = [], blank) {
  const options = [];
  if (blank) options.push({ text: blank, value: "" });
  collection.map((item) =>
    options.push({
      ...item,
      text: `${item.name || item.title}`,
      value: item.id,
    })
  );
  return options;
}
