import { normalize, schema } from "normalizr";
import { apiGet } from "./helpers/api";

const reportSchema = new schema.Entity(
  "reports",
  {
    mockTemplate: {
      mocks: [new schema.Entity("mocks")],
    },
  },
  { idAttribute: "courseId" }
);

export const fetchReports = (dispatch, courseId) => {
  dispatch({ type: "REPORT_FETCH_REQUESTED" });
  return apiGet(`/api/v1/courses/${courseId}/reports`).then((response) => {
    dispatch({
      type: "REPORT_FETCHED",
      payload: { [courseId]: response.body.data },
      ...normalize(response.body.data, reportSchema),
    });
  });
};

export const fetchClassroomReports = (dispatch, classroomId, userId) => {
  apiGet(
    `/api/v1/classrooms/${classroomId}/users/${userId}/classroom_reports`
  ).then((response) => {
    const data = response.body.data;
    const courseId = data["courseId"];

    dispatch({
      type: "REPORT_FETCHED",
      payload: { [courseId]: data },
      ...normalize(response.body.data, reportSchema),
    });
  });
};

export const selectedCourseIdChanged = (dispatch, id) =>
  dispatch({ type: "SELECTED_COURSE_CHANGED", id });
