import { normalize, schema } from "normalizr";
import { apiGet, apiPost, apiDel } from "./helpers/api";

export const likeSchema = new schema.Entity("likes");

export const fetchLikes = (dispatch) => {
  apiGet("/api/v1/likes").then((response) => {
    dispatch({
      type: "LIKES_FETCHED",
      ...normalize(response.body.data, new schema.Array(likeSchema)),
    });
  });
};

export const createLike = (dispatch, likeableType, likeableId) => {
  return apiPost(`/api/v1/likes/`)
    .send({ likeableType, likeableId })
    .then((response) => {
      dispatch({
        type: "LIKE_CREATED",
        ...normalize(response.body.data, likeSchema),
      });
    });
};

export const deleteLike = (dispatch, likeId) => {
  return apiDel(`/api/v1/likes/${likeId}`).then(() => {
    dispatch({
      type: "LIKE_DELETED",
      id: likeId,
    });
  });
};
