export default function filterBySearchFields(
  object,
  search,
  fields,
  showAllOnEmptySearch = true
) {
  if (!search) {
    return showAllOnEmptySearch;
  }

  const filterSearch = search.toLowerCase();

  return fields.some((field) => {
    const fieldValue = String(object[field]).toLowerCase();
    return fieldValue.includes(filterSearch);
  });
}
