import { css } from "glamor";
import React from "react";
import { Dropdown } from "semantic-ui-react";
import { fromNow } from "../../utils/formatDate";
import Link from "../library/Link";
import {
  decorationColors,
  spacing,
  styles,
  typographyColors,
} from "../library/theme";
import FollowFormContainer from "../networking/FollowFormContainer";

const IMAGE_SIZE = 50;
const userWrapper = css({
  display: "flex",
  alignItems: "top",
  justifyContent: "left",
});
const userAvatarStyle = {
  ...styles.roundedCorners,
  position: "relative",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  height: IMAGE_SIZE,
  width: IMAGE_SIZE,
  margin: `0 ${spacing.s} ${spacing.xs} 0 `,
  cursor: "pointer",
};
const userNameClass = css({
  color: typographyColors.blackLetters,
  fontSize: 16,
  cursor: "pointer",
  marginBottom: 0,
  display: "flex",
  alignItems: "center",
  marginRight: spacing.xs,
});
const dateClass = css({
  color: typographyColors.defaultBody,
  fontSize: "10px",
});
const followClass = css({
  marginLeft: spacing.s,
});
const actionStyle = {
  marginLeft: "auto",
  height: "25px",
};
const badgeClass = css({
  ...styles.roundedCorners,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  position: "absolute",
  left: 0,
  right: 0,
  top: 40,
  bottom: 0,
  backgroundColor: decorationColors.decorationBrand,
  height: 16,
  width: IMAGE_SIZE,
  textTransform: "capitalize",
  fontSize: 8,
  letterSpacing: 0.8,
  color: typographyColors.inverseTitle,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const titleClass = css({
  height: "28px",
  display: "flex",
  alignItems: "center",
});
const shortcutClass = css({
  flex: 1,
});
const shorcutTitle = css({
  height: "100%",
  alignItems: "initial",
});
const shortcutName = css({
  alignItems: "initial",
  marginRight: 0,
  fontSize: 14,
  flex: 1,
});

export default function UserHeader({
  user = {},
  createdAt,
  actions,
  currentUserId,
  shortcut,
}) {
  const { avatarUrl, firstName, badges, id } = user;
  const userAvatar = css(userAvatarStyle, {
    backgroundImage: `url(${avatarUrl})`,
  });

  return (
    <div className={userWrapper}>
      <Link to={`/networking/${id}`} component="div" className={userAvatar}>
        {badges.length > 0 && (
          <div className={badgeClass}>
            {badges[0]?.id == 1 && "Fundador"}
            {badges[0]?.id == 2 && "T2"}
          </div>
        )}
      </Link>
      <div className={css(shortcut && shortcutClass)}>
        <div className={css(titleClass, shortcut && shorcutTitle)}>
          <Link
            to={`/networking/${id}`}
            component="h4"
            className={css(userNameClass, shortcut && shortcutName)}
          >
            {firstName}
          </Link>
          {currentUserId != id && (
            <div className={followClass}>
              <FollowFormContainer userId={id} small />
            </div>
          )}
        </div>
        {createdAt && <p className={dateClass}>{fromNow(createdAt)}</p>}
      </div>
      {actions && (
        <Dropdown icon="ellipsis vertical" direction="left" style={actionStyle}>
          <Dropdown.Menu>{actions}</Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
}
