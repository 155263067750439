import { createSelector } from "reselect";
import { orderByName } from "../utils/orderByName";

function orderByDate(a, b) {
  return new Date(b.createdAt) - new Date(a.createdAt);
}

function orderByScore(a, b) {
  return b.score === a.score ? a.id - b.id : b.score - a.score;
}

const getMocks = (state) => state.entities.mocks;
const getMockRankings = (state) => state.entities.mockRankings;
const getFetchedIds = (state) => state.mockList.mockIds;
const getRankingIds = (state) => state.mockList.rankingIds;
const getAnswers = (state) => state.entities.answers;
const getMockTemplates = (state) => state.entities.mockTemplates;
const getId = (_, id) => id;
const getProps = (_, props) => props;
export const getCurrentMockId = (state) => state.mock.currentMockId;
export const getMockId = (state, id) => id || state.mock.currentMockId;

export const getAllMocks = createSelector(getMocks, (mocks) =>
  Object.values(mocks).sort(orderByDate)
);

export const makeHasMocks = createSelector(
  getAllMocks,
  (mocks) => !!mocks.length
);

export const getMock = createSelector(
  getMocks,
  getMockId,
  (mocks, id) => mocks[id]
);

export const getMocksByFetchedIds = createSelector(
  getMocks,
  getFetchedIds,
  (mocks, ids) =>
    Object.values(mocks)
      .filter((mock) => ids.includes(mock.id))
      .sort(orderByDate)
);

export const getAllMockRankings = createSelector(
  getMockRankings,
  (mockRankings) => Object.values(mockRankings)
);

export const getMocksForRanking = createSelector(
  getAllMockRankings,
  getId,
  getRankingIds,
  (mockRankings, id, ids) =>
    mockRankings
      .filter((mock) => mock.mockTemplateId == id && ids.includes(mock.id))
      .sort(orderByScore)
);

export const getCurrentMock = createSelector(
  getMocks,
  getCurrentMockId,
  (mocks, currentMockId) => mocks[currentMockId]
);

export const currentMockQuestions = createSelector(
  getCurrentMock,
  (mock) => mock && Object.values(mock?.questionIds)
);

export const currentMockAnswers = createSelector(
  getCurrentMock,
  getAnswers,
  (mock, answers) =>
    Object.values(answers).filter((answer) => answer?.mockId === mock?.id)
);

export const showAnswer = createSelector(
  getCurrentMock,
  (mock) => !mock?.exam || !!mock?.finishedAt
);

export const makeIsLastAnswer = createSelector(
  currentMockQuestions,
  currentMockAnswers,
  (questions, answers) =>
    questions &&
    answers &&
    (questions?.length - answers?.length == 1 ||
      questions?.length == answers?.length)
);

export const makeIsComplete = createSelector(
  currentMockQuestions,
  currentMockAnswers,
  (questions, answers) =>
    questions && answers && questions?.length === answers?.length
);

export const makeIsFirstFinishedMock = createSelector(
  getAllMocks,
  (mocks) => mocks.filter((mock) => mock.finishedAt).length === 1
);

export const getAllMockTemplates = createSelector(
  getMockTemplates,
  (mockTemplates) => Object.values(mockTemplates)
);

export const getMockTemplateById = createSelector(
  getAllMockTemplates,
  getProps,
  (mockTemplates, id) =>
    mockTemplates.find((mockTemplate) => mockTemplate.id == id)
);

export const levelsWithResultsFromMockTemplate = createSelector(
  getCurrentMock,
  getMockTemplateById,
  (mock, mockTemplate) =>
    mockTemplate?.levels
      ?.map((levelMockTemplate) => {
        const levelWithResult = mock?.results?.find(
          (level) => levelMockTemplate.levelId == level.id
        );

        return {
          ...levelMockTemplate,
          name: levelMockTemplate.name.split(" ")[0],
          countTrue: levelWithResult?.countTrue,
          countFalse: levelWithResult?.countFalse,
        };
      })
      .sort(orderByName)
);

export const getResumeMock = createSelector(getAllMocks, (mocks) =>
  mocks.find(({ finishedAt }) => !finishedAt)
);
