import React from "react";
import { Card, Table, Tab, Icon } from "semantic-ui-react";
import Link from "../library/Link";
import Button from "../library/Button";
import formatDate from "../../utils/formatDate";
import { css } from "glamor";
import { spacing } from "../library/theme";
import Empty from "./Empty";

const dashboardButtonStyle = {
  width: 160,
};
const topTenTitle = css({
  marginBottom: spacing.m,
});
const topTenTitleWrapper = css({
  padding: spacing.m,
  flexGrow: "unset !important",
});
const emptyWrapperStyle = {
  display: "flex",
  justifyContent: "center",
  width: "100%",
};

export default function CompanyHistory({ company }) {
  return (
    <Card fluid>
      <Card.Header className={topTenTitleWrapper}>
        <h3>Histórico</h3>
      </Card.Header>

      <Card.Content>
        <Table unstackable style={{ overflowX: "auto" }}>
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>Matriculado em</Table.HeaderCell>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>Curso</Table.HeaderCell>
              <Table.HeaderCell>Turma</Table.HeaderCell>
              <Table.HeaderCell>Matrícula expira em</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {company?.meta?.enrollments
              ?.sort((a, b) => b.id - a.id)
              ?.map((enrollment) => (
                <Table.Row textAlign="center">
                  <Table.Cell>
                    {formatDate(enrollment.createdAt, "DD/MM/YYYY")}
                  </Table.Cell>
                  <Table.Cell>{enrollment.userName}</Table.Cell>
                  <Table.Cell>{enrollment.courseName}</Table.Cell>
                  <Table.Cell>{enrollment.classroomName}</Table.Cell>
                  <Table.Cell>
                    {formatDate(enrollment.expiresAt, "DD/MM/YYYY")}
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
        {company?.meta?.enrollments?.length === 0 && <Empty />}
      </Card.Content>
    </Card>
  );
}
