import React from "react";
import QuestionPaginationItemContainer from "./QuestionPaginationItemContainer";
import { spacing } from "../library/theme";

const container = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
  maxWidth: "100%",
  marginTop: spacing.s,
};

export default function QuestionPagination({ questions }) {
  return questions.length ? (
    <div style={container}>
      {questions.map((question, index) => (
        <QuestionPaginationItemContainer
          key={index}
          index={index}
          question={question}
        />
      ))}
    </div>
  ) : null;
}
