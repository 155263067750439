import { css } from "glamor";
import React, { useCallback, useState } from "react";
import { FaRegEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Header, Icon, Image, Input, Tab, Table } from "semantic-ui-react";
import { baseUrl } from "../../processes/helpers/api";
import { getSession } from "../../processes/sessionProcesses";
import { getUsersBySearch } from "../../selectors/classroomSelectors";
import formatDate from "../../utils/formatDate";
import Button from "../library/Button";
import Link from "../library/Link";
import { spacing, styles, typographyColors } from "../library/theme";
import CustomTableHeaderCell from "./CustomTableHeaderCell";

const tableWrapper = css({
  display: "grid",
  gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
  overflow: "auto",
  whiteSpace: "nowrap",
});
const tabPaneClass = css({
  padding: `${spacing.s} 0px ${spacing.l} 0px !important`,
  border: "none !important",
  boxShadow: "none !important",
  "> div > div": styles.alternativeFontFamily,
}).toString();
const inputClass = css({
  minWidth: 300,
  "@media(max-width: 575px)": {
    width: "100%",
  },
});
const headerClass = css({
  display: "flex",
  gap: spacing.m,
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: spacing.m,
});
const linkWrapper = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: spacing.xxs,
  fontSize: 16,
  whiteSpace: "nowrap",
};
const linkClass = css({
  color: `${typographyColors.defaultTitle} !important`,
  textDecoration: "underline",
});
const imageStyle = { aspectRatio: "1 / 1" };

export default function ClassroomStudents({ classroom }) {
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("name");
  const [order, setOrder] = useState("asc");
  const toggleOrder = useCallback(() => {
    setOrder(order == "asc" ? "desc" : "asc");
  }, [order]);
  const searchChangeHandler = useCallback((_, { value }) => {
    setSearch(value);
  }, []);
  const session = getSession();
  const reportUrl = `${baseUrl}/classrooms/${classroom.id}?session[access-token]=${session.headers["access-token"]}&session[client]=${session.headers["client"]}&session[uid]=${session.headers["uid"]}`;
  const users = useSelector((state) =>
    getUsersBySearch(state, {
      classroomId: classroom?.id,
      search,
      sort,
      order,
    })
  );

  const tableHeaderOptions = { sort, order, toggleOrder, setSort };

  return (
    <Tab.Pane attached={false} className={tabPaneClass}>
      <div className={headerClass}>
        <Input
          iconPosition="left"
          placeholder="Digite um nome ou email"
          onChange={searchChangeHandler}
          className={inputClass}
        >
          <Icon name="search" />
          <input />
        </Input>

        <Link component={Button} target="_blank" href={reportUrl} primary>
          Baixar relatório
        </Link>
      </div>
      <div className={tableWrapper}>
        <Table unstackable compact="very" celled={false}>
          <Table.Header>
            <Table.Row>
              <CustomTableHeaderCell
                content="Nome"
                columnName="name"
                textAlign="left"
                {...tableHeaderOptions}
              />
              <CustomTableHeaderCell
                content="Email"
                columnName="email"
                textAlign="left"
                {...tableHeaderOptions}
              />
              <CustomTableHeaderCell
                content="Aulas assistidas"
                columnName="classroomProgress"
                {...tableHeaderOptions}
              />
              <CustomTableHeaderCell
                content="Avaliações realizadas"
                columnName="classroomFinishedMocksCount"
                {...tableHeaderOptions}
              />
              <CustomTableHeaderCell
                content="Nota de avaliações"
                columnName="mocksAverageScore"
                {...tableHeaderOptions}
              />
              <CustomTableHeaderCell
                content="Nota de simulados livres"
                columnName="simpleMocksAverageScore"
                {...tableHeaderOptions}
              />
              <CustomTableHeaderCell
                content="Último acesso"
                columnName="lastSeenAt"
                {...tableHeaderOptions}
              />
              <CustomTableHeaderCell content="Ver performance" />
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {users.map((user) => (
              <Table.Row>
                <Table.Cell>
                  <Header as="h4" image>
                    <Image
                      style={imageStyle}
                      src={user.avatarUrl}
                      rounded
                      size="mini"
                    />
                    <Header.Content>
                      {user.name.split(" ")[0]}
                      <Header.Subheader>
                        {user.name.split(" ")[1]}
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
                <Table.Cell textAlign="center">
                  {user.classroomProgress}%
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {user.classroomFinishedMocksCount} de{" "}
                  {classroom.meta.mockTemplates.length}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {user.mocksAverageScore}%
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {user.simpleMocksAverageScore}%
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {formatDate(user.lastSeenAt, "DD/MM/YYYY")}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <div style={linkWrapper}>
                    <Link
                      className={linkClass}
                      href={`/classrooms/${classroom.id}/users/${user.id}/performance`}
                      target="_blank"
                    >
                      <FaRegEye size={20} />
                    </Link>
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </Tab.Pane>
  );
}
