import superagent from "superagent";
import normalizeObject from "../../lib/normalizeObject";
import middleware from "./railsMiddleware";
import unauthorizedMiddleware from "./unauthorizedMiddleware";

export function serializeSnakeCase(data) {
  return JSON.stringify(normalizeObject(data, "snake"));
}

export const baseUrl = process.env.API_URL || "https://cursos.t2.com.br";

const api = (method) => (url) => {
  return superagent[method](`${baseUrl}${url}`)
    .use(middleware)
    .use(unauthorizedMiddleware)
    .type("json")
    .accept("json")
    .serialize(serializeSnakeCase);
};

export const apiGet = api("get");
export const apiPost = api("post");
export const apiPatch = api("patch");
export const apiDel = api("del");
export const apiPut = api("put");
