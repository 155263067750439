import React from "react";
import { Field } from "redux-form";
import { spacing } from "../theme";
import Hint from "../Hint";
import Label from "./Label";
import { css } from "glamor";

const container = css({
  display: "flex",
  alignItems: "center",
  gap: spacing.xs,
  marginBottom: 1,
  "& input": {
    minHeight: "26px !important",
  },
});
const hintWrapper = {
  marginLeft: spacing.m,
  whiteSpace: "pre-line",
  fontSize: 12,
};
const labelClass = {
  ":after": {
    content: "",
  },
};

export default function RadioField(props) {
  const { name, value, hint, label, disabled } = props;
  const id = `${name}${value?.toString()}`;

  return (
    <div className={container}>
      <Field
        id={id}
        name={name}
        type="radio"
        component="input"
        value={value.toString()}
        disabled={disabled}
      />{" "}
      <Label htmlFor={id} disabled={disabled} className={labelClass}>
        {label || name}
      </Label>
      {hint && <Hint style={hintWrapper}>{hint}</Hint>}
    </div>
  );
}
