import React from "react";
import { Modal } from "semantic-ui-react";
import Button from "../library/Button";
import NewClassroomFormContainer from "./NewClassroomFormContainer";

export default function NewClassroomModal({ company, courseId }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = React.useCallback(() => setOpen(true), []);
  const handleClose = React.useCallback(() => setOpen(false), []);

  return (
    <React.Fragment>
      <Modal
        dimmer="blurring"
        open={open}
        onClose={handleClose}
        size="tiny"
        closeIcon
      >
        <Modal.Header>Nova Turma</Modal.Header>
        <Modal.Content>
          <NewClassroomFormContainer
            company={company}
            courseId={courseId}
            handleClose={handleClose}
          />
        </Modal.Content>
      </Modal>

      <Button onClick={handleOpen} primary icon="plus" content="Nova Turma" />
    </React.Fragment>
  );
}
