import { css } from "glamor";
import React from "react";
import Button from "../library/Button";
import Link from "../library/Link";
import SecurityBadge from "../library/images/security-badge.svg";
import { Form, TextField } from "../library/forms";
import { colors, spacing, styles } from "../library/theme";
import LobbyLayout from "../lobby/LobbyLayout";
import EmailField from "../library/forms/EmailField";

const buttonMargin = css({ margin: `${spacing.m} 0` });
const linkClass = css({
  display: "block",
  color: `${colors.dark} !important`,
  margin: `${spacing.s} 0 ${spacing.m} 0`,
  "& + &": {
    borderTop: `1px solid ${colors.greyLight}`,
    paddingTop: spacing.m,
    marginBottom: 0,
  },
});
const forgottenPasswordClass = css(linkClass, {
  marginTop: spacing.xs,
  textAlign: "right",
});
const cardClass = css({
  margin: `${spacing.xl} auto 0`,
  maxWidth: "470px !important",
  alignSelf: "center",
  borderRadius: spacing.m,
  backgroundColor: "white",
  textAlign: "center",
  padding: `${spacing.l} ${spacing.xl}`,
  "@media(max-width: 515px)": {
    padding: `${spacing.l} ${spacing.m}`,
    margin: `${spacing.xl} ${spacing.m}`,
  },
});
const titleWrapper = {
  textAlign: "left",
  display: "flex",
  gap: spacing.s,
  paddingBottom: spacing.m,
};
const textClass = css(styles.alternativeFontFamily, {
  color: colors.black,
  fontWeight: 500,
  lineHeight: 1,
});
const titleClass = css(textClass, {
  lineHeight: 1,
  color: colors.black,
  fontSize: 28,
  fontWeight: "bold",
});

const successMessage = ({ confirmed }) => {
  if (confirmed) {
    return {
      content:
        "Sua conta foi confirmada com sucesso! Digite seu e-mail e senha nos campos abaixo para acessar a plataforma.",
    };
  }
  return null;
};

export default function SignInForm(props) {
  const { confirmed } = props;

  return (
    <LobbyLayout centered>
      <div className={cardClass}>
        <div style={titleWrapper}>
          <img src={SecurityBadge} />
          <div>
            <p className={textClass}>Login</p>
            <p className={titleClass}>Acesse sua conta</p>
          </div>
        </div>

        <Form
          onSubmit={props.handleSubmit}
          error={props.error}
          success={successMessage({ confirmed })}
        >
          <EmailField name="email" placeholder="E-mail" required />
          <TextField
            name="password"
            type="password"
            placeholder="Senha"
            required
            fluid
          />
          <Link
            to="/forgotten-password"
            component="u"
            className={forgottenPasswordClass}
          >
            Esqueci minha senha
          </Link>

          <div className={buttonMargin}>
            <Button
              secondary
              loading={props.submitting}
              disabled={props.pristine || props.submitting}
              size="big"
              fluid
            >
              Entrar
            </Button>
          </div>

          <Link to="/signup" className={linkClass}>
            Não tenho uma conta? <u>Cadastre-se aqui</u>
          </Link>
          <Link
            to="/resend-confirmation-email"
            component="u"
            className={linkClass}
          >
            Reenviar e-mail de verificação
          </Link>
        </Form>
      </div>
    </LobbyLayout>
  );
}
