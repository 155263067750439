import { createSelector } from "reselect";

const getCreditCardTokens = (state) => state.entities.creditCardTokens;

const AMEX = "AMEX";

const cctFormatter = (cct) => {
  const name =
    cct.brand == AMEX
      ? `XXXX XXXXXX ${cct.number}`
      : `XXXX XXXX XXXX ${cct.number}`;

  const icon = `cc ${cct.brand.toLowerCase()}`;

  return { ...cct, name, icon };
};

export const getAllCreditCardTokens = createSelector(
  getCreditCardTokens,
  (creditCardTokens) => {
    return Object.values(creditCardTokens).map(cctFormatter);
  }
);
