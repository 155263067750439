import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchInvoices } from "../../processes/invoicesProcesses";
import Invoices from "./Invoices";

function InvoicesContainer(props) {
  const { dispatch } = props;

  useEffect(() => {
    fetchInvoices(dispatch);
  }, [dispatch]);

  return <Invoices {...props} />;
}

function mapStateToProps(state) {
  return {
    loading: state.invoicesList.loading,
    invoices: state.invoicesList.invoices,
  };
}

export default connect(mapStateToProps)(InvoicesContainer);
