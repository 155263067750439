import { createSelector } from "reselect";

const getSurveys = (state) => state.entities.surveys;
const getSurveysUsers = (state) => state.entities.surveysUsers;
const getSurveyType = (_, surveyType) => surveyType;

export const getSurveyUsers = createSelector(getSurveysUsers, (surveysUsers) =>
  Object.values(surveysUsers)
);

export const getAllSurveys = createSelector(getSurveys, (surveys) =>
  Object.values(surveys)
);

export const getFirstSurveyFromType = createSelector(
  getAllSurveys,
  getSurveyType,
  getSurveyUsers,
  (surveys, surveyType, surveysUsers) => {
    return Object.values(surveys).filter(
      (survey) =>
        survey.surveyType == surveyType &&
        !surveysUsers
          .map((surveyUser) => surveyUser.surveyId)
          .includes(survey.id)
    )[0];
  }
);
