import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getCurrentLevel,
  getLessonsFromLevel,
} from "../../selectors/lessonSelectors";
import { getSublevelsFromLevel } from "../../selectors/levelSelectors";
import LevelListItem from "./LevelListItem";

function LevelListItemContainer(props) {
  const { currentLevel, level, currentIndex } = props;

  const isActive = currentLevel
    ? currentLevel?.sublevelId === level.id || currentLevel?.id === level.id
    : currentIndex === 0;

  const [active, setActive] = useState(isActive);
  const handleClick = useCallback(() => setActive(!active), [active]);

  useEffect(() => setActive(isActive), [isActive]);

  return <LevelListItem handleClick={handleClick} active={active} {...props} />;
}

function mapStateToProps(state, ownProps) {
  return {
    sublevels: getSublevelsFromLevel(state, ownProps),
    currentLevel: getCurrentLevel(state),
    lessons: getLessonsFromLevel(state, ownProps),
  };
}

export default connect(mapStateToProps)(LevelListItemContainer);
