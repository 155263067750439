import { css } from "glamor";
import React from "react";
import { Header, Image, Modal } from "semantic-ui-react";
import { Button, Link } from "../library";
import Paragraph from "../library/Paragraph";
import GraduationCapIcon from "../library/images/graduation-cap-icon.svg";
import SliderIcon from "../library/images/slider-icon.svg";
import { styles, colors, spacing } from "../library/theme";

const modalClass = css({
  padding: `${spacing.l} ${spacing.xl}`,
  maxWidth: 550,
}).toString();

const modalTitleClass = css({
  textAlign: "center",
  marginBottom: spacing.l,
  fontWeight: "bold",
});

const optionClass = css({
  display: "flex",
  alignItems: "center",
  border: `1px solid ${colors.grey}`,
  borderRadius: 10,
  height: 150,
  cursor: "pointer",
  ...styles.transition,
  ":hover": {
    outline: `1px solid ${colors.primary}`,
    transform: "scale(1.05)",
    ...styles.optionShadow,
  },
});

const chosenOptionClass = css({
  border: `1px solid ${colors.primary}`,
  borderLeft: "none",
  backgroundColor: colors.primary,
});

const optionTextContainer = css({ padding: spacing.s });

const headerStyle = { marginBottom: spacing.s };

const paragraphStyle = { color: colors.black, lineHeight: 1.3 };

const modalContentWrapper = css({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: spacing.s,
});

const iconWrapper = css({
  backgroundColor: colors.greyLighter,
  padding: spacing.xl,
  paddingLeft: spacing.l,
  borderRadius: "10px 50% 50% 10px",
  width: 200,
  height: 150,
  zIndex: -1,
});

const chosenIconWrapper = {
  backgroundColor: colors.primaryLight,
  zIndex: "unset",
};

const sliderIconStyle = {
  position: "absolute",
  transform: "translate(-10px, -12px)",
};

const buttonClass = css({ width: 200 });

const buttonStyle = {
  marginTop: spacing.m,
  borderRadius: 15,
  backgroundColor: colors.primary,
  color: colors.white,
};

const chosenOptionClassByOption = (option) =>
  css(optionClass, option && chosenOptionClass);
const optionIconWrapperByOption = (option) =>
  css(iconWrapper, option && chosenIconWrapper);
const optionImageStyleByOption = (option) => ({
  ...sliderIconStyle,
  filter: option && "invert(1) contrast(150%)",
});
const headerStyleByOption = (option) => ({
  ...headerStyle,
  color: option ? colors.white : colors.black,
});
const paragraphStyleByOption = (option) => ({
  ...paragraphStyle,
  color: option ? colors.white : colors.black,
});

export default function MockModal({
  isMobile,
  handleClose,
  firstOption,
  secondOption,
  setFirstOptionHandler,
  setSecondOptionHandler,
}) {
  const linkRoute = firstOption ? "/mocks/custom/new" : "/mocks/exam/new";

  return (
    <Modal className={modalClass} open closeIcon onClose={handleClose}>
      <h2 className={modalTitleClass}>Qual o tipo de simulado?</h2>

      <div className={modalContentWrapper}>
        <div
          onClick={setFirstOptionHandler}
          className={chosenOptionClassByOption(firstOption)}
        >
          {!isMobile && (
            <div className={optionIconWrapperByOption(firstOption)}>
              <Image
                style={optionImageStyleByOption(firstOption)}
                src={SliderIcon}
              />{" "}
            </div>
          )}

          <div className={optionTextContainer}>
            <Header as="h3" style={headerStyleByOption(firstOption)}>
              Simulado Personalizado
            </Header>
            <Paragraph style={paragraphStyleByOption(firstOption)}>
              Personalize seus simulados escolhendo o módulo que deseja
              reponder, o número de questões e outras opções do questionário.
            </Paragraph>
          </div>
        </div>

        <div
          onClick={setSecondOptionHandler}
          className={chosenOptionClassByOption(secondOption)}
        >
          {!isMobile && (
            <div className={optionIconWrapperByOption(secondOption)}>
              <Image
                style={optionImageStyleByOption(secondOption)}
                src={GraduationCapIcon}
              />{" "}
            </div>
          )}

          <div className={optionTextContainer}>
            <Header as="h3" style={headerStyleByOption(secondOption)}>
              Simular Prova
            </Header>
            <Paragraph style={paragraphStyleByOption(secondOption)}>
              Faça a prova semelhante ao exame do curso selecionado, com o mesmo
              número de questões e os pesos por módulo da prova.
            </Paragraph>
          </div>
        </div>

        <Link
          to={linkRoute}
          className={buttonClass}
          style={buttonStyle}
          disabled={!firstOption && !secondOption}
          component={Button}
          size="big"
        >
          Criar {secondOption ? "Prova" : "Simulado"}
        </Link>
      </div>
    </Modal>
  );
}
