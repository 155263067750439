import { useCallback, useMemo } from "react";
import { integromat } from "../../processes/integromatProcesses";
import { rdstation } from "../../processes/rdstationProcesses";
import useBeforeUnload from "../../utils/useBeforeUnload";

function cleanUserData(user) {
  if (user) {
    const { avatarUrl, coverUrl, zendeskToken, ...userData } = user;
    return userData;
  }
}

export default function useCartAbandonment(props) {
  const {
    currentCourseId,
    user,
    leadSavedData,
    stored,
    coupon,
    billingType,
    offer,
    submitSucceeded,
  } = props;

  const cartAbandonmentDependicies = [
    currentCourseId,
    user?.id,
    leadSavedData?.email,
    stored?.email,
    coupon?.id,
    billingType,
    offer.id,
  ];

  const contactParamsDependencies = [
    user?.id,
    leadSavedData?.email,
    currentCourseId,
  ];

  const cartAbandonmentParams = useMemo(() => {
    const userData = cleanUserData(user);
    const customerData = userData || leadSavedData;

    return {
      customerData,
      paymentData: stored,
      coupon,
      billingType,
      courseOffer: offer,
      webhookName: "cart_abandonment",
    };
  }, cartAbandonmentDependicies);

  const contactParams = useMemo(() => {
    const userData = cleanUserData(user);
    const customerData = userData || leadSavedData;

    return { ...customerData, tagType: "abandono", courseId: currentCourseId };
  }, contactParamsDependencies);

  const handleCartAbandonment = useCallback(() => {
    rdstation.syncContact(contactParams);
    integromat.payloadWebhookSender(cartAbandonmentParams);
  }, [contactParams, cartAbandonmentParams]);

  useBeforeUnload(() => {
    rdstation.syncContact(contactParams);
    integromat.payloadWebhookSender(cartAbandonmentParams);

    return "Cancelar?";
  }, submitSucceeded);

  return { handleCartAbandonment };
}
