import React from "react";
import { connect } from "react-redux";
import AnnotationListItem from "./AnnotationListItem";
import { deleteAnnotation } from "../../processes/annotationProcesses";
import { replace } from "connected-react-router";

function AnnotationListItemContainer(props) {
  const { dispatch, annotation } = props;
  const handleDelete = React.useCallback(
    () => deleteAnnotation(dispatch, annotation),
    [dispatch, annotation]
  );
  const handleTimeClick = React.useCallback(() => {
    dispatch(
      replace(`/courses/${annotation.courseId}/lessons/${annotation.lessonId}`)
    );
    dispatch({
      type: "INITIAL_TIME_CHANGED",
      initialTime: annotation.videoTime,
      requestedInitialTimeChangeAt: Date.now(),
    });
  }, [dispatch, annotation]);

  return (
    <AnnotationListItem
      handleDelete={handleDelete}
      handleTimeClick={handleTimeClick}
      {...props}
    />
  );
}

export default connect()(AnnotationListItemContainer);
