import { colors } from "../components/library/theme";

const calculateRelativeLuminance = (color) => {
  const gammaCorrect = (value) => {
    value /= 255;
    return value <= 0.04045
      ? value / 12.92
      : Math.pow((value + 0.055) / 1.055, 2.4);
  };

  const [R, G, B] = color.map(gammaCorrect);

  return 0.2126 * R + 0.7152 * G + 0.0722 * B;
};

const hexToRgb = (hex) => {
  const bigint = parseInt(hex.substring(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return [r, g, b];
};

const getContrastTextColor = (hexColor) => {
  if (!hexColor) return colors.black;
  
  const rgbColor = hexToRgb(hexColor);
  const backgroundLuminance = calculateRelativeLuminance(rgbColor);

  return backgroundLuminance > 0.5 ? colors.black : colors.white;
};

export default getContrastTextColor;
