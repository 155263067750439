import React from "react";
import { Card } from "semantic-ui-react";
import LessonWatchedGraph from "./LessonWatchedGraph";

const headerStyle = {
  flexGrow: 0,
};

export default function MyPerformanceProgressCard(props) {
  const { lessons = {} } = props.report;
  const {
    lessonsCount = 0,
    lessonsWatchedCount = 0,
    workloadTotalTime = 0,
    workloadWatchedTime = 0,
  } = lessons;

  return (
    <Card fluid padded>
      <Card.Content style={headerStyle}>
        <Card.Header>Progresso</Card.Header>
      </Card.Content>
      <Card.Content>
        <LessonWatchedGraph
          lessonsCount={lessonsCount}
          lessonsWatchedCount={lessonsWatchedCount}
          workloadTotalTime={workloadTotalTime}
          workloadWatchedTime={workloadWatchedTime}
        />
      </Card.Content>
    </Card>
  );
}
