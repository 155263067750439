import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import ContentReplyListItem from "./ContentReplyListItem";
import { deleteComment } from "../../processes/commentProcesses";
import { getCurrentUserId } from "../../selectors/userSelectors";
import { getReplyByCommentAsc } from "../../selectors/commentSelectors";

function ContentReplyListItemContainer(props) {
  const { dispatch, reply } = props;
  const [isEditMode, setIsEditMode] = useState(false);

  const toogleIsEditMode = useCallback(() => {
    setIsEditMode(!isEditMode);
  }, [isEditMode]);

  const deleteHandler = useCallback(() => {
    deleteComment(dispatch, reply.id);
  }, [dispatch, reply]);

  return (
    <ContentReplyListItem
      isEditMode={isEditMode}
      toogleIsEditMode={toogleIsEditMode}
      deleteHandler={deleteHandler}
      {...props}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const { reply } = ownProps;
  return {
    currentUserId: getCurrentUserId(state),
    replies: getReplyByCommentAsc(state, reply.id),
  };
}

export default connect(mapStateToProps)(ContentReplyListItemContainer);
