import React from "react";
import { Rating as Stars } from "semantic-ui-react";
import { css } from "glamor";
import { colors, spacing } from "../theme";

const container = css({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: spacing.m,
  "> .ui.rating": {
    fontSize: 32,
  },
  "> .ui.star.rating .active.icon:before, > .ui.star.rating .active.icon, > .ui.star.rating .selected.icon":
    {
      color: `${colors.secondaryLight} !important`,
      textShadow: "none !important",
    },
});

export default function RatingInput(props) {
  const { className, value, defaultValue, onChange, ...rest } = props;

  return (
    <div className={css(container, className)}>
      <Stars
        icon="star"
        maxRating={5}
        rating={value || defaultValue}
        {...rest}
      />
    </div>
  );
}
