export function formatStringList(strings) {
  if (strings.length <= 1) {
    return strings[0] || "";
  } else {
    const lastTwoItems = strings.slice(-2).join(" e ");
    const remainingItems = strings.slice(0, -2).join(", ");

    return remainingItems.length > 0
      ? `${remainingItems}, ${lastTwoItems}`
      : lastTwoItems;
  }
}
