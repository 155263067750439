import { createSelector } from "reselect";
import orderByPosition from "./helpers/orderByPosition";
import { getCourseId, getCurrentCourseId } from "./courseSelectors";

export const getLevels = (state) => state.entities.levels;
const getLessons = (state) => state.entities.lessons;
const getLessonId = (_, id) => id;
export const getLevel = (_, props) => props.level;

export const getAllLevels = createSelector(getLevels, (levels) =>
  Object.values(levels)
);

export const getLevelsFromCurrentCourse = createSelector(
  getLevels,
  getCurrentCourseId,
  (levels, currentCourseId) =>
    levels &&
    Object.values(levels)
      .filter((level) => {
        return level.courseId === currentCourseId && !level.sublevelId;
      })
      .sort(orderByPosition)
);

export const getLevelsFromCourse = createSelector(
  getLevels,
  getCourseId,
  (levels, courseId) =>
    levels &&
    Object.values(levels)
      .filter((level) => {
        return (
          level.courseId === courseId &&
          level.withQuestions &&
          !level.sublevelId
        );
      })
      .sort(orderByPosition)
);

export const getSublevelsFromLevel = createSelector(
  getLevel,
  getLevels,
  (currentLevel, levels) =>
    levels &&
    Object.values(levels)
      .filter((level) => level.sublevelId === currentLevel.id)
      .sort(orderByPosition)
);

export const getLevelsForDropdownOptions = createSelector(
  getLevelsFromCourse,
  (levels) =>
    Object.values(levels).map((level) => ({
      id: level.id,
      name: level.name,
      hint: `( ${level.answeredQuestionsCount} de ${level.questionsCount} respondidas )`,
    }))
);

export const getLessonById = createSelector(
  getLessons,
  getLessonId,
  (lessons, id) => lessons[id]
);

export const getLevelByLessonId = createSelector(
  getLevels,
  getLessonById,
  (levels, lesson) => {
    if (!levels || !lesson) return null;
    return levels[lesson?.levelId];
  }
);
