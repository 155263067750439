import React from "react";
import NotificationListItem from "./NotificationListItem";
import { connect } from "react-redux";
import { getNotificationsUserByNotificationId } from "../../selectors/notificationsUserSelectors";

function NotificationListItemContainer(props) {
  return <NotificationListItem {...props} />;
}

function mapStateToProps(state, props) {
  return {
    notificationsUser: getNotificationsUserByNotificationId(
      state,
      props?.notification?.id
    ),
  };
}

export default connect(mapStateToProps)(NotificationListItemContainer);
