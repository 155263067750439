import { css } from "glamor";
import React from "react";
import { colors, spacing, utilityColors } from "../theme";

const labelStyles = {
  default: {
    textAlign: "left",
    display: "inline-block",
    cursor: "pointer",
    color: colors.black,
  },
  required: {
    ":after": {
      content: "'* '",
      color: utilityColors.danger,
      verticalAlign: "top",
      marginLeft: spacing.xxs,
    },
  },
  optional: {
    ":after": {
      content: "'(opcional)'",
      color: colors.grey,
      verticalAlign: "top",
      marginLeft: spacing.xxs,
    },
  },
  disabled: {
    opacity: 0.5,
  },
};

export default function Label(props) {
  const { children, required, disabled, className, ...rest } = props;
  const label = css(
    labelStyles.default,
    required && labelStyles.required,
    !required && labelStyles.optional,
    disabled && labelStyles.disabled,
    className
  );
  return (
    <label className={label} {...rest}>
      {children}
    </label>
  );
}
