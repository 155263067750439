import { css } from "glamor";
import React from "react";
import { toBRLCurrency } from "../../utils/formatCurrency";
import { colors, spacing } from "../library";

const container = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  color: colors.greyDarker,
  gap: spacing.xxs,
  lineHeight: 1.4,
  padding: `${spacing.xxs} 0`,
};
const valueStyle = {
  color: colors.greyDarker,
  fontWeight: 700,
};
const striketroughClass = css({
  textDecoration: "line-through",
  fontWeight: "normal",
});

export default function CourseCheckoutDetailItem(props) {
  const {
    title,
    titleWeight,
    titleColor,
    value,
    valueColor,
    valuePrefix = "",
    striketrough,
    backgroundColor,
    fontSize,
    valueFontSize,
    valueFontWeight,
  } = props;

  const detailClass = css(
    container,
    backgroundColor && {
      backgroundColor,
      padding: `${spacing.xxs} ${spacing.xs}`,
    }
  );
  const titleClass = css(
    titleColor && { color: titleColor },
    titleWeight && { fontWeight: titleWeight },
    fontSize && { fontSize }
  );
  const valueClass = css(
    valueStyle,
    striketrough && striketroughClass,
    valueColor && { color: valueColor },
    (valueFontSize || fontSize) && { fontSize: valueFontSize || fontSize },
    valueFontWeight && { fontWeight: valueFontWeight }
  );

  return (
    <div className={detailClass}>
      <p className={titleClass}>{title}</p>
      <span className={valueClass}>
        {valuePrefix}
        {toBRLCurrency(value)}
      </span>
    </div>
  );
}
