import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import { updateProfile } from "../../processes/profileProcesses";
import { getCurrentUser } from "../../selectors/userSelectors";
import buildForm from "../library/forms/buildForm";
import TrailChangeForm from "./TrailChangeForm";
import { getAllTrails } from "../../selectors/trailSelectors";

const form = buildForm("trailChange");

function TrailChangeFormContainer(props) {
  const { submit } = props;

  const [openModal, setOpenModal] = useState(false);

  const toggleModalHandler = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  const submitHandler = useCallback(() => {
    submit();
    toggleModalHandler();
  }, [submit, toggleModalHandler]);

  return (
    <TrailChangeForm
      openModal={openModal}
      submitHandler={submitHandler}
      toggleModalHandler={toggleModalHandler}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const currentUser = getCurrentUser(state);

  return {
    initialValues: {
      trailId: currentUser?.trailId,
    },
    trails: getAllTrails(state),
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: updateProfile,
    enableReinitialize: true,
  })(TrailChangeFormContainer)
);
