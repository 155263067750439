const getMockTemplates = (action) => action.entities.mockTemplates;

export default function mockTemplatesReducer(state = {}, action) {
  switch (action.type) {
    case "MOCK_TEMPLATE_FETCHED":
    case "MOCK_TEMPLATES_FETCHED":
      return { ...state, ...getMockTemplates(action) };
    default:
      return state;
  }
}
