import React, { useCallback, useRef } from "react";
import { BiImageAdd } from "react-icons/bi";
import Button from "../Button";

const doFocus = (e) => e.target.focus();

const buttonStyle = {
  height: 41,
  width: 41,
  padding: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default function ImageButtonInput(props) {
  const { id, value, onFileChange, clear, buttonType, invalid, ...rest } =
    props;
  const inputRef = useRef();

  const clickHandler = useCallback(() => {
    inputRef.current.click();
  }, []);

  const onChangeHandler = useCallback(
    (e) => {
      e.preventDefault();
      onFileChange(e.target.files);
      e.target.value = "";
    },
    [onFileChange]
  );

  return (
    <>
      <Button type="button" style={buttonStyle} onClick={clickHandler}>
        <BiImageAdd size={22} />
      </Button>
      <input
        ref={inputRef}
        hidden
        accept="image/*"
        {...rest}
        title={value ? value.name : undefined}
        onClick={doFocus}
        onChange={onChangeHandler}
      />
    </>
  );
}
