import { createSelector } from "reselect";
import { getAllValidCourses, getCourses } from "./courseSelectors";

const getMockTemplates = (state) => state.entities.mockTemplates;
const getId = (_, id) => id;

export const getAllMockTemplates = createSelector(
  getMockTemplates,
  (mockTemplates) => Object.values(mockTemplates)
);

export const getUserMockTemplates = createSelector(
  getAllMockTemplates,
  getAllValidCourses,
  (mockTemplates, courses) =>
    mockTemplates.filter(
      (item) =>
        courses.map(({ id }) => id).includes(item.courseId) &&
        item.type == "exam"
    )
);

export const getMockTemplateById = createSelector(
  getMockTemplates,
  getId,
  (mockTemplates, id) => mockTemplates[id]
);

export const getCourseByMockTemplateId = createSelector(
  getMockTemplates,
  getId,
  getCourses,
  (mockTemplates, id, courses) => {
    const mockTemplate = mockTemplates[id];

    return courses[mockTemplate?.courseId];
  }
);

export const questionsCountFromMockTemplate = createSelector(
  getMockTemplateById,
  (mockTemplate) => {
    const levels = mockTemplate?.levels || [];
    const questionsQuantitiesList = levels.map((level) => level.quantity);
    const count = questionsQuantitiesList.reduce((a, b) => a + b, 0);

    return count;
  }
);
