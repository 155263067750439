import { apiGet, apiPost } from "./helpers/api";
import { normalize, schema } from "normalizr";
import { fetchCourse } from "./courseProcesses";

const lessonsUser = new schema.Entity("lessonsUsers");

export const fetchLessonsUsers = (dispatch, courseId) => {
  apiGet(`/api/v1/courses/${courseId}/lessons_users`).then((response) => {
    dispatch({
      type: "LESSONS_USERS_FETCHED",
      ...normalize(response.body.data, new schema.Array(lessonsUser)),
    });
  });
};

export const upsertLessonsUser = (dispatch, courseId, lessonId, progress) => {
  return apiPost(`/api/v1/courses/${courseId}/lessons_users`)
    .send({ courseId, lessonId, progress })
    .then((response) => {
      dispatch({
        type: "LESSONS_USER_UPDATED",
        ...normalize(response.body.data, lessonsUser),
      });
    })
    .catch(
      (err) =>
        new Error(
          `Error on create or update the lesson user: ${err.response.body.errors}`
        )
    );
};
