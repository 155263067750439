import { connect } from "react-redux";
import FollowForm from "./FollowForm";
import { getFollow } from "../../selectors/userSelectors";
import buildForm from "../library/forms/buildForm";
import { createOrDeleteFollow } from "../../processes/userProcesses";

const form = buildForm("followForm");

function mapStateToProps(state, ownProps) {
  const { userId } = ownProps;

  return {
    isFollower: getFollow(state, userId),
    currentUserId: state.currentUserId,
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: createOrDeleteFollow,
  })(FollowForm)
);
