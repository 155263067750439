import React, { useEffect } from "react";
import { connect } from "react-redux";
import ContentCategoryForm from "./ContentCategoryForm";
import { contentCommentSubmitFilter } from "../../processes/commentProcesses";
import { getConfig } from "../../selectors/configSelectors";
import { reduxForm } from "redux-form";

function ContentCategoryFormContainer(props) {
  const { initialValues, initialize, initialized } = props;

  useEffect(() => {
    !initialized && initialize(initialValues);
  }, [initialized]);

  return <ContentCategoryForm {...props} />;
}

function mapStateToProps(state, ownProps) {
  const { contentId, initialCategoryId } = ownProps;

  return {
    form: ownProps.formName,
    initialValues: {
      categoriesIdIn: initialCategoryId ? [initialCategoryId] : [],
    },
    contentCategories: getConfig(state, "contentCategories"),
    contentId,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    onSubmit: contentCommentSubmitFilter,
  })(ContentCategoryFormContainer)
);
