import { css } from "glamor";
import React from "react";
import { Popup, Table } from "semantic-ui-react";
import CourseProgress from "../courses/CourseProgress";
import { Page, Spinner, colors, spacing } from "../library";

const contentContainer = css({
  borderRadius: spacing.m,
  padding: spacing.m,
  background: colors.white,
  "> table": {
    border: "none !important",
    boxShadow: "none !important",
  },
});
const emptyText = {
  padding: spacing.xxl,
  textAlign: "center",
};
const linkClass = css({
  color: colors.primary,
  display: "flex",
  justifyContent: "end",
  paddingRight: spacing.m,
});
const disabledLink = {
  color: colors.greyLight,
  cursor: "normal",
};

const tableHeaderCell = css({
  backgroundColor: `${colors.greyLight} !important`,
  ":first-child": {
    borderTopLeftRadius: `${spacing.xs} !important`,
    borderBottomLeftRadius: `${spacing.xs} !important`,
  },
  ":last-child": {
    borderTopRightRadius: `${spacing.xs} !important`,
    borderBottomRightRadius: `${spacing.xs} !important`,
  },
}).toString();

const WithoutList = ({ loading }) =>
  loading ? (
    <Spinner wrapped />
  ) : (
    <p style={emptyText}>Você ainda não tem certificados</p>
  );

export default function Certificates(props) {
  const { courses, lessons, loading } = props;

  return (
    <Page title="Meus Certificados">
      {courses?.length || lessons?.length ? (
        <div className={contentContainer}>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className={tableHeaderCell}>
                  Curso/Aula
                </Table.HeaderCell>
                <Table.HeaderCell className={tableHeaderCell}>
                  Progresso
                </Table.HeaderCell>
                <Table.HeaderCell
                  className={tableHeaderCell}
                ></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {courses.map((course, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{course.name}</Table.Cell>
                  <Table.Cell>
                    <CourseProgress
                      separator="/"
                      progress={course?.meta?.progress}
                      showCounters={false}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Popup
                      disabled={course?.meta?.progress > 0.8}
                      content="O download ficará disponível quando você concluir 80% do curso"
                      on="hover"
                      position="top right"
                      trigger={
                        <div>
                          {course?.meta?.progress > 0.8 ? (
                            <a
                              href={course?.certificateUrl}
                              target="_blank"
                              className={linkClass}
                            >
                              Download
                            </a>
                          ) : (
                            <p className={css(linkClass, disabledLink)}>
                              Download
                            </p>
                          )}
                        </div>
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ))}

              {lessons.map((lesson, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{lesson.name}</Table.Cell>
                  <Table.Cell>
                    <CourseProgress
                      separator="/"
                      progress={lesson?.meta?.progress}
                      showCounters={false}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Popup
                      disabled={lesson?.meta?.progress > 0.8}
                      content="O download ficará disponível quando você concluir 80% do curso"
                      on="hover"
                      position="top right"
                      trigger={
                        <div>
                          {lesson?.meta?.progress > 0.8 ? (
                            <a
                              href={lesson?.certificateUrl}
                              target="_blank"
                              className={linkClass}
                            >
                              Download
                            </a>
                          ) : (
                            <p className={css(linkClass, disabledLink)}>
                              Download
                            </p>
                          )}
                        </div>
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      ) : (
        <WithoutList loading={loading} />
      )}
    </Page>
  );
}
