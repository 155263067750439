import React from "react";
import { connect } from "react-redux";
import {
  canAccessCourse,
  canAccessTrialEnrollment,
  getCurrentCourseId,
} from "../../selectors/courseSelectors";
import { getCourseUserByCourseId } from "../../selectors/coursesUserSelectors";
import {
  canAccessLesson,
  getCurrentLessonId,
} from "../../selectors/lessonSelectors";
import { getIsWatchedByLessonId } from "../../selectors/lessonsUserSelectors";

export default function withLessonListItemData(WrappedComponent) {
  function WrappedComponentContainer(props) {
    const {
      currentCourseId,
      currentLessonId,
      activeDisabled,
      canAccessCourse,
      lesson,
    } = props;

    const lessonPath = `/courses/${currentCourseId}/lessons/${lesson.id}`;
    const active = currentLessonId === lesson.id && !activeDisabled;

    const hasDemoVideo =
      lesson?.player == "scaleup"
        ? !!lesson?.demoScaleupUrl
        : !!lesson?.demoUrl;
    const demoEnabled = !canAccessCourse && hasDemoVideo;

    return (
      <WrappedComponent
        lessonPath={lessonPath}
        active={active}
        demoEnabled={demoEnabled}
        {...props}
      />
    );
  }

  function mapStateToProps(state, ownProps) {
    const { lesson } = ownProps;

    return {
      currentCourseId: getCurrentCourseId(state),
      currentLessonId: getCurrentLessonId(state),
      watched: getIsWatchedByLessonId(state, lesson?.id),
      lesson,
      canAccessLesson: canAccessLesson(state, lesson?.id),
      canAccessCourse: canAccessCourse(state, lesson?.courseId),
      courseUser: getCourseUserByCourseId(state, lesson?.courseId),
      isTrialEnrollment: canAccessTrialEnrollment(state, lesson?.courseId),
    };
  }

  return connect(mapStateToProps)(WrappedComponentContainer);
}
