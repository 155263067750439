import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { change, formValueSelector, reduxForm } from "redux-form";
import { fetchCities } from "../../processes/configProcesses";
import {
  notifyError,
  notifySubmitSucceeded,
} from "../../processes/notifierProcesses";
import { createPayment } from "../../processes/paymentProcesses";
import { searchAddressForPayment } from "../../processes/postalCodeProcesses";
import {
  getCitiesByStateId,
  getConfig,
  getConfigByName,
  getCountryCodeOptions,
} from "../../selectors/configSelectors";
import { getCouponByCode } from "../../selectors/couponSelectors";
import {
  getCourseById,
  getCurrentCourseId,
} from "../../selectors/courseSelectors";
import { getAllCreditCardTokens } from "../../selectors/creditCardTokenSelectors";
import { getPaymentCheckout } from "../../selectors/paymentCheckoutSelectors";
import { getCurrentUser } from "../../selectors/userSelectors";
import calcPaymentValue from "../../utils/calcPaymentValue";
import { toBRLCurrency } from "../../utils/formatCurrency";
import { getItem, setItem } from "../../utils/localStorage";
import PaymentForm from "./PaymentForm";
import useCartAbandonment from "./useCartAbandonment";

const FORM_NAME = "payment";
const ADD = "add";
const COUPON_FORM_NAME = "searchCoupon";
const STEP_FIELD = "creditCardStep";
const LEAD_FORM_NAME = "leadForm";
const COUPON_FIELD_NAME = "couponId";
const BILLING_TYPE_FIELD_NAME = "billingType";
const CREDIT_CARD_STEP_FIELD_NAME = "creditCardStep";
const CREDIT_CARD_FIElD_NAME = "creditCard";
const INSTALLMENT_COUNT_FIELD_NAME = "installmentCount";
const BRAZIL_PHONE_COUNTRY_CODE_ID = 31;

const removeUndefinedProps = (object) => JSON.parse(JSON.stringify(object));

const saveValues = ({ customerData = {} }, dispatch, props) => {
  const savedValues = getItem(FORM_NAME) || {};
  const cleanCustomerData = removeUndefinedProps(customerData);

  return setItem(FORM_NAME, { ...savedValues, ...cleanCustomerData });
};

function PaymentFormContainer(props) {
  const {
    creditCardStep,
    dispatch,
    externalContentDetails,
    billingType,
    user,
    coupon,
  } = props;

  useEffect(() => {
    if (coupon) {
      dispatch(change(FORM_NAME, COUPON_FIELD_NAME, coupon?.id));
    }
  }, [dispatch, coupon]);

  const stored = getItem(FORM_NAME) || {};

  useEffect(() => {
    const initialZipCode = user?.addressZip || stored?.postalCode;
    const initialStateId = user?.stateId || stored?.stateId;

    searchAddressForPayment(dispatch, initialZipCode);
    fetchCities(dispatch, initialStateId);
  }, [dispatch, user]);

  useEffect(() => {
    if (!user) {
      dispatch(change(FORM_NAME, "customerData[cpfCnpj]", stored?.cpfCnpj));
      dispatch(
        change(FORM_NAME, "customerData[postalCode]", stored?.postalCode)
      );
      dispatch(change(FORM_NAME, "customerData[street]", stored?.street));
      dispatch(
        change(FORM_NAME, "customerData[neighborhood]", stored?.neighborhood)
      );
      dispatch(
        change(FORM_NAME, "customerData[addressNumber]", stored?.addressNumber)
      );
      dispatch(
        change(
          FORM_NAME,
          "customerData[addressComplement]",
          stored?.addressComplement
        )
      );
      dispatch(change(FORM_NAME, "customerData[stateId]", stored?.stateId));
      dispatch(change(FORM_NAME, "customerData[cityId]", stored?.cityId));
    }
  }, [dispatch, user, billingType]);

  const copyToClipboardHandler = useCallback(() => {
    navigator.clipboard.writeText(externalContentDetails).then(
      function () {
        notifySubmitSucceeded(dispatch, "Copiado!");
      },
      function (err) {
        notifyError(dispatch, "Não foi possível copiar");
      }
    );
  }, [dispatch, externalContentDetails]);

  const resetBillingTypeHandler = useCallback(() => {
    dispatch(change(FORM_NAME, BILLING_TYPE_FIELD_NAME, null));
    dispatch(change(FORM_NAME, CREDIT_CARD_FIElD_NAME, {}));
    dispatch(change(FORM_NAME, CREDIT_CARD_STEP_FIELD_NAME, 0));
    dispatch(change(FORM_NAME, INSTALLMENT_COUNT_FIELD_NAME, 1));
  }, [dispatch]);

  const changeLeadHandler = useCallback(() => {
    dispatch({ type: "CHECKOUT_LEAD_CHANGED" });
  }, [dispatch]);

  const previousStepHandler = useCallback(() => {
    dispatch(change(FORM_NAME, STEP_FIELD, creditCardStep - 1));
  }, [dispatch, creditCardStep]);

  const fetchCitiesHandler = useCallback(
    (stateId) => {
      fetchCities(dispatch, stateId);
      dispatch(change(FORM_NAME, "customerData[cityId]", ""));
    },
    [dispatch]
  );

  const { handleCartAbandonment } = useCartAbandonment(props);

  return (
    <PaymentForm
      copyToClipboardHandler={copyToClipboardHandler}
      previousStepHandler={previousStepHandler}
      fetchCities={fetchCitiesHandler}
      changeLeadHandler={changeLeadHandler}
      handleCartAbandonment={handleCartAbandonment}
      resetBillingTypeHandler={resetBillingTypeHandler}
      {...props}
    />
  );
}

const getInstallmentOptions = ({
  offer,
  wasPreviousStudent,
  coupon,
  discountConfirmed,
  billingType,
}) => {
  if (Number.isNaN(offer?.maxInstallments)) return [];

  const options = Array.from(
    { length: offer?.maxInstallments },
    (_, i) => i + 1
  );

  return options.map((item) => {
    const paymentValue = calcPaymentValue(
      wasPreviousStudent,
      offer,
      coupon,
      item,
      billingType
    );

    return {
      text: `${item}x de ${toBRLCurrency(paymentValue / item)} ${
        item >= offer?.interestStartsAt ? "(com juros)" : "(sem juros)"
      }`,
      value: item,
    };
  });
};

function mapStateToProps(state, { offer, discountConfirmed }) {
  const user = getCurrentUser(state);
  const course = getCourseById(state, offer?.courseId);
  const creditCardTokens = getAllCreditCardTokens(state);

  const valueSelector = formValueSelector(FORM_NAME);
  const {
    billingType,
    creditCardStep,
    customerData,
    creditCard,
    goodThru,
    installmentCount,
  } = valueSelector(
    state,
    "billingType",
    "creditCardStep",
    "customerData",
    "creditCard",
    "goodThru",
    "installmentCount"
  );

  const couponFormValueSelector = formValueSelector(COUPON_FORM_NAME);
  const searchedCoupon = couponFormValueSelector(state, "code");
  const coupon = getCouponByCode(state, searchedCoupon);

  const {
    externalContentDetails,
    bankslipBarcodeUrl,
    externalContent,
    checkoutBillingAddress,
    leadCreated,
    leadIsFromPreparatoryCourse,
    addressLoading,
  } = getPaymentCheckout(state);

  const wasPreviousStudent =
    user?.isFromPreparatoryCourse || leadIsFromPreparatoryCourse;

  const installments = getInstallmentOptions({
    offer,
    wasPreviousStudent,
    coupon,
    discountConfirmed,
    billingType,
  });

  const leadSavedData = !user ? getItem(LEAD_FORM_NAME) || {} : {};

  const paymentValue = calcPaymentValue(
    wasPreviousStudent,
    offer,
    coupon,
    installmentCount,
    billingType
  );
  const userName = user?.name || leadSavedData?.name;
  const userPhone = user?.phone || leadSavedData?.phone;

  return {
    initialValues: {
      creditCardStep: 0,
      customerData: {
        cpfCnpj: user?.document,
        addressNumber: user?.addressNumber,
        addressComplement: user?.addressComp,
        postalCode: user?.addressZip,
        street: user?.address,
        neighborhood: user?.addressNeighborhood,
        stateId: user?.stateId,
        cityId: user?.cityId,
        name: userName,
        email: user?.email || leadSavedData?.email,
        phone: userPhone,
        phoneCountryCodeId:
          user?.phoneCountryCodeId || BRAZIL_PHONE_COUNTRY_CODE_ID,
      },
      couponId: coupon?.id,
      installmentCount: installments[0]?.value,
    },
    incompleteName: !userName || userName?.split(" ")?.length < 2,
    incompletePhone: !userPhone || userPhone?.length < 11,
    zipCode: checkoutBillingAddress.zipCode,
    street: checkoutBillingAddress.street,
    neighborhood: checkoutBillingAddress.neighborhood,
    stateId: checkoutBillingAddress.stateId,
    cityId: checkoutBillingAddress.cityId,
    states: getConfig(state, "states"),
    cities: getCitiesByStateId(state, customerData?.stateId),
    stateIdField: customerData?.stateId,
    addressLoading,
    installments,
    coupon,
    billingType,
    creditCard,
    goodThru,
    creditCardStep,
    creditCardTokens,
    hasCreditCardSaved: !!creditCardTokens.length,
    externalContent,
    externalContentDetails,
    bankslipBarcodeUrl,
    defaultGateway: getConfigByName(state, "defaultGateway"),
    user,
    leadCreated,
    currentCourseId: getCurrentCourseId(state),
    leadSavedData,
    installmentCount,
    paymentValue,
    wasPreviousStudent,
    countryCodeOptions: getCountryCodeOptions(state),
  };
}

const validate = (values) => {
  const { goodThru } = values;
  const postalCode = values.customerData?.postalCode;
  const currentYear = new Date().getFullYear() - 2000;
  const currentMonth = new Date().getMonth() + 1;
  const inputMonth = goodThru?.split("/")[0];
  const inputYear = goodThru?.split("/")[1];

  const invalidMonth = inputMonth > 12;
  const invalidYear = inputYear < currentYear;
  const invalidMonthForCurrentYear =
    inputYear == currentYear && inputMonth < currentMonth;

  const errors = { customerData: {} };

  if (postalCode?.length < 8) {
    errors.customerData.postalCode = "invalid";
  }
  if (invalidMonth || invalidYear || invalidMonthForCurrentYear) {
    errors.goodThru = "invalid";
  }

  return errors;
};

const asyncValidate = (values, dispatch, props) => {
  const { zipCode: searchedPostalCode } = props;
  const { customerData } = values;
  const postalCode = customerData?.postalCode;
  const initialPostalCode = props?.initialValues?.customerData?.postalCode;

  const alreadySearched = postalCode == searchedPostalCode;

  const isPostalCodeChanged =
    postalCode != initialPostalCode ||
    (searchedPostalCode && searchedPostalCode != initialPostalCode);

  if (isPostalCodeChanged && !alreadySearched) {
    searchAddressForPayment(dispatch, postalCode);
  }

  return Promise.resolve(true);
};

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    onSubmit: createPayment,
    onChange: saveValues,
    asyncValidate,
    destroyOnUnmount: false,
    validate,
  })(PaymentFormContainer)
);
