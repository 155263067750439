import React, { useCallback } from "react";
import { connect } from "react-redux";
import AnnotationsList from "./AnnotationsList";
import { currentCourse } from "../../selectors/courseSelectors";
import { getAnnotationsByParams } from "../../selectors/annotationSelectors";
import { fetchCourseAnnotations } from "../../processes/annotationProcesses";

const AnnotationListContainer = (props) => {
  const { dispatch, course } = props;

  const handleFetchCourseAnnotations = useCallback(() => {
    if (course) {
      fetchCourseAnnotations(dispatch, course.id);
    }
  }, [dispatch, course]);

  return (
    <AnnotationsList
      handleFetchCourseAnnotations={handleFetchCourseAnnotations}
      {...props}
    />
  );
};

const mapStateToProps = (state, { lesson, scope, order }) => ({
  course: currentCourse(state),
  annotations: getAnnotationsByParams(state, { lesson, scope, order }),
  loading: state.coursesList.annotationsLoading,
  error: state.coursesList.annotationsError,
});

export default connect(mapStateToProps)(AnnotationListContainer);
