import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { resendConfirmationEmail } from "../../processes/signUpProcesses";
import ResendConfirmationEmailForm from "./ResendConfirmationEmailForm";

function mapStateToProps(state) {
  return {
    initialValues: {},
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "resendConfirmationEmail",
    onSubmit: resendConfirmationEmail,
  })(ResendConfirmationEmailForm)
);
