import { css } from "glamor";
import React from "react";
import { Card, Grid, Select } from "semantic-ui-react";
import makeDropDownOptions from "../../../utils/makeDropDownOptions";
import { Link, Page, colors, spacing, styles } from "../../library";
import MyPerformanceLessonsCard from "../../myPerformance/MyPerformanceLessonsCard";
import MyPerformanceMockCard from "../../myPerformance/MyPerformanceMockCard";
import MyPerformanceProgressCard from "../../myPerformance/MyPerformanceProgressCard";
import { AiFillCaretLeft } from "react-icons/ai";
import Spinner from "../../library/Spinner";

const container = css({
  padding: spacing.m,
});
const filterClass = css({
  marginBottom: spacing.m,
});
const titleClass = css(styles.alternativeFontFamily, {
  display: "flex",
  alignItems: "center",
  gap: spacing.s,
  fontSize: 24,
  fontWeight: "bold",
  color: colors.secondary,
  lineHeight: 1.5,
  marginBottom: spacing.m,
});
const backLinkClass = css({
  color: colors.primaryShadow,
  display: "flex",
  alignItems: "center",
  gap: spacing.xxs,
  marginBottom: spacing.xs,
  fontSize: 18,
  fontWeight: "bold",
  ":hover": {
    color: colors.primary,
    cursor: "pointer",
  },
});

export default function MyPerformance({
  courses,
  handleCourseChange,
  selectedCourseId,
  report,
  course,
  disableCourseDropdown,
  title = "Meu Desempenho",
  coursesLoading,
  reportLoading,
}) {
  return coursesLoading ? (
    <Spinner />
  ) : (
    <div className={container}>
      <div className={titleClass}>Meu Desempenho</div>

      <Select
        options={makeDropDownOptions(courses)}
        onChange={handleCourseChange}
        value={selectedCourseId}
        className={filterClass}
        disabled={disableCourseDropdown}
        loading={reportLoading}
        fluid
      />
      {report ? (
        <>
          <Grid columns="equal" stretched>
            <Grid.Column computer={8} mobile={16}>
              <MyPerformanceLessonsCard report={report} />
            </Grid.Column>
            <Grid.Column computer={8} mobile={16}>
              <MyPerformanceProgressCard report={report} />
            </Grid.Column>
          </Grid>
          <Grid columns="equal" doubling>
            <Grid.Column>
              <MyPerformanceMockCard course={course} report={report} />
            </Grid.Column>
          </Grid>
        </>
      ) : (
        <Card fluid>
          <Card.Content textAlign="center">
            <p>Simulados não disponível para este curso</p>
          </Card.Content>
        </Card>
      )}
    </div>
  );
}
