import { useEffect } from "react";

const useEventListener = (eventName, handler, target = window) => {
  useEffect(() => {
    const eventListener = (event) => handler(event);

    target.addEventListener(eventName, eventListener);

    return () => {
      target.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler, target]);
};

export default useEventListener;
