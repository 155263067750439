import memoize from "fast-memoize";
import React from "react";
import { Field } from "redux-form";
import TextInput from "./TextInput";
import buildFormField from "./buildFormField";

const FormField = buildFormField(TextInput, (input, meta, rest) => {
  return {
    ...input,
    autoComplete: "off",
    ...rest,
    invalid: `${meta.touched && (meta.invalid || rest.invalid)}`,
  };
});

let id = 0;
function memoizedId(x) {
  if (!x.__memoizedId) x.__memoizedId = ++id;
  return { __memoizedId: x.__memoizedId };
}

// this needs to be memoizable!
const buildValidator = memoize(
  (required, other) => {
    return [
      (value) => {
        if (required && !value) {
          return "required";
        }
        if (value.split(" ").length < 2) {
          return "minSize";
        }
        if (value?.includes("@")) {
          return "invalid";
        }
        return undefined;
      },
    ].concat(other || []);
  },
  {
    serializer: (args) => {
      const argumentsWithFuncIds = Array.from(args).map((x) => {
        if (typeof x === "function") return memoizedId(x);
        return x;
      });
      return JSON.stringify(argumentsWithFuncIds);
    },
  }
);

export default function NameField(props) {
  const { required, validate } = props;

  return (
    <Field
      {...props}
      component={FormField}
      validate={buildValidator(required, validate)}
    />
  );
}
