import React from "react";
import { Route } from "react-router-dom";
import CourseCheckoutContainer from "../components/courseCheckout/CourseCheckoutContainer";
import Lobby from "../components/Lobby";
import CertificateUserFormContainer from "../components/certificate_user/CertificateUserFormContainer";
import RequireSignIn from "../components/lobby/RequireSignIn";
import NewPasswordFormContainer from "../components/recoveryPassword/NewPasswordFormContainer";
import RecoveryPasswordFormContainer from "../components/recoveryPassword/RecoveryPasswordFormContainer";
import ResendConfirmationEmailFormContainer from "../components/resendConfirmationEmail/ResendConfirmationEmailFormContainer";
import ImpersonateContainer from "../components/signIn/ImpersonateContainer";
import SignInFormContainer from "../components/signIn/SignInFormContainer";
import SignUpFormContainer from "../components/signUp/SignUpFormContainer";

export default function LobbyRoutes(props) {
  return (
    <Lobby {...props}>
      <Route path="/signin" component={SignInFormContainer} exact />
      <Route path="/impersonate" component={ImpersonateContainer} exact />
      <Route path="/signup" component={SignUpFormContainer} exact />
      <Route
        path="/courses/:courseId/checkout"
        component={CourseCheckoutContainer}
        exact
      />
      <Route
        path="/courseOffers/:courseOfferId"
        component={CourseCheckoutContainer}
      />
      <Route
        path="/forgotten-password"
        component={RecoveryPasswordFormContainer}
        exact
      />
      <Route
        path="/reset-password"
        component={NewPasswordFormContainer}
        exact
      />
      <Route
        path="/resend-confirmation-email"
        component={ResendConfirmationEmailFormContainer}
        exact
      />
      <Route path="*" component={RequireSignIn} />
      <Route
        path="/valide-seu-certificado"
        component={CertificateUserFormContainer}
        exact
      />
    </Lobby>
  );
}
