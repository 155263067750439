import { connect } from "react-redux";
import Classrooms from "./Classrooms";
import { getAllClassrooms } from "../../selectors/classroomSelectors";

function mapStateToProps(state) {
  return {
    classrooms: getAllClassrooms(state),
  };
}

export default connect(mapStateToProps)(Classrooms);
