import React from "react";
import { css, select } from "glamor";
import Link from "./Link";
import { colors, spacing, typographyColors } from "./theme";

const iconClass = css({
  marginRight: spacing.s,
  opacity: 0.3,
});
const activeClass = css(
  {
    cursor: "pointer",
    transition: ".3s all",
    color: `${colors.primaryShadow} !important`,
    borderBottom: `${spacing.xxs} solid ${colors.primaryShadow}`,
    "@media(max-width: 575px)": {
      borderBottom: "none",
      background: colors.primaryClear,
      width: "100%",
    },
  },
  select(`& .${iconClass}`, {
    transition: ".3s opacity",
    opacity: 1,
  })
);
const item = css({
  display: "flex",
  alignItems: "center",
  padding: `${spacing.s} ${spacing.m}`,
  color: `${typographyColors.defaultBody} !important`,
  transition: ".3s all",
  height: 80,
  borderBottom: `${spacing.xxs} solid transparent`,
  "&:hover": {
    ...activeClass,
  },
  "&:visited": {
    color: "inherit",
  },
});
const subItemClass = css({
  fontSize: 14,
  paddingLeft: 24,
  lineHeight: 1.5,
});
const disabledClass = css({
  color: colors.greyLight,
  pointerEvents: "none",
  userSelect: "none",
  "&:hover": {
    borderBottom: "none",
  },
  "&:visited": {
    borderBottom: "none",
    color: colors.greyLight,
  },
});

export default function MenuItem(props) {
  const {
    label,
    icon,
    active,
    children,
    subItem,
    className,
    disabled,
    ...rest
  } = props;
  const baseClass = css(
    item,
    subItem && subItemClass,
    className && className,
    active && activeClass,
    disabled && disabledClass
  );
  const Icon = icon;

  return (
    <Link className={baseClass} {...rest}>
      {icon && <Icon size={subItem ? 16 : 22} className={iconClass} />}
      {label || children}
    </Link>
  );
}
