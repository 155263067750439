import React, { useEffect, useCallback, useState, useMemo } from "react";
import { connect } from "react-redux";
import MockResultsModal from "./MockResultsModal";
import {
  getCurrentMock,
  getMockTemplateById,
  levelsWithResultsFromMockTemplate,
} from "../../selectors/mockSelectors";
import { getCurrentUser } from "../../selectors/userSelectors";
import { fetchMockTemplate } from "../../processes/mockTemplateProcesses";
import { formatStringList } from "../../utils/formatStringList";

const calculateLevelsSummary = (score, levels = []) => {
  const approvedLevels = [];
  const failedLevels = [];
  const passingScore = score >= 70 ? 50 : 70;

  levels.forEach((level) => {
    const levelScore =
      (level.countTrue * 100) / (level.countTrue + level.countFalse);

    levelScore >= passingScore
      ? approvedLevels.push(level.name)
      : failedLevels.push(level.name);
  });

  return {
    approvedLevelsNames: formatStringList(approvedLevels),
    failedLevelsNames: formatStringList(failedLevels),
  };
};

function MockResultsModalContainer(props) {
  const {
    dispatch,
    mock = {},
    mockTemplate,
    levelsWithResults,
    history,
  } = props;
  const { mockTemplateId } = mock;

  const handleClose = useCallback(() => {
    history?.goBack();
  }, [history?.goBack]);

  useEffect(() => {
    if (!mockTemplateId) return;
    fetchMockTemplate(dispatch, mockTemplateId);
  }, [dispatch, mockTemplateId]);

  const score = parseInt(mock?.average);
  const levelsSummary = useMemo(
    () =>
      mockTemplate?.courseSlug == "cfp"
        ? calculateLevelsSummary(score, levelsWithResults)
        : {},
    [score, levelsWithResults, mockTemplate?.courseSlug]
  );

  return (
    <MockResultsModal
      handleClose={handleClose}
      score={score}
      levelsSummary={levelsSummary}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const mock = getCurrentMock(state);

  return {
    mock,
    isFinished: !!mock?.finishedAt,
    mockTemplate: getMockTemplateById(state, mock?.mockTemplateId),
    levelsWithResults: levelsWithResultsFromMockTemplate(
      state,
      mock?.mockTemplateId
    ),
    currentUser: getCurrentUser(state),
  };
}

export default connect(mapStateToProps)(MockResultsModalContainer);
