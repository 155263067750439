import React from "react";
import { Table, Card, Icon } from "semantic-ui-react";
import { spacing } from "../library/theme";
import { css } from "glamor";
import { useSelector } from "react-redux";
import { getUsersTopTenByProps } from "../../selectors/classroomSelectors";

const topTenWrapper = css({
  marginTop: spacing.xl,
});
const topTenTitle = css({
  marginBottom: spacing.m,
});
const topTenTitleWrapper = css({
  padding: spacing.m,
  flexGrow: "unset !important",
});
const tableWrapper = css({
  display: "grid",
  gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
  overflow: "auto",
  whiteSpace: "nowrap",
});

export default function TopTenCard({
  title,
  subTitle,
  lastColumnTitle,
  color,
  valueSuffix,
  items = [],
}) {
  const cardTitleStyle = { color };
  const headerCellClass = css({
    backgroundColor: `${color}20 !important`,
  }).toString();

  return (
    <Card
      className={css({
        boxShadow: "none !important",
        border: `1px solid ${color} !important`,
      }).toString()}
    >
      <Card.Content className={topTenTitleWrapper}>
        <Card.Meta textAlign="center" style={cardTitleStyle}>
          {subTitle}
        </Card.Meta>
        <Card.Header textAlign="center" style={cardTitleStyle}>
          {title}
        </Card.Header>
      </Card.Content>
      <div className={tableWrapper}>
        <Table unstackable compact="very" celled={false}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className={headerCellClass} textAlign="center">
                Rank
              </Table.HeaderCell>
              <Table.HeaderCell className={headerCellClass}>
                Nome
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center" className={headerCellClass}>
                {lastColumnTitle}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {items?.map((item) => (
              <Table.Row>
                <Table.Cell textAlign="center">{item?.rank}º</Table.Cell>
                <Table.Cell>{item?.name}</Table.Cell>
                <Table.Cell textAlign="center">
                  {item?.value}
                  {valueSuffix}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        {!items?.length && (
          <span
            style={{
              width: "100%",
              textAlign: "center",
              paddingTop: spacing.m,
              paddingBottom: spacing.l,
            }}
          >
            <Icon name="x" />
            Vazio
          </span>
        )}
      </div>
    </Card>
  );
}
