import React from "react";
import { Card } from "semantic-ui-react";
import { spacing } from "../library/theme";
import Button from "../library/Button";
import Form from "../library/forms/Form";
import TextField from "../library/forms/TextField";
import EmailField from "../library/forms/EmailField";
import FileField from "../library/forms/FileField";
import DropDownField from "../library/forms/DropDownField";
import makeDropDownOptions from "../../utils/makeDropDownOptions";
import { css } from "glamor";

const emailFieldClass = css({
  backgroundColor: "white",
});
const submitButtonStyle = {
  marginTop: spacing.m,
};
const linkStyle = {
  display: "block",
  cursor: "pointer",
};

export default function ImportStudentsForm({
  handleSubmit,
  company,
  classrooms,
  pristine,
  submitting,
}) {
  return (
    <Form onSubmit={handleSubmit}>
      <div style={{ width: "100%" }}>
        <div style={{ marginBottom: spacing.m }}>
          <FileField
            name="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            placeholder="planilha"
          />

          <a
            style={linkStyle}
            href="/import_template.xlsx"
            download="import_template.xlsx"
            target="_blank"
          >
            Baixar planilha de exemplo
          </a>
        </div>

        <DropDownField
          name="courseId"
          label="Curso"
          required
          options={makeDropDownOptions(
            company?.meta?.courses || [],
            "Nenhum curso adquirido"
          )}
        />
        <DropDownField
          name="classroomId"
          required
          label="Turma"
          options={makeDropDownOptions(classrooms)}
        />
      </div>

      <Button
        type="button"
        disabled={pristine || submitting}
        loading={submitting}
        onClick={handleSubmit}
        fluid
        color="green"
        style={submitButtonStyle}
      >
        IMPORTAR
      </Button>
    </Form>
  );
}
