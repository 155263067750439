import React, { useEffect, useCallback } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import FilterForm from "./FilterForm";
import { fetchUsers } from "../../processes/userProcesses";
import debounce from "../../utils/debounce";
import { getConfig, getCitiesByStateId } from "../../selectors/configSelectors";
import { formValueSelector } from "redux-form";
import { fetchCities } from "../../processes/configProcesses";

function FilterFormContainer(props) {
  const { stateId, dispatch, reset } = props;

  useEffect(() => {
    stateId && fetchCities(dispatch, stateId);
  }, [dispatch, stateId]);

  const cleanFiltersHandler = useCallback(() => {
    reset();
  }, [reset]);

  return (
    <FilterForm
      isStateSelected={!!stateId}
      cleanFiltersHandler={cleanFiltersHandler}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const usersFilterFormSelector = formValueSelector("usersFilterForm");
  const stateId = usersFilterFormSelector(state, "stateIdEq");

  return {
    initialValues: {},
    activePage: state.networking.activePage,
    states: getConfig(state, "states"),
    cities: getCitiesByStateId(state, stateId),
    stateId,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "usersFilterForm",
    onChange: debounce(fetchUsers, 500),
  })(FilterFormContainer)
);
