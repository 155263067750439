import { LOCATION_CHANGE } from "connected-react-router";
import { matchPath } from "react-router-dom";

const initialState = {
  externalContent: undefined,
  externalContentDetails: undefined,
  bankslipBarcodeUrl: undefined,
  leadCreated: undefined,
  leadIsFromPreparatoryCourse: undefined,
  checkoutBillingAddress: {
    addressLoading: undefined,
    zipCode: undefined,
    street: undefined,
    neighborhood: undefined,
    cityId: undefined,
    stateId: undefined,
  },
};

export default function paymentCheckoutReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      const atCheckout = matchPath(action.payload.location.pathname, {
        path: "/courses/:courseId/checkout",
      });
      return atCheckout ? state : initialState;
    case "SAVE_EXTERNAL_CONTENT":
      return {
        ...state,
        externalContent: action.externalContent,
        externalContentDetails: action.externalContentDetails,
        bankslipBarcodeUrl: action.bankslipBarcodeUrl,
      };
    case "CHECKOUT_START_ADDRESS_LOADING":
      return {
        ...state,
        addressLoading: true,
        checkoutBillingAddress: {
          zipCode: state.checkoutBillingAddress.zipCode,
        },
      };
    case "CHECKOUT_END_ADDRESS_LOADING":
      return { ...state, addressLoading: false };
    case "CHECKOUT_LEAD_CREATED":
      return {
        ...state,
        leadCreated: true,
        leadIsFromPreparatoryCourse: action.isFromPreparatoryCourse,
      };
    case "CHECKOUT_LEAD_CHANGED":
      return {
        ...state,
        leadCreated: false,
        leadIsFromPreparatoryCourse: false,
      };
    case "CHECKOUT_ADDRESS_FETCHED":
      return {
        ...state,
        checkoutBillingAddress: {
          zipCode: action.zipCode,
          street: action.street,
          neighborhood: action.neighborhood,
          cityId: action.cityId,
          stateId: action.stateId,
        },
      };
    default:
      return state;
  }
}
