import { css } from "glamor";
import React from "react";
import { Page, Link, Button, spacing } from "../library";
import QuestionContainer from "../questions/QuestionContainer";
import MockTimer from "./MockTimer";
import { typographyColors } from "../library/theme";
import MockPreferecencesContainer from "./MockPreferencesModalContainer";

const actionsClass = css({
  display: "flex",
  gap: spacing.m,
  overflowX: "auto",
  scrollbarWidth: "none",
  "::-webkit-scrollbar": {
    display: "none",
  },
  marginBottom: spacing.m,
});

const mockTimerClass = css({
  display: "flex",
  justifyContent: "right",
  position: "relative",
  bottom: 68,
  lineHeight: 1,
  height: 0,
  "@media (max-width: 1200px)": {
    display: "none",
  },
});

const timeTextStyle = {
  color: typographyColors.blackLetters,
  fontSize: 20,
  minWidth: 260,
};

export default function Mock({ mock }) {
  const canViewResults = !!mock?.finishedAt && !!mock?.results;
  const canViewRanking =
    canViewResults && !mock?.exam && !!mock?.mockTemplateId;

  return (
    <Page title={mock?.title}>
      <MockTimer
        className={mockTimerClass}
        textStyle={timeTextStyle}
        createdAt={mock?.createdAt}
        finishedAt={mock?.finishedAt}
        showTimer
      />

      <div className={actionsClass}>
        <>
          {canViewResults && (
            <Link component={Button} to={`/mocks/${mock?.id}/results`}>
              Ver Resultado
            </Link>
          )}
          {canViewRanking && (
            <Link component={Button} to={`/mocks/${mock?.id}/ranking`}>
              Ver Ranking
            </Link>
          )}
        </>
        {!mock?.exam && <MockPreferecencesContainer />}
      </div>

      <QuestionContainer />
    </Page>
  );
}
