const getCourseFiles = (action) => action.entities.courseFiles;

export default function courseFilesReducer(state = {}, action) {
  switch (action.type) {
    case "COURSE_FILES_FETCHED":
      return { ...state, ...getCourseFiles(action) };
    default:
      return state;
  }
}
