import React from "react";
import { TextArea } from "semantic-ui-react";
import { css } from "glamor";
import { colors } from "../theme";

const container = css({
  backgroundColor: `${colors.greyLighter} !important`,
  border: "none !important",
  borderRadius: "10px !important",
});

export default function TextAreaInput({ invalid, ...rest }) {
  return <TextArea className={container.toString()} {...rest} />;
}
