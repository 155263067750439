import React, { useCallback, useLayoutEffect, useState } from "react";
import { css } from "glamor";
import { spacing, typographyColors, uiColors } from "../library/theme";
import { IoIosArrowDropdown } from "react-icons/io";
import ReactDOM from "react-dom";

const moveUpDown = css.keyframes({
  "0%, 100%": {
    transform: "translateY(0)",
  },
  "50%": {
    transform: "translateY(-10px)",
  },
});
const questionAlert = css({
  position: "fixed",
  height: 200,
  zIndex: 4,
  left: 0,
  right: 0,
  backgroundImage: `linear-gradient(to top, ${uiColors.backgroundDark} 5%, transparent)`,
  cursor: "pointer",
});
const alertContent = css({
  paddingTop: spacing.xl,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: spacing.m,
  backgroundImage: `radial-gradient(${uiColors.backgroundDark}, transparent)`,
});
const alertTitle = css({
  color: typographyColors.inverseBody,
});
const arrowClass = css({
  color: uiColors.background,
  animation: `${moveUpDown} 1s linear infinite`,
  background: uiColors.fieldBackground,
  borderRadius: "50%",
  ":hover": {
    animation: "none",
    cursor: "pointer",
  },
});

export default function LessonQuestionAlert(props) {
  const { questionRef } = props;
  const [questionY, setQuestionY] = useState(0);

  const goToQuestion = useCallback(() => {
    window.scrollTo(0, questionY);
  }, [questionY]);

  const bottomPosition = {
    bottom: 0,
  };

  useLayoutEffect(() => {
    setQuestionY(questionRef?.current?.clientHeight + 200);
  });

  return ReactDOM.createPortal(
    <div
      id="question-alert"
      className={css(questionAlert, bottomPosition)}
      onClick={goToQuestion}
    >
      <div className={alertContent}>
        <h3 className={alertTitle}>Responda a questão disponível abaixo</h3>
        <IoIosArrowDropdown size={44} className={arrowClass} />
      </div>
    </div>,
    document.getElementById("LMS")
  );
}
