import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Classroom from "./Classroom";
import { getClassroomById } from "../../selectors/classroomSelectors";
import { fetchClassroom } from "../../processes/classroomsProcesses";
import { getCurrentUser } from "../../selectors/userSelectors";

function ClassroomContainer(props) {
  const { dispatch, classroomId, user } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (classroomId) {
      fetchClassroom(dispatch, classroomId).finally(() => setLoading(false));
    }
  }, [dispatch, classroomId]);

  return <Classroom loading={loading || !user} {...props} />;
}

function mapStateToProps(state, ownProps) {
  const { classroomId } = ownProps.match.params;

  return {
    classroomId,
    classroom: getClassroomById(state, classroomId),
    user: getCurrentUser(state),
  };
}

export default connect(mapStateToProps)(ClassroomContainer);
