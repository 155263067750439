import { apiPost } from "./helpers/api";
import { fetchUser } from "./userProcesses";

export const createCertificationExam = (values, dispatch, props) => {
  const { course, userId } = props;

  return apiPost("/api/v1/certification_exams")
    .send({ ...values,  courseId: course.id, userId })
    .then(() => {
      fetchUser(dispatch, userId);
    })
    .catch((err) => {
      throw new SubmissionError({
        _error: err.response?.body?.errors?.full_messages[0],
      });
    });
};
