import React, { useEffect } from "react";
import { connect } from "react-redux";
import LobbyRoutes from "./LobbyRoutes";
import LmsRoutes from "./LmsRoutes";

function RoutesContainer({ history, loggedIn, path, dispatch, ...rest }) {
  useEffect(() => {
    dispatch({ type: "AFTER_SIGN_IN_PATH", path });
  }, []);

  return loggedIn ? <LmsRoutes {...rest} /> : <LobbyRoutes {...rest} />;
}

function mapStateToProps(state) {
  const { currentUserId, router } = state;
  const location = router.location;

  return {
    loggedIn: !!currentUserId,
    path: location.pathname + location.search,
  };
}

export default connect(mapStateToProps)(RoutesContainer);
