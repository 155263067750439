import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";
import toQueryString from "../utils/toQueryString";

const lesson = new schema.Entity("lessons", {
  meta: { question: new schema.Entity("questions") },
});

export const fetchLessons = (dispatch, courseId) => {
  return apiGet(`/api/v1/courses/${courseId}/lessons`).then((response) => {
    dispatch({
      type: "LESSONS_FETCHED",
      ...normalize(response.body.data, new schema.Array(lesson)),
    });
  });
};

export const submitSearchFilter = (values, dispatch, { courseId }) => {
  if (!values.nameOrKeywordsCont) return;

  dispatch({ type: "COURSE_LESSON_SEARCH_FETCH_REQUEST" });

  return apiGet(
    `/api/v1/courses/${courseId}/lessons?${toQueryString(values)}`
  ).then((response) => {
    dispatch({
      type: "COURSE_LESSON_SEARCH_FETCHED",
      ...normalize(response.body.data, new schema.Array(lesson)),
    });
  });
};
