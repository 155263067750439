const getTrails = (action) => action.entities.trails;

export default function trailsReducer(state = {}, action) {
  switch (action.type) {
    case "TRAILS_FETCHED":
      return { ...state, ...getTrails(action) };
    default:
      return state;
  }
}
