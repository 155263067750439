import { connect } from "react-redux";
import LessonSidebarContents from "./LessonSidebarContents";
import { getLevelsFromCurrentCourse } from "../../selectors/levelSelectors";
import { formValueSelector } from "redux-form";
import { getLessonsByIds } from "../../selectors/lessonSelectors";

function mapStateToProps(state) {
  const searchLessonSelector = formValueSelector("courseSearchLesson");
  const nameOrKeywordsCont = searchLessonSelector(state, "nameOrKeywordsCont");

  return {
    levels: getLevelsFromCurrentCourse(state),
    loading: state.coursesList.searchLessonLoading,
    searchParam: nameOrKeywordsCont,
    lessons: getLessonsByIds(state, state.coursesList.searchLessonIds),
  };
}

export default connect(mapStateToProps)(LessonSidebarContents);
