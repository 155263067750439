import React from "react";
import { Comment } from "semantic-ui-react";
import { fromNow } from "../../utils/formatDate";
import Link from "../library/Link";
import Paragraph from "../library/Paragraph";
import { spacing, styles, uiColors, utilityColors } from "../library/theme";
import { AiFillPushpin } from "react-icons/ai";
import { css } from "glamor";

const linkStyle = {
  cursor: "pointer",
  color: utilityColors.danger,
  paddingTop: spacing.xxs,
  display: "block",
  textDecoration: "underline",
};

const htmlClass = css({
  ...styles.wrappingText,
  whiteSpace: "pre-line",
  "& + &": {
    marginTop: spacing.m,
  },
});

export default function CommentListItem(props) {
  const { comment, currentUserId, deleteHandler } = props;
  return (
    <Comment>
      <Comment.Avatar src={comment.meta.user.avatarUrl} />
      <Comment.Content>
        <Comment.Author as="a">{comment.meta.user.name}</Comment.Author>
        <Comment.Metadata>{fromNow(comment.createdAt)}</Comment.Metadata>
        <Comment.Metadata>
          {comment.highlight && <AiFillPushpin size="15" />}
        </Comment.Metadata>
        <Comment.Text>
          <div
            className={htmlClass}
            dangerouslySetInnerHTML={{
              __html: comment.text,
            }}
          ></div>
        </Comment.Text>
        {comment.pristine && (
          <Paragraph>
            (Este comentário só será exibido após aprovação.)
          </Paragraph>
        )}
        {currentUserId === comment?.userId && (
          <Link onClick={deleteHandler} style={linkStyle} noHref>
            Excluir Comentário
          </Link>
        )}
      </Comment.Content>
      {comment.meta.replies.length > 0 && (
        <Comment.Group>
          {comment.meta.replies.map((reply) => (
            <CommentListItemContainer
              comment={reply}
              key={`commentReply${reply.id}`}
            />
          ))}
        </Comment.Group>
      )}
    </Comment>
  );
}
