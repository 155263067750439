import React from "react";
import Spinner from "../library/Spinner";
import GreetingFormContainer from "./GreetingFormContainer";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../selectors/userSelectors";
import Page from "../library/Page";

export default function GreetingQuestions() {
  const user = useSelector((state) => getCurrentUser(state));

  return (
    <Page title="Cargo e Interesses">
      {user ? <GreetingFormContainer /> : <Spinner wrapped />}
    </Page>
  );
}
