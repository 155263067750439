import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

const lessonFile = new schema.Entity("lessonFiles");

export const fetchLessonFiles = (dispatch, courseId, lessonId) => {
  apiGet(`/api/v1/courses/${courseId}/lessons/${lessonId}/lesson_files`).then(
    (response) => {
      dispatch({
        type: "LESSON_FILES_FETCHED",
        ...normalize(response.body.data, new schema.Array(lessonFile)),
      });
    }
  );
};
