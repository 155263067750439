import React from "react";
import { matchPath, useLocation } from "react-router";
import { makeIsMobile } from "../../utils/useScreenWidth";
import AppSidebar from "./AppSidebar";
import LessonSidebar from "./LessonSidebar";
import MockSidebar from "./MockSidebar";
import { css } from "glamor";
import { Icon, Modal } from "semantic-ui-react";
import { spacing } from "../library/theme";

const appMenuClass = css({
  cursor: "pointer",
}).toString();
const modalClass = css({
  minHeight: "100vh",
  margin: "0 !important",
  borderRadius: "0 !important",
}).toString();
const mobileSidebarClass = css({
  overflow: "auto",
  minHeight: "100vh",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: spacing.m,
  marginRight: "auto !important",
});

const getCurrentSidebar = ({
  pathname,
  mock,
  lesson,
  currentCourse,
  expanded,
}) => {
  const isMockPath = !!matchPath(pathname, { path: "/mocks/:id" });
  const isNewMockPath = !!matchPath(pathname, {
    path: ["/mocks/new", "/mocks/custom/new", "/mocks/exam/new"],
  });
  const isLessonPath = !!matchPath(pathname, {
    path: "/courses/:courseId/lessons/:lessonId",
  });

  if (isNewMockPath) {
    return <AppSidebar />;
  }
  if (isMockPath) {
    return <MockSidebar mock={mock} />;
  }
  if (isLessonPath) {
    return <LessonSidebar lesson={lesson} currentCourse={currentCourse} />;
  }
  return <AppSidebar expanded={expanded} />;
};

export default function Sidebar(props) {
  const {
    user,
    expanded,
    lesson,
    currentCourse,
    mock,
    appSidebarVisible,
    toggleAppSidebarVisibility,
    isLarge,
  } = props;

  if (!user) return null;

  const isMobile = makeIsMobile();
  const { pathname } = useLocation();

  const sidebar = getCurrentSidebar({
    pathname,
    mock,
    lesson,
    currentCourse,
    expanded,
  });

  return isMobile ? (
    <Modal
      open={appSidebarVisible}
      onOpen={toggleAppSidebarVisibility}
      onClose={toggleAppSidebarVisibility}
      centered={false}
      dimmer="blurring"
      closeOnDocumentClick={true}
      className={modalClass}
      trigger={
        <Icon
          name="bars"
          size="big"
          color="black"
          className={appMenuClass}
          onClick={toggleAppSidebarVisibility}
        />
      }
      as={() => (
        <div className={mobileSidebarClass}>
          <div>{sidebar}</div>
        </div>
      )}
    />
  ) : (
    sidebar
  );
}
