import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";
import { courseReinstatement } from "../../../processes/courseProcesses";
import { fetchLevels } from "../../../processes/levelProcesses";
import { createMock } from "../../../processes/mockProccess";
import {
  getAllCourses,
  getCourseById,
  getCourseForDropdownOptions,
  getCourseIdsByMocksPresence,
  hasMockCourses,
} from "../../../selectors/courseSelectors";
import {
  getLevelsForDropdownOptions,
  getLevelsFromCourse,
} from "../../../selectors/levelSelectors";
import { getCurrentUser } from "../../../selectors/userSelectors";
import { makeIsMobile } from "../../../utils/useScreenWidth";
import MockCustomForm from "./MockCustomForm";
import { getCourseUserByCourseId } from "../../../selectors/coursesUserSelectors";

function MockCustomFormContainer(props) {
  const { dispatch, course, courseId, history } = props;

  const isMobile = makeIsMobile();

  useEffect(() => {
    if (courseId) fetchLevels(dispatch, courseId);
  }, [dispatch, courseId]);

  const reinstatementHandler = React.useCallback(() => {
    courseReinstatement(dispatch, course?.id);
  }, [dispatch, course?.id]);

  return (
    <MockCustomForm
      reinstatementHandler={reinstatementHandler}
      isMobile={isMobile}
      {...props}
    />
  );
}

const formName = "mockForm";

function mapStateToProps(state) {
  const selector = formValueSelector(formName);
  const courseId = selector(state, "courseId");
  const course = getCourseById(state, courseId);
  const onlyCalcRequired = selector(state, "onlyCalcRequired");
  const onlyCalcNotRequired = selector(state, "onlyCalcNotRequired");
  const courses = getCourseForDropdownOptions(state);
  const currentUser = getCurrentUser(state);

  return {
    initialValues: {
      courseId: courses[0]?.id,
    },
    coursesReady: getAllCourses(state)?.length > 0,
    courses,
    levelsForDropdownOptions: getLevelsForDropdownOptions(state, courseId),
    levels: getLevelsFromCourse(state, courseId),
    courseId,
    course,
    onlyCalcRequired,
    onlyCalcNotRequired,
    hasMockCourses: hasMockCourses(state),
    courseWithMockIds: getCourseIdsByMocksPresence(state),
    hasMocks: currentUser?.hasMocks,
    courseUser: getCourseUserByCourseId(state, courseId),
    mockCreated: state.mockList.mockCreated,
    routePrefix: "/mobile",
  };
}

const validate = (values, { courseWithMockIds = [], course, courseUser }) => {
  const errors = {};

  if (courseWithMockIds?.includes(values.courseId) || courseUser?.paused) {
    errors.courseId = "invalid";
  }

  return errors;
};

export default connect(mapStateToProps)(
  reduxForm({
    form: formName,
    onSubmit: createMock,
    validate,
  })(MockCustomFormContainer)
);
