import React, { useEffect } from "react";
import { connect } from "react-redux";
import buildForm from "../library/forms/buildForm";
import { getCurrentUser } from "../../selectors/userSelectors";
import { updateCurrentUser } from "../../processes/userProcesses";
import PhoneForm from "./PhoneForm";

const FORM_NAME = "phoneForm";
const form = buildForm(FORM_NAME);

function PhoneFormContainer(props) {
  const { setOpenModal, submitSucceeded } = props;

  useEffect(() => {
    if (submitSucceeded) setOpenModal(false);
  }, [submitSucceeded]);

  return <PhoneForm {...props} />;
}

function mapStateToProps(state) {
  const currentUser = getCurrentUser(state);

  return {
    initialValues: { phone: currentUser.phone },
  };
}

export default connect(mapStateToProps)(
  form.connect({
    form: FORM_NAME,
    onSubmit: updateCurrentUser,
  })(PhoneFormContainer)
);
