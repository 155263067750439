import React from "react";
import { Form } from "../library/forms";
import LikeButton from "../library/forms/LikeButton";

export default function CommentLikeForm(props) {
  return (
    <Form onSubmit={props.handleSubmit}>
      <LikeButton
        disabled={props.submitting}
        active={!!props.likeId}
        count={props.likesCount}
      />
    </Form>
  );
}
