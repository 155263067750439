import React from "react";
import { css } from "glamor";
import { Button, spacing } from "../library";
import { Form, TextField } from "../library/forms";
import { createTextMask } from "redux-form-input-masks";

const phoneNumberMask = createTextMask({
  pattern: "(99) 99999-9999",
  guide: false,
  allowEmpty: true,
});
const container = { margin: spacing.m };
const fieldTitleStyle = { color: "black", marginBottom: spacing.xxs };
const buttonStyle = {
  width: 100,
};
const fieldWrapper = css({
  display: "flex",
  gap: spacing.s,
  marginBottom: spacing.xxs,
});
const textFieldWrapper = { flex: 1 };
const emoticonStyle = { fontSize: 18 };
const textStyle = { color: "black", fontSize: 12, lineHeight: 1.5 };

export default function PhoneForm(props) {
  const { handleSubmit } = props;

  return (
    <div style={container}>
      <p style={emoticonStyle}>👏</p>
      <p style={textStyle}>
        Agora que temos a data agendada para sua prova, poderia confirmar o seu
        número de WhatsApp conosco para que possamos enviar algumas mensagens
        importantes?
      </p>
      <Form onSubmit={handleSubmit}>
        <p style={fieldTitleStyle}>
          <span style={{ color: "red" }}>*</span>{" "}
          <strong>Celular (WhatsApp)</strong>
        </p>
        <div className={fieldWrapper}>
          <div style={textFieldWrapper}>
            <TextField
              name="phone"
              type="phone"
              placeholder="(99) 99999-9999"
              {...phoneNumberMask}
              fluid
              required
            />
          </div>

          <Button
            style={buttonStyle}
            circular={false}
            color="blue"
            type="submit"
            fluid
          >
            Confirmar
          </Button>
        </div>
      </Form>
    </div>
  );
}
