import { createSelector } from "reselect";
import {
  getLevel,
  getLevels,
  getAllLevels,
  getSublevelsFromLevel,
  getLevelByLessonId,
} from "./levelSelectors";
import orderByPosition from "./helpers/orderByPosition";
import {
  getAllCourses,
  getCurrentCourseId,
  currentCourse,
} from "./courseSelectors";
import { getCurrentUser } from "./userSelectors";
import { getCourseUserByLessonId } from "./coursesUserSelectors";

const orderByProgress = (a, b) => {
  return b?.meta?.progress - a?.meta?.progress;
};

const getLessons = (state) => state.entities.lessons;
export const getLessonId = (_, id) => id;
export const getCurrentLessonId = (state) => state.currentLessonId;
export const getIds = (_, ids) => ids;
const getLessonsUsers = (state) => state.entities.lessonsUsers;
export const getCertificateLessons = (state) =>
  state.certificates.certificateLessons;

export const getAllLessonsUsers = createSelector(
  getLessonsUsers,
  (lessonsUsers) => Object.values(lessonsUsers)
);

export const getAllLessons = createSelector(getLessons, (lessons) =>
  Object.values(lessons)
);

export const getCurrentLesson = createSelector(
  getLessons,
  getLessonId,
  (lessons, id) => lessons[id]
);

export const getLessonsFromLevel = createSelector(
  getLevel,
  getLessons,
  (level, lessons) =>
    Object.values(lessons)
      .filter((lesson) => lesson.levelId === level.id)
      .sort(orderByPosition)
);

const getAllLessonsFromTopLevel = createSelector(
  getSublevelsFromLevel,
  getLessons,
  (levels, lessons) => {
    const levelIds = Object.values(levels)?.map((level) => level.id);
    return Object.values(lessons).filter((lesson) =>
      levelIds.includes(lesson.levelId)
    );
  }
);

export const getLevelDuration = createSelector(
  getAllLessonsFromTopLevel,
  getLessonsFromLevel,
  (subLevellessons, levelLessons) => {
    const lessons =
      subLevellessons.length !== 0 ? subLevellessons : levelLessons;
    const durations = Object.values(lessons).map((lesson) => lesson.duration);
    return durations && durations.reduce((a, b) => a + b, 0);
  }
);

export const getCurrentLevel = createSelector(
  getLevels,
  getLessons,
  getCurrentLessonId,
  (levels, lessons, lessonId) => {
    if (!levels || !lessons || !lessonId) return null;
    const lesson = lessons[lessonId];
    if (!lesson) return null;

    return levels[lesson?.levelId];
  }
);

export const orderedListOfLessons = createSelector(
  getAllLevels,
  getAllLessons,
  getCurrentCourseId,
  (levels, lessons, courseId) => {
    const allLessonsOrdered = [];
    levels
      ?.filter((level) => level.courseId === courseId)
      .sort(orderByPosition)
      .map((level) => {
        lessons
          ?.filter((lesson) => lesson.levelId === level.id)
          .sort(orderByPosition)
          .map((lesson) => allLessonsOrdered.push(lesson));
      });
    return allLessonsOrdered;
  }
);

const nextOrPrevLesson = (lessons, currentLessonId, value = 1) => {
  const lesson = lessons?.filter((lesson) => lesson.id === currentLessonId)[0];
  const idx = lessons?.indexOf(lesson);
  return lessons[idx + value];
};

export const getNextLesson = createSelector(
  orderedListOfLessons,
  getCurrentLessonId,
  (lessons, currentLessonId) => nextOrPrevLesson(lessons, currentLessonId)
);

export const getPrevLesson = createSelector(
  orderedListOfLessons,
  getCurrentLessonId,
  (lessons, currentLessonId) => nextOrPrevLesson(lessons, currentLessonId, -1)
);

export const makeIsCommentsEnabled = createSelector(
  getCurrentLesson,
  getAllCourses,
  (lesson, courses) =>
    courses.find((course) => course.id === lesson?.courseId)?.enableComments
);

export const getLessonsByIds = createSelector(
  getAllLessons,
  getIds,
  (lessons, ids) => lessons.filter((lesson) => ids.includes(lesson.id))
);

export const getContinueCourseLesson = createSelector(
  orderedListOfLessons,
  getAllLessonsUsers,
  (lessons, lessonsUsers) =>
    lessons?.find(
      (l) =>
        !lessonsUsers?.find((lu) => lu.lessonId == l.id && lu.progress > 0.8)
    ) ?? lessons[0]
);

export const getLevelLessons = createSelector(
  getAllLevels,
  getIds,
  getAllLessons,
  (levels, id, lessons) => {
    if (!levels.find((level) => level?.id == id)) return [];

    const sublevelIds = levels
      .filter((lv) => lv.sublevelId == id)
      .map((lv) => lv.id);

    return lessons.filter(
      (l) => sublevelIds.includes(l.levelId) || l.levelId == id
    );
  }
);

export const getLessonById = createSelector(
  getLessons,
  getLessonId,
  (lessons, id) => lessons[id]
);

export const canAccessLesson = createSelector(
  getLessonById,
  getLevelByLessonId,
  getCourseUserByLessonId,
  getCurrentUser,
  (lesson, level, courseUser, user) => {
    const hasDemoVideo =
      lesson?.player == "scaleup"
        ? !!lesson?.demoScaleupUrl
        : !!lesson?.demoUrl;

    if (user?.meta?.features?.canManageCourses || hasDemoVideo) {
      return true;
    }

    const isEnrollmentEnabled = courseUser?.status === "enabled";
    if (!courseUser || !isEnrollmentEnabled || courseUser?.expired) {
      return false;
    }

    if (courseUser?.trial && !level?.trialEnabled) return false;

    return true;
  }
);

export const certificateLessons = createSelector(
  getLessons,
  getAllCourses,
  getCertificateLessons,
  (lessons, courses, certificateLessons) => {
    const certificateLessonsIds =
      certificateLessons?.map((lesson) => lesson.id) || [];

    return certificateLessonsIds
      .map((id) => lessons[id])
      .filter((item) => !!item)
      .map((lesson) => {
        const course = courses.find((course) => lesson?.courseId == course?.id);

        return { ...lesson, courseName: course?.name };
      })
      .sort(orderByPosition)
      .sort(orderByProgress);
  }
);
