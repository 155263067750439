import { createSelector } from "reselect";
import { getLessonId } from "./lessonSelectors";
import { getCurrentUserId } from "./userSelectors";

const getLessonsUsers = (state) => state.entities.lessonsUsers;
const getProps = (_, props) => props;

const getAllLessonsUsers = createSelector(getLessonsUsers, (lessonsUsers) =>
  Object.values(lessonsUsers)
);

export const getLessonsUser = createSelector(
  getLessonsUsers,
  getLessonId,
  getCurrentUserId,
  (lessonsUsers, lessonId, currentUserId) =>
    Object.values(lessonsUsers).filter(
      (lessonsUser) =>
        lessonsUser.lessonId === lessonId &&
        lessonsUser.userId === currentUserId
    )[0]
);

export const getIsWatchedByLessonId = createSelector(
  getLessonsUser,
  (lessonsUser) => lessonsUser?.watched
);

export const getProgressByLessonId = createSelector(
  getLessonsUser,
  (lessonsUser) => (lessonsUser?.watched ? 0 : lessonsUser?.progress)
);

export const getLessonsUsersFromLessons = createSelector(
  getAllLessonsUsers,
  getProps,
  (lessonsUsers, lessons) => {
    return lessonsUsers.filter(
      (lu) => lessons.map((l) => l.id).includes(lu.lessonId) && lu.watched
    );
  }
);
