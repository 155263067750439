import React from "react";
import { Modal } from "semantic-ui-react";
import { HiOutlineTrophy } from "react-icons/hi2";
import { Spinner, spacing } from "../library";
import { css } from "glamor";
import Leaderboard from "../library/leaderboard/Leaderboard";

const modalClass = css({
  ".ui.modal > .close": {
    top: "11px",
  },
  ".ui.modal > .content": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: spacing.xs,
  },
});
const spinnerClass = css({
  ":before": {
    borderColor: `black !important`,
  },
});
const headerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: spacing.xs,
};

export default function MockRankingModal(props) {
  const { loading, mock, mocks, handleClose } = props;

  return (
    <Modal
      open
      closeIcon
      onClose={handleClose}
      className={modalClass}
      size="small"
    >
      <Modal.Header style={headerStyle}>
        <HiOutlineTrophy size={20} />
        Ranking
      </Modal.Header>
      <Modal.Content>
        {loading ? (
          <Spinner className={spinnerClass} wrapped />
        ) : (
          <Leaderboard data={mocks} highlightedId={mock?.id} />
        )}
      </Modal.Content>
    </Modal>
  );
}
