import React from "react";
import ContentCommentListItemContainer from "./ContentCommentListItemContainer";
import Page from "../library/Page";
import Spinner from "../library/Spinner";
import { css } from "glamor";
import { Grid } from "semantic-ui-react";
import NotFound from "../notFound/NotFound";

const loading = css({
  display: "flex",
  justifyContent: "center",
  width: "100%",
});

export default function ContentComment({ comment, goBack }) {
  return (
    <Page goBack={goBack} title="Publicação">
      {comment ? (
        <Grid centered>
          <Grid.Column computer={12} mobile={16}>
            {comment.commentableType == "Content" ? (
              <ContentCommentListItemContainer comment={comment} />
            ) : (
              <NotFound />
            )}
          </Grid.Column>
        </Grid>
      ) : (
        <div className={loading}>
          <Spinner inline text="Carregando comentário..." />
        </div>
      )}
    </Page>
  );
}
