import React, { useMemo } from "react";
import { Table, Tab, Card, Statistic } from "semantic-ui-react";
import { colors, spacing, styles } from "../library/theme";
import { css } from "glamor";
import { useSelector } from "react-redux";
import { getUsersTopTenByProps } from "../../selectors/classroomSelectors";
import TopTenCard from "../library/TopTenCard";

const ClassroomTopTenCard = (props) => {
  const { classroomId, value, order } = props;

  const users = useSelector((state) =>
    getUsersTopTenByProps(state, { classroomId, value, order })
  );

  return <TopTenCard items={users} {...props} />;
};

const MOCK_COLOR = "#C35517";
const LESSON_COLOR = "#0A8032";
const ANSWERS_COUNT_COLOR = "#3405B9";

const tabPaneClass = css({
  padding: `${spacing.s} 0px ${spacing.l} 0px !important`,
  border: "none !important",
  boxShadow: "none !important",
  "> div > div": styles.alternativeFontFamily,
}).toString();
const topTenWrapper = css({
  marginTop: spacing.xl,
});
const topTenTitle = css({
  marginBottom: spacing.m,
});

const tabOptions = { color: "blue", secondary: true, pointing: true };

const getBigNumbers = (classroom) => [
  {
    header: "Total de alunos",
    description: (
      <Statistic size="small">
        <Statistic.Value>{classroom?.studentCount}</Statistic.Value>
      </Statistic>
    ),
  },
  {
    header: "Média das avaliações direcionadas",
    description: (
      <Statistic horizontal>
        <Statistic.Value>{classroom?.meta?.mocksAverage}%</Statistic.Value>
      </Statistic>
    ),
  },
  {
    header: "Aulas assistidas",
    description: (
      <Statistic size="small">
        <Statistic.Value>{classroom?.meta?.averageProgress}%</Statistic.Value>
      </Statistic>
    ),
  },
];

const getTabPanes = (classroomId) => [
  {
    menuItem: "Melhores",
    render: () => (
      <Card.Group itemsPerRow={3} stackable>
        <ClassroomTopTenCard
          title="Mais presentes"
          subTitle="Aulas"
          lastColumnTitle="Aulas assistidas"
          color={LESSON_COLOR}
          classroomId={classroomId}
          value="classroomProgress"
          valueSuffix="%"
          order="desc"
        />
        <ClassroomTopTenCard
          title="Maiores notas"
          subTitle="Avaliações Direcionadas"
          lastColumnTitle="Nota"
          color={MOCK_COLOR}
          classroomId={classroomId}
          value="mocksAverageScore"
          valueSuffix="%"
          order="desc"
        />
        <ClassroomTopTenCard
          title="Mais respostas"
          subTitle="Simulados Livres"
          lastColumnTitle="Quantidade"
          color={ANSWERS_COUNT_COLOR}
          classroomId={classroomId}
          value="simpleMocksAnswersCount"
          order="desc"
        />
      </Card.Group>
    ),
  },
  {
    menuItem: "Piores",
    render: () => (
      <Card.Group itemsPerRow={3} stackable>
        <ClassroomTopTenCard
          title="Menos presentes"
          subTitle="Aulas"
          lastColumnTitle="Aulas assistidas"
          color={LESSON_COLOR}
          classroomId={classroomId}
          value="classroomProgress"
          valueSuffix="%"
          order="asc"
        />
        <ClassroomTopTenCard
          title="Menores notas"
          subTitle="Avaliações Direcionadas"
          lastColumnTitle="Nota"
          color={MOCK_COLOR}
          classroomId={classroomId}
          value="mocksAverageScore"
          valueSuffix="%"
          order="asc"
        />
        <ClassroomTopTenCard
          title="Menos respostas"
          subTitle="Simulados Livres"
          lastColumnTitle="Quantidade"
          color={ANSWERS_COUNT_COLOR}
          classroomId={classroomId}
          value="simpleMocksAnswersCount"
          order="asc"
        />
      </Card.Group>
    ),
  },
];

export default function ClassroomDashboard({ classroom }) {
  const bigNumberCardItems = useMemo(
    () => getBigNumbers(classroom),
    [classroom]
  );
  const topTenPanes = useMemo(
    () => getTabPanes(classroom?.id),
    [classroom?.id]
  );

  return (
    <Tab.Pane attached={false} className={tabPaneClass}>
      <Card.Group itemsPerRow={3} stackable items={bigNumberCardItems} />
      <div className={topTenWrapper}>
        <h2 className={topTenTitle}>Top 10 da turma</h2>
        <Tab menu={tabOptions} panes={topTenPanes} />
      </div>
    </Tab.Pane>
  );
}
