import React from "react";
import CommentListItemContainer from "./CommentListItemContainer";

export default function CommentList({ comments }) {
  return comments.length ? (
    comments.map((comment, index) => (
      <CommentListItemContainer key={index} comment={comment} />
    ))
  ) : (
    <p>Seja o primeiro a comentar.</p>
  );
}
