import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";
import { courseReinstatement } from "../../../processes/courseProcesses";
import { createMock } from "../../../processes/mockProccess";
import { fetchExamMockTemplates } from "../../../processes/mockTemplateProcesses";
import {
  getAllCourses,
  getCourseIdsByMocksPresence,
  hasMockCourses,
} from "../../../selectors/courseSelectors";
import { getCourseUserByCourseId } from "../../../selectors/coursesUserSelectors";
import {
  getCourseByMockTemplateId,
  getMockTemplateById,
  getUserMockTemplates,
  questionsCountFromMockTemplate,
} from "../../../selectors/mockTemplateSelectors";
import { makeIsMobile } from "../../../utils/useScreenWidth";
import MockExamForm from "./MockExamForm";

const FORM_NAME = "mockExamForm";

function MockExamFormContainer(props) {
  const { dispatch, course, history } = props;
  const isMobile = makeIsMobile();
  const [loading, setLoading] = React.useState(false);

  const reinstatementHandler = React.useCallback(() => {
    courseReinstatement(dispatch, course?.id);
  }, [dispatch, course?.id]);

  useEffect(() => {
    setLoading(true);
    fetchExamMockTemplates(dispatch).then(() => {
      setLoading(false);
    });
  }, [dispatch]);

  return (
    <MockExamForm
      loading={loading}
      reinstatementHandler={reinstatementHandler}
      isMobile={isMobile}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const selector = formValueSelector(FORM_NAME);
  const mockTemplateId = selector(state, "mockTemplateId");
  const course = getCourseByMockTemplateId(state, mockTemplateId);

  return {
    initialValues: {
      exam: true,
    },
    course,
    courseUser: getCourseUserByCourseId(state, course?.id),
    mockTemplates: getUserMockTemplates(state),
    chosenMockTemplate: getMockTemplateById(state, mockTemplateId),
    examQuestionsCount: questionsCountFromMockTemplate(state, mockTemplateId),
    courseWithMockIds: getCourseIdsByMocksPresence(state),
    coursesReady: getAllCourses(state)?.length > 0,
    hasMockCourses: hasMockCourses(state),
    routePrefix: "/mobile",
  };
}

const validate = (values, { courseWithMockIds = [], course, courseUser }) => {
  const errors = {};

  if (courseWithMockIds?.includes(course?.id) || courseUser?.paused) {
    errors.mockTemplateId = "invalid";
  }

  return errors;
};

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    onSubmit: createMock,
    validate,
  })(MockExamFormContainer)
);
