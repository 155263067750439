import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchReports,
  selectedCourseIdChanged,
} from "../../processes/reportProcesses";
import {
  getCourseById,
  getUserCoursesForDropdownOptions,
} from "../../selectors/courseSelectors";
import {
  getReport,
  getSelectedCourseId,
} from "../../selectors/reportSelectors";

export default function withMyPerformanceData(WrappedComponent) {
  function WrappedComponentContainer(props) {
    const { dispatch, selectedCourseId, courses } = props;

    const handleCourseChange = useCallback(
      (_, e) => selectedCourseIdChanged(dispatch, e.value),
      [dispatch]
    );

    useEffect(() => {
      if (selectedCourseId) {
        fetchReports(dispatch, selectedCourseId);
      }
    }, [dispatch, selectedCourseId]);

    useEffect(() => {
      if (!!courses.length) {
        selectedCourseIdChanged(dispatch, courses[0]?.id);
      }
    }, [dispatch, courses, selectedCourseIdChanged]);

    return (
      <WrappedComponent handleCourseChange={handleCourseChange} {...props} />
    );
  }

  function mapStateToProps(state) {
    const selectedCourseId = getSelectedCourseId(state);
    const course = getCourseById(state, selectedCourseId);

    return {
      coursesLoading: state.coursesList.loading,
      reportLoading: state.report.loading,
      courses: getUserCoursesForDropdownOptions(state),
      course,
      report: getReport(state) || {},
      selectedCourseId,
    };
  }

  return connect(mapStateToProps)(WrappedComponentContainer);
}
