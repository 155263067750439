import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

const creditCardToken = new schema.Entity("creditCardTokens");

export function fetchCreditCardTokens(dispatch) {
  return apiGet("/api/v1/credit_card_tokens").then((response) => {
    dispatch({
      type: "CREDIT_CARD_TOKENS_FETCHED",
      ...normalize(response.body.data, [creditCardToken]),
    });
  });
}
