export function orderByFirstName(a, b) {
  if (a.firstName === b.firstName) {
    return 0;
  }
  return a.firstName.localeCompare(b.firstName);
}

export function orderByName(a, b) {
  if (a.name === b.name) {
    return 0;
  }
  return a.name.localeCompare(b.name);
}

export function orderByHighlight(a, b) {
  return b.highlight - a.highlight;
}
