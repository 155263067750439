import { createSelector } from "reselect";
import { getLessonId } from "./lessonSelectors";

const getLessonFiles = (state) => state.entities.lessonFiles;

export const getFilesFromLesson = createSelector(
  getLessonId,
  getLessonFiles,
  (lessonId, files) =>
    Object.values(files).filter((file) => file.lessonId === lessonId)
);

export const makeHasFiles = createSelector(
  getLessonId,
  getLessonFiles,
  (lessonId, files) =>
    !!Object.values(files).filter((file) => file.lessonId === lessonId).length
);
