const PIX_METHOD = "PIX";
const COUPON_TYPE_PERCENTAGE = "percent";

export function calcValue(wasPreviousStudent, offer, coupon, billingType) {
  const price = calcPrice(wasPreviousStudent, offer, billingType);
  const discount = couponDiscount(coupon, price);

  return coupon ? price - discount : price;
}

export function calcPrice(wasPreviousStudent, offer, billingType) {
  const resellDiscountEnabled = offer?.hasStudentDiscount && wasPreviousStudent;
  const price = resellDiscountEnabled ? offer?.resellPrice : offer?.price;
  const pixDiscountPercentage = offer?.pixDiscount / 100;
  const pixDiscountValue = price * pixDiscountPercentage;
  const finalPrice =
    billingType == PIX_METHOD ? price - pixDiscountValue : price;

  return parseFloat(finalPrice);
}

export function couponDiscount(coupon, price) {
  if (!coupon) return 0;

  if (coupon.couponType == COUPON_TYPE_PERCENTAGE) {
    const percent = coupon.value / 100;
    return parseFloat(price) * percent;
  }

  return parseFloat(coupon.value);
}

export function installmentInterestValue(
  wasPreviousStudent,
  offer,
  coupon,
  installmentCount,
  billingType
) {
  if (
    installmentCount &&
    offer?.interestStartsAt &&
    installmentCount >= offer?.interestStartsAt
  ) {
    const valueWithInterst = paymentValueWithInterest(
      wasPreviousStudent,
      offer,
      coupon,
      installmentCount,
      billingType
    );
    const value = calcValue(wasPreviousStudent, offer, coupon, billingType);

    return valueWithInterst - value;
  }

  return 0;
}

export function paymentValueWithInterest(
  wasPreviousStudent,
  offer,
  coupon,
  installmentCount,
  billingType
) {
  const i = parseFloat(offer.installmentInterest) / 100;
  const pv = calcValue(wasPreviousStudent, offer, coupon, billingType);
  const n = installmentCount;

  return pv * (((1 + i) ** n * i) / ((1 + i) ** n - 1)) * n; // Total PMT formula
}

export default function calcPaymentValue(
  wasPreviousStudent,
  offer,
  coupon,
  installmentCount,
  billingType
) {
  const value = calcValue(wasPreviousStudent, offer, coupon, billingType);
  const interest = installmentInterestValue(
    wasPreviousStudent,
    offer,
    coupon,
    installmentCount,
    billingType
  );

  return value + interest;
}
