import { normalize, schema } from "normalizr";
import { SubmissionError } from "redux-form";
import { apiPost } from "./helpers/api";
import logEvent from "../utils/logEvent";

const ADD = "add";
const CREDIT_CARD = "CREDIT_CARD";
const BOLETO = "BOLETO";
const PIX = "PIX";
const EXPIRY_MONTH_FIELD_NAME = "expiryMonth";
const EXPIRY_YEAR_FIELD_NAME = "expiryYear";

const onlyNumbers = (num) => {
  if (!num) return undefined;
  return num?.replace(/[^0-9]+/g, "");
};

const payment = new schema.Entity("payments");

export const createPayment = (values, dispatch, props) => {
  const { offer, billingType } = props;
  const courseOfferId = offer?.id;

  if (!courseOfferId) return;

  const { goodThru, creditCard, creditCardStep, customerData, ...attrs } =
    values;

  if (billingType == CREDIT_CARD) {
    const [expiryMonth, expiryYear] = goodThru.split("/");
    creditCard[EXPIRY_MONTH_FIELD_NAME] = expiryMonth;
    creditCard[EXPIRY_YEAR_FIELD_NAME] = `20${expiryYear}`;
  }

  return apiPost(`/api/v1/payments`)
    .send({
      ...attrs,
      billingType,
      customerData,
      creditCard,
      courseOfferId,
    })
    .then((response) => {
      const { data } = response.body;

      logEvent("purchase", {
        transactionId: data.transactionCode,
        value: data.value,
        couponCode: data.couponCode,
        discount: data.discount,
        offerId: data.courseOfferId,
        offerName: data.courseOfferName,
        offerPrice: data.courseOfferPrice,
      });

      dispatch({
        type: "PAYMENT_FETCHED",
        ...normalize(data, [payment]),
      });

      if (billingType == BOLETO) {
        window.open(data.externalContent, "_blank");
      }

      if (billingType == BOLETO || billingType == PIX) {
        dispatch({
          type: "SAVE_EXTERNAL_CONTENT",
          externalContent: data.externalContent,
          externalContentDetails: data.externalContentDetails,
          bankslipBarcodeUrl: data.bankslipBarcodeUrl,
        });
      }
    })
    .catch((err) => {
      throw new SubmissionError({ _error: err.response.body.errors });
    });
};
