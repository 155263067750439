import React, { useCallback } from "react";
import { Form, Grid } from "semantic-ui-react";
import { TextField } from "../library/forms";

import { css } from "glamor";
import { colors, spacing } from "../library";
import ProfileEditPage from "./ProfileEditPage";
import SubmitButton from "./SubmitButton";

const container = css({
  padding: `${spacing.l} !important`,
  background: colors.white,
  borderRadius: spacing.s,
  marginBottom: `${spacing.m} !important`,
});

export default function ProfileEditChangePasswordForm(props) {
  const { handleSubmit, error } = props;
  const skipActionHandler = useCallback((e) => {
    e.preventDefault();
  }, []);

  return (
    <ProfileEditPage
      currentUser={props?.currentUser}
      shouldBlockNavigation={props?.dirty}
    >
      <Form onSubmit={handleSubmit} error={error}>
        <div className={container}>
          <Grid>
            <Grid.Column computer={8} mobile={16}>
              <TextField
                name="currentPassword"
                type="password"
                icon="lock"
                iconPosition="left"
                label="Senha Atual"
                hint="É necessário digitar a senha atual para confirmar."
                placeholder="*******"
                fluid
                required
              />
            </Grid.Column>
            <Grid.Column computer={8} only="computer tablet"></Grid.Column>
            <Grid.Column computer={8} mobile={16}>
              <TextField
                name="password"
                type="password"
                icon="lock"
                iconPosition="left"
                label="Nova senha"
                placeholder="*******"
                fluid
                required
              />
            </Grid.Column>

            <Grid.Column computer={8} mobile={16}>
              <TextField
                name="passwordConfirmation"
                type="password"
                icon="lock"
                onPaste={skipActionHandler}
                onDrop={skipActionHandler}
                iconPosition="left"
                label="Repita a nova senha"
                placeholder="*******"
                fluid
                required
              />
            </Grid.Column>
          </Grid>
        </div>

        <SubmitButton {...props} />
      </Form>
    </ProfileEditPage>
  );
}
