import React, { useCallback } from "react";
import { Field } from "redux-form";
import FileInput from "./FileInput";
import memoize from "fast-memoize";
import buildFormField from "./buildFormField";
const FILE_MIME_TYPE = new RegExp(
  "^image/.*$|.pdf|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
);

// memoize doesn't work with functions, so the first param is the field name
const toValue = (delegate, files) => {
  if (files && files.length > 0 && files[0].type.match(FILE_MIME_TYPE)) {
    delegate(files[0]);
  }
};

const buildValidator = memoize((required, other) => {
  return [(value) => (required && !value ? "required" : undefined)].concat(
    other || []
  );
});

const FileFieldComponent = buildFormField(FileInput, (input, meta, rest) => ({
  ...input,
  onChange: useCallback(
    (files) => toValue(input.onChange, files),
    [input.onChange]
  ),
  onBlur: useCallback((files) => toValue(input.onBlur, files), [input.onBlur]),
  onFileChange: useCallback(
    (files) => toValue(input.onChange, files),
    [input.onChange]
  ),
  clear: useCallback(() => input.onChange(""), [input]),
  invalid: meta.touched && meta.invalid,
  ...rest,
}));

export default function FileField(props) {
  return (
    <Field
      {...props}
      type="file"
      component={FileFieldComponent}
      validate={buildValidator(props.required, props.validate)}
    />
  );
}
