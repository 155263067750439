import React from "react";
import { connect } from "react-redux";
import { currentCourse } from "../../selectors/courseSelectors";
import { courseReinstatement } from "../../processes/courseProcesses";
import CourseUnlock from "./CourseUnlock";
import { matchPath } from "react-router-dom";
import { getCurrentCourseUser } from "../../selectors/coursesUserSelectors";

function CourseUnlockContainer(props) {
  const { dispatch, course, isCoursePath, courseUser } = props;
  const [loading, setLoading] = React.useState(false);

  const reinstatementHandler = React.useCallback(() => {
    setLoading(true);
    courseReinstatement(dispatch, course?.id).finally(() => {
      setLoading(false);
    });
  }, [course?.id, dispatch]);

  return isCoursePath && courseUser?.paused ? (
    <CourseUnlock
      loading={loading}
      reinstatementHandler={reinstatementHandler}
      {...props}
    />
  ) : null;
}

function mapStateToProps(state, ownProps) {
  const course = currentCourse(state);
  const courseUser = getCurrentCourseUser(state);
  const isCoursePath = !!matchPath(state.router.location.pathname, {
    path: `/courses/${course?.id}`,
    exact: true,
  });

  return {
    isCoursePath,
    courseUser,
    course,
  };
}

export default connect(mapStateToProps)(CourseUnlockContainer);
