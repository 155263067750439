import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import SubLevelListItem from "./SubLevelListItem";
import {
  getCurrentLevel,
  getLessonsFromLevel,
} from "../../selectors/lessonSelectors";

function SubLevelListItemContainer(props) {
  const { currentLevel, level } = props;

  const isActive = currentLevel?.id === level.id;

  const [accordionActive, setAccordionActive] = useState(isActive);

  const handleAccordionClick = useCallback(
    () => setAccordionActive(!accordionActive),
    [accordionActive]
  );

  return props.lessons.length ? (
    <SubLevelListItem
      handleAccordionClick={handleAccordionClick}
      accordionActive={accordionActive}
      {...props}
    />
  ) : null;
}

function mapStateToProps(state, ownProps) {
  return {
    lessons: getLessonsFromLevel(state, ownProps),
    currentLevel: getCurrentLevel(state),
  };
}

export default connect(mapStateToProps)(SubLevelListItemContainer);
