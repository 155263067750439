import { createSelector } from "reselect";
import { statusString } from "../utils/statusString";
import { getCurrentUser } from "./userSelectors";

const getContents = (state) => state.entities.contents;
export const getContentId = (_, id) => id;
export const getContentStatus = (state) => state.content.contentState;
export const getContentCategories = (state) => state.content.categories;

export const getAllContents = createSelector(getContents, (content) =>
  Object.values(content)
);

export const getContentById = createSelector(
  getContents,
  getContentId,
  (contents, id) => contents[id]
);

export const canAccessContent = createSelector(
  getContentStatus,
  getAllContents,
  getCurrentUser,
  (contentStatus, contents, currentUser) => {
    if (contentStatus === statusString.loading || !currentUser) {
      return statusString.loading;
    }
    if (contents?.length > 0) {
      return statusString.allow;
    }
    return statusString.deny;
  }
);

export const getHighlightedCategoryComments = createSelector(
  getContentCategories,
  (categories) => Object.values(categories)
);

export const getHighlightedCategoryCommentsLoading = createSelector(
  getHighlightedCategoryComments,
  (categories) => {
    return (
      categories.length > 0 &&
      categories?.some((category) => category?.status === statusString.loading)
    );
  }
);
