import { connect } from "react-redux";
import buildForm from "../library/forms/buildForm";
import CertificateUserForm from "./CertificateUserForm";
import { fetchCertificateUser } from "../../processes/certificateUserProcesses";

const form = buildForm("certficateValidationForm");

function mapStateToProps(state) {
  return {
    initialValues: {},
    loading: state.certificateUser.loading,
    certificateData: state.certificateUser,
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: fetchCertificateUser,
  })(CertificateUserForm)
);
