import { normalize, schema } from "normalizr";
import { apiGet } from "./helpers/api";

const invoices = new schema.Object("invoices");

export const fetchInvoices = (dispatch) => {
  dispatch({ type: "INVOICES_FETCH_REQUESTED" });
  return apiGet(`/api/v1/enotas`).then((response) => {
    dispatch({
      type: "INVOICES_FETCHED",
      ...normalize(response.body, invoices),
    });
  });
};
