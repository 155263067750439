import React from "react";
import { Grid, Divider, Pagination } from "semantic-ui-react";
import ContentCommentFormContainer from "./ContentCommentFormContainer";
import { spacing } from "../library/theme";
import ContentCommentList from "./ContentCommentList";
import Spinner from "../library/Spinner";
import ContentCategoryFormContainer from "./ContentCategoryFormContainer";
import { useScreenWidth } from "../../utils/useScreenWidth";
import ContentPage from "./ContentPage";

const container = {
  maxWidth: 1600,
};
const dividerStyle = {
  margin: `${spacing.l} 0`,
};
const loadingStyle = {
  display: "flex",
  justifyContent: "center",
};
const paginationStyle = {
  marginTop: spacing.m,
};

export default function ContentFeed({
  content,
  comments,
  currentPage,
  fetchCommentsHandler,
  totalPages,
  loading,
  initialCategoryId,
  path,
  goBack,
}) {
  const screenWidth = useScreenWidth();

  return (
    <ContentPage
      content={content}
      path={path}
      className={container}
      goBack={goBack}
    >
      <Grid centered>
        <Grid.Column computer={12} tablet={12} mobile={16}>
          <ContentCommentFormContainer contentId={content?.id} />
          <Divider style={dividerStyle} />
          {screenWidth < 754 && (
            <ContentCategoryFormContainer
              formName={"contentCategoryFormMobile"}
              contentId={content.id}
              loading={loading}
              initialCategoryId={initialCategoryId}
            />
          )}
          {loading ? (
            <Spinner text="Carregando posts" style={loadingStyle} inline />
          ) : (
            <ContentCommentList comments={comments} />
          )}
          {totalPages > 1 && (
            <Pagination
              style={paginationStyle}
              activePage={currentPage}
              onPageChange={fetchCommentsHandler}
              totalPages={totalPages}
            />
          )}
        </Grid.Column>
        <Grid.Column width={4} only="computer tablet">
          <ContentCategoryFormContainer
            formName={"contentCategoryForm"}
            contentId={content?.id}
            loading={loading}
            initialCategoryId={initialCategoryId}
          />
        </Grid.Column>
      </Grid>
    </ContentPage>
  );
}
