import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";
import toQueryString from "../utils/toQueryString";

const question = new schema.Entity("questions");

export const fetchQuestions = (dispatch, mockId) => {
  apiGet(`/api/v1/mocks/${mockId}/questions`).then((response) => {
    dispatch({
      type: "QUESTIONS_FETCHED",
      ...normalize(response.body.data, new schema.Array(question)),
    });
  });
};

export const goToIndex = (dispatch, index) =>
  dispatch({ type: "CURRENT_INDEX_CHANGED", index });

export const submitSearch = (values, dispatch) => {
  const { search } = values;
  const filters = Number.isNaN(+search)
    ? { sanitizedTitleUnaccent: search }
    : { idEq: search };
  const searchParams = toQueryString(filters);

  if (!search) {
    return;
  }

  dispatch({ type: "QUESTIONS_SEARCH_FETCH_REQUESTED" });
  apiGet(`/api/v1/questions?${searchParams}page=1`).then((response) => {
    dispatch({
      type: "QUESTIONS_SEARCH_FETCHED",
      ...normalize(response.body.data, new schema.Array(question)),
    });
  });
};
