import React from "react";
import { useSelector } from "react-redux";
import { Tab, Card, Statistic } from "semantic-ui-react";
import Link from "../library/Link";
import Button from "../library/Button";
import { spacing } from "../library/theme";
import { css } from "glamor";
import TopTenCard from "../library/TopTenCard";
import {
  getTotalSudentsByCompanyId,
  getAverageProgressByCompanyId,
  getUsersTopTenByProps,
} from "../../selectors/classroomSelectors";
import CompanyTopTenCardContainer from "./CompanyTopTenCardContainer";

const MOCK_COLOR = "#C35517";
const LESSON_COLOR = "#0A8032";
const ANSWERS_COUNT_COLOR = "#3405B9";

const topTenWrapper = css({
  marginTop: spacing.xl,
});
const topTenTitle = css({
  marginBottom: spacing.m,
});

export default function CompanyDashboard({
  company,
  totalStudents,
  mocksAverage,
  averageProgress,
}) {
  return (
    <div>
      <Card.Group
        itemsPerRow={3}
        stackable
        items={[
          {
            header: "Total de alunos",
            description: (
              <Statistic size="small">
                <Statistic.Value>{totalStudents || "--"}</Statistic.Value>
              </Statistic>
            ),
          },
          {
            header: "Média das avaliações direcionadas",
            description: (
              <Statistic horizontal>
                <Statistic.Value>
                  {mocksAverage ? `${mocksAverage}%` : "--"}
                </Statistic.Value>
              </Statistic>
            ),
          },
          {
            header: "Aulas assistidas",
            description: (
              <Statistic size="small">
                <Statistic.Value>
                  {averageProgress ? `${averageProgress}%` : "--"}
                </Statistic.Value>
              </Statistic>
            ),
          },
        ]}
      />
      <div className={topTenWrapper}>
        <h2 className={topTenTitle}>Top 10</h2>
        <Tab
          menu={{ color: "blue", secondary: true, pointing: true }}
          panes={[
            {
              menuItem: "Melhores",
              render: () => (
                <Card.Group itemsPerRow={3} stackable>
                  <CompanyTopTenCardContainer
                    title="Mais presentes"
                    subTitle="Aulas"
                    lastColumnTitle="Aulas assistidas"
                    color={LESSON_COLOR}
                    companyId={company?.id}
                    value="classroomProgress"
                    valueSuffix="%"
                    order="desc"
                  />
                  <CompanyTopTenCardContainer
                    title="Maiores notas"
                    subTitle="Avaliações Direcionadas"
                    lastColumnTitle="Nota"
                    color={MOCK_COLOR}
                    companyId={company?.id}
                    value="mocksAverageScore"
                    valueSuffix="%"
                    order="desc"
                  />
                  <CompanyTopTenCardContainer
                    title="Mais respostas"
                    subTitle="Simulados Livres"
                    lastColumnTitle="Quantidade"
                    color={ANSWERS_COUNT_COLOR}
                    companyId={company?.id}
                    value="simpleMocksAnswersCount"
                    order="desc"
                  />
                </Card.Group>
              ),
            },
            {
              menuItem: "Piores",
              render: () => (
                <Card.Group itemsPerRow={3} stackable>
                  <CompanyTopTenCardContainer
                    title="Menos presentes"
                    subTitle="Aulas"
                    lastColumnTitle="Aulas assistidas"
                    color={LESSON_COLOR}
                    companyId={company?.id}
                    value="classroomProgress"
                    valueSuffix="%"
                    order="asc"
                  />
                  <CompanyTopTenCardContainer
                    title="Menores notas"
                    subTitle="Avaliações Direcionadas"
                    lastColumnTitle="Nota"
                    color={MOCK_COLOR}
                    companyId={company?.id}
                    value="mocksAverageScore"
                    valueSuffix="%"
                    order="asc"
                  />
                  <CompanyTopTenCardContainer
                    title="Menos respostas"
                    subTitle="Simulados Livres"
                    lastColumnTitle="Quantidade"
                    color={ANSWERS_COUNT_COLOR}
                    companyId={company?.id}
                    value="simpleMocksAnswersCount"
                    order="asc"
                  />
                </Card.Group>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
}
