import React from "react";
import { Checkbox } from "semantic-ui-react";
import { spacing, typographyColors } from "../theme";
import { css } from "glamor";

const container = {
  marginBottom: spacing.m,
};
const hintStyle = {
  display: "inline",
  color: typographyColors.defaultBody,
  fontSize: 16,
  marginLeft: spacing.xxs,
};
const labelStyle = {
  fontSize: 16,
};

export default ({ item, onChange, fields, disabled }) => {
  return (
    <div style={container}>
      <Checkbox
        id={`${item.name}-${item.id}`}
        name={String(item.id)}
        label={
          item.hint ? (
            <label style={labelStyle}>
              {item.name || item.title}
              <div style={hintStyle}>{item.hint}</div>
            </label>
          ) : (
            `${item.name || item.title}`
          )
        }
        onChange={onChange}
        checked={(fields?.getAll() || []).indexOf(item.id) > -1}
        disabled={disabled}
      />
    </div>
  );
};
