import { normalize, schema } from "normalizr";
import { SubmissionError } from "redux-form";
import { put } from "superagent";
import { apiPut, baseUrl } from "./helpers/api";
import middleware from "./helpers/railsMiddleware";
import { notifySubmitError, notifySubmitSucceeded } from "./notifierProcesses";

export const user = new schema.Entity("users");

const isFile = (obj) => obj && !!obj.lastModified;

export const updatedUser = (dispatch, response) => {
  dispatch({
    type: "USER_UPDATED",
    ...normalize(response.body.data, user),
  });
};

const updateUserImage = (image, type, dispatch) => {
  const req = put(`${baseUrl}/api/v1/auth`).use(middleware);
  req.attach(type, image);
  return req
    .then((response) => {
      updatedUser(dispatch, response);
    })
    .catch((err) => {
      throw new SubmissionError({ _error: err.response?.body?.errors });
    });
};

export const updateProfile = async (
  { avatarUrl, coverUrl, ...attrs },
  dispatch,
  props
) => {
  if (isFile(avatarUrl)) {
    await updateUserImage(avatarUrl, "avatar", dispatch);
  }

  return apiPut("/api/v1/auth")
    .send(attrs)
    .then((response) => {
      updatedUser(dispatch, response);
      notifySubmitSucceeded(
        dispatch,
        "Uhuu, Seu perfil foi atualizado com sucesso!"
      );
    })
    .catch((err) => {
      notifySubmitError(dispatch, {
        message: "Dados de senha inválidos.",
      });
      throw new SubmissionError({
        _error: err.response.body.errors.full_messages[0],
      });
    });
};
