import { css } from "glamor";
import React, { forwardRef } from "react";
import { Modal } from "semantic-ui-react";
import { Button, Link, colors, spacing, styles } from "../library";
import VimeoPlayer from "../library/VimeoPlayer";

const container = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  width: "100%",
  paddingBottom: "32.6%",
  "@media(max-width: 575px)": {
    paddingBottom: "68%",
  },
});
const bannerBaseClass = css({
  zIndex: 2,
  cursor: "pointer",
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: "100%",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  color: "red",
});
const htmlClass = css({
  ...styles.wrappingText,
  color: colors.greyShadow,
  whiteSpace: "pre-line",
  paddingTop: spacing.m,
  "& + &": {
    marginTop: spacing.m,
  },
});
const modalActionsStyle = {
  display: "flex",
  justifyContent: "center",
};

const Banner = forwardRef(
  ({ banner, isLightbox, openLightbox, toggleLightbox, linkTarget }, ref) => {
    const hasCTA = !!banner?.lightboxActionUrl;
    const hasContent = banner?.url || banner?.lightboxDescription;
    const BannerComponent = isLightbox ? "div" : "a";

    const containerClass = css(container, {
      backgroundColor: banner.backgroundColor || colors.brandDark,
    });

    const bannerClass = css(bannerBaseClass, {
      backgroundImage: `url(${banner.imageUrl})`,
      "@media(max-width: 575px)": {
        backgroundImage: `url(${
          banner.imageMobileUrl ? banner.imageMobileUrl : banner.imageUrl
        })`,
      },
    });

    return (
      <BannerComponent className={containerClass} ref={ref}>
        <div className={bannerClass} />
        {isLightbox && hasContent && (
          <Modal open={openLightbox} onClose={toggleLightbox} closeIcon>
            {banner?.lightboxTitle && (
              <Modal.Header>{banner?.lightboxTitle}</Modal.Header>
            )}
            <Modal.Content>
              {banner?.url && <VimeoPlayer url={banner?.url} skipPreferences />}
              {banner?.lightboxDescription && (
                <Modal.Description>
                  <div
                    className={htmlClass}
                    dangerouslySetInnerHTML={{
                      __html: banner.lightboxDescription,
                    }}
                  ></div>
                </Modal.Description>
              )}
            </Modal.Content>
            {hasCTA && (
              <Modal.Actions style={modalActionsStyle}>
                <Link
                  href={banner?.lightboxActionUrl}
                  target={linkTarget}
                  component={Button}
                  secondary
                  size="big"
                >
                  {banner?.lightboxActionTitle || "Clique Aqui!"}
                </Link>
              </Modal.Actions>
            )}
          </Modal>
        )}
      </BannerComponent>
    );
  }
);

export default Banner;
